import axios from "axios";
import { config } from "config";
import { IAppointment } from "interfaces";
import { IPractitionerResponseItem } from "interfaces/appointment/IPractitionerResponseItem";

class PublicApiService {
  constructor (
    protected domainPath: string
  ) {}

  /**
   * 
   * @returns array of appointments
   */
  async getAppointments (filters?: {insuranceCompanyId?: number, practitionerId?:number, appointmentStart?: number}): Promise<IAppointment[]> {
    const url = `${this.domainPath}/appointments`;
    try {
      const response = await axios.post(url, {filters} );
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as IAppointment[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async getPhysicians (filters?: {practitionerId?: number}): Promise<IPractitionerResponseItem[]> {
    const url = `${this.domainPath}/practitioners`;
    try {
      const response = await axios.post(url, {filters});
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as IPractitionerResponseItem[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async checkActivationCode (activationCode: string): Promise<any> {
    const url = `${this.domainPath}/product/code`;
    try {
      const response = await axios.post(url, {code: activationCode} );
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async getActivationCodeFromHash (codeHash: string): Promise<{statusId: number, code: string}> {
    const url = `${this.domainPath}/product/code-hash`;
    try {
      const response = await axios.post(url, {codeHash} );
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async logUserEvent (data: Object, uniqueUserId: string): Promise<any> {
    const url = `${this.domainPath}/log-event?fg=${uniqueUserId}`;
    try {
      const response = await axios.post(url, data );
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (error: any) {
      throw Error(error.message);
    }    
  }
}

const publicApiService = new PublicApiService(`${config.apiDomain}${config.apiBasePath}/user`);

export { publicApiService }