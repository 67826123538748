import React, { useState } from "react";
import { PricingDetail } from "../PricingDetail";

const Pricing:React.FunctionComponent<{showByDefault?: boolean}>= ({showByDefault = false})  => {

  const [showPricing, setShowPricing] = useState<boolean>(showByDefault);

  return (
    <>
      <div className="mt-5 text-center pricing py-5 bg-big-white-circles">
        <div className="">
          <div className="medium-text-2 dark-blue strong">No insurance? We've got you covered</div>
          <div className="medium-text-1 dark-blue">We are affordable and accept credit card payments.</div>
        </div>
        {showPricing && <PricingDetail />}

        {!showPricing && 
        <div role="button" className="mt-5 orange medium-text-1 strong" onClick={()=>{setShowPricing(true)}}>See our self-pay pricelist</div>
        }
      </div>
    </>
  );
}

export { Pricing }