enum ToggleStateEnum {
  OPEN = 1,
  AVAILABLE = 2,
  UNAVAILABLE = 3,
  FILLED = 4,
}


const toggleStateEnumNames: Map<ToggleStateEnum, {className: string, name: string}> = new Map<ToggleStateEnum, {className: string, name: string}>([
  [ToggleStateEnum.OPEN, {className: "open", name: "Open Slot"}],
  [ToggleStateEnum.AVAILABLE, {className: "available", name: "Available Slot"}],
  [ToggleStateEnum.UNAVAILABLE, {className: "unavailable", name: "Unavailable Slot"}],
  [ToggleStateEnum.FILLED, {className: "filled", name: "Filled Slot"}],
]);

export { ToggleStateEnum, toggleStateEnumNames }