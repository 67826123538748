import React, { useEffect, useState } from 'react';
import { adminStorageService } from 'services';

const AdminDownload: React.FunctionComponent = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [downloadUrl, setDownloadUrl] = useState<string>('');

  useEffect(()=> {
    const _init = async () => {
      const path = queryParams.get('path');
      if (path) {
        const url =  await adminStorageService.downloadFile(path);
        setDownloadUrl(url);
      }
    }
    _init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      Click <a download="label.pdf" href={downloadUrl}>Here</a> to download the labels.
    </div>
  );
}

export { AdminDownload }