import React from 'react';

import { practitionerRolesName } from '_constants/PractitionerRolesEnum';
import { IAccount } from 'interfaces';

const AccessDenied: React.FunctionComponent<{ account?: IAccount, loginUrl?: string }> = ({ account, loginUrl='/login' }) => {
  return <div className="container">
    <div className="row justify-content-center">
      <div className="col-6 alert alert-danger text-center">
        <div className="fw-bold">Access Denied</div>

        <div className="my-5">
          {account ? <>
            <div className="fw-bold">Insufficient Access</div>
            <div>Logged in as: {account.email}</div>
            <div>Role: {practitionerRolesName.get(account.roleId || 0)}</div>
          </> : <>
            <div>You are not logged in.</div>
            <div><a href={loginUrl}>Log in</a></div>
          </>}
        </div>
      </div>
    </div>
  </div>
}

export { AccessDenied }