import React from 'react';

const PrivacyPolicy = () => {
  return(
<div className="privacy-policy container mt-5 mb-3 bg-lighterblue p-5">
<h1 className="medium-text-1 text-center my-3 fw-bold">Privacy Policy</h1>
<p>
This notice describes how medical information about you may be used and disclosed and how you can get access to this information. <span className="strong">Please review it carefully.</span>
</p>




<p>
<div className="strong">Your Rights</div>

You have the right to:
<ul>
<li>Get a copy of your paper or electronic medical record</li>
<li>Correct your paper or electronic medical record</li>
<li>Request confidential communication</li>
<li>Ask us to limit the information we share</li>
<li>Get a list of those with whom we've shared your information</li>
<li>Get a copy of this privacy notice</li>
<li>Choose someone to act for you</li>
<li>File a complaint if you believe your privacy rights have been violated</li>
</ul>
</p>

<p>
<div className="strong">Your Choices</div>
You have some choices in the way that we use and share information as we:
<ul>
<li>Tell family and friends about your condition</li>
<li>Provide disaster relief</li>
<li>Include you in a hospital directory</li>
<li>Provide mental health care</li>
<li>Market our services and sell your information</li>
<li>Raise funds</li>
</ul>
</p>

<p>
<div className="strong">Our Uses and Disclosures</div>
We may use and share your information as we:
<ul>
<li>Treat you</li>
<li>Run our organization</li>
<li>Bill for your services</li>
<li>Help with public health and safety issues</li>
<li>Do research</li>
<li>Comply with the law</li>
<li>Respond to organ and tissue donation requests</li>
<li>Work with a medical examiner or funeral director</li>
<li>Address workers' compensation, law enforcement, and other government requests</li>
<li>Respond to lawsuits and legal actions</li>
</ul>
</p>

<p>
<div className="strong">Your Rights</div>
When it comes to your health information, you have certain rights. This section explains your rights and some of our responsibilities to help you.
</p>

<p>
<div className="strong">Get an electronic or paper copy of your medical record</div>
<ul>
<li>You can ask to see or get an electronic or paper copy of your medical record and other health information we have about you. Ask us how to do this.</li>
<li>We will provide a copy or a summary of your health information, usually within 30 days of your request. We may charge a reasonable, cost-based fee.</li>
</ul>
</p>

<p>
<div className="strong">Ask us to correct your medical record</div>
<ul>
<li>You can ask us to correct health information about you that you think is incorrect or incomplete. Ask us how to do this.</li>
<li>We may say "no" to your request, but we'll tell you why in writing within 60 days.</li>
</ul>
</p>

<p>
<div className="strong">Request confidential communications</div>
<ul>
<li>You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different address.</li>
<li>We will say "yes" to all reasonable requests.</li>
</ul>
</p>

<p>
<div className="strong">Ask us to limit what we use or share</div>
<ul>
<li>You can ask us not to use or share certain health information for treatment, payment, or our operations. We are not required to agree to your request, and we may say "no" if it would affect your care.</li>
<li>If you pay for a service or health care item out-of-pocket in full, you can ask us not to share that information for the purpose of payment or our operations with your health insurer. We will say "yes" unless a law requires us to share that information.</li>
</ul>
</p>

<p>
<div className="strong">Get a list of those with whom we've shared information</div>
<ul>
<li>You can ask for a list (accounting) of the times we've shared your health information for six years prior to the date you ask, who we shared it with, and why.</li>
<li>We will include all the disclosures except for those about treatment, payment, and health care operations, and certain other disclosures (such as any you asked us to make). We'll provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within 12 months.</li>
</ul>
</p>

<p>
<div className="strong">Get a copy of this privacy notice</div>
You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically. We will provide you with a paper copy promptly.
</p>

<p>
<div className="strong">Choose someone to act for you</div>
<ul>
<li>If you have given someone medical power of attorney or if someone is your legal guardian, that person can exercise your rights and make choices about your health information.</li>
<li>We will make sure the person has this authority and can act for you before we take any action.</li>
</ul>
</p>

<p>
<div className="strong">File a complaint if you feel your rights are violated</div>
<ul>
<li>You can complain if you feel we have violated your rights by contacting us using the information on page 1.</li>
<li>You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696-6775, or visiting <a href="https://www.hhs.gov/ocr/privacy/hipaa/complaints/" rel="noreferrer" target="_blank">www.hhs.gov/ocr/privacy/hipaa/complaints/</a>.</li>
<li>We will not retaliate against you for filing a complaint.</li>
</ul>
</p>

<p>
<div className="strong">Your Choices</div>
For certain health information, you can tell us your choices about what we share. If you have a clear preference for how we share your information in the situations described below, talk to us. Tell us what you want us to do, and we will follow your instructions.
In these cases, you have both the right and choice to tell us to:
<ul>
<li>Share information with your family, close friends, or others involved in your care</li>
<li>Share information in a disaster relief situation</li>
<li>Include your information in a hospital directory</li>
</ul>
</p>

<p className="fst-italic">
If you are not able to tell us your preference, for example if you are unconscious, we may go ahead and share your information if we believe it is in your best interest. We may also share your information when needed to lessen a serious and imminent threat to health or safety.
</p>

<p>
In these cases we never share your information unless you give us written permission:
<ul>
<li>Marketing purposes</li>
<li>Sale of your information</li>
<li>Most sharing of psychotherapy notes</li>
</ul>
</p>

<p>
In the case of fundraising:
<ul>
<li>We may contact you for fundraising efforts, but you can tell us not to contact you again.</li>
</ul>
</p>

<p>
<div className="strong">Our Uses and Disclosures</div>
How do we typically use or share your health information?
We typically use or share your health information in the following ways.
</p>

<p>
<div className="strong">Treat you</div>
We can use your health information and share it with other professionals who are treating you.
<div className="fst-italic">Example: A doctor treating you for an injury asks another doctor about your overall health condition.</div>
</p>

<p>
<div className="strong">Run our organization</div>
We can use and share your health information to run our practice, improve your care, and contact you when necessary.
<div className="fst-italic">Example: We use health information about you to manage your treatment and services.</div>
</p>

<p>
<div className="strong">Bill for your services</div>
We can use and share your health information to bill and get payment from health plans or other entities.
<div className="fst-italic">Example: We give information about you to your health insurance plan so it will pay for your services.</div>
</p>

<p>
<div className="strong">How else can we use or share your health information?</div>
We are allowed or required to share your information in other ways . usually in ways that contribute to the public good, such as public health and research. We have to meet many conditions in the law before we can share your information for these purposes. For more information see: www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html.
</p>

<p>
<div className="strong">Help with public health and safety issues</div>
We can share health information about you for certain situations such as:
<ul>
<li>Preventing disease</li>
<li>Helping with product recalls</li>
<li>Reporting adverse reactions to medications</li>
<li>Reporting suspected abuse, neglect, or domestic violence</li>
<li>Preventing or reducing a serious threat to anyone's health or safety</li>
</ul>
</p>

<p>
<div className="strong">Do research</div>
We can use or share your information for health research.
</p>

<p>
<div className="strong">Comply with the law</div>
We will share information about you if state or federal laws require it, including with the Department of Health and Human Services if it wants to see that we're complying with federal privacy law.
</p>

<p>
<div className="strong">Respond to organ and tissue donation requests</div>
We can share health information about you with organ procurement organizations.
</p>

<p>
<div className="strong">Work with a medical examiner or funeral director</div>
We can share health information with a coroner, medical examiner, or funeral director when an individual dies.
</p>

<p>
<div className="strong">Address workers' compensation, law enforcement, and other government requests</div>
We can use or share health information about you:
<ul>
<li>For workers' compensation claims</li>
<li>For law enforcement purposes or with a law enforcement official</li>
<li>With health oversight agencies for activities authorized by law</li>
<li>For special government functions such as military, national security, and presidential protective services</li>
</ul>
</p>

<p>
<div className="strong">Respond to lawsuits and legal actions</div>
We can share health information about you in response to a court or administrative order, or in response to a subpoena.
</p>

<p>
<div className="strong">Our Responsibilities</div>
<ul>
<li>We are required by law to maintain the privacy and security of your protected health information.</li>
<li>We will let you know promptly if a breach occurs that may have compromised the privacy or security of your information.</li>
<li>We must follow the duties and privacy practices described in this notice and give you a copy of it.</li>
<li>We will not use or share your information other than as described here unless you tell us we can in writing. If you tell us we can, you may change your mind at any time. Let us know in writing if you change your mind.</li>
</ul>
</p>

<p>
For more information see: <a href="https://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html" rel="noreferrer" target="_blank">www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html</a>.
</p>

<p>
<div className="strong">Changes to the Terms of this Notice</div>
We can change the terms of this notice, and the changes will apply to all information we have about you. The new notice will be available upon request.
</p>


</div>



  );
}

export { PrivacyPolicy }