import React from 'react';
import { Pricing } from './PractitionerListingPage';

const PricingPage: React.FunctionComponent = () => {
  return (

    <Pricing showByDefault={true} />

  );
}

export { PricingPage }