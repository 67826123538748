import { AccountContext } from "components/common";
import { FormattedPhone } from "components/common/form/FormattedPhone";
import { MessengerContext } from "components/common/messenger";
import { ErrorMessage, Form, Formik } from "formik";
import { IAccount } from "interfaces";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { validationMap, Validator } from "schemas";
import { Url } from "_constants";
import { AccountSetup, AddressInfo, PersonalInfo } from "./subforms";

const PatientProfile: React.FunctionComponent = () => {

  const accountContext = useContext(AccountContext);
  const msgrContext = useContext(MessengerContext);
  const navigate = useNavigate();

  const [ validationSchema, setValidationSchema] = useState<any>(validationMap.get(Validator.PROFILE_FORM));

  // useEffect(()=>{
  //   if (!accountContext || !accountContext.account) {
  //     navigate(Url.PATIENT_LOGIN);
  //   }
  // },[])

  const onClickLogout = async () => {
    await accountContext?.logout();
    navigate(Url.DEFAULT_HOME);
  }

  const initialValues = {

    email: accountContext?.account.email || '',
    firstName: accountContext?.account.firstName || '',
    lastName: accountContext?.account.lastName || '',
    address1: accountContext?.account.address1 || '',
    address2: accountContext?.account.address2 || '',
    city: accountContext?.account.city || '',
    state: accountContext?.account.state || '',
    zipcode: accountContext?.account.zipcode || '',
    organization: accountContext?.account.organization || '',
    country: accountContext?.account.country || '',
    phoneNumber: accountContext?.account.phoneNumber || '',
    password: '',
    confirmPassword: '',
  }

  const onSubmit = async (data: any, actions: any) => {
    const { setSubmitting, setStatus } = actions;

    if (data.password === "") {
      delete data.password;
      delete data.confirmPassword;
    }

    const account: IAccount = {
      firstName: data.firstName,
      lastName: data.lastName,
      // email: data.email,  // cannot update email
      password: data.password,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      zipcode: data.zip,
      phoneNumber: typeof data.phoneNumber === "number" ? data.phoneNumber : data.phoneNumber.replaceAll(/[^\d]/g,''),
      dateOfBirth: data.dateOfBirth,
    };

    try {
      setSubmitting(true);
      await accountContext?.update(account)
      msgrContext.setMessage({title: 'Patient Profile', body: "Record Saved"});
    } catch (err: any) {
      setStatus(`Error: ${err}`);
    }
  };

  const togglePasswordSection = () => {
    setValidationSchema(validationMap.get(Validator.PROFILE_FORM_PASSWORD));
    // if (showPasswordFields) {
    //   setValidationSchema(validationMap.get(Validator.PROFILE_FORM));
    // } else {
    //   setValidationSchema(validationMap.get(Validator.PROFILE_FORM_PASSWORD));
    //   console.log('with password form');
    // }
    // setShowPasswordFields(!showPasswordFields);

  }
  return (
    <>
      {accountContext && accountContext.account &&
        <>
        <div className="medium-text-2 dark-blue strong">My Profile</div>
          <div className="text-end">
            <button type="button" className="btn btn-primary" onClick={onClickLogout}>Logout</button>
          </div>

          <div className="patient-profile container-md border rounded py-3 mt-2">
            <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
              {({ touched, errors, setFieldValue, status, values }) => {
                return (
                  
                  <Form>
                    <div className="container g-0">

                    <div className="row justify-content-center mt-5">
                        <div className="col-md-6">
                        <AccountSetup title={<>Account Credentials</>} errors={errors} touched={touched} emailDisabled={true} showPasswordToggle={true} onShowPassword={togglePasswordSection}/>
                        </div>
                      </div>

                      <div className="row justify-content-center mt-3">
                        <div className="col-md-6">
                          {/* Personal Information*/}
                          <PersonalInfo errors={errors} touched={touched} />
                        </div>
                      </div>

                      
                      {/* <div className="row justify-content-center">
                        <div className="col-md-6">
                          <button type="button" role="link" className="btn btn-link" onClick={()=>{togglePasswordSection()}}>Change Password</button>
                          {showPasswordFields && 
                          <PasswordChange errors={errors} touched={touched} />
                          }
                        </div>
                      </div> */}


                      <div className="row justify-content-center mt-3">
                        <div className="col-md-6">
                          {/* Address */}
                          <AddressInfo errors={errors} touched={touched} />
                        </div>
                      </div>

                      <div className="row justify-content-center mt-3">
                        <div className="col-md-6">
                          <div className="container">
                            <div className="form-group">
                              <label>Phone Number</label>
                              <FormattedPhone
                                initialValue={parseInt(values["phoneNumber"] as string)}
                                className={'form-control' + (errors && errors["phoneNumber"] && touched && touched["phoneNumber"] ? ' is-invalid' : '')}
                                fieldName={"phoneNumber"} setPhoneNumber={(phone: string) => { setFieldValue("phoneNumber", phone) }} />
                              {errors && <ErrorMessage name={"phoneNumber"} component="div" className="invalid-feedback" />}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-center mt-5">
                        <div className="col-md-6 text-center">
                          <button type="submit" className="btn btn-primary">Update</button>
                        </div>
                      </div>

                      {status &&
                        <div className="row mt-5 justify-content-center">
                          <div className="col-md-6 alert alert-warning text-center">
                            {status}
                          </div>
                        </div>
                      }

                    </div>
                  </Form>
                )
              }}

            </Formik>
          </div>
        </>
      }
    </>
  );
};

export { PatientProfile };
