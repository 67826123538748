import React, { useReducer } from 'react';
import { IAccount, ICredentials, IAccountContext } from 'interfaces';
import { AccountContext } from './AccountContext';

import { AuthenticationService, AccountService } from 'services';
import { useUniqueUserId } from '_hooks/useUniqueUserId';

enum AccountAction {
  LOGIN = 1,
  LOGOUT = 2,
  UPDATE = 3,
  CHANGE_PASSWORD = 4,
  REGISTER = 5,
  RESET_PASSWORD = 6,
  CONFIRM_EMAIL = 7,
  REGISTER_PHONE = 8,
  VERIFY_MOBILE = 9,
  CHECK_REGISTERED_PHONE_NUMBER = 10,
  ERROR = 99,
}

interface IAccountState {
  account: IAccount | null;
  error: any;
}

interface IAccountAction {
  type: AccountAction,
  payload?: any,
}

const accountReducer = (state: IAccountState, action: IAccountAction): any => {

  switch(action.type) {
    case AccountAction.LOGIN: {
      const result = {
        error: null,
        account: action.payload.account,
      };
      //console.log('dispatched login with result ', result);
      return result;
    }

    case AccountAction.LOGOUT: {
      return {
        error: null,
        account: null,
      }
    }

    case AccountAction.ERROR: {
      return {
        account: state.account,
        error: action.payload.error,
      }
    }

   case AccountAction.UPDATE: {
      console.log('update dispatch', {
        error: null,
        account: action.payload.account,
      })
      return {
        error: null,
        account: action.payload.account,
      }
    }

    case AccountAction.REGISTER: {
      return {
        error: null,
        account: action.payload.account,
      }
    }

    case AccountAction.REGISTER_PHONE: {
      return {
        error: null,
        account: state.account,
      }
    }

    case AccountAction.VERIFY_MOBILE: {
      return {
        error: null,
        //account: action.payload.account
        account: state.account
      }
    }

    case AccountAction.CHECK_REGISTERED_PHONE_NUMBER: {
      return {
        error: null,
        account: state.account,
      }
    }

    default:
      throw new Error(`Invalid Action Type: ${action.type}`);
  }

}

const AccountProvider: React.FunctionComponent<{authService: AuthenticationService, accountService: AccountService, children: any}> = ({authService, accountService, children}) => {

  const defaultAccountState: IAccountState = {
    account: authService.getLocalAccount() || null,
    error: null,
  }
  
  const [ accountState, dispatchAccountAction ] = useReducer( accountReducer, defaultAccountState);
  const uniqueUserId = useUniqueUserId();

  const loginHandler = async (credentials: ICredentials) => {
    try {
      await authService.login(credentials);
      const account: any = await authService.getLocalAccount();
      dispatchAccountAction({type: AccountAction.LOGIN, payload: {account}});
      return account;
    } catch (error: any) {
      console.error('in loginhandler error', error);
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }


  const logoutHandler = (callback?: () => void ) => {
    authService.logout();
    dispatchAccountAction({type: AccountAction.LOGOUT, payload: {}});
    if (callback) {
      callback();
    }
  }

  const updateHandler = async (account: IAccount) => {
    try {
      //console.log('updating with', {account});
      await accountService.updateAccount(account);
      const updatedAccount: IAccount | null = await authService.getLocalAccount();
      if (updatedAccount !== null) {
        //console.log('payload', {updatedAccount});
        dispatchAccountAction({type: AccountAction.UPDATE, payload: {account: updatedAccount}});
        return updatedAccount;
      } else {
        throw Error('could not get account after update');
      }
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const registerHandler = async (account: IAccount) => {
    try {
      const response = await accountService.register(account);
      dispatchAccountAction({type: AccountAction.REGISTER, payload: {account: response as IAccount}});
      return response as IAccount;
    } catch (error: any) {
      throw Error(error);
    }
  }


  const changePasswordHandler = async () => {
  }
  
  const confirmEmailHandler = async () => {

  }

  const resetPasswordHandler = async () => {
    try {
      
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const registerPhoneNumberHandler = async (phoneNumber: number) => {
    try {
      const response = await accountService.registerPhoneNumber(phoneNumber, uniqueUserId);
      //console.log('confirmMobileHandler response', {response});
      dispatchAccountAction({type: AccountAction.REGISTER_PHONE, payload: {}})
      return response;
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const checkRegisteredPhoneNumberHandler = async (phoneNumber: number, phoneNumberCode: string) => {
    try {
      const success: boolean = await accountService.checkRegisteredPhoneNumber(phoneNumber, phoneNumberCode, uniqueUserId);
      console.log({success});
      if (!success) {
        throw Error('Could not verify the code. Please check again.');
      }
      dispatchAccountAction({type: AccountAction.CHECK_REGISTERED_PHONE_NUMBER, payload: {}})
      return success;
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }  

  const verifyMobileHandler = async (code: string) => {
    try {
      const response = await accountService.verifyMobile({code});
      //console.log('verifyMobileHandler response', {response});
      dispatchAccountAction({type: AccountAction.VERIFY_MOBILE, payload: {account: response as IAccount}})
      return response;
    } catch (error: any) {
      dispatchAccountAction({type: AccountAction.ERROR, payload: {error}});
      throw Error(error);
    }
  }

  const accountContext: IAccountContext = {
    account: accountState.account,
    login: loginHandler,
    logout: logoutHandler,
    update: updateHandler,
    register: registerHandler,
    changePassword: changePasswordHandler,
    error: accountState.error,
    confirmEmail: confirmEmailHandler,
    resetPassword: resetPasswordHandler,
    //confirmMobile: confirmMobileHandler,
    registerPhoneNumber: registerPhoneNumberHandler,
    checkRegisteredPhoneNumber: checkRegisteredPhoneNumberHandler,
    verifyMobile: verifyMobileHandler,
  }

  return (
    <AccountContext.Provider value={accountContext}>
      {children}
    </AccountContext.Provider>
  );

}


export { AccountProvider }
