import React, { useContext, useEffect, useState } from 'react';
import { AppointmentContext } from '../scheduler/AppointmentContext';
import { Collect } from './components/Collect';
import { Completed } from './components/Completed';
import { Questions } from './components/Questions';
import { RegisterVerify } from './components/RegisterVerify';
import { Navigate } from 'react-router';
import { Url } from '_constants';
import { trackingService } from 'services/common/TrackingService';
import { useLandingPage } from '_hooks/useLandingPage';

const AppointmentWizard: React.FunctionComponent = () => {
  const apptContext = useContext(AppointmentContext);
  
  interface IPhase {
    key: number,
    title: string,
    element: JSX.Element,
  }

  // if initially unregistered user and not activating -- all 4 phases
  // if initially unregistered user and activating - register, insurance info, confirm appt
  // if logged in user and not activating - reason, insurance, confirm appt
  // if logged in user and activating - insurance, confirm appt

  enum phaseEnum {
    REGISTER = 0,
    REASON = 1,
    INSURANCE = 2,
    CONFIRM = 3,
    DONE = 4,
  }

  const landingPage = useLandingPage();

  const phaseEnumActions: Map<number, {action: string, label: string}> = new Map<number, {action: string, label: string}>([
    [phaseEnum.REGISTER, {action: `${landingPage.getLandingPage()}Make Appointment | completed registration+verification`, label: 'Register and Verify'}],
    [phaseEnum.REASON, {action:`${landingPage.getLandingPage()}Make Appointment | Submitted reason for visit`, label: 'Submit Reason'}],
    [phaseEnum.INSURANCE, {action:`${landingPage.getLandingPage()}Make Appointment | Submitted insurance form`, label: 'Submit Insurance'}],
    [phaseEnum.CONFIRM, {action:`${landingPage.getLandingPage()}Make Appointment | Submitted pre-confirmation`, label: 'Review Appointment'}],
    [phaseEnum.DONE, {action:`${landingPage.getLandingPage()}Make Appointment | Confirmed appointment`, label: 'Confirm Appointment'}],
  ]);

  
  const isActivating = (): boolean => {
    return apptContext ? apptContext.appointment.isActivatingKit === true : false;
  }


  const onSuccess = () => {

    // skip REGISTER. it's handled in Verify
    if (currentPhase !== phaseEnum.REGISTER) {
        trackingService.fireGoogleEvent({
          action: phaseEnumActions.get(currentPhase)?.action || '',
          category: 'Make Appointment',
          label: phaseEnumActions.get(currentPhase)?.label || '',
        })
    }

    // skip reason phase if activating
    if ((currentPhase + 1) === phaseEnum.REASON && isActivating()) {
      setCurrentPhase(currentPhase + 2);
    } else {
      setCurrentPhase(currentPhase + 1);
    }
    
  }

  const phases: IPhase[] = [
    { key: phaseEnum.REGISTER, title: 'Register', element: <RegisterVerify onSuccess={onSuccess} /> },
    { key: phaseEnum.REASON, title: 'Reason', element: <Questions onSuccess={onSuccess} /> },
    { key: phaseEnum.INSURANCE, title: 'Insurance', element: <Collect onSuccess={onSuccess} /> },
    { key: phaseEnum.CONFIRM, title: 'Confirm', element: <Completed onSuccess={onSuccess}/> },
    { key: phaseEnum.DONE, title: 'Done', element: <Navigate to={Url.PATIENT_APPOINTMENT_CONFIRMED} replace /> },
  ];

  const [currentPhase, setCurrentPhase] = useState<number>(apptContext && apptContext.appointment.isConfirming === true ? phaseEnum.CONFIRM : 0);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (currentPhase === phaseEnum.DONE) return; // don't fire GA for done. It is fired from the appointment-confirmed page.

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPhase])

  const PhaseProgress = () => {

    return (
      <div className="d-flex mt-3">
        {phases.filter((phase: IPhase) => phase.key !== (isActivating() ? phaseEnum.REASON : -1)).map((phase: IPhase, i: number) => {
          return <div key={`phase-key-${i}`} className={`flex-fill appt-phase-cell
            ${i === 0 ? "left-rounded" : ""} 
            text-center
            ${i < currentPhase || currentPhase >= phases.length - 1 ? "checked" : ""}
            ${i === currentPhase ? "right-rounded" : ""}
            ${i <= currentPhase ? "current fw-bold" : "d-none"}`}>

            <div className="appt-phase-number">
              <div className={`btn-circle 
                ${i < currentPhase || currentPhase >= phases.length - 1 ? "checked" : ""}
                ${i === currentPhase && currentPhase !== phases.length -1 && i < phases.length ? "current" : ""}
                ${i === currentPhase && currentPhase === phases.length ? "checked" : ""}
                `}>{i + 1}</div>
            </div>
            <div className="appt-phase-title">{phase.title}</div>
          </div>
        }
        )}
      </div>
    )
  }

  const renderPhase = (phase: IPhase) => {

    if (!phase) {
      <>Nothing</>
      return;
    }

    return <div className="container appointment-wizard ">
      {/* <div>Current phase: {currentPhase}</div> */}
      <div>{phase.element}</div>
    </div>
  }

  return <>
    <div className="container">
      <PhaseProgress />
      {renderPhase(phases[currentPhase])}

      {/* {currentPhase > 1 && currentPhase < phases.length - 1 &&
        <div className="mt-3">
          <button type="button" className="btn btn-primary" onClick={onClickBack}>Previous</button>
        </div>
      } */}
    </div>
  </>
}

export { AppointmentWizard }