import React, { useContext, useState } from 'react';
import { MessengerContext } from 'components/common/messenger';
import { IAppointmentResponseItem, ModalAppointment, NewAppointmentDetailCard } from 'components/patient/appointments';
import { IAppointment, IPractitioner, IPractitionerAppointments, ITimeSlot } from 'interfaces';
import { publicApiService } from 'services';
import { practitionerService } from 'services/PractitionerService';
import { AppointmentStatusEnum, appointmentStatusNames, appointmentStatusSort } from '_constants/AppointmentStatusEnum';
import { Helper } from '_utils';
import { PractitionerHelper } from 'components/common/PractitionerListingPage/Helper';
import { FullSchedule } from 'components/common/PractitionerListingPage/FullSchedule';

const AppointmentGrid: React.FunctionComponent<{
  appts: IAppointmentResponseItem[],
  setData: React.Dispatch<React.SetStateAction<IAppointmentResponseItem[]>>,
  refresh: () => void
}> = ({ appts, setData, refresh }) => {

  const msgrContext = useContext(MessengerContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newAppts, setNewAppts] = useState<Map<number, IPractitionerAppointments>>();
  const [timeSlot, setTimeSlot] = useState<ITimeSlot | null>(null);
  const [practitioner, setPractitioner] = useState<IPractitioner>({} as IPractitioner);
  const [status, setStatus] = useState<string>('');
  const [currentAppt, setCurrAppt] = useState<IAppointmentResponseItem>({} as IAppointmentResponseItem);
  const [isRescheduleOpen, setIsRescheduleOpen] = useState<boolean>(false);

  const onClickConfirm = async (appt: IAppointmentResponseItem): Promise<any> => {
    try {
      await practitionerService.updateAppointmentStatus(appt.id, AppointmentStatusEnum.SCHEDULE_PRACTITIONER_CONFIRMED);
      setData(appts.map((item: IAppointmentResponseItem) => {
        if (item.id === appt.id) {
          item.statusId = AppointmentStatusEnum.SCHEDULE_PRACTITIONER_CONFIRMED;
        }
        return item;
      }));
      msgrContext.setMessage({ title: 'Appointment Update', body: 'Appointment Successfully Updated' });
    } catch (error: any) {
      msgrContext.setMessage({ title: 'Appointment Error', body: `Appointment was not updated: ${(error as Error).message}` });
      throw Error((error as Error).message);
    }
  }

  const onClickCancel = async (appt: IAppointmentResponseItem, isOpen: boolean = true): Promise<any> => {
    if (!window.confirm("Are you sure you want to cancel?")) return;

    try {
      await practitionerService.cancelAppointment(appt.id);
      if (isOpen) {
        // keep the appointment open. create a new slot
        const slots = [
          {
            start: appt.start,
            end: appt.end,
            price: 1 //@TODO: finalize
          }
        ]
        await practitionerService.addAppointmentSlots(slots);
      }
      setData(appts.map((item: IAppointmentResponseItem) => {
        if (item.id === appt.id) {
          item.statusId = AppointmentStatusEnum.CANCELED;
        }
        return item;
      }));
      msgrContext.setMessage({ title: 'Appointment Update', body: 'Appointment Successfully Cancelled' });
      refresh();
    } catch (error: any) {
      msgrContext.setMessage({ title: 'Appointment Error', body: `Appointment could not be cancelled: ${(error as Error).message}` });
      throw Error((error as Error).message);
    }
  }

  const onClickReschedule = async (appt: IAppointmentResponseItem, isOpen: boolean): Promise<any> => {
    const filters: { practitionerId: number, appointmentStart: number } = { practitionerId: appt.practitioner.id, appointmentStart: new Date().getTime() / 1000 };
    // console.log({ filters });
    const appts: IAppointment[] = await publicApiService.getAppointments(filters);
    const newAppts = PractitionerHelper.groupAppointmentsByDoctor(appts);
    setIsRescheduleOpen(isOpen);
    setCurrAppt(appt);
    setShowModal(true);
    setNewAppts(newAppts);
    setTimeSlot(null);
    setPractitioner({} as IPractitioner);
    refresh();
  }

  const onAppointmentClick = (timeslot: ITimeSlot, practitioner: IPractitioner) => {
    setTimeSlot(timeslot);
    setPractitioner(practitioner);
  }

  const onRequestNewClick = async (oldAppointment: IAppointmentResponseItem, newAppointmentId: number, isOpen: boolean = true) => {
    try {
      await practitionerService.rescheduleAppointment(newAppointmentId, oldAppointment.id);
      // console.log({ result });
      if (isOpen) {
        // keep the appointment open. create a new slot
        const slots = [
          {
            start: oldAppointment.start,
            end: oldAppointment.end,
            price: 1 //@TODO: finalize
          }
        ]
        await practitionerService.addAppointmentSlots(slots);
      }
      msgrContext.setMessage({ title: 'Reschedule Appointment', body: 'Appointment was rescheduled successfully' })
      setShowModal(false);
      refresh();
    } catch (error: any) {
      setStatus(`Unexpected error: ${(error as Error).message}`);
    }
  }

  const sendSmsReminder = async (appointmentId: number) => {
    try {
      await practitionerService.sendSmsReminder(appointmentId);
      msgrContext.setMessage({ title: 'SMS Reminder', body: <>Reminder sent.</> })
    } catch (error: any) {
      msgrContext.setMessage({ title: 'SMS Failure', body: (error as Error).message })
    }
  }

  return (
    <>
      <table className="table table-striped appointment-list">
        <thead>
          <tr className="table-dark">
            <th>Creation Date</th>
            <th>Appointment ID
              <i className="bi bi-sort-up"></i>
            </th>
            <th>Appointment Date</th><th>Appointment Time</th><th>Practitioner</th><th>Patient</th><th>Reason for Visit</th>
            <th>Status</th>
            <th colSpan={6} align="justify">Actions</th>
          </tr>
        </thead>

        <tbody>
          {appts.sort((a, b) => (a.statusId && b.statusId && (appointmentStatusSort.get(a.statusId) || 0) < (appointmentStatusSort.get(b.statusId) || 0) ? -1 : 1)).map((item: IAppointmentResponseItem, key: number) =>
            <tr >
              <td >{item.scheduledAt && Helper.getFormattedDateTime(new Date(item.scheduledAt * 1000))}</td>
              <td>{item.id}</td>
              <td>{Helper.getYMDFromEpoch(item.start)}</td>
              <td>{item.start ? Helper.getFormattedTimeHHMM(new Date(item.start * 1000)) : ''} - {item.end ? Helper.getFormattedTimeHHMM(new Date(item.end * 1000)) : ''}</td>
              <td>
                <div>{item.practitioner.id}</div>
                <div>{item.practitioner.lastName}, {item.practitioner.firstName}</div>
              </td>
              <td>
                <div>{item.user && item.user.id}</div>
                <div>{item.user && item.user.lastName}, {item.user && item.user.firstName}</div>
              </td>
              <td>{item.reasonText}</td>
              <td>
                <div className={`appointment-list-status-${item.statusId} p-3`}>
                  <div>
                    {item.statusId && appointmentStatusNames.get(item.statusId)}
                  </div>
                  <>
                    {item.statusId === AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE &&
                      <>
                        <div>
                          Last SMS Sent: {item.lastSmsTimestamp || "N/A"}
                        </div>
                        <div>
                          <div role="button" className="btn btn-primary my-2" onClick={() => sendSmsReminder(item.id)}>Send Reminder</div>
                        </div>
                      </>
                    }
                  </>
                </div>
              </td>
              <td>{item.statusId === AppointmentStatusEnum.OPEN && <button type="button" className="btn btn-primary" onClick={() => onClickConfirm(item)}>Set Patient</button>}</td>
              <td>{item.statusId === AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE && <button type="button" className="btn btn-primary" onClick={() => onClickConfirm(item)}>Confirm</button>}</td>
              <td>{[AppointmentStatusEnum.SCHEDULE_PRACTITIONER_CONFIRMED, AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE, AppointmentStatusEnum.SCHEDULE_PRACTITIONER_NOT_AVAILABLE, AppointmentStatusEnum.CANCELLING].indexOf(item.statusId || 0) >= 0 && <button type="button" className="btn btn-primary" onClick={() => onClickCancel(item, false)}>Cancel and Close Slot</button>}</td>
              <td>{[AppointmentStatusEnum.SCHEDULE_PRACTITIONER_CONFIRMED, AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE, AppointmentStatusEnum.CANCELLING].indexOf(item.statusId || 0) >= 0 && <button type="button" className="btn btn-primary" onClick={() => onClickCancel(item, true)}>Cancel and Re-Open Slot</button>}</td>
              <td>{item.statusId !== AppointmentStatusEnum.CANCELED && item.statusId !== AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE && <button type="button" className="btn btn-primary" onClick={() => onClickReschedule(item, false)}>Reschedule and Close Old Slot</button>}</td>
              <td>{item.statusId !== AppointmentStatusEnum.CANCELED && item.statusId !== AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE && item.statusId !== AppointmentStatusEnum.SCHEDULE_PRACTITIONER_NOT_AVAILABLE && <button type="button" className="btn btn-primary" onClick={() => onClickReschedule(item, true)}>Reschedule and Re-Open Old Slot</button>}</td>
            </tr>)}
          <tr>
          </tr>
        </tbody>
      </table>


      <ModalAppointment show={showModal} handleClose={() => setShowModal(false)} title="Select New Appointment">
        <div className="container-fluid">

          {!timeSlot && newAppts &&
            <FullSchedule
              appointments={newAppts}
              practitioner={currentAppt.practitioner}
              onAppointmentClick={onAppointmentClick}
              returnButton={<button className="btn btn-primary" onClick={() => setShowModal(false)}>Cancel</button>}
            />
          }

          {timeSlot && currentAppt &&
            <div>
              <NewAppointmentDetailCard appointment={currentAppt} newTimeSlot={timeSlot} newPractitioner={practitioner} />
              <button type="button" className="btn btn-primary my-2" onClick={() => onRequestNewClick(currentAppt, timeSlot.id, isRescheduleOpen)}>Reschedule To This Appointment</button>
            </div>
          }

          {status && <div className="alert alert-warning my-3">{status}</div>}

        </div>
      </ModalAppointment>
    </>
  );
}


export { AppointmentGrid as PractitionerSchedulerAppointmentGrid }