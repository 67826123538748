import React, { useContext, useEffect, useState } from 'react';
import { IAppointment, IPractitioner, IPractitionerAppointments, ITimeSlot } from 'interfaces';
import { Modal } from 'react-bootstrap';
import { patientService, publicApiService } from 'services';
import { IAppointmentResponseItem } from './IAppointmentResponseItem';
import { PractitionerHelper } from 'components/common/PractitionerListingPage';
import { Helper } from '_utils';
import { AppointmentStatusEnum, appointmentStatusNamesPatient } from '_constants/AppointmentStatusEnum';
import { MessengerContext } from 'components/common/messenger';
import { PractitionerCard } from 'components/common/PractitionerListingPage/PractitionerCard';
import { AppointmentDeck } from 'components/common/PractitionerListingPage/AppointmentDeck';
import { AccountContext } from 'components/common';
import { trackingService } from 'services/common/TrackingService';

const ModalAppointment: React.FunctionComponent<{ show: any, handleClose: any, children: any, title: string }> = ({ show, handleClose, children, title }) => {
  return (
    <Modal scrollable={true} show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="text-primary">{title}</Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
}

const NewAppointmentDetailCard: React.FunctionComponent<{ appointment: IAppointmentResponseItem, newTimeSlot: ITimeSlot, newPractitioner: IPractitioner }> = ({ appointment, newTimeSlot, newPractitioner }) => {
  return (
    <PractitionerCard practitioner={newPractitioner}>
      <div className="small-text-1 strong text-center">New Appointment Details</div>
      <div className="small-text-1 text-center">Date: {newTimeSlot.date}</div>
      <div className="small-text-1 text-center">Time: {newTimeSlot.startTime} - {newTimeSlot.endTime}</div>
      <div className="small-text-1 margin-center">&nbsp;</div>
    </PractitionerCard>
  )
}

const AppointmentDetailCard: React.FunctionComponent<{ appointment: IAppointmentResponseItem }> = ({ appointment }) => {
  const { id, practitioner, start, end, statusId } = appointment;
  const timeSlot = {
    id,
    date: Helper.getFormattedDayMDY(new Date(start * 1000)),
    startTime: Helper.getFormattedTimeHHMM(new Date(start * 1000)),
    endTime: Helper.getFormattedTimeHHMM(new Date(end * 1000))
  }
  return <PractitionerCard practitioner={practitioner}>
    {/* <div>Appointment Details</div>
    <div>Date: {timeSlot.date}</div>
    <div>Time: {timeSlot.startTime} - {timeSlot.endTime}</div>
    <div>Reason for Appointment: {reasonText}</div> */}

    <div className="container g-0">
      <div className="small-text-1 strong text-center">Appointment Details</div>
      <div className="small-text-1 text-center">Date: {timeSlot.date}</div>
      <div className="small-text-1 text-center">Time: {timeSlot.startTime} - {timeSlot.endTime}</div>
      <div className="small-text-1 text-center">Status: {appointmentStatusNamesPatient.get(statusId || 0)}</div>
      {/* <div className="small-text-1 margin-center appt-checked">&nbsp;</div> */}
    </div>

  </PractitionerCard>

}

const PatientAppointments: React.FunctionComponent = () => {

  const [appts, setAppts] = useState<IAppointmentResponseItem[]>([]);

  const [newAppts, setNewAppts] = useState<Map<number, IPractitionerAppointments>>();
  const [showSchedule, setShowSchedule] = useState<boolean>(false);
  const [timeSlot, setTimeSlot] = useState<ITimeSlot | null>(null);
  const [practitioner, setPractitioner] = useState<IPractitioner>({} as IPractitioner);
  const [, setStatus] = useState<string>('');
  const [loaded, setLoaded] = useState<boolean>(false);

  const msgrContext = useContext(MessengerContext);
  const acctContext = useContext(AccountContext);

  useEffect(() => {
    const _init = async () => {
      const appts: IAppointmentResponseItem[] = await patientService.getAppointments();
      setAppts(appts.filter((appt: IAppointmentResponseItem) => [AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE, AppointmentStatusEnum.SCHEDULE_PRACTITIONER_CONFIRMED, AppointmentStatusEnum.CANCELLING].indexOf(appt.statusId || 0) >= 0));
      // setAppts(appts);
      setStatus('');
      setLoaded(true);
    }
    _init();
  }, [newAppts, loaded, timeSlot])


  const onClickReschedule = async () => {
    const filters: { appointmentStart: number } = { appointmentStart: new Date().getTime() / 1000 };
    const newAppts: IAppointment[] = await publicApiService.getAppointments(filters);
    const grouped = PractitionerHelper.groupAppointmentsByDoctor(newAppts);

    trackingService.fireGoogleEvent({
      action: 'Patient Dashboard | Clicked Reschedule',
      category: 'Patient Dashboard',
      label: 'Reschedule Appointment',
    });
    setShowSchedule(true);
    setTimeSlot(null);
    setNewAppts(grouped);

  }

  const onAppointmentClick = (timeslot: ITimeSlot, practitioner: IPractitioner) => {

    trackingService.fireGoogleEvent({
      action: 'Patient Dashboard | Selected New Appointment',
      category: 'Patient Dashboard',
      label: 'Reschedule Appointment',
    });
    setTimeSlot(timeslot);
    setPractitioner(practitioner);
  }

  const onRequestNewClick = async (oldAppointmentId: number, newAppointmentId: number) => {
    try {
      await patientService.rescheduleAppointment(newAppointmentId, oldAppointmentId);
      msgrContext.setMessage({ title: 'Reschedule Appointment', body: 'Submitted Rescheduling Request Successfully.' })
      setShowSchedule(false);
      trackingService.fireGoogleEvent({
        action: 'Patient Dashboard | Requested New Appointment',
        category: 'Patient Dashboard',
        label: 'Reschedule Appointment',
      });
    } catch (error: any) {
      msgrContext.setMessage({ title: 'Reschedule Appointment Error', body: (error as Error).message })
      setStatus(`Unexpected error: ${(error as Error).message}`);
    }
  }

  const onClickCancel = async () => {
    if (window.confirm('Are you sure you want to cancel?')) {
      try {
        await patientService.cancelAppointment(appts[0].id);
        msgrContext.setMessage({ title: "Appointment Change", body: "Appointment was Cancelled" });
        appts[0].statusId = AppointmentStatusEnum.CANCELLING;
        setAppts(appts);
        trackingService.fireGoogleEvent({
          action: 'Patient Dashboard | Requested Appointment Cancellation',
          category: 'Patient Dashboard',
          label: 'Cancel Appointment',
        });
      } catch (error: any) {
        console.error('cancel error', { error });
        msgrContext.setMessage({ title: "Request to Cancel Appointment", body: `Unexpected error: ${(error as Error).message}` });
      }
    }
  }

  return (
    <>
      <div className="medium-text-2 strong dark-blue mb-5">My Appointments</div>

      {!loaded &&
        <div className="d-flex flex-col justify-content-center">
          <div className="strong">Loading...</div>
        </div>
      }

      {loaded && appts &&
        <>
          {appts.length === 0 &&
            <>
              You have no booked appointments.
            </>
          }

          {appts.length > 0 && appts[0].practitioner && !showSchedule &&
            <>
              <AppointmentDetailCard appointment={appts[0]} />

              {[AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE, AppointmentStatusEnum.SCHEDULE_PRACTITIONER_CONFIRMED].indexOf(appts[0].statusId || 0) >= 0 &&
                <div className="my-3 text-start">
                  <div className="d-flex flex-column">
                    <div className="margin-center"><div className="btn btn-primary" onClick={onClickReschedule}>Reschedule Appointment</div></div>
                    <div className="margin-center"><button type="button" className="btn btn-link mx-5" onClick={onClickCancel}>Cancel Appointment</button></div>
                  </div>
                </div>
              }

            </>
          }

          {showSchedule &&
            <>
              <div className="small-text-2 strong dark-blue">Select New Appointment</div>
              {!timeSlot && newAppts &&
                <AppointmentDeck
                  appointments={newAppts}
                  onAppointmentClick={onAppointmentClick}
                  insuranceId={parseInt(acctContext?.account.insuranceCompanyId || "0", 10)}
                />
              }

              {timeSlot && appts && appts.length > 0 && appts[0].practitioner && appts[0].id ? (
                <div>
                  <NewAppointmentDetailCard appointment={appts[0]} newTimeSlot={timeSlot} newPractitioner={practitioner} />
                  <div className="my-2 text-start d-flex flex-column justify-content-center">
                    <div className="margin-center"><button type="button" className="btn btn-primary my-1 btn-appt btn-appt-confirm" onClick={() => onRequestNewClick(appts[0].id, timeSlot.id)}>Confirm</button></div>
                    <div className="margin-center"><button type="button" className="btn btn-primary my-1 btn-appt btn-appt-change" onClick={() => setShowSchedule(false)}>Cancel</button></div>
                  </div>
                </div>

              ) : (
                <div className="text-center text-md-start text-lg-start text-xl-start text-xxl-start">
                  <div className="btn btn-primary margin-center" onClick={() => setShowSchedule(false)}>Cancel</div>
                </div>
              )}
            </>
          }

        </>
      }


    </>
  );
}

export { PatientAppointments, ModalAppointment, NewAppointmentDetailCard }