import { DoctorCarousel, VirtualVisitInfo } from 'components/common/PractitionerListingPage';
import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { trackingService } from 'services';
import { InsuranceCompanyEnum } from '_constants/InsuranceCompanyEnum';
import { useUnload } from '_hooks/useUnload';
import { QualifySection } from './QualifySection';

const Steps: React.FunctionComponent<{ onClickBook: () => void }> = ({ onClickBook }): JSX.Element => {

  return <>
    <div className="kit-step my-2">
      <div className="kit-step-label fw-bold orange medium-text-3">
        Step 1
      </div>
      <div className="kit-step-head mx-3 mt-3 dark-blue fw-bold small-text-3">
        Confirm that you qualify for free testing if you meet each of the following criteria:
      </div>
      <div className="kit-step-head-body">
        <ol>
          <li className="my-3">The test is for you and you have not had a preventive visit with a doctor within the last 12 months.</li>
          <li className="my-3">You are 45 years or older</li>
          <li className="my-3">You live in New Jersey and have one of the following health insurance carriers: <b>Horizon BC/BS, United Healthcare, Cigna, Medicare Part B</b></li>
        </ol>
      </div>
    </div>

    <div className="kit-step">
      <div className="kit-step-label fw-bold orange medium-text-3">
        Step 2
      </div>
      <div className="kit-step-head mx-3 mt-3 dark-blue fw-bold small-text-3">
        Book a Virtual Visit with a Physician Below
      </div>

      <div className="kit-step-body mt-3 mx-3">
        <p>
          If you meet ALL of the above criteria, then you qualify for the Free Test Kit and prescription. <b>New Jersey state requires a physician to see you before
            ordering the test.</b> Convena Care provides virtual visits to fulfill this requirement and provide you with a comprehensive preventive health visit to
          protect your health.
        </p>
        <p>
          Please <HashLink
            style={{ textDecoration: "none" }}
            className="fw-bold orange"
            onClick={() => onClickBook()}
            scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth' })}
            to={'#book-now'}>
            book a virtual visit
          </HashLink> now with one of our physicians. The physician will review your medical and family history and
          prescribe all appropriate tests for you. The visit is fully covered by the above health plans if you have not had another preventive visit within the
          last 12 months.
        </p>
      </div>
    </div>

    <VirtualVisitInfo isLarge={false} />

  </>
}

const FreeKitNoFormPage: React.FunctionComponent = () => {

  const EVENT_CATEGORY = 'Free Kit (No Form)';
  useUnload( (e: Event) => {
    e.preventDefault();
    trackingService.fireGoogleUnload({category: EVENT_CATEGORY, label: 'exit page' });
  })


  useEffect(() => {
    document.title = 'Convena Care At-Home Colon Cancer Testing';
  }, [])

  const onClickShowVideo = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Show Video`,
      category: EVENT_CATEGORY,
      label: ' Video: How The Test Is Done',
    });
  }

  const onClickBook = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Book A Virtual Visit`,
      category: EVENT_CATEGORY,
      label: 'book a virtual visit',
    });
  }

  const trackEvent = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Appointment Slot`,
      category: EVENT_CATEGORY,
      label: 'Select Time Slot',
    });
  }

  return (
    <>
      <div className="bg-lighterblue rounded">
        <QualifySection onClickShowVideo={onClickShowVideo}>
          <Steps onClickBook={onClickBook} />
        </QualifySection>
      </div>
      <div id="book-now" className="fw-bold dark-blue medium-text-1 my-3">Book a Virtual Visit with a Physician Below:</div>
      <DoctorCarousel insuranceId={InsuranceCompanyEnum.HORIZON_BCBS.toString()} trackEvent={trackEvent} />

    </>
  );
}

export { FreeKitNoFormPage }