import React, { useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";

import { AccountContext } from "components/common";
import { Url } from "_constants";
import { IAccount } from "interfaces";
import { PractitionerRolesEnum } from "_constants/PractitionerRolesEnum";

const PractitionerNavigationHeader: React.FunctionComponent = () => {

  const accountContext = useContext(AccountContext);
  const account: IAccount | undefined = accountContext?.account;

  return (
    <Navbar expand="lg" className="navbar fixed-top bg-white pb-0">
      <div className="container-fluid">
      <Navbar.Brand href={Url.DEFAULT_HOME}><div className="header-logo"><img src="/images/logo.png" alt="Convena Health"/></div></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>

        {/* collapsed */}
        <div className="d-sm-inline-block d-xs-inline-block d-md-block d-lg-none d-xl-none">
          <Nav className="justify-content-end w-100 ">
            <Nav.Link href={Url.PRACTITIONER_HOME} className="my-1 strong">Home</Nav.Link> 
  
            {account ? 
              <>
              {account.roleId === PractitionerRolesEnum.DOCTOR_ROLE  && 
              <Nav.Link href={Url.PRACTITIONER_CALENDAR} className="my-1 strong">Availability</Nav.Link> 
              }
              <Nav.Link href={Url.PRACTITIONER_APPOINTMENTS} className="my-1 strong">Appointments</Nav.Link> 
              <Nav.Link href={Url.PRACTITIONER_PATIENTS} className="my-1 strong">Patients</Nav.Link> 
              {account.roleId === PractitionerRolesEnum.NURSE_ROLE  && 
              <Nav.Link href={Url.PRACTITIONER_REPORT_USER_LOGS} className="my-1 strong">User Log</Nav.Link> 
              }

              <Nav.Link href={Url.PRACTITIONER_REFERENCE} className="my-1 strong">Reference</Nav.Link> 
              <Nav.Link href={Url.PRACTITIONER_PROFILE} className="my-1 strong">My Account</Nav.Link> 
              </>
              :
              <Nav.Link href={Url.PRACTITIONER_LOGIN} className="menu-item my-lg-2">Log in</Nav.Link>}
          </Nav>
        </div>


      </Navbar.Collapse>

      {/* menu using icons (expanded) */}
      <div className="d-none d-lg-inline-block">
      <Nav className="justify-content-end w-100">
        <Nav.Link href={Url.PRACTITIONER_HOME} className="my-2 my-1 strong">Home</Nav.Link> 
      {account ? 
          <>
          {account.roleId === PractitionerRolesEnum.DOCTOR_ROLE  && 
          <Nav.Link href={Url.PRACTITIONER_CALENDAR} className="my-2 my-1 strong">Availability</Nav.Link> 
          }
          <Nav.Link href={Url.PRACTITIONER_APPOINTMENTS} className="my-2 mx-1 strong">Appointments</Nav.Link> 
          <Nav.Link href={Url.PRACTITIONER_PATIENTS} className="my-2 mx-1 strong">Patients</Nav.Link> 
          {account.roleId === PractitionerRolesEnum.NURSE_ROLE  && 
              <Nav.Link href={Url.PRACTITIONER_REPORT_USER_LOGS} className="my-2 mx-1 strong">User Log</Nav.Link> 
              }
          <Nav.Link href={Url.PRACTITIONER_REFERENCE} className="my-2 mx-1 strong">Reference</Nav.Link> 
          <Nav.Link href={Url.PRACTITIONER_PROFILE} className="my-2 mx-1 strong">My Account</Nav.Link> 
          </>
          :
          <>
          <Nav.Link href={Url.PRACTITIONER_LOGIN} className="my-2 mx-1 strong">Log in</Nav.Link>
          </>
        }
      </Nav>
      </div>
      </div>
    </Navbar>
  );
}


const Header:React.FunctionComponent = () => {
  const acctContext = useContext(AccountContext);

  return (
    <div className="container-fluid">
      <PractitionerNavigationHeader />
      <div className="my-5">&nbsp;</div>
      {acctContext?.account && 
      <div className="d-flex justify-content-end"> Logged in as: {acctContext?.account.lastName}, {acctContext?.account.firstName}</div>
      }
    </div>
  );
}

export { Header as PractitionerHeader }
