import React from 'react';

import { InsuranceForm } from './InsuranceForm';

const InsuranceSection: React.FunctionComponent<{onSuccess: ()=>void}> = ({onSuccess}) => {

  return <>
    <InsuranceForm onSuccess={onSuccess}/>
  </>
}

export { InsuranceSection }