import React, { ChangeEvent, useState } from 'react';
import { Helper } from '_utils';

const FormattedPhone: React.FunctionComponent<
  {
    setPhoneNumber: (p: string) => void, 
    fieldName: string,
    initialValue?: number,
    [x: string]: any,
  }
  > = (
  { 
    setPhoneNumber,
    fieldName,
    initialValue,
    ...rest
  }) => {



  const [ phone, setPhone ] = useState<string>(initialValue ? Helper.formattedPhoneNumber(initialValue.toString()) : "");
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = Helper.formattedPhoneNumber(e.currentTarget.value);
    setPhone(formattedNumber);
    setPhoneNumber(formattedNumber);
  }


  return (
    <input name={fieldName} onChange={onChange} value={phone} {...rest} placeholder="1 (999) 999-9999"></input>
  );

}

export { FormattedPhone }