import { AccountContext, LoginForm } from 'components/common';
import { IAccount, ICredentials } from 'interfaces';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { validationMap, Validator } from 'schemas';
import { Url } from '_constants';

const PractitionerLoginPage: React.FunctionComponent<{onLogin?: ()=>void}> = ({onLogin}) => {

  const accountContext = useContext(AccountContext);
  const navigate = useNavigate();

  useEffect(()=>{
    if (accountContext?.account) {
      // check if expired; may need to log in again
      navigate(Url.PRACTITIONER_DASHBOARD);
    }
  },[])

  const initialFormState: ICredentials = {
    email:  '',
    password: '',
  }

  const onSubmit = async (data: any, actions: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    const redir = queryParams.get("redir");

    const { email, password } = data;
    const { setStatus } = actions;
    try {
      const account: IAccount | undefined = await accountContext?.login({email, password});
      if (!account) {
        throw Error('Could not get account');
      }
      if (onLogin) {
        onLogin();
      } else {
        navigate(Url.PRACTITIONER_DASHBOARD, {replace: true});
      }
    } catch (error) {
      setStatus("Invalid Login");
    }
  }

  const validationSchema = validationMap.get(Validator.LOGIN_FORM);

  return (

    <div className="container">
      <div className="row justify-content-center my-3">
        <div className="col-12 col-md-8 col-lg-6 col-xl-6 col-xxl-6">
        <LoginForm initialFormState={initialFormState} onSubmit={onSubmit} validationSchema={validationSchema} passwordResetUrl="/practitioner/forgot-password"/>
        </div>
      </div>
    </div>

  );
}
export { PractitionerLoginPage }