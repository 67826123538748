import React, { useEffect, useState } from 'react';
import { AdminPractitionerCalendar } from './AdminPractitionerCalendar';
import { IPractitioner } from 'interfaces';
import { PractitionerDropDown } from './PractitionerDropDown';

const AdminPractitionerCalendarPage: React.FunctionComponent = () => {

  const [practitioner, setPractitioner] = useState<IPractitioner | undefined>(undefined);

  useEffect(()=>{}, [practitioner])

  return (
    <>


      {practitioner &&
        <>
        Physician: <PractitionerDropDown onSelect={setPractitioner} initialPractitionerId={practitioner ? practitioner.id.toString() : ""} />
        <AdminPractitionerCalendar practitioner={practitioner} />
        </>
      }

      {!practitioner &&
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
            <div className="alert alert-warning my-3 text-center">Please select a physician below to view their calendar.</div>
            <PractitionerDropDown onSelect={setPractitioner} initialPractitionerId={""} />
            </div>
          </div>
        </div>

      }

    </>
  );
}

export { AdminPractitionerCalendarPage }