import axios from "axios";
import { IAppointmentResponseItem } from "components/patient/appointments";
import { config } from "config";
import { IPractitionerAppointment } from "interfaces/appointment/IPractitionerAppointment";
import { AppointmentPaymentMethodEnum } from "_constants/AppointmentPaymentMethod";
import { Helper } from "_utils";
import { AuthenticationService, patientAuthenticationService } from "./AuthenticationService";

class PatientService {
  constructor(
    protected basePath: string,
    protected authService: AuthenticationService,
  ) {}

  /**
   * retrieve the appointments for the logged-in Patient
   * @returns Promise<IAppointmentResponseItem[]>
   */
  async getAppointments(): Promise<IAppointmentResponseItem[]> {
    const url = `${this.basePath}/management/appointments`;
    try {
      const response: any = await axios.get<IAppointmentResponseItem[]>(url, await this.authService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as IAppointmentResponseItem[];
    } catch (error: any) {
      console.error('error getting appointments', {error});
      throw Error((error as Error).message);
    }
  }

  /**
   * update the appointment of the logged-in patient
   * @param practitionerAppointment 
   * @returns Promise<any>
   */
  async saveAppointment(practitionerAppointment: IPractitionerAppointment): Promise<any> {
    const {
      timeslot,
      activationCode: kitActivationCode ,
      reason,
      paymentMethod: paymentMethodId,
      creditCard
    } = practitionerAppointment;

    const basePayload: Object = Helper.removeBlanksFromObject<Object>({
      kitActivationCode,
      paymentMethodId,
      reasonId: reason?.id,
      reasonText: reason?.name,
    });

    try {
      const url = `${this.basePath}/management/appointment/schedule/${timeslot?.id}`;

      let response: any;
      if (paymentMethodId === AppointmentPaymentMethodEnum.PAYMENT_CC_VERIFY) {
        // console.log('posting', {data: {
        //   ...basePayload,
        //   creditCard: {
        //     cardNumber: creditCard?.creditCardNumber,
        //     expirationDate: Helper.getEpochTime(new Date(`${creditCard?.expirationYear}-${creditCard?.expirationMonth}-01`)),
        //     cardCode: creditCard?.cardCode,
        //   }}});
        response = await axios.put(url, {
          ...basePayload,
          creditCard: {
            cardNumber: creditCard?.creditCardNumber,
            expirationDate: `${creditCard?.expirationYear}${creditCard?.expirationMonth.toString().padStart(2, '0')}`,
            cardCode: creditCard?.cardCode,
          }
        }, await this.authService.getAuthHeader())
      } else {
        response = await axios.put(url, basePayload, await this.authService.getAuthHeader())
      }

      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (error: any) {
      console.error('error saving appointment', {error});
      throw Error(`error saving appointment: ${(error as Error).message}`);
    }
  }

  async rescheduleAppointment (newAppointmentId: number, oldAppointmentId: number) {
    const url = `${this.basePath}/management/appointment/reschedule`;
    try {
      const response = await axios.put(url, {newAppointmentId, oldAppointmentId}, await this.authService.getAuthHeader())
      if (response.data.error) {
        throw new Error(response.data.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.error('error rescheduling', {error});
      throw Error(`could not reschedule appointment: ${(error as Error).message} `);
    }
  }

  async cancelAppointment (appointmentId: number) {
    const url = `${this.basePath}/management/appointment/cancel/${appointmentId}`;
    try {
      const response = await axios.put(url, {}, await this.authService.getAuthHeader())
      if (response.data.error) {
        throw new Error(response.data.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.error('error rescheduling', {error});
      throw Error(`could not reschedule appointment: ${(error as Error).message} `);
    }
  }

}

const patientService = new PatientService(`${config.apiDomain}${config.apiBasePath}/user`, patientAuthenticationService);

export { patientService }