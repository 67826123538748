import { config } from "config";
import { IUser } from "interfaces";
import { practitionerAuthenticationService } from "./AuthenticationService";
import { BaseApiService } from "./common";

class PractitionerApiService<T> extends BaseApiService<T> {


  async getAll () {
    return await super.getAll(true, true);
  }

}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/practitioner`;
const practitionerApiService: PractitionerApiService<IUser> = new PractitionerApiService<IUser>(apiBaseUrl, '/management/user', '/management/users', practitionerAuthenticationService);

export { practitionerApiService }