import React from 'react';

import { FieldWithError } from 'components/common';
import { Form, Formik } from 'formik';
import { validationMap, Validator } from 'schemas';
import { MonthDropDown } from 'components/common/form/MonthDropDown';
import { YearDropDown } from 'components/common/form/YearDropDown';
import { ICreditCard } from 'interfaces/appointment/ICreditCard';

const CreditCardForm: React.FunctionComponent<{onSubmit: (creditCard: ICreditCard)=>void}> = ({onSubmit}) => {

  const initialValues = {
    creditCardNumber: null,
    expirationMonth: null,
    expirationYear: null,
    cardCode: null,
  }

  const onSubmitForm = (data: any, actions: any) => {
    const creditCard: ICreditCard = data;
    onSubmit(creditCard);
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationMap.get(Validator.CREDIT_CARD_FORM)} onSubmit={onSubmitForm}>
      {({ status, errors, touched, isSubmitting }) => {
        return <Form>
          <div className="container">
            <div className="card">
              <h4 className="card-header">Credit Card Details</h4>
              <div className="card-body">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <FieldWithError fieldName="creditCardNumber" label="Credit Card Number" errors={errors} touched={touched} />
                    </div>

                    <div className="col-8">
                      <div className="form-group">
                      <label>Exp Month</label>
                      <MonthDropDown name="expirationMonth" />
                      </div>
                      
                    </div>
                    <div className="col-4">
                    <div className="form-group">
                      <label>Exp Year</label>
                      <YearDropDown name="expirationYear" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <FieldWithError fieldName="cardCode" label="CVV Code" errors={errors} touched={touched} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 my-2">
                    <button className="btn btn-primary" type="submit">Next</button>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </Form>
      }}
    </Formik>
  );
}

export { CreditCardForm }