import React from "react";
import { AddressFields, BaseForm, FieldWithError, FileUpload } from "components/common";
import { IDataField, IUser } from "interfaces";
import { adminPractitionerApiService as apiService, adminStorageService } from "services";
import { Validator, validationMap } from 'schemas';
import { RoleDropDown } from "./RoleDropDown";
import { SpecialtyDropDown } from "./SpecialtyDropDown";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IUser) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField[];

}

const PractitionerForm: React.FunctionComponent<IAppProps> = ({
  isEditing, onSuccess, initialValues, onCancelClick, editableFields }: IAppProps) => {

  const beforeSubmit = async (data: any, actions: any) => {
    try {

      // only update if its object
      if (typeof data.avatar === "object") {
        data.avatar = await adminStorageService.uploadPublicImage(data.avatar);
      } else {
        delete data['avatar'];
      }

      if (data.phoneNumber && typeof data.phoneNumber === "string") {
        data.phoneNumber = data.phoneNumber.replaceAll(/[^\d]/g, '');
      }
      console.log('uploaded to', { data });
    } catch (error: any) {
      throw Error(error.message);
    }
  };


  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.COMMON_ADDRESS)}
      beforeSubmit={beforeSubmit}
      editableFields={editableFields}
    >
      {({ errors, touched, setFieldValue, values }) => {
        return (
          <>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

            <div className="container">
              <div className="row">
                <div className="col">
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="email"
                    placeholder="E-Mail Address"
                    label="Email Address"
                  />

                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="organization"
                    placeholder="Organization"
                    label="Organization"
                  />
                  
                  <RoleDropDown disabled={isEditing} name="roleId" errors={errors} label="Role" touched={touched} />
                  <SpecialtyDropDown name="specialityId" errors={errors} label="Medical Specialty" touched={touched} />
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="degree"
                    placeholder="Degrees"
                    label="Degrees (separated by comma)"
                  />
                </div>

                <div className="col">
                  <FileUpload
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    name="image-upload"
                    targetField="avatar"
                    touched={touched}
                    title="Avatar"
                    initialUrl={values["avatarUrl"]}
                  />
                </div>
              </div>

              <div className="row my-3">
                <FieldWithError 
                    fieldName="about"
                    errors={errors}
                    touched={touched}
                    label="About Me" 
                    component="textarea"
                    rows={8}></FieldWithError>
              </div>
              <hr />
              <div className="row my-3">
                <div className="col">
                  <AddressFields values={values} errors={errors} touched={touched} setFieldValue={setFieldValue} />
                </div>
              </div>

            </div>
          </>
        )
      }}
    </BaseForm>
  );
};


export { PractitionerForm }