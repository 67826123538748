import React, { useEffect, useState } from 'react';
import { IAppointmentResponseItem } from 'components/patient/appointments';
import { Helper } from '_utils';

interface IProps {
  appts: IAppointmentResponseItem[],
  onAppointmentClick: (appt: IAppointmentResponseItem) => void,
  onDateClick: (date: Date) => void,
}

const PreviousMonthButton:React.FunctionComponent<{calendarDate: Date, setCalendarDate: any}> = ({calendarDate, setCalendarDate}) => {
  const date = new Date(calendarDate);
  const prevMonth: Date = new Date(date.setMonth(date.getMonth()-1));
  return (
  <button className="btn btn-primary mx-1" onClick={()=>setCalendarDate(prevMonth)}>&lt;&lt; {prevMonth.toLocaleString('en-us', {month: 'short'})}</button>
  );
}

const NextMonthButton:React.FunctionComponent<{calendarDate: Date, setCalendarDate: any}> = ({calendarDate, setCalendarDate}) => {
  const date = new Date(calendarDate);
  const nextMonth: Date = new Date(date.setMonth( (date.getMonth()+1)));
  return (
  <button className="btn btn-primary mx-1" onClick={()=>setCalendarDate(nextMonth)}>{nextMonth.toLocaleString('en-us', {month: 'short'})} &gt;&gt;</button>
  );
}

const Calendar: React.FunctionComponent<IProps> = ({ appts: appointments, onAppointmentClick, onDateClick }) => {

  const dayNames: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const firstDate = new Date();
  firstDate.setDate(1);
  const [startCalendarDate, setStartCalendarDate] = useState<Date>(firstDate);
  const [appts, setAppts] = useState<IAppointmentResponseItem[]>(appointments);


  useEffect(() => {
    setAppts(appointments);
  }, [appointments]);

  const grid: any[] = [];
  let dayCounter = 0;
  const date = new Date(startCalendarDate.getTime() + (86400000 * (0 - startCalendarDate.getDay())));
  for (let row: number = 0; row < 6; row++) {
    let cols = [];
    for (let col: number = 0; col < 7; col++) {
      let calendarDate = (new Date(date.getTime() + (86400000 * dayCounter)))
      if (col === 0 && row > 4 && calendarDate.getMonth() !== startCalendarDate.getMonth()) { break; }
      if (col < date.getDay() && row === 0) {
        cols[col] = <div></div>  //`row ${row}, col ${col}`;
      } else {
        // get the slots that are in the selected day
        let slots = appts.filter((appt: IAppointmentResponseItem) => Helper.isInDay(new Date(appt.start * 1000), new Date(appt.end * 1000), calendarDate));
        cols[col] =
          <div className="calendar-block">
            <div role="button" onClick={() => onDateClick(calendarDate)} className={`calendar-day ${calendarDate.getMonth() !== startCalendarDate.getMonth() ? 'text-muted' : 'fw-bold'}`}>
              {calendarDate.getDate()}
            </div>
            {slots.length > 0 && <div className="calendar-day-content">
              {slots.map((appt: IAppointmentResponseItem) => <>
                <button disabled={appt.start <= new Date().getTime() /1000 } onClick={() => { onAppointmentClick(appt) }} className={`m-1 btn calendar-day-content-timeslot calendar-day-content-timeslot-${appt.statusId}`}>{Helper.getFormattedTimeHHMM(new Date(appt.start * 1000))}</button>
              </>)}
            </div>
            }
          </div>
        dayCounter++;
      }
    }
    grid[row] = cols;
  }

  return (
    <>
      <table className="table table-striped calendar">
        <thead className="table-dark">
          <tr className="calendar-month-name table-light">
            <th colSpan={7}>{`${startCalendarDate.toLocaleString('en-us', { month: 'long' })} ${startCalendarDate.getFullYear()}`}
              <div className="d-flex justify-content-end">
                <PreviousMonthButton calendarDate={startCalendarDate} setCalendarDate={setStartCalendarDate} />
                <NextMonthButton calendarDate={startCalendarDate} setCalendarDate={setStartCalendarDate} />
              </div>

            </th>
          </tr>
          <tr>{dayNames.filter((col: any, i: number) => i !== 0).map(((day, i) => <th className="text-center" key={`dayName-${i}`}>{day}</th>))}</tr>
        </thead>
        <tbody>
          {
            grid.map((row, i: number) => <tr key={`grid-row-${i}`}>
              {
                row.filter((col: any, i: number) => i !== 0).map((col: any, i: number) => <td className="border calendar-day-block" key={`day-block-${i}`}> {/* filters Sat and Sun */}
                  {col}
                </td>)
              }
            </tr>)
          }
        </tbody>
      </table>
    </>
  );
}

export { Calendar }