import React, { useContext, useEffect, useState } from 'react';
import { AccountContext, AddressFields, ConfirmCheck } from 'components/common';
import { IAccount, IAddressFieldMap, IAppointment, IPractitioner, ITimeSlot } from 'interfaces';
import { Form, Formik } from 'formik';
import { GuarantorRelationshipDropDown } from 'components/common/form/GuarantorRelationshipDropDown';
import { AppointmentContext } from '../scheduler/AppointmentContext';
import { AppointmentPaymentMethodEnum } from '_constants/AppointmentPaymentMethod';
import { ModalAppointmentPicker } from 'components/practitioner/scheduler/ModalAppointmentPicker';
import { InsuranceDetails } from './InsuranceDetails';
import { publicApiService } from 'services';
import { InsuranceCompanyEnum, insuranceCompanyNames } from '_constants/InsuranceCompanyEnum';
import { InsuranceCompanyDropDown } from 'components/common/form/InsuranceCompanyDropDown copy';
import { ModalAgreement } from 'components/ModalAgreement';
import { ConsentBilling } from 'components/common/_static/ConsentBilling';
import { validationMap, Validator } from 'schemas';



const InsForm: React.FunctionComponent<{
  values: any,
  status: any,
  errors: any,
  touched: any,
  setFieldValue: any,
}>
  = ({ values, status, errors, touched, setFieldValue }) => {

    const guarantorFieldMap: IAddressFieldMap = {
      firstName: {fieldName: 'guarantorFirstName', fieldTitle: 'Guarantor First Name'},
      lastName: {fieldName:'guarantorLastName', fieldTitle: 'Guarantor Last Name'},
      address1: {fieldName:'guarantorAddress1', fieldTitle: 'Guarantor Address 1'},
      address2: {fieldName:'guarantorAddress2', fieldTitle: 'Guarantor Address 2'},
      city: {fieldName:'guarantorCity', fieldTitle: 'Guarantor City'},
      state: {fieldName:'guarantorState', fieldTitle: 'Guarantor State'},
      zipcode: {fieldName:'guarantorZipcode', fieldTitle: 'Guarantor Zip Code'},
      phoneNumber: {fieldName:'guarantorPhoneNumber', fieldTitle: 'Guarantor Phone Number'},
      dateOfBirth: {fieldName:'guarantorDateOfBirth', fieldTitle: 'Guarantor Date of Birth'},
    }

    const accountContext = useContext(AccountContext);
    const apptContext = useContext(AppointmentContext);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showConsent, setShowConsent] = useState<boolean>(false);

    const onCheckSameAddress = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
      if (e.target.checked) {
        const account: IAccount | undefined = accountContext?.account;
        if (!account) {
          console.warn('no account found');
          return;
        }
        setFieldValue('guarantorAddress1', account.address1);
        setFieldValue('guarantorAddress2', account.address2);
        setFieldValue('guarantorCity', account.city);
        setFieldValue('guarantorState', account.state);
        setFieldValue('guarantorZipcode', account.zipcode);
        setFieldValue('guarantorPhoneNumber', account.phoneNumber);
        setFieldValue('guarantorDateOfBirth', account.dateOfBirth);
      } else {
        setFieldValue('guarantorAddress1', '');
        setFieldValue('guarantorAddress2', '');
        setFieldValue('guarantorCity', '');
        setFieldValue('guarantorState', null);
        setFieldValue('guarantorZipcode', '');
        setFieldValue('guarantorPhoneNumber', undefined);
        setFieldValue('guarantorDateOfBirth', undefined);
      }
    }

    const onAppointmentClick = (timeslot: ITimeSlot, practitioner: IPractitioner) => {
      //console.log('appt clicked', { timeslot, practitioner });
      apptContext?.setAppointment(timeslot, practitioner);
      //setFieldValue('insuranceCompanyId', apptContext?.appointment.insuranceCompanyId);
      setShowModal(false);
    }

    useEffect(() => {
      // check if selected insurance company is different AND check if appointment's doctor accepts selected.

      const _load = async () => {
        // check selected
        // eslint-disable-next-line eqeqeq
        if (values.insuranceCompanyId == apptContext?.appointment.insuranceCompanyId) {
          setShowModal(false);
          return;
        }

        // check doctor
        const filters: { practitionerId: number, insuranceCompanyId: number } = {
          practitionerId: apptContext?.appointment.practitioner?.id || 0,
          insuranceCompanyId: parseInt(values.insuranceCompanyId, 10)
        };
        const appts: IAppointment[] = await publicApiService.getAppointments(filters);
        setShowModal(appts.filter((appt: IAppointment) => appt.id === apptContext?.appointment.timeslot?.id).length === 0);
      }
      _load();

    }, [
      values.insuranceCompanyId,
      apptContext?.appointment.insuranceCompanyId,
      apptContext?.appointment.practitioner?.id,
      apptContext?.appointment.timeslot?.id
    ]);

    return <Form>
      <div className="container g-0 insurance-form">
        <div className="row">
          <div className="col">
            <InsuranceCompanyDropDown errors={errors} label="Insurance Company" name="insuranceCompanyId" touched={touched} />
            {values.insuranceCompanyId === InsuranceCompanyEnum.OTHER.toString() &&
              <div className="alert alert-warning my-3">
                Unfortunately, we currently only work with the listed insurance carriers. If you would like to continue without insurance coverage, please use the Credit Card section below.
              </div>
            }

            {values.insuranceCompanyId === InsuranceCompanyEnum.SELF_PAY.toString() &&
              <div className="alert alert-warning my-3">
                If you would like to pay of out pocket, please use the Credit Card option below
              </div>
            }

            {values.insuranceCompanyId !== "0" &&
              values.insuranceCompanyId !== InsuranceCompanyEnum.SELF_PAY.toString() &&
              values.insuranceCompanyId !== InsuranceCompanyEnum.OTHER.toString() &&

              <ModalAppointmentPicker
                handleClose={() => setShowModal(false)}
                title="Pick Appointment"
                insuranceCompanyId={values.insuranceCompanyId}
                show={showModal}
                onAppointmentClick={onAppointmentClick}>
                <div className="alert alert-warning">
                  {insuranceCompanyNames.get(parseInt(values.insuranceCompanyId, 10))} is not accepted by the doctor you selected, Dr. {apptContext?.appointment.practitioner?.lastName}.
                  Please pick from the doctors who accept {insuranceCompanyNames.get(parseInt(values.insuranceCompanyId, 10))} below.
                </div>
              </ModalAppointmentPicker>
            }
          </div>
        </div>

        <div className="row">
          <div className="col">
            <InsuranceDetails values={values} setFieldValue={setFieldValue} errors={errors} touched={touched} />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col">
            <h4 className="card-header">Insurance Guarantor Form</h4>
            <hr />
            <GuarantorRelationshipDropDown errors={errors} touched={touched} name="guarantorRelationshipId" label="Patient's Relationship to Guarantor" />
            <div className="small-text-2 mt-3">
              <div className="title">Guarantor Address</div>
              <div className="">
                <input className="me-1" type="checkbox" name="chk-same-as-self" id="same-as-self" value="1" onChange={(e) => onCheckSameAddress(e, setFieldValue)} />
                <label htmlFor="chk-same-as-self">Use Same Address as Patient</label>
              </div>

            </div>
            <AddressFields values={values} setFieldValue={setFieldValue} errors={errors} touched={touched} fieldMap={guarantorFieldMap} />
          </div>
        </div>


        <ModalAgreement show={showConsent} handleClose={() => setShowConsent(false)} title="Consent for Billing">
          <ConsentBilling />
        </ModalAgreement>
        <ConfirmCheck name="agreeConsentBilling" errors={errors} touched={touched}>
          <div className="agree-billing align-items-center">
            I agree to the <a className="btn btn-link p-0 m-0 btn-agree-billing" onClick={() => { setShowConsent(true) }}>Consent for Billing</a>
          </div>
        </ConfirmCheck>


       {/* {touched && Object.keys(touched).length > 0 && errors && Object.keys(errors).length > 0 &&  <div className="alert alert-danger my-3">There are errors on the form. Please review and correct them before submitting again.</div>} */}

        <button type="submit" className="btn btn-primary mt-3" disabled={
          !(values.insuranceCompanyId !== "0" &&
            values.insuranceCompanyId !== InsuranceCompanyEnum.SELF_PAY.toString() &&
            values.insuranceCompanyId !== InsuranceCompanyEnum.OTHER.toString())
        }>Save Insurance</button>

        {status && <div className="alert alert-warning mt-2">{status}</div>}
      </div>
    </Form>
  }

const InsuranceForm: React.FunctionComponent<{ onSuccess?: () => void }> = ({ onSuccess }) => {

  const accountContext = useContext(AccountContext);
  const apptContext = useContext(AppointmentContext);

  const initialValues = {
    insuranceCompanyId: accountContext?.account.insuranceCompanyId,
    insurancePhoneNumber: accountContext?.account.insurancePhoneNumber || undefined,
    insuranceMemberId: accountContext?.account.insuranceMemberId || "",
    insuranceGroupId: accountContext?.account.insuranceGroupId || "",

    agreeConsentBilling: false,

    guarantorRelationshipId: accountContext?.account.guarantorRelationshipId || 0,
    guarantorFirstName: accountContext?.account.guarantorFirstName || "",
    guarantorLastName: accountContext?.account.guarantorLastName || "",
    guarantorAddress1: accountContext?.account.guarantorAddress1 || "",
    guarantorAddress2: accountContext?.account.guarantorAddress2 || "",
    guarantorCity: accountContext?.account.guarantorCity || "",
    guarantorState: accountContext?.account.guarantorState || "",
    guarantorZipcode: accountContext?.account.guarantorZipcode || "",
    guarantorPhoneNumber: accountContext?.account.guarantorPhoneNumber || undefined,
    guarantorDateOfBirth: accountContext?.account.guarantorDateOfBirth || undefined,
  }

  const onSubmit = async (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;

    const {
      insuranceCompanyId,
      insurancePhoneNumber,
      insuranceMemberId,
      insuranceGroupId,
      guarantorRelationshipId,
      guarantorFirstName,
      guarantorLastName,
      guarantorAddress1,
      guarantorAddress2,
      guarantorCity,
      guarantorState,
      guarantorZipcode,
      guarantorDateOfBirth,
      guarantorPhoneNumber,
    } = data;

    const accountData: IAccount = {
      //insuranceName: insuranceCompanyNames.get(data.insuranceId),
      insuranceCompanyId,
      insurancePhoneNumber: (typeof insurancePhoneNumber === 'string' ? parseInt(insurancePhoneNumber.replaceAll(/[^\d]/g, '')) : insurancePhoneNumber),
      insuranceMemberId,
      insuranceGroupId,
      guarantorRelationshipId,
      guarantorFirstName,
      guarantorLastName,
      guarantorAddress1,
      guarantorAddress2,
      guarantorCity,
      guarantorState,
      guarantorZipcode,
      guarantorDateOfBirth,
      guarantorPhoneNumber: (typeof guarantorPhoneNumber === 'string' ? parseInt(guarantorPhoneNumber.replaceAll(/[^\d]/g, '')) : guarantorPhoneNumber),
    }

    // check if they selected the same insurance id
    // if not check if the doctor they selected accepts the newly selected insurance company
    // if (data.insuranceCompanyId !== apptContext?.appointment.insuranceCompanyId) {
    // }

    try {
      if (guarantorAddress2 === '') {
        delete accountData["guarantorAddress2"]
      }
      console.log('account update', {accountData});
      await accountContext?.update(accountData);
      setSubmitting(false);
      apptContext?.setPaymentMethod(AppointmentPaymentMethodEnum.INSURANCE);
      apptContext?.setInsurance(insuranceCompanyId);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error: any) {
      console.error('error updating account', { error });
      setStatus((error as Error).message);
    }
  }

  // const onClickSameAddress = (setFieldValue: any) => {
  //   const account: IAccount | undefined = accountContext?.account;
  //   if (!account) {
  //     console.warn('no account found');
  //     return;
  //   }
  //   setFieldValue('guarantorAddress1', account.address1);
  //   setFieldValue('guarantorAddress2', account.address2);
  //   setFieldValue('guarantorCity', account.city);
  //   setFieldValue('guarantorState', account.state);
  //   setFieldValue('guarantorZipcode', account.zipcode);
  // }


  return <>
    <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationMap.get(Validator.INSURANCE_FORM)}>
      {({ values, status, errors, touched, setFieldValue }) => {
        return <InsForm values={values} status={status} errors={errors} touched={touched} setFieldValue={setFieldValue} />
      }}
    </Formik>
  </>;
}




export { InsuranceForm }

