import React, { useEffect } from 'react';
import { useLandingPage } from '_hooks/useLandingPage';

const LandingPage: React.FunctionComponent<{children: any}> = ({children}) => {

  const landingPage = useLandingPage();
  useEffect(()=>{
    landingPage.setLandingPage();
  },[])

  return <>{children}</>;
}

export { LandingPage }