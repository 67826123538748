import React from 'react';
import { IPractitioner, ITimeSlot } from "interfaces";
import { PractitionerCardTall } from 'components/common/PractitionerListingPage/PractitionerCardTall';


const AppointmentConfirmation: React.FunctionComponent<{ practitioner: IPractitioner, timeslot: ITimeSlot }> = ({ practitioner, timeslot }) => {
  return <>

    <div className="container-md">
      <div className="medium-text-1 dark-blue strong mb-3 py-3 text-center">
        <div className="mt-3"></div>
        Your appointment request has been made
      </div>
      <div className="small-text-1 mb-3 py-3 text-center">
        Thank you for choosing an appointment with us. You will receive an email and text soon to finalize your appointment.
      </div>

      <PractitionerCardTall about="" practitioner={practitioner}>
        <div className="container g-0">
          <div className="small-text-1 strong text-center">Appointment Details</div>
          <div className="small-text-1 text-center">Date: {timeslot.date}</div>
          <div className="small-text-1 text-center">Time: {timeslot.startTime} - {timeslot.endTime}</div>
          <div className="small-text-1 margin-center appt-checked">&nbsp;</div>
        </div>
      </PractitionerCardTall>
      <div className="mb-3">&nbsp;</div>

    </div>




  </>;

}

export { AppointmentConfirmation }