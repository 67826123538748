import React from "react";

const Terms = () => {
  return (
    <div className="terms-and-conditions container mt-5 mb-3 bg-lighterblue p-5">

<div className="medium-text-1 strong text-center my-3">CONVENA CARE</div>
<div className="small-text-3 strong text-center my-3">Patient Portal Terms and Conditions</div>

<p>These terms and conditions constitute a binding agreement between you and CONVENA CARE ("we," "us," or "our").</p>

<p>The Patient Portal is an Internet service that allows our patients to: communicate with us; access their health records; request or reschedule appointments; view and update personal information; request prescriptions; receive test results; read patient educational material; view statements; pay bills; and access related services and content.</p>

<p>The Patient Portal is provided on behalf us and our physicians, employees, managers, officers, directors, agents, and representatives and powered by athenahealth, Inc. (all of the foregoing, collectively, the "Practice Entities"). The Practice Entities are intended third-party beneficiaries of this agreement and entitled to enforce all terms and conditions of this agreement.</p>

<p>By accessing or using the Patient Portal, you confirm that you agree to these terms and conditions. If you don't agree, don't use the Patient Portal. By agreeing to these terms and conditions, you acknowledge that you are at least 18 years of age, or legally emancipated, and that you are requesting access to Patient Portal. You acknowledge that Patient Portal is offered as a courtesy to our patients and agree that we may limit or discontinue your use of Patient Portal at any time for any reason.</p>

<p>
  <div className="strong">Minimum Requirements and Security</div>
  <ul>
    <li>This website uses encryption software. In order to use Patient Portal, you must have:
      <ul>
        <li>a personal computer equipped with an Internet browser that has 128-bit encryption enabled and configured to accept cookies (e.g., Internet Explorer 7 or 8, Safari, or Firefox; Internet Explorer 6 may not allow you to view some materials, such as test results);</li>
        <li>Internet access, a valid email account, software to receive and read email messages, and spam filters set to accept email from our domain; and</li>
        <li>Adobe Reader installed (to download a copy of Adobe Reader, please go to http://www.adobe.com/products/acrobat/readstep2.html).</li>
      </ul>
    </li>
    <li>While we use state-of-the-art security, no system can guard against risks of intentional intrusion or inadvertent disclosure of information. When using Patient Portal, information may be transmitted over media that are beyond the control of the Practice Entities and their contractors and subcontractors and that may not be secure. For example, you may receive email, text, or telephone communications in connection with your use of Patient Portal, all of which are inherently unsecure and subject to disclosure to or access by third parties (e.g., if your phone is used by someone else, you do not keep your phone or email information up to date on Patient Portal and communications are misdirected, or the network or systems of a telecommunications provider are hacked). Furthermore, electronic services such as those provided by the Practice Entities, their contractors and subcontractors, and any telecommunications providers involved in the transmission of data are all inherently subject to failure and none of the foregoing can guarantee that their services will be provided without error or interruption at all times that you may wish to use those services. YOU HEREBY EXPRESSLY ASSUME THE RISK OF ANY UNAUTHORIZED DISCLOSURE OR INTENTIONAL INTRUSION, OR OF ANY DELAY, FAILURE, INTERRUPTION, OR CORRUPTION OF DATA OR OTHER INFORMATION TRANSMITTED IN CONNECTION WITH THE USE OF ANY SERVICE RELATED TO PATIENT PORTAL. Once information is received by us, your medical information will be treated as confidential and given the same protection that all of our other medical records are given.</li>
  </ul>
</p>

<p>
<div className="strong">Security, Confidentiality, and Usage Guidelines for Patient Portal</div>
<ul>
<li>YOU MUST NOT SEND ANY MESSAGES REQUIRING URGENT ATTENTION USING PATIENT PORTAL. Doing so may create a delay in your receiving necessary medical treatment and could result in loss of life, permanent injury, or significant deterioration in your health. If you believe that you have an urgent medical matter, you should call 911 immediately or proceed to the nearest emergency room. If the matter is not urgent but you need a response in less than two business days, please contact your clinician's office directly by telephone during our regular business hours, which you may find on the log-in screen for Patient Portal.
<div className="strong">FOR ALL MEDICAL EMERGENCIES, IMMEDIATELY CALL 911</div></li>

<li>Patient portal is designed as a secure Internet-based environment through which you may receive confidential medical information about yourself. When you initially enroll to use the Patient Portal, you will need to confirm your identity via two unique workflows, as well as establish an email and a confidential password for login credentials. Please DO NOT SHARE YOUR PASSWORD with anyone. Anyone with access to your password will be able to view your medical and financial information and communicate with your health care team as if that person were you. Thus, when you give someone your password, you are authorizing that person to access your account, and you are responsible for all transactions that person performs while using your service. It is your responsibility to prevent disclosure of your password and to change your password if you feel that your security has been compromised. If you believe that your password has become known to an unauthorized person and want verification of any changes that may have been made to your account, please contact us for assistance. You can change your password in the Patient Portal at any time by clicking the "Security Settings" link under "My Profile."</li>
<li>You may request a Patient Portal account for yourself. You may not request or access a Patient Portal account for anyone else, except that a parent or guardian may request and access an account on behalf of a minor child. By accepting these terms and conditions, you certify that you are authorized to open or access the account you are requesting and that all information you have provided to us is true and correct to the best of your knowledge.</li>
<li>Once you have activated your Patient Portal account, the Patient Portal Inbox will become the primary vehicle to receive important messages from your participating provider(s) and other personal medical information. You MUST provide us with your email address when activating your Patient Portal account and keep your email address current via the My Profile link, to ensure that you receive notification of newly released information in a timely manner. You may continue to receive communications via the United States Postal Service or other means from us.</li>
<li>We keep track of the pages our patients visit within Patient Portal, in order to determine what services are the most popular. This data is used to deliver customized content within Patient Portal to patients whose behavior indicates that they are interested in a particular subject area.</li>
<li>We secure your personal information from unauthorized access, use or disclosure. The personally identifiable information you provide is maintained by us on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When personal information is transmitted from your PC and/or mobile devices to Patient Portal, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol. As a user of Patient Portal you hereby agree that you (and not the Practice Entities) are solely liable for viruses, worms, Trojan horses, cancel bots, and other electronic mechanisms designed to destroy or impair the functioning of computer systems already residing on your PC and/or mobile device.</li>
<li>When you use the Patient Portal, we collect personally identifiable information, such as your email address, name, home address, telephone number, ZIP code, age, gender, contact preferences, access times, and account activity. This information is used by us for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of Patient Portal. We may share data with business associates working on our behalf to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. We encourage you to review the Privacy Statement for further information regarding how your personal information may be used.</li>
<li>Cookies are used for system performance functionality. You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Patient Portal services.</li>
</ul>
</p>

<p>
<div className="strong">How We Protect Your Personal Health Information</div>
<ul>
<li>We consider the privacy of your health information to be one of the most important elements in our relationship with you and one we take very seriously. By accessing or using Patient Portal, you acknowledge that you have received and read a copy of our Notice of Privacy Practices, which can be found in paper copy at our office locations or through your Patient Portal account under the "Forms" link.</li>
<li>We are committed to protecting the privacy of the information you send and receive through Patient Portal, and will only collect and use such information as permitted under the Patient Portal Privacy Statement, which can be found online within Patient Portal. By using Patient Portal, you also consent to all of the terms and conditions described in the Privacy Statement.</li>
<li>All communications between you and our health care team using Patient Portal are carried over a secure, encrypted connection directly into our electronic medical record. While you may receive email messages notifying you of new messages in your Patient Portal Inbox, these emails will not contain any personal health information. It is your responsibility to login to your Patient Portal account and read the messages promptly.</li>
</ul>
</p>

<p>
<div className="strong">Your Responsibilities</div>
<li>Patient Portal is a communication service offered as a convenience to our patients. We reserve the right to change the terms, conditions, and notices under which Patient Portal is offered. By accessing or using Patient Portal, you further agree that any and all such modifications are effective and binding upon you immediately upon posting of the modified version. We reserve the right in the future to charge a fee for the use of Patient Portal. You will be notified in advance of any such change and asked to re-apply if you agree to the fee and wish to continue with the Patient Portal service.</li>
<li>You understand that by accepting the terms and conditions of this Agreement you are agreeing to receive your patient balance statements delivered electronically to your Patient Portal account. You have the option at any time to revert back to receiving your patient balance statements as paper statements sent to you via U.S. Mail. You may exercise that option by checking the appropriate checkbox located at the bottom of the Billing Tab in Patient Portal under "Patient Preferences."</li>
<li>You acknowledge that by entering your home and/or mobile telephone number into Patient Portal you are providing written consent for the Practice Entities to send automated, prerecorded, or artificial voice telephone calls to all provided numbers. You may opt-out of receiving these calls on the "Contact Preferences" page, located under the My Profile tab.</li>
<li>You understand that Patient Portal provides you with online access to some clinical information about you. You are solely responsible for any sharing of Patient Portal content that you intentionally or unintentionally communicate to others.</li>
</p>

<p>
<div className="strong">Patient Portal Messaging</div>
<li>By using Patient Portal, you acknowledge and agree that this messaging service is intended to facilitate dialogue regarding personal health matters. You agree not to use Patient Portal to post or send or post any illicit or offensive material.</li>
<li>You understand that messages will be routed to the appropriate department as necessary for handling, and therefore our employees other than your physician, nurse practitioner, or physician's assistant may be involved in addressing your request. If your physician is out of the office or unavailable to respond, messages sent via Patient Portal may be routed to other authorized healthcare providers within our practice to facilitate a timely response to your request. Therefore, use of Patient Portal may not be appropriate if there is sensitive information that you want to discuss directly with your healthcare provider.</li>
<li>You understand that your health care team may send you messages, test results, and other communications via Patient Portal as authorized in the sole discretion of your physician. These messages may contain information important to your health and medical care. It is your responsibility to monitor these messages. By entering your valid and functional email address at registration, you have enabled us to notify you of messages sent to your Patient Portal Inbox.</li>
</p>

<p>
<div className="strong">Discontinuing Use of Patient Portal</div>
<li>You may discontinue your use of Patient Portal at any time by sending a secure message to your primary care provider's office requesting this change.</li>
<li>We reserve the right, in our sole discretion, to limit or discontinue your use of Patient Portal at any time and for any reason, including, but not limited to, your use of Patient Portal on behalf of another person.</li>
<li>Your Patient Portal service will be discontinued if you no longer have a primary care provider who participates in Patient Portal at your practice.</li>
<li>You will be notified if your Patient Portal service is discontinued.</li>
</p>

<p>
<div className="strong">Your Clinical Information</div>
<li>As we provide your health care, we are required to maintain documentation of your medical history, current conditions, treatment plan, and all treatments given, including the results of all tests, procedures, and therapies. Patient Portal is an Internet application that enables a patient to have secure web-based access to personal clinical information, as released by the treating physician, and allows secure electronic messaging with our participating physicians. By using Patient Portal, you understand that not all of your medical record with us will be accessible through your Patient Portal account. Posting of information to your Patient Portal account is at the sole discretion of your individual physician(s).</li>
<li>Messages you send or receive via Patient Portal may become part of your permanent medical record at the sole discretion of your physician. If such messages are made part of your permanent medical record, they will be accessible to our current and future staff members who are involved with your care.</li>
<li>All of our electronic medical records are subject to state and federal regulations governing the security and confidentiality of medical records.</li>
</p>

<p>
<div className="strong">Copyright and Trademarks</div>
All content included in Patient Portal and on all of our, including, but not limited to, text, photographs, graphics, button icons, images, artwork, names, logos, trademarks, service marks, and data ("Content"), in any form, are protected by U.S. and international copyright and trademark law and conventions. The Content includes both Content owned or controlled by one or more of the Practice Entities, as well as Content owned or controlled by third parties and licensed to one or more of the Practice Entities, and no right, title, or interest is granted to you in or to any Content other than the right to use such content in connection with your use of Patient Portal. Reproduction of any Content, in whole or in part, by any means, is prohibited without our express written consent.
Site Access and Licenses
<li>We grant to you a non-assignable, non-sublicensable, terminable, limited license to make personal use of our website, Patient Portal, and the associated services in accordance with these terms and conditions. This license expressly excludes, without limitation, any reproduction, duplication, sale, resale, or other commercial use of the website, Patient Portal, or any of the associated services; making any derivative of the website, Patient Portal, or any of the associated services; and the collection and use of user email addresses or other user information (including, without limitation, health information or any data extraction or data mining whatsoever).</li>
<li>Failure to comply with any of the terms and conditions contained herein may result in revocation of your license and cancellation of your Patient Portal account, including the right to access information through Patient Portal. We reserve the right to report violations of law to appropriate law enforcement agencies and to pursue all other rights and remedies available to it under civil or criminal law.</li>
</p>

<p>
<div className="strong">Links to Third-Party Websites</div>
This website and Patient Portal contain links to websites operated by other parties. The Practice Entities do not sponsor, operate, control, or endorse any of these sites, nor the information, products, or services provided by third parties through the Internet, nor do the Practice Entities make any guarantee, warranty, or representation regarding the accuracy of the information contained on the websites. The Practice Entities have no control over the security or privacy practices of these external websites. Use of other sites is strictly at your own risk including, but not limited to, any risks associated with destructive viruses. You are responsible for viewing and abiding by the terms and conditions of use and the privacy statements of the other websites.
</p>

<p>
<div className="strong">No Warranties</div>
This Patient Portal and all other sites hosted by any of the Practice Entities and the content contained herein and therein are provided by the Practice Entities on an "As Is" basis. THE PRACTICE ENTITIES MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF ITS SITES, OR THE CONTENT, PRODUCTS, OR SERVICES INCLUDED THEREIN. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, THE PRACTICE ENTITIES DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND INFRINGEMENT.
</p>

<p>
<div className="strong">Limitation of Liability</div>
<li>Some of the material on our website and in Patient Portal is provided by third parties, and the Practice Entities shall not be held responsible for any such third-party material. The Practice Entities disclaim any responsibility for, or liability related to, such third-party material. Any questions, complaints, or claims related to any product should be directed to the appropriate third party.</li>
<li>None of the Practice Entities or any of their contractors or subcontractors shall be liable for any loss, injury, illness, damages, or claims of any kind resulting from your failure to timely read messages you may receive through Patient Portal.</li>
<li>If you have any basis for recovering damages from the Practice Entities or its affiliates or vendors, you can only recover direct damages up to $10. You cannot recover any other damages, including consequential, lost profits, special, indirect, incidental, or punitive damages.</li>
<li>Some or all of these limitations or exclusions may not apply to you if your state, province, or country does not allow the exclusion or limitation of incidental, consequential, or other damages.</li>
</p>

<p>
<div className="strong">General</div>
By using our website (including, but not limited to, Patient Portal), you agree that the laws of New Jersey, without regard to principles of conflict of laws, will govern these terms and conditions and any dispute that might arise between you and any of the Practice Entities. You expressly agree that exclusive jurisdiction for any dispute with any of the Practice Entities resides in the courts of Somerset County, New Jersey, and you further agree and expressly consent to the exercise of personal jurisdiction in the courts of Somerset County, New Jersey, in connection with any claim involving any of the Practice Entities. Use of our website is unauthorized in any jurisdiction that does not give effect to the terms and conditions set forth herein.
</p>


    </div>
  );
};

export { Terms };
