const PricingDetail = (): JSX.Element => {
return (
  <div className="container mt-5 mx-auto">
  <div className="row p-3 small-text-4 bg-white">
    <div className="col text-start"><span className="dark-blue strong">Preventive Health Visit</span></div>
    <div className="col strong text-end">$150</div>
  </div>
  <div className="row p-3 small-text-4" style={{ backgroundColor: "#E7ECF4" }}>
    <div className="col text-start strong">Primary Care Sick Visit</div>
    <div className="col strong text-end">$125</div>
  </div>
  <div className="row p-3 small-text-4 bg-white">
    <div className="col text-start strong">Fecal Occult Blood Test</div>
    <div className="col strong text-end">$5 + visit price</div>
  </div>
</div>
);
}

export { PricingDetail }