import React, { useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { AppointmentPaymentMethodEnum } from '_constants/AppointmentPaymentMethod';
import { InsuranceCompanyEnum } from '_constants/InsuranceCompanyEnum';
import { AppointmentContext } from '../scheduler/AppointmentContext';
import { CreditCardSection } from './CreditCardSection';
import { InsuranceSection } from './InsuranceSection';


interface IInsurancePreference {
  name: string,
  element: JSX.Element,
}

const InsurancePreference: React.FunctionComponent<{onSuccess: ()=>void}> = ({onSuccess}) => {

  const insuranceCollectionPreferences: Map<number, IInsurancePreference> = new Map<number, IInsurancePreference>([
    [AppointmentPaymentMethodEnum.INSURANCE, { name: 'Complete Insurance Form', element: <InsuranceSection onSuccess={onSuccess} /> }],
    [AppointmentPaymentMethodEnum.PAYMENT_CC_VERIFY, { name: 'No Insurance. Pay with Credit Card', element: <CreditCardSection onSuccess={onSuccess}/> }],
  ]);

  const apptContext = useContext(AppointmentContext);

  const defaultActiveKey = apptContext && apptContext.appointment && apptContext.appointment.insuranceCompanyId && apptContext.appointment.insuranceCompanyId.toString() === InsuranceCompanyEnum.SELF_PAY.toString() ? AppointmentPaymentMethodEnum.PAYMENT_CC_VERIFY : AppointmentPaymentMethodEnum.INSURANCE;

  return (
    <div className="insurance-verification">
      <Accordion defaultActiveKey={defaultActiveKey.toString()}>
        {Array.from(insuranceCollectionPreferences.keys()).map((key: number) => {
          const i: IInsurancePreference | undefined = insuranceCollectionPreferences.get(key);
          return i ? (
              <Accordion.Item key={`insurance-pref-key-${key}`} className="small-text-4 primary-dark" eventKey={key.toString()}>
                <Accordion.Header>
                  <div className="small-text-4 primary-dark">{i.name}</div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="small-text-2 card-body">{i.element}</div>
                </Accordion.Body>
              </Accordion.Item>
          ) : (<>Invalid</>);
        })}
      </Accordion>
    </div>
  );
}

export { InsurancePreference }