// using Athenahealth mapping
// https://docs.athenahealth.com/api/workflows/patient-relationship-mapping

enum GuarantorRelationshipEnum {
  PATIENT = 1,
  SPOUSE = 2,
  PARENT = 3,
  OTHER = 4,
  GRANDCHILD = 5,
  GRANDPARENT = 6,
  UNCLE_OR_AUNT = 7,
  FOSTER_PARENT = 9,
  GUARDIAN = 10,
  STEPMOTHER_OR_STEPFATHER = 11,
  EMPLOYER = 12,
  UNKNOWN = 13,
  SIGNIFICANT_OTHER = 17,
  CHILD = 18,
  LIFE_PARTNER = 26,
}

const guarantorRelationshipNames: Map<number, string> = new Map<number, string>(
  [
    [ GuarantorRelationshipEnum.PATIENT, "Self" ],
    [ GuarantorRelationshipEnum.SPOUSE, "Spouse" ],
    [ GuarantorRelationshipEnum.PARENT, "Parent" ],
    [ GuarantorRelationshipEnum.OTHER, "Other" ],
    [ GuarantorRelationshipEnum.GRANDCHILD, "Grandchild" ],
    [ GuarantorRelationshipEnum.GRANDPARENT, "Grandparent" ],
    [ GuarantorRelationshipEnum.UNCLE_OR_AUNT, "Uncle or Aunt" ],
    [ GuarantorRelationshipEnum.FOSTER_PARENT, "Foster Parent" ],
    [ GuarantorRelationshipEnum.GUARDIAN, "Guardian" ],
    [ GuarantorRelationshipEnum.STEPMOTHER_OR_STEPFATHER, "Stepmother or Stepfather" ],
    [ GuarantorRelationshipEnum.EMPLOYER, "Employer" ],
    [ GuarantorRelationshipEnum.UNKNOWN, "Unknown" ],
    [ GuarantorRelationshipEnum.SIGNIFICANT_OTHER, "Significant Other" ],
    [ GuarantorRelationshipEnum.CHILD, "Child" ],
    [ GuarantorRelationshipEnum.LIFE_PARTNER, "Life Partner" ],
  ]
);

export { GuarantorRelationshipEnum, guarantorRelationshipNames }
