
const ConsentBilling = (): JSX.Element => {
  return (
    <div className="terms-and-conditions container">
      <h1 className="text-center">Consent for Billing</h1>

      <p>CONSENT FOR TREATMENT: By this document, I do hereby request and authorize Convena Care, LLC, its medical practices and providers including physicians, technicians, nurses, and other qualified personnel (CONVENA CARE) to perform evaluation and treatment services and procedures as may be necessary in accordance with the judgment of the attending medical practitioner(s). I acknowledge that no guarantee can be made by anyone concerning the results of treatments, examinations or procedures. </p>

      <p>PRIVACY NOTICE: I acknowledge receipt of the Health Information Privacy Notice for CONVENA CARE on or after 08/14/2022. </p>

      <p>INSURANCE AUTHORIZATION AND ASSIGNMENT: I request that payment of authorized medical benefits is made on my behalf directly to the CONVENA CARE provider of service(s) furnished to me. I authorize CONVENA CARE to release any medical information to my health insurance carrier and/or its legitimate agents that is necessary to process related health insurance claims and/or to verify plan benefits in accordance with HIPAA health information standards. I authorize payment of service(s), otherwise payable to me under the terms of my private, group employer’s or group health insurance plan, directly to CONVENA CARE. I hereby authorize that photocopies of this form to be valid as the original. </p>

      <p>PAYMENT GUARANTEE: I understand that I am responsible for any part of the charges that are not covered/paid by my insurance and I will be billed directly for those services.
        I do hereby guarantee payment of all fees and charges related to all services and durable goods provided to me through CONVENA CARE medical practices and providers from my first date of examination or treatment. I agree to make full payment immediately upon receipt of an CONVENA CARE billing statement whether it is an interim or final bill. In the event that I fail to make full payment or fail to comply with other payment arrangements made with Convena Care’s approval, I understand that appropriate collection measures may be initiated. </p>

      <p>ELECTRONIC HEALTH RECORD: Healthcare providers require access to patient medical information whenever or wherever a patient presents for care to assure safety, quality and to coordinate patient care across the provider network, avoiding duplication of services. CONVENA CARE has a system-wide electronic medical record that is available to caregivers on a “need to know” basis, to share information about patient care provided in the hospital, outpatient or physician office settings. Confidentiality of records including those reflecting treatment for behavioral health issues, HIV/AIDS or drug or alcohol problems is maintained per relevant governmental and regulatory standards. Patient care summaries are automatically sent to designated CONVENA CARE and other community primary care/family/referring physicians, as well as to physicians who are consulted by the attending physician for coordination of care. CONVENA CARE and/or the attending physician can furnish and release to federal and state healthcare oversight agencies, or upon written request, to all insurance companies or their representatives any information with respect to treatment of the patient herein named including copies of the medical record. </p>

      <p>ELECTRONIC PRESCRIBING: I understand that CONVENA CARE medical practices and offices may use an electronic prescription system which allows prescriptions and related information to be electronically sent between my CONVENA CARE providers and my pharmacy. I have been informed and understand that CONVENA CARE providers using the electronic prescribing system will be able to see information about medications I am already taking, including those prescribed by other providers. I give my consent to my CONVENA CARE providers to see this health information. </p>

      <p>IMMUNIZATION REGISTRY: I understand that CONVENA CARE may participate in the New Jersey Dept. of Health’s statewide immunization registry that collects vaccination history and information to serve the public health goal of preventing the spread of vaccine preventable diseases. The registry complies with federal health information privacy laws. </p>

      <p>PERMISSION TO FAX CHILDHOOD IMMUNIZATION RECORD TO SCHOOLS: I do hereby grant permission for CONVENA CARE to send or fax childhood immunization records to schools, upon request. I, or my legal representative, certify that I have read this document, that it has been fully explained to me and that I understand its contents, and hereby agree to all terms and conditions set forth above and acknowledge the receipt of a copy if requested </p>

    </div>
  );
};

export { ConsentBilling };
