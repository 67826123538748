enum SpecialtyEnum {
  FAMILY_PRACTICE = 1, 
  INTERNAL_MEDICINE = 2,
}

const SpecialtyEnumName: Map<number, string> = new Map([
  [SpecialtyEnum.FAMILY_PRACTICE, 'Family Practice'],
  [SpecialtyEnum.INTERNAL_MEDICINE, 'Internal Medicine'],
])

export { SpecialtyEnum, SpecialtyEnumName }