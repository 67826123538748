import React, {useState, useEffect } from 'react';
import { Helper } from '_utils';

const DateDropDown:React.FunctionComponent<{setValue: any, selected: string}> = ({setValue, selected}) => {
  const y = [...new Array(80)].map((_, i) => new Date().getFullYear() - i - 18);
  const d = [...new Array(31)].map((_, i) => i + 1);

  const months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


  const [ days, setDays ] = useState<number[]>(d);
  const [ year, setYear] = useState<number>(selected ? parseInt(selected.toString().substring(0,4)) : 0);
  const [ month, setMonth ] = useState<number>(selected ? parseInt(selected.toString().substring(4,6)) : 0);
  const [ day, setDay] = useState<number>(selected ? parseInt(selected.toString().substring(6)) : 0);

  useEffect(()=> {
    if(month === 0 || day === 0  || year === 0) {
      console.log('setting value to null');
      console.log('selected', {selected});
      setValue(null);
    } else {
      //setValue(`${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`);
      setValue(parseInt(`${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, day, days])

  const onYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const year = Number(e.target.value);
    const numDays = Helper.getDaysInMonth(new Date(year, month - 1));
    const d = [...new Array(numDays)].map((_, i) => i + 1);
    setYear(Number(e.target.value));
    setDays(d);
    if (d.indexOf(day) < 0 ) {
      setDay(1);
    }
  }

  const onMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const numDays = Helper.getDaysInMonth(new Date(year, Number(e.target.value) - 1));
    const d = [...new Array(numDays)].map((_, i) => i + 1);

    setMonth(Number(e.target.value));
    setDays(d);
    if (d.indexOf(day) < 0 ) {
      setDay(1);
    }
  }

  const onDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if(isNaN(month) || isNaN(day) || isNaN(year)) {
      setValue('');
    } else {
      setDay(Number(e.currentTarget.value));
      //setValue(`${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`);
    }
  }

  return (
    <>
      <select name="month" onChange={onMonthChange} defaultValue={month}>
        <option key='month-select' value={0}>Month</option>
        {months.map((name: string, i: number) => (
          <option key={`month-${i}`}  value={i+1}>{name}</option>
        ))}
      </select> / 

      <select name="day" onChange={onDayChange} defaultValue={day}>
         <option key='day-select' value={0}>Day</option>
        {days.map((i: number) => (
          <option key={`day-${i}`} value={i} >{i.toString().padStart(2, '0')}</option>
        ))}
      </select> / 

      <select name="year" onChange={onYearChange} defaultValue={year}>
        <option key='year-select' value={0}>Year</option>
        {y.map((i: number) => (
          <option key={`year-${i}`} value={i}>{i}</option>
        ))}
      </select>

    </>
  );
}

export { DateDropDown }