import React from 'react';
import { UserLog } from 'components/common/reports/UserLog';
import { practitionerReportApiService } from 'services/PractitionerReportApiService';
import { useUserLog } from '_hooks/useUserLog';

const List: React.FunctionComponent = () => {

  const data = useUserLog(practitionerReportApiService);

  return (

    <UserLog data={data} />

  );
}

export { List as PractitionerReportUserLogList }
