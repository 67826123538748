
class Helper {

  /**
   * Clones an object with only the given fields as its properties
   * @param fields 
   * @param object 
   * @returns 
   */
  public static cloneObject<T>(fields: string[], object: Object) {
    const clone: any = Object.assign({}, object);
    // eslint-disable-next-line array-callback-return
    Object.keys(clone).map( k => {
      if (fields.indexOf(k) < 0) {
        delete clone[k];
      }
     })
     return clone as T;
  }

  public static removeBlanksFromObject<T>(object: Object) {
    const clone: any = Object.assign({}, object);
    // eslint-disable-next-line array-callback-return
    Object.keys(clone).map( k => {
      if (clone[k] === null || clone[k] === '') {
        delete clone[k];
      }
     })
    return clone as T;
  }

  public static getSecondsRemaining(targetDate: Date): number {
    const today = new Date();
    const diffInMs =  targetDate.getTime() - today.getTime();

    if (diffInMs < 0) {
      return 0;
    }

    // @TODO: localize the date
    return diffInMs / 1000;
  }

  public static getTimeRemaining(toDate: Date, fromDate: Date): {
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  } {

    const diffInMs =  toDate.getTime() - fromDate.getTime();

    if (diffInMs < 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
    }

    const seconds = diffInMs / 1000;
    const minutes =  seconds / 60;
    const hours =  minutes / 60;
    const days = hours / 24;

    // localize the date
    return {
      days : Math.floor(days),
      hours: Math.floor(hours % 24),
      minutes: Math.floor(minutes % 60),
      seconds: Math.floor(seconds % 60),
    }
  }

  /**
   * determine whether the given from and to timestamp is inside the day
   * @param fromDateTime 
   * @param toDateTime 
   * @param date 
   */
  public static isInDay (fromDateTime: Date, toDateTime: Date, date: Date) {
    
    const startDate = this.getFormattedDateMDY(fromDateTime);
    const endDate = this.getFormattedDateMDY(toDateTime);
    const calendarDate = this.getFormattedDateMDY(date);
    return (startDate === calendarDate && endDate === calendarDate);

  }

  public static getLastDateOfMonth(date?: Date) : Date {
    if (!date) date = new Date();

    let year = date.getFullYear();
    let month = date.getMonth();

    const lastDate = new Date(year, month+1, 0);
    return lastDate;
  }

  public static getDaysInMonth(date?: Date) : number {
    if (!date) date = new Date();
    const lastDate = this.getLastDateOfMonth(date);
    return lastDate.getDate();
  }

  public static getDaysFromNow(date: Date) : string {
    const today: Date = new Date();
    const dayInSeconds: number = 1000 * 60 * 60 * 24;
    const seconds: string = ((date.getUTCSeconds() - today.getUTCSeconds())/dayInSeconds).toFixed(0);
    return seconds;
  }

  public static getUtcDate (date: Date) : string {
    if (!date) date = new Date();

    let year = date.getUTCFullYear();
    let month = (1 + date.getUTCMonth()).toString().padStart(2, '0');
    let day = date.getUTCDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
  }

  public static getFormattedDateTime (date: Date): string {
    if (!date) date = new Date();

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let hour = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hour}:${minute}`;
  }

  public static getYMDFromEpoch (seconds: number | null): string {
    const date = seconds ? new Date(seconds * 1000) : new Date();
    return date.toLocaleDateString('en-US');
  }

  public static getLocalDateTimeFromUTC(date: Date): string {
    const _date =  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),  date.getHours(), date.getMinutes(), date.getSeconds()));
    return _date.toLocaleString();

  }

  public static getFormattedDate (date: Date): string {
    if (!date) date = new Date();

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return year + '-' + month + '-' + day;
  }

  public static getFormattedDateMDY ( date: Date) : string {
    if (!date) {
    	date = new Date();
    }

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

		return month + '/' + day + '/' + year;
  }

  public static getFormattedDayMDY ( date: Date) : string {
    if (!date) {
    	date = new Date();
    }

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

		return `${days[date.getDay()]}, ${month}/${day}/${year}`;
  }  

  /**
   * for example, if given 01/01/2020 12:30:26:
   * return 01/01/2020 00:00:00 if isEnd === false (in milliseconds)
   * return 01/01/2020 23:59:59 if isEnd === true  (in milliseconds)
   * @param timestamp 
   */
  public static getAbsoluteDateTime(date: Date, isEnd:boolean = false): number {
    if (isEnd) {
      return new Date(`${this.getFormattedDate(date)} 23:59:59` ).getTime()
    } else {
      return new Date(`${this.getFormattedDate(date)} 00:00:00` ).getTime()
    }
  }

  /**
   * return the date in integer format
   * @param date 
   * @returns 
   */
  public static getEpochTime (date: Date): number {
    return date.getTime() / 1000;
  }

  public static getFormattedTimeHHMM (date?: Date): string {
    if (!date) date = new Date();

    let hour = date.getHours();
    const ampm = hour >= 12 ? "PM" : "AM";
    if (hour > 12) {
      hour = hour - 12;
    }
    let minute = date.getMinutes().toString().padStart(2, '0');

    return `${hour.toString().padStart(2, '0')}:${minute} ${ampm}`;
  }

  public static restoreDash(code: string): string {
    return code.slice(0,4) + '-' + code.slice(4);
  }

  public static async getPreviewFile (file: any) {
    return new Promise ( (resolved, reject) => {
      const reader = new window.FileReader();
      reader.onload = () => {
        resolved(reader.result);   
      }
      reader.onerror = () => {
        reject('could not upload');
      }
      reader.readAsDataURL(file);
    })
  }

  public static formatPhoneAsUS(phoneNumber: string): number {
    if ((phoneNumber as string).charAt(0) === '1') {
      return parseInt(`${phoneNumber.replaceAll(/[^\d]/g,'')}`);
    }
    return parseInt(`1${phoneNumber.replaceAll(/[^\d]/g,'')}`);

  }

  public static formattedPhoneNumber = (s: string) => {
    if (!s) return s;
    if (s === "1") return "1";
    const phone = s.replace(/[^\d]/g, '').replace(/^1/g, ''); // remove non digits and "1"
    if (phone.length < 4) return `${phone}`;
    if (phone.length < 7 ) {
      return `1 (${phone.slice(0,3)}) ${phone.slice(3)}`
    }
    return `1 (${phone.slice(0,3)}) ${phone.slice(3, 6)}-${phone.slice(6,10)}`
  }


  /**
   * takes a date formatted as a number like 20110501 (which is presumed YYYYMMDD)
   * and formats it to MM/DD/YYYY
   * @param n : number
   */
  public static formattedMDY = (n: number) => {
    const s: string = n.toString();
    const month = s.substring(4,6);
    const day = s.substring(6);
    const year = s.substring(0,4);
    return `${month}/${day}/${year}`;
  }

  /**
   * formats a phone number like 19085551212 to 1 (908) 555-1212
   * @param n 
   */
  public static formattedPhoneFromNumber = (n: number) => {
    const s = n.toString();
    const a = s.substring(0,0);
    const b = s.substring(1,4);
    const c = s.substring(4,7);
    const d = s.substring(7);
    
    return `${1} (${b}) ${c}-${d}`;
  }

}

export { Helper }