import React from 'react';
import { IPractitioner } from 'interfaces';
import { IPractitionerAppointments, ITimeSlot } from 'interfaces/appointment';
import { PractitionerHelper } from './Helper';
import { SpecialtyEnumName } from '_constants/Specialty';


const FullSchedule: React.FunctionComponent<{
  practitioner: IPractitioner,
  appointments: Map<number, IPractitionerAppointments>,
  onAppointmentClick: (timeslot: ITimeSlot, practitioner: IPractitioner) => void,
  returnButton?: JSX.Element,
  showPractitionerCard?: boolean,
}> = ({
  practitioner,
  appointments,
  onAppointmentClick,
  returnButton,
  showPractitionerCard = false

}) => {
    return (
      <div className="fullschedule">

        {returnButton && <>{returnButton}</>}

        <div className="container-fluid g-0">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
              {!!showPractitionerCard &&
                <div className="fullschedule-doctor card-rounded margin-center">
                  <div className='practitioner-card-title'>Dr. {practitioner.firstName} {practitioner.lastName}, {practitioner.degree}</div>
                  <div className="practitioner-card-specialty">{SpecialtyEnumName.get(practitioner.specialityId || 0)}</div>
                  <div className="practitioner-card-board-certified">Board Certified</div>
                  <div className="practitioner-card-avatar"><img src={practitioner.avatarUrl || '/images/doc-icon.png'} alt="Doctor" className="rounded" /></div>
                </div>
              }
            </div>

            <div className="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 col-xxl-9">
              <div className="fullschedule-slots d-flex flex-row flex-wrap justify-content-center justify-content-md-start justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                {
                  Array.from(PractitionerHelper.groupTimeSlotsByDate(appointments.get(practitioner.id)?.appointments || [])).map(([_, dateTimeSlots], key: number) =>
                    <>
                      {
                        <>
                          <div className="fullschedule-slots-date card-rounded mx-2">
                            <div className="fullschedule-slots-date-text">{dateTimeSlots.date}</div>
                            <div className="fullschedule-slots-times d-flex flex-row flex-wrap">
                              {dateTimeSlots.timeSlots.map((timeslot: ITimeSlot, i: number) => <>
                                <div key={`timeslot-key-${key}`} className={`fullschedule-slots-times-item mx-2`} onClick={() => { onAppointmentClick(timeslot, practitioner) }}>{timeslot.startTime}</div>
                              </>)}
                            </div>
                          </div>
                        </>
                      }
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }

export { FullSchedule }