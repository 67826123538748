import React, { useEffect, useState } from 'react';
import { IAppointmentResponseItem } from 'components/patient/appointments';
import { practitionerService } from 'services/PractitionerService';
import { AppointmentStatusEnum } from '_constants/AppointmentStatusEnum';
import { AppointmentForm } from './AppointmentForm';
import { PractitionerSchedulerAppointmentGrid } from './PractitionerSchedulerAppointmentGrid';

const PractitionerSchedulerList: React.FunctionComponent = () => {

  const [appts, setData] = useState<IAppointmentResponseItem[]>([] as IAppointmentResponseItem[]);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    const _load = async () => {
      try {
        const appts = await practitionerService.getAppointments();
        setData(appts.filter((d: IAppointmentResponseItem) => d.statusId !== AppointmentStatusEnum.OPEN && d.start * 1000 >= new Date().getTime() ));
        //setData(appts);
      } catch (error: any) {
        throw Error((error as Error).message);
      }
    }
    _load();
  }, [counter])

  const onAdd = () => {
    setIsAdding(false);
  }

  const onCancel = () => {
    setIsAdding(false);
  }

  return (
    <>
      <div className='practitioner-layout-section-title'>Appointment Manager</div>

      {isAdding ? (
        <AppointmentForm onAdd={onAdd} onCancel={onCancel}/>
      )
        : (
          <>
            <div className='strong medium-text-2'>List of Appointments</div>

            {/* <button type="button" className="btn btn-primary my-2" onClick={onClickAddAppointment}>Add Appointment</button> */}

            {appts && <>
              <PractitionerSchedulerAppointmentGrid appts={appts} setData={setData} refresh={()=>setCounter(counter + 1)}/>
              {/* <pre>{JSON.stringify(appts, null, 2)}</pre> */}
            </>}
          </>
        )}
    </>
  );
}

export { PractitionerSchedulerList }