import React, { useContext, useEffect } from "react";
import { IAccount, ICredentials } from "interfaces";
import * as Yup from "yup";

import { useNavigate } from "react-router";
import { AccountContext, LoginForm } from "components/common";
import { Url } from "_constants";

const Login: React.FunctionComponent = (props: any) => {
  const navigate = useNavigate();
  const accountContext = useContext(AccountContext);


  useEffect(()=>{
    if (accountContext?.account) {
      // check if expired; may need to log in again
      navigate(Url.ADMIN_DASHBOARD);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const initialFormState: ICredentials = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter your email"),
    password: Yup.string().required("Password required"),
  });

  const onSubmit = async (data: any, actions: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    const redir = queryParams.get("redir");

    const { email, password } = data;
    const { setStatus } = actions;
    try {
      const account: IAccount | undefined = await accountContext?.login({ email, password });
      if (!account) {
        throw Error("unexpected error");
      }

      // see if there's a redirect first and redirect there
      if (redir) {
        navigate(redir);
      } else {
        // if no redir, go to the Role's default page
        navigate(Url.ADMIN_DASHBOARD);
      }
    } catch (error) {
      setStatus("Invalid Login");
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center my-3 vh-25">
        <div className="col-12 col-md-8 col-lg-6 col-xl-6 col-xxl-6 align-self-center">
          <LoginForm
            initialFormState={initialFormState}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            passwordResetUrl="/admin/forgot-password"
          />
        </div>
      </div>
    </div>
  );
};

export { Login as AdminLogin };
