import React from "react";
import { useNavigate } from "react-router";
import { Url } from "_constants";
import { Faq } from "components";
import { DoctorCarousel, Pricing, VirtualVisitInfo } from "components/common/PractitionerListingPage"
import { trackingService } from "services/common/TrackingService";
import { InsuranceCompanyEnum } from "_constants/InsuranceCompanyEnum";
import { useLandingPage } from "_hooks/useLandingPage";
import { IPractitioner } from "interfaces";
import { useUnload } from "_hooks/useUnload";
import { MembershipFAQ } from "./common/_static/MembershipFaq";

interface ICard {
  icon: string,
  title: string,
  description: string | React.ReactNode,
  onClick?: () => void,
}

const InsuranceSection = () => {
  return (
    <>
      <div className="small-text-3 dark-blue text-center my-3 my-md-5 mb-md-0">We accept the following insurance:</div>

      <div className="container-fluid g-0">
        <div className="row align-items-center justify-content-between">
          <div className="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2"><img className="insurance-image" src="/images/insurance/ins-horizon.png" alt="Horizon/Blue Cross/Blue Shield" /></div>
          <div className="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2"><img className="insurance-image" src="/images/insurance/ins-united-healthcare.png" alt="United HealthCare" /></div>
          <div className="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2"><img className="insurance-image" src="/images/insurance/ins-cigna.png" alt="Cigna" /></div>
          <div className="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2"><img className="insurance-image" src="/images/insurance/ins-medicare.png" alt="Medicare.gov" /></div>

        </div>
      </div>
    </>
  );
}

const PreventableSection: React.FunctionComponent<{altText?: JSX.Element}> = ({altText}) => {
  return (
    <div className="row mt-5 mx-2 preventable-section align-items-center">
      <div className="col-6 col-lg-6 text-start px-lg-5 my-2">
        <div className="large-text-2 strong dark-blue align-top">
          {altText && <>{altText}</>}
          {!altText && <>
            Early detection is <br /><span className="orange">the key</span><br /> to Cancer survival
          </>}
        </div>
      </div>
      <div className="col-6 col-lg-6">
        <div className="couple-image">
          <img src="/images/couple.png" alt="Couple" />
        </div>
      </div>
    </div>
  );
};

const FeatureCard: React.FunctionComponent<ICard> = ({ icon, title, description, onClick }: ICard) => {
  return (
    <div className="step-card" onClick={onClick} role={onClick ? "button" : ""}>
      <div className={`step-card-icon ${icon}`}></div>
      <div className="medium-text-2 my-5 mb-3 strong title">{title}</div>
      <div className="step-card-description small-text-2 gray">{description}</div>
    </div>
  );
};

const DigitalResultCard: React.FunctionComponent<ICard> = ({ icon, title, description }: ICard) => {
  return (
    <div className="container h-100">
      <div className="row step-card digital-result-card">
        <div className="col-xs-12 col-sm-12 col-lg-4  ">
          <div className={`step-card-icon ${icon}`}></div>
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-8">
          <div className="container">
            <div className="row">
              <div className="col medium-text-2 dark-blue text-center text-md-start text-lg-start text-xl-start strong mb-3 mt-3">
                {title}
              </div>
            </div>
            <div className="row">
              <div className="col small-text-1 text-center text-md-start text-lg-start text-xl-start">{description}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Envelope = () => {
  return (
    <>
      <div className="envelope ">
        <img src="/images/Envelop.png" alt="Convena Health Test Kit" />
      </div>
      {/* <div className="rounded-box small-text-3 dark-blue fsa shadow">
        <span>US Licensed Physicians</span>
      </div> */}
    </>
  );
};


const Jumbotron: React.FunctionComponent<{ 
  onClick: any, 
  altImage?: JSX.Element, 
  altLeadText?: JSX.Element, 
  altButtonLabel?: JSX.Element, 
  altCopay?: JSX.Element,
  hasLicensedPhysicians?: boolean,
  altLeadButton?: JSX.Element,
  }> = ({ onClick, altImage, altLeadText, altButtonLabel, altCopay, hasLicensedPhysicians, altLeadButton }) => {
  const navigate = useNavigate();
  const landingPage = useLandingPage();
  const category = landingPage.getLandingPage();

  const onClickPhysician = () => {
    console.log({ category })
    trackingService.fireGoogleEvent({
      action: `Home Page ${category} | Clicked Meet Our Physicians`,
      category: 'Home Page - Jumbotron',
      label: 'Meet Our Physicians',
    });
    navigate(Url.PUBLIC_PHYSICIANS)
  }

  return (
    <div className="row jumbotron px-0">
      <div className="col-12 col-md-6">
        <div className="px-lg-5 pt-md-4 text-center text-md-start text-lg-start text-xl-start text-sm-center text-xs-center">
          {altLeadButton && <>{altLeadButton}</>}
          {!altLeadButton &&
            <div role="button" className="d-inline-block recommended p-1 px-2 mt-2 py-md-2 px-md-3 smaller-text-5 mx-3" onClick={() => onClickPhysician()}>
              Meet Our Physicians
            </div>
          }
          <div className="text-center px-3 text-md-start text-lg-start dark-blue mt-3 mt-md-5 mt-lg-5 mt-xl-5 mt-xxl-5">
            {altLeadText && 
              <>{altLeadText}</>
            }

            {!altLeadText && 
            <>
            <div className="title">Virtual Checkups with a <br />Top Doctor <span className="text-green">at No Cost*</span></div>
            <div className="subtitle mt-3">Get peace of mind with a <span className="fw-bold">Telehealth Wellness Visit and get tests ordered</span> from the privacy and comfort of home</div>
            </>
            }
          </div>

          <div className="d-block d-md-none mt-2">
            <div className="panel-2">
              {altImage && <>
                {altImage}
              </>
              }

              {!altImage &&
                <img src="/images/couple-teledoc.jpg" alt="Convena Care" />
              }
            </div>
          </div>

          <div className="mt-1 mt-md-4">
            <div className="d-flex flex-column align-items-center">
              <div className="text-center text-md-start text-lg-start text-xl-start text-sm-center text-xs-center">
                <button className="btn btn-cta small-text-3 m-3" onClick={onClick}>
                  
                  {altButtonLabel && <>{altButtonLabel}</>}

                  {!altButtonLabel && <>Book a Virtual Visit Now</>}

                </button>
              </div>
              <div className="small-text-3 text-gray fw-bold">Or call us at <a href="tel:19088956969" className="telno">(908) 895 6969</a></div>

              {altCopay && <>{altCopay}</>}

              {!altCopay && <><div className="dark-gray mt-3 fw-bold small-text-2 text-center my-5"><span className="text-green">*FREE</span> and <span className="text-green">NO CO-PAY</span> with qualifying health insurance</div></>}
              
            </div>
          </div>
        </div>
      </div>

      {/* desktop only */}
      <div className="col-6 p-0 d-none d-md-block">
        <div className="panel-2">
          {altImage && <>
            {altImage}
          </>
          }

          {!altImage &&
            <img src="/images/couple-teledoc.jpg" alt="Convena Care" />
          }

        </div>
      </div>

      {hasLicensedPhysicians &&
      <>
        <div className="col-12 col-md-6">
          
        </div>
        <div className="col-12 col-md-6">
        <div className="my-3">
            <div className="d-flex flex-row justify-content-center ">
              <div className="rounded-box small-text-3 dark-blue shadow my-3">
                <span>US Board-certified <br/> Licensed Physicians</span>
              </div>
            </div>
          </div>
          
        </div>
      </>
        }


    </div>
  );
};

const HowItWorksSection = () => {
  return (
    <>
      {/* HOW IT WORKS */}
      <div className="row mt-5 mx-lg-5 text-center">
        <div className="col orange medium-text-1 strong">HOW IT WORKS</div>
      </div>
      <div className="row my-5">
        <div className="col-auto col-sm-12 col-md-4 mb-3">
          <FeatureCard
            icon="step-1-icon"
            title="1. Online Doctor Visit"
            description="Our doctor will meet with you via video for a full preventive care visit. They will evaluate and prescribe all appropriate tests, including the Colon Cancer screening, that are needed to make sure you are getting the very best preventive care."
          />
        </div>
        <div className="col-auto col-sm-12 col-md-4 mb-3">
          <FeatureCard
            icon="step-2-icon"
            title="2. Get Diagnostic Tests"
            description="Your doctor will prescribe appropriate blood tests at your local lab and any imaging studies at your preferred local imaging center."
          />
        </div>

        <div className="col-auto col-sm-12 col-md-4 mb-3">
          <FeatureCard
            icon="step-3-icon"
            title="3. Receive results online"
            description="You will be able to login to the ConvenaCare Patient Portal to view your test results on our secure, private platform. If appropriate, your physician may shedule a follow-up visit to review your results with you."
          />
        </div>
      </div>
    </>
  );
};

const HowItWorksMembershipSection = () => {
  return (
    <>
      {/* HOW IT WORKS */}
      <div className="row mt-5 mx-lg-5 text-center">
        <div className="col orange medium-text-1 strong">HOW IT WORKS</div>
      </div>
      <div className="row my-5">
        <div className="col-auto col-sm-12 col-md-4 mb-3">
          <FeatureCard
            icon="step-1-icon"
            title="1. Online Doctor Visit"
            description="Our doctors will meet with you via video for full telehealth visits. They will evaluate and prescribe all appropriate tests, including blood and cancer screening, that are needed to make sure you are getting the very best preventive care."
          />
        </div>
        <div className="col-auto col-sm-12 col-md-4 mb-3">
          <FeatureCard
            icon="step-2-icon"
            title="2. Get Diagnostic Tests"
            description="Your doctor will prescribe appropriate blood tests at your local lab and any imaging studies at your preferred local imaging center."
          />
        </div>

        <div className="col-auto col-sm-12 col-md-4 mb-3">
          <FeatureCard
            icon="step-3-icon"
            title="3. Receive results online"
            description="You will be able to login to the ConvenaCare Patient Portal to view your test results on our secure, private platform. If appropriate, your physician may shedule a follow-up visit to review your results with you."
          />
        </div>
      </div>
    </>
  );
};

const AcgRecommendsSection:React.FunctionComponent<{altText?: JSX.Element}> = ({altText}) => {
  return (
    <div className="row mt-5 text-center acg-recommend-container">
      <div className="col col-12">
        <div className="acg-recommend">
          <div className="container py-3">
            <div className="row text d-flex align-items-center">
              <div className="col-4 col-lg-3 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                <div className="home-test-kit-image text-center">
                  <img src="/images/clock.png" alt="Convena Health Test Kit" />
                </div>
              </div>

              <div className="col-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 strong">
                {altText && <>{altText}</>}
                {!altText && <>Save time and money with a preventive health visit from home or office!<span > <a href="/make-appointment" className="text-decoration-none light-orange make-appointment-now">Make an appointment now - Click Here!</a></span> Or call us at (908) 895 6969.</>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CertifiedLabSection = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="row mt-5 mx-lg-5 text-center">
        <div className="col orange medium-text-1 strong">HIGH QUALITY STANDARDS</div>
      </div>

      <div className="row my-5">
        <div className="col-auto col-md-4 mb-3">
          <FeatureCard
            icon="physician-oversight-icon"
            title="Board-Certified Physicians"
            description="All Convena physicians are Board-certified and licensed in the US and  your state to ensure the highest quality patient care."
            onClick={() => { navigate(Url.PUBLIC_PHYSICIANS) }}
          />
        </div>
        <div className="col-auto col-md-4 mb-3">
          <FeatureCard
            icon="certified-lab-icon"
            title="Certified Lab"
            description="We use local CLIA-certified labs that are most convenient for you. "
          />
        </div>
        <div className="col-auto col-md-4 mb-3">
          <FeatureCard
            icon="private-secure-icon"
            title="Private and Secure Data"
            description={
              <>
                Convena is HIPAA-compliant and takes our patients' privacy very seriously. We use state-of-the-art encryption and
                storage to make sure your data is safe.
                <br />
                <br />
                We will NEVER sell your data.
              </>
            }
          />
        </div>
      </div>
    </>
  );
};

const DigitalResultsSection = () => {
  return (
    <div className="row my-5 digital-results-section">
      <div className="col">
        <div className="container py-3 p-lg-5 p-md-1 p-sm-1 p-xs-1">
          <div className="row mt-lg-5 mx-lg-5 text-center ">
            <div className="col orange medium-text-1 strong">DIGITAL RESULTS</div>
          </div>
          <div className="row mx-lg-5 mb-lg-5 text-center my-2">
            <div className="col black large-text-3 strong">Results you can understand sent to your device</div>
          </div>

          <div className="row px-lg-5">
            <div className="col-auto col-lg-6 mb-lg-5 mb-3">
              <div className="digital-results mt-lg-5 mx-lg-5">
                <div className="mx-lg-3 mt-5 mt-5">
                  <img src="/images/laptop.png" alt="Digital Results" />
                </div>
              </div>
            </div>

            <div className="col-auto col-lg-6 mb-lg-5 mb-3">
              <DigitalResultCard
                icon="easy-to-understand-icon"
                title="Easy To Understand"
                description="Gain meaningful insights about your health with easy-to-understand test results. We also provide you with additional resources and helpful tips along the way."
              />
            </div>
          </div>

          <div className="row px-lg-5">
            <div className="col-auto col-lg-6 mb-lg-5 mb-3">
              <DigitalResultCard
                icon="personalized"
                title="Personalized"
                description="View a personalized report of each test as well as detailed information about what your results mean for you."
              />
            </div>

            <div className="col-auto col-lg-6 mb-lg-5 mb-3">
              <DigitalResultCard
                icon="actionable"
                title="Actionable"
                description="Take action on your health and wellness. Use your reports to help guide your next steps."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FaqSection = () => {
  return (
    <>
      <div id="faq" className="my-4 pt-5"></div>
      <div className="row mt-5 mx-5 text-center">
        <div className="col orange medium-text-1 strong">Frequently Asked Questions</div>
      </div>

      <div className="row my-5 faq-section">
        <div className="col-auto col-sm-12 col-md-6 doc-image mb-3">
          <img className="rounded-3" src="/images/elderly-couple-md.jpg" alt="Convena Care FAQ" />
        </div>
        <div className="col-auto col-sm-12 col-md-6">
          <Faq />
        </div>
      </div>
    </>
  );
};

const MembershipFaqSection = () => {
  return (
    <>
      <div id="faq" className="my-4 pt-5"></div>
      <div className="row mt-5 mx-5 text-center">
        <div className="col orange medium-text-1 strong">Frequently Asked Questions</div>
      </div>

      <div className="row my-5 faq-section">
        <div className="col-auto col-sm-12 col-md-6 doc-image mb-3">
          <img className="rounded-3" src="/images/elderly-couple-md.jpg" alt="Convena Care FAQ" />
        </div>
        <div className="col-auto col-sm-12 col-md-6">
          <MembershipFAQ />
        </div>
      </div>
    </>
  );
}

const HomePage: React.FunctionComponent<{ showDoctorCarousel?: boolean }> = ({ showDoctorCarousel }) => {

  const EVENT_CATEGORY = 'Home Page';

  const navigate = useNavigate();
  const landingPage = useLandingPage();
  landingPage.setLandingPage();

  const onClickSetupAppt = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} ${!!showDoctorCarousel ? 'With Docs ' : ''}| Clicked Book a Virtual Visit Now`,
      category: `${EVENT_CATEGORY} ${!!showDoctorCarousel ? 'With Docs ' : ''} - Jumbotron`,
      label: 'Book a Virtual Visit Now',
    });
    navigate(Url.ROOT_DOCTOR_LIST);
  };

  const onClickMore = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} With Docs | Clicked More`,
      category: `${EVENT_CATEGORY} With Docs`,
      label: 'More',
    });
  }
  const trackEvent = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} With Docs | Clicked Appointment Slot`,
      category: `${EVENT_CATEGORY} With Docs`,
      label: 'Select Time Slot',
    });
  }

  return (
    <div className="my-2">
      <Jumbotron onClick={onClickSetupAppt} />

      <div className="d-block d-md-none d-lg-none d-xl-none d-xxl-none">
        <InsuranceSection />
      </div>

      <div className="d-none d-md-block d-lg-block d-xl-block d-xxl-block">
        <InsuranceSection />
      </div>

      <div className="container-fluid">
        <VirtualVisitInfo />

        {showDoctorCarousel &&
          <>
            <div id="book-now" className="fw-bold dark-blue medium-text-1 my-1 my-md-5">Book a Virtual Visit with a Physician Below:</div>
            <DoctorCarousel onExpand={onClickMore} insuranceId={InsuranceCompanyEnum.HORIZON_BCBS.toString()} trackEvent={trackEvent} />
          </>
        }

        <HowItWorksSection />

        <AcgRecommendsSection />

        <PreventableSection />

        <CertifiedLabSection />

        <Pricing />

        <FaqSection />

      </div>

    </div>
  );
};


const HomePage2: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const landingPage = useLandingPage();
  landingPage.setLandingPage();

  const CATEGORY = 'Home Page With Docs - 2'

  useUnload( (e: Event) => {
    e.preventDefault();
    trackingService.fireGoogleUnload({category: CATEGORY, label: 'exit page' });
  })

  const onClickSetupAppt = () => {
    trackingService.fireGoogleEvent({
      action: `${CATEGORY} | Clicked Book a Virtual Visit Now`,
      category: `${CATEGORY} - Jumbotron`,
      label: 'Book a Virtual Visit Now',
    });
    navigate(Url.ROOT_DOCTOR_LIST);
  };

  const onClickMore = () => {
    trackingService.fireGoogleEvent({
      action: `${CATEGORY} | Clicked More`,
      category: 'Home Page With Docs -2',
      label: 'More',
    });
  }
  const trackEvent = () => {
    trackingService.fireGoogleEvent({
      action: `${CATEGORY} | Clicked Appointment Slot`,
      category: `${CATEGORY}`,
      label: 'Select Time Slot',
    });
  }

  return (
    <div className="my-2">
      <Jumbotron onClick={onClickSetupAppt} 
      altLeadText={<>
        <div className="title">Virtual Primary Care with a <br />Top Doctor</div>
        <div className="subtitle mt-3">Get checked via <span className="fw-bold">Telehealth and get tests ordered</span> from the privacy and comfort of home</div>
        </>}
      altImage={<img src="/images/older-couple-on-couch.jpg" alt="Convena Care" />} />

      <div className="d-block d-md-none d-lg-none d-xl-none d-xxl-none">
        <InsuranceSection />
      </div>

      <div className="d-none d-md-block d-lg-block d-xl-block d-xxl-block">
        <InsuranceSection />
      </div>

      <div className="container-fluid">
        <VirtualVisitInfo />

        <>
          <div id="book-now" className="fw-bold dark-blue medium-text-1 my-1 my-md-5">Book a Virtual Visit with a Physician Below:</div>
          <DoctorCarousel onExpand={onClickMore} insuranceId={InsuranceCompanyEnum.HORIZON_BCBS.toString()} trackEvent={trackEvent} />
        </>

        <HowItWorksSection />

        <AcgRecommendsSection />

        <PreventableSection />

        <CertifiedLabSection />

        <Pricing />

        <FaqSection />

      </div>

    </div>
  );
};


const MembershipInfo = (): JSX.Element => {
  return (
    <div className="d-flex flex-row flex-wrap mt-5 justify-content-evenly align-items-center virtual-visit">
      <div className="virtual-visit-item virtual-visit-item-left">
        <div className="m-3">
          <img className="rounded-3" src="/images/woman-telehealth-visit.png" width="100%" alt="ConvenaCare" />
        </div>
      </div>
      <div className="virtual-visit-item virtual-visit-item-right">
        <div className="d-flex justify-content-center">
          <div className="m-3">
            <span className={`${"large-text-1"} dark-blue strong`}>Your Membership Includes:</span>
            <ul className={`${"medium-text-1"}`}>
              <li className="my-2">Unlimited telehealth physician visits</li>
              <li className="my-2">Same day or next day appointments</li>
              <li className="my-2">Personalized Lab and Imaging orders </li>
              <li className="my-2">Prescriptions as appropriate</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}  


const SubscriptionPricing = () : JSX.Element => {
  return(<>
    <div className="mt-5 text-center pricing py-5 bg-big-white-circles">
    <div className="">
      <div className="medium-text-2 dark-blue strong">No insurance? We've got you covered</div>
      <div className="medium-text-1 dark-blue">We are affordable and accept credit card payments.</div>
    </div>

    <div className="mt-5 orange medium-text-1 strong">Unlimited Virtual visits for just $59 per visit with Membership</div>
  </div>
  </> 
  );
}

const HomePage3: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const landingPage = useLandingPage();
  landingPage.setLandingPage();

  const CATEGORY = 'Home Page - Subscription'

  useUnload( (e: Event) => {
    e.preventDefault();
    trackingService.fireGoogleUnload({category: CATEGORY, label: 'exit page' });
  })

  const onClickSetupAppt = () => {
    trackingService.fireGoogleEvent({
      action: `${CATEGORY} | Clicked Sign-up Now`,
      category: `${CATEGORY} - Jumbotron`,
      label: 'Sign-up Now',
    });
    navigate(Url.PATIENT_SUBSCRIBE);
  };

  const onClickSignUpClock = () => {
    trackingService.fireGoogleEvent({
      action: `${CATEGORY} | Clicked Sign-up Now (Clock)`,
      category: `${CATEGORY} - Jumbotron`,
      label: 'Sign-up Now',
    });
    navigate(Url.PATIENT_SUBSCRIBE);
  };  

  return (
    <div className="my-2">
      <Jumbotron onClick={onClickSetupAppt} 
        altLeadText={<div className="text-center">
        <div className="title">Virtual Primary Care for <br />only <span className="text-green">$59 per visit</span> for members</div>
        <div className="subtitle mt-3 fw-bold">Annual membership is only <span className="text-decoration-line-through">$199</span> <br/> 
        <span className="text-green">$99</span> per year per person<br/>
        for a limited time
        </div>
        </div>}
      altImage={<img src="/images/older-couple-on-couch.jpg" alt="Convena Care" />} 
      altButtonLabel={<>Sign-up Now</>}
      altCopay = {<>&nbsp;</>}
      hasLicensedPhysicians={true}
      altLeadButton = {
        <div className="d-flex justify-content-center">
        <div className="align-self-center text-center d-inline-block recommended-orange p-1 px-2 mt-2 py-md-2 px-md-3 smaller-text-5 mx-3">
          Great for High-deductible plans
        </div>
        </div>
      }
      />

      <div className="d-block d-md-none d-lg-none d-xl-none d-xxl-none">
        <InsuranceSection />
      </div>

      <div className="d-none d-md-block d-lg-block d-xl-block d-xxl-block">
        <InsuranceSection />
      </div>

      <div className="container-fluid">
        <MembershipInfo />

        <HowItWorksMembershipSection />

        <AcgRecommendsSection altText={<>Save time and money with televisits from home or office. <div role="button" onClick={onClickSignUpClock} className="text-decoration-none light-orange make-appointment-now">Sign up now!</div></>}/>

        <PreventableSection altText={<>Primary care has never been so affordable</>}/>

        <CertifiedLabSection />

        <SubscriptionPricing />

        <MembershipFaqSection />

      </div>

    </div>
  );
};

export {
  PreventableSection,
  FeatureCard,
  DigitalResultCard,
  Envelope,
  HowItWorksSection,
  AcgRecommendsSection,
  CertifiedLabSection,
  DigitalResultsSection,
  FaqSection,
  HomePage,
  HomePage2,
  HomePage3
};
