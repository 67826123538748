import React from 'react';
import { ResetPassword } from 'components/common/ResetPassword';
import { adminAccountService } from 'services';

const AdminResetPassword: React.FunctionComponent = () => {
  return(
    <ResetPassword accountService={adminAccountService} />
  );
}

export { AdminResetPassword }