import React, { useContext, useRef, useState } from 'react';
import { Field, FieldArray, Form, Formik } from 'formik';
import { AccountContext } from '../AccountContext';
import { trackingService } from 'services';

export const VerificationCodeFancy: React.FunctionComponent<{
  length: number,
  phoneNumber: number,
  onSubmit: any,
}> = ({ length, phoneNumber, onSubmit }) => {

  const codeRefs = useRef<(HTMLInputElement | undefined)[]>([]);
  const verifyRef = useRef<any>();

  const acctContext = useContext(AccountContext);
  const [resendCount, setResendCount] = useState<number>(0);

  const CATEGORY = 'Subscribe with Phone';

  const onClickResend = async () => {
    try {
      await acctContext?.registerPhoneNumber(phoneNumber)
      setResendCount(resendCount + 1);
      trackingService.fireGoogleEvent({
        action: 'Appointment | Resent Verification',
        category: 'Appointment',
        label: 'Resend Verification Code',
      });

    } catch (error: any) {
      console.error('error resending', { error });
    }
  }

  const _onSubmit = async (data: any, actions: any) => {
    const { setStatus } = actions;
    const confirmationCode = data.codes.join('');

    try {
      if (!phoneNumber) throw Error('missing phone number');

      const success: boolean = await acctContext?.checkRegisteredPhoneNumber(phoneNumber, confirmationCode);
      if (success) {
        if (onSubmit) {
            trackingService.fireGoogleEvent({
              action: `${CATEGORY} | Verified Phone`,
              category: CATEGORY,
              label: 'Enter Verification Code',
            });
          onSubmit(confirmationCode);
        }
      } else {
        throw Error('Could not verify the code');
      }

    } catch (error: any) {
      trackingService.fireGoogleEvent({
        action: `${CATEGORY} | Failed Verification`,
        category: CATEGORY,
        label: 'Enter Verification Code',
      });
      setStatus((error as Error).message);
    }
  }

  const onChange = (e: any, setFieldValue: any) => {

    const idx = (e.nativeEvent.target.name).split('.')[1];
    console.log({ value: e.currentTarget.value })
    setFieldValue(`codes.${idx}`, e.currentTarget.value);

    if (e.currentTarget.value !== '') {
      // click verify
      if (parseInt(idx) + 1 >= length) {
        const verify = verifyRef.current;
        if (verify) {
          verify.click();
        }
      }

      const next = codeRefs.current[parseInt(idx) + 1];
      if (next) {
        next.select();
        next.focus();
      }
    }
  }

  return (
    <>
      <Formik initialValues={
        {
          codes: new Array<string>(length).fill(""),
        }} onSubmit={_onSubmit}>
        {({ status, isSubmitting, values, setFieldValue }) => {

          return (
            <Form>
              <FieldArray name="codes">
                {({ insert }) => (
                  <>
                    <div className="d-flex flex-row justify-content-center">
                      {values.codes.length > 0 && values.codes.map((code: any, i: number) => <div className="border-1 rounded mx-2" key={`_code_${i}`}>
                        <Field
                          innerRef={(el: HTMLInputElement) => codeRefs.current[i] = el}
                          className="verification-form-field"
                          name={`codes.${i}`}
                          maxLength={1}
                          onChange={(e: any) => onChange(e, setFieldValue)} />
                      </div>
                      )}
                    </div>

                  </>
                )}
              </FieldArray>

              <div className="text-center my-3">
                <button ref={verifyRef} type="submit" className="btn btn-primary mx-3" disabled={isSubmitting}>Verify</button>
              </div>

              {status && <div className="alert alert-warning mt-2">{status}</div>}

              <div className="my-3 text-center">
                <p>If you do not receive a code via text within a couple of minutes, click the link below to resend the code.</p>
                <button type="button" className="btn btn-link" disabled={isSubmitting} onClick={() => onClickResend()}>Resend</button>
                {resendCount > 0 && <div>Verification code was resent. Please check again. ({resendCount})</div>}
              </div>

            </Form>
          );

        }}

      </Formik>


    </>
  )
}