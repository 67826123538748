import { FieldWithError } from 'components/common/form';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { validationMap, Validator } from 'schemas';
import { AccountContext } from 'components/common/AccountContext';
import { trackingService } from 'services/common/TrackingService';


const VerificationCodeForm: React.FunctionComponent<{ onSubmit: any, phoneNumber: number }> = ({ onSubmit, phoneNumber }) => {
  const initialValues = {
    confirmationCode: undefined
  }

  const acctContext = useContext(AccountContext);
  const [resendCount, setResendCount] = useState<number>(0);


  const onClickResend = async () => {
    try {
      await acctContext?.registerPhoneNumber(phoneNumber)
      setResendCount(resendCount + 1);
      trackingService.fireGoogleEvent({
        action: 'Appointment | Resent Verification',
        category: 'Appointment',
        label: 'Resend Verification Code',
      });

    } catch (error: any) {
      console.error('error resending', { error });
    }
  }

  const _onSubmit = async (data: any, actions: any) => {
    const { setStatus } = actions;
    try {
      if (!phoneNumber) throw Error('missing phone number');

      const { confirmationCode } = data;
      const success: boolean = await acctContext?.checkRegisteredPhoneNumber(phoneNumber, confirmationCode);
      if (success) {
        if (onSubmit) {
            trackingService.fireGoogleEvent({
              action: 'Free Kit (No Appt) | Verified Phone',
              category: 'Free Kit (No Appt)',
              label: 'Enter Verification Code',
            });
          onSubmit(confirmationCode);
        }
      } else {
        throw Error('Could not verify the code');
      }

    } catch (error: any) {
      trackingService.fireGoogleEvent({
        action: 'Free Kit (No Appt) | Failed Verification',
        category: 'Free Kit (No Appt)',
        label: 'Enter Verification Code',
      });
      setStatus((error as Error).message);
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={_onSubmit} validationSchema={validationMap.get(Validator.PATIENT_MOBILE_CONFIRMATION)}>
      {({ status, setStatus, isSubmitting, errors, touched }) => {
        return (
          <Form>

            <div className="d-flex flex-column">
              <div className="small-text-2 fw-bold text-center my-2">Verification Code</div>
              <div className="d-flex flex-row justify-content-center">
                <div>
                  <FieldWithError maxLength={6} fieldName="confirmationCode" errors={errors} touched={touched} />
                </div>
                <div>
                  <button type="submit" className="btn btn-primary mx-3" disabled={isSubmitting}>Verify</button>
                </div>
              </div>
              {status && <div className="alert alert-warning mt-2">{status}</div>}

              <div className="my-3 text-center">
                <p>If you do not receive a code via text within a couple of minutes, click the button below to resend the code.</p>
                <button type="button" className="btn btn-primary bg-blue" disabled={isSubmitting} onClick={() => onClickResend()}>Resend</button>
                {resendCount > 0 && <div>Verification code was resent. Please check again. ({resendCount})</div>}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export { VerificationCodeForm }