import { InsurancePreference } from 'components/patient/insurance';
import React from 'react';

const Collect:React.FunctionComponent<{onSuccess: ()=>void}> = ({onSuccess}) => {
  return <>
  
  <div className="medium-text-1 dark-blue strong mt-3 py-3">Payment Method</div>
  <div className="mt-3">
      <InsurancePreference onSuccess={onSuccess}/>
  </div>

  </>
}

export { Collect }