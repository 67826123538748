import React, { useContext, useState } from "react";
import { AccountContext } from "components/common";
import { Formik, Form } from "formik";
import { IAccount } from "interfaces";
import { useNavigate } from "react-router";
import { Url } from "_constants";
import { AccountSetup, AddressInfo, ContactInfo, PersonalInfo } from "./subforms";
import { validationMap, Validator } from "schemas";
import { useLocation } from "react-router";

enum Stage {
  REGISTER = 1,
  REVIEW = 2,
  DONE = 3,
}

const ProgressTab: React.FunctionComponent<{ stage: Stage }> = ({ stage }) => {
  // Map usage <key: currentTab>
  const stageMap: Map<number, Map<number, string>> = new Map([
    [
      Stage.REGISTER,
      new Map([
        [Stage.REGISTER, "checked right-rounded"],
        [Stage.REVIEW, ""],
        [Stage.DONE, ""],
      ]),
    ],
    [
      Stage.REVIEW,
      new Map([
        [Stage.REGISTER, "checked"],
        [Stage.REVIEW, "checked right-rounded"],
        [Stage.DONE, ""],
      ]),
    ],
    [
      Stage.DONE,
      new Map([
        [Stage.REGISTER, "checked"],
        [Stage.REVIEW, "checked"],
        [Stage.DONE, "checked right-rounded"],
      ]),
    ],
  ]);

  return (
    <>
      <div className="container">
        <div className="row stage-bar-container">
          <div className={"col-4 stage-bar left-rounded text-center " + stageMap.get(stage)?.get(Stage.REGISTER)}>Register</div>
          <div className={"col-4 stage-bar text-center " + stageMap.get(stage)?.get(Stage.REVIEW)}>Review</div>
          <div className={"col-4 stage-bar text-center " + stageMap.get(stage)?.get(Stage.DONE)}>Done</div>
        </div>
      </div>
    </>
  );
};

const PatientRegistration: React.FunctionComponent = () => {
  const accountContext = useContext(AccountContext);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const redir = queryParams.get("redir");
  const [stage, setStage] = useState<Stage>(Stage.REGISTER);

  const directAccessStates = ["NJ"]; // states where this product is valid. i.e only states specified here will be allowed to register

  //@TODO: remove test values
  const initialFormState: any = {

    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phoneNumber: "",
    dateOfBirth: "",
    agreeTerms: false,
  };

  const validationSchema = validationMap.get(Validator.REGISTRATION_FORM);

  const onSubmit = async (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;

    if (stage === Stage.REGISTER) {  // stage prior to submit
      setStage(stage + 1);
      // show the Review form
    }

    if (stage === Stage.REVIEW) {
      // submit the changes
      const registrationData: IAccount = {
        email: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zipcode: data.zipcode,
        organization: data.organization,
        phoneNumber: data.phoneNumber,
        country: data.country,
      };

      try {
        setSubmitting(true);
        const account: IAccount | null | undefined = await accountContext?.register(registrationData);
        setSubmitting(false);
        if (!account) {
          throw Error("Unexpected error: empty account");
        }
        setStage(stage + 1);
      } catch (error) {
        console.error({error});
        setSubmitting(false);
        setStatus(`${error}`);
        setStage(Stage.REGISTER);
      }
    }

    if (stage === Stage.DONE) {
    }
  };

  const onClickEditInfo = () => {
    setStage(Stage.REGISTER);
  };

  const onClickDone = () => {
    // check if redirecting, else go to the dashboard
    if (redir) {
      navigate(redir);
    } else {
      navigate(Url.PATIENT_SCHEDULER, {
        state: location.state
      });
    }
  };

  const onLoginClick = () => {
    const loginUrl = redir ? `${Url.PATIENT_LOGIN}?redir=${redir}` : Url.PATIENT_LOGIN;
    navigate(loginUrl);
  };

  return (
    <div className="container-lg" id="questionnaire">
      <div className="mt-5 pt-5">
        <ProgressTab stage={stage} />
      </div>

      <Formik enableReinitialize initialValues={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, status, isSubmitting, errors, touched, setFieldValue }) => {
          return (
            <Form>
              {stage === Stage.REGISTER && (
                <>
                  <div className="d-flex flex-column justify-content-center">
                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6 text-center medium-text-1 text-primary">Registration and Account Set Up</div>
                    </div>

                    {/* Already Registered */}
                    <div className="small-text-2 text-center mt-5">
                      You will need an account to order test kits and to manage your current orders and kits. Please register or
                      login if you already have an account.
                    </div>

                    <div className="small-text-2 text-center">
                      Already registered?{" "}
                      <button type="button" className="btn btn-primary mx-3" onClick={onLoginClick}>
                        Go To Login Page
                      </button>
                    </div>
                  </div>

                  <div className="container-md my-3">
                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6">
                        {/* Account Set up */}
                        <AccountSetup errors={errors} touched={touched} />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6">
                        {/* Personal Information*/}
                        <PersonalInfo errors={errors} touched={touched} />
                      </div>

                      <div className="row justify-content-center mt-5">
                        <div className="col-md-6">
                          {/* Address */}
                          <AddressInfo errors={errors} touched={touched} />
                        </div>
                      </div>
                      <div className="row justify-content-center mt-5">
                        <div className="col-md-6">
                          {/* Contact Information*/}
                          <ContactInfo errors={errors} touched={touched} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-md">
                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6">{status && <div className="alert alert-warning my-3">{status}</div>}</div>
                    </div>

                    <div className="row justify-content-center py-5">
                      <div className="col-md-6 text-center">
                        <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-md">
                          Next: Review Info
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {stage === Stage.REVIEW && (
                <>
                  <div className="container-md my-3">
                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6 text-center medium-text-1 text-primary">Please Review Your Information</div>
                    </div>

                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6">
                        {/* Account Set up */}
                        <AccountSetup errors={errors} touched={touched} readOnly={true} />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6">
                        {/* Personal Information*/}
                        <PersonalInfo errors={errors} touched={touched} readOnly={true} />
                      </div>

                      <div className="row justify-content-center mt-5">
                        <div className="col-md-6">
                          {/* Address */}
                          <AddressInfo errors={errors} touched={touched} readOnly={true} />
                        </div>
                      </div>
                      <div className="row justify-content-center mt-5">
                        <div className="col-md-6">
                          {/* Contact Information*/}
                          <ContactInfo errors={errors} touched={touched} readOnly={true} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center py-5">
                    <div className="col-md-6 text-center">
                      {directAccessStates.indexOf(values.state) < 0 && (
                        <div className="alert alert-warning mb-5">
                          <div className="fw-bold">ATTENTION</div>
                          We expect to offer our tests in your state within the next two months. Please continue registering if
                          you would like us to notify you as soon as it is available. Otherwise, you can abandon this registration
                          process and your information will be deleted from our systems.
                        </div>
                      )}

                      <div className="d-flex justify-content-evenly">
                        <button
                          disabled={isSubmitting}
                          onClick={onClickEditInfo}
                          type="button"
                          className="btn btn-primary btn-md"
                        >
                          Edit Info
                        </button>

                        <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-md">
                          Looks Good. Submit Info!
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {stage === Stage.DONE && (
                <>
                  <div className="container-md my-3 p-5">
                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6 text-center medium-text-1 text-primary">Account Setup Complete!</div>
                    </div>

                    <div className="row justify-content-center mt-5">
                      <div className="col-md-6 text-center small-text-1">
                        <p>
                          Thank you for setting up account. Your account was registered and you will now be able to finalize your appointment selection.
                        </p>

                        <button type="button" className="btn btn-primary" onClick={onClickDone}>
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { PatientRegistration };
