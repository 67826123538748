import { AccountContext } from 'components/common';
import { PatientLogin } from 'components/patient/account';
import { AccountSetup, AddressInfo, DateOfBirth, PersonalInfo } from 'components/patient/account/subforms';
import { Form, Formik } from 'formik';
import { IAccount } from 'interfaces';
import React, { useContext, useState } from 'react';
import { validationMap, Validator } from 'schemas';

const Register: React.FunctionComponent<{ onSuccess: () => void, phoneNumberCode: string, phoneNumber?: number, trackEvent: ()=>void }> = ({ onSuccess, phoneNumberCode, phoneNumber, trackEvent }) => {
  const initialValues: any = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    agreeTerms: false,
    dateOfBirth: '',
  };

  const accountContext = useContext(AccountContext);

  const [showLogin, setShowLogin] = useState<boolean>(false);

  const onSubmit = async (data: any, actions: any) => {

    const { setStatus, setSubmitting } = actions;

    // submit the changes
    const registrationData: IAccount = {
      email: data.email,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      zipcode: data.zipcode,
      organization: data.organization,
      country: data.country,
      dateOfBirth: data.dateOfBirth,
      phoneNumberCode,
      phoneNumber,
    };

    try {
      setSubmitting(true);
      const account: IAccount | null | undefined = await accountContext?.register(registrationData);
      trackEvent();
      setSubmitting(false);
      if (!account) {
        throw Error("Unexpected error: empty account");
      }
    } catch (error) {
      console.error({ error });
      setSubmitting(false);
      setStatus(`${error}`);
    }
  }

  return <>

    {showLogin ? (
      <PatientLogin onLogin={onSuccess} />

    ) : (
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationMap.get(Validator.REGISTRATION_FORM)} onSubmit={onSubmit}>
        {({ values, status, isSubmitting, errors, touched, setFieldValue }) => {
          return <Form>
            <div className="registration-form container-fluid my-3">
              <div className="row justify-content-center text-center">
                <div className="col-md-6">
                  <div>Already have an account? <button type="button" className="btn btn-link" onClick={() => { setShowLogin(true) }}>Log in</button></div>
                </div>
              </div>
              <div className="row justify-content-center mt-5">
                <div className="col-md-6">
                  {/* Account Set up */}
                  <AccountSetup errors={errors} touched={touched} title={<div className="margin-center text-center">Register</div>}/>
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <div className="col-md-6">
                  {/* Personal Information*/}
                  <PersonalInfo errors={errors} touched={touched} />

                  <div className="container my-3">
                    <DateOfBirth errors={errors} touched={touched} values={values} fieldName="dateOfBirth" setFieldValue={setFieldValue} />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mt-3">
                <div className="col-md-6">
                  {/* Address */}
                  <AddressInfo errors={errors} touched={touched} />
                </div>
              </div>

              {status &&
                <div className="row justify-content-center mt-3">
                  <div className="col-md-6">
                    <div className="alert alert-warning my-2">{status}</div>
                  </div>
                </div>
              }

              <div className="row justify-content-center my-3">
                <div className="col-md-6">
                  <div className="d-flex justify-content-end mx-2">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Next</button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        }}
      </Formik>
    )
    }



  </>
}

export { Register }