import React, { useContext, useEffect, useState } from 'react';
import { IAppointment, IPractitioner } from 'interfaces';
import { publicApiService } from 'services';
import { useNavigate } from 'react-router';
import { Url } from '_constants';
import { IPractitionerAppointments, ITimeSlot } from 'interfaces/appointment';
import { InsuranceCompanyEnum } from '_constants/InsuranceCompanyEnum';
import { MessengerContext } from 'components/common/messenger';
import { AppointmentContext } from 'components/patient/scheduler/AppointmentContext';
import { PractitionerHelper } from './Helper';
import { AppointmentDeck } from './AppointmentDeck';

interface IProps {
  insuranceId: string,
  practitionerId?: number,
  onExpand?: (practitioner: IPractitioner) => void,
  onCollapse?: () => void,
  trackEvent: ()=>void,
}

const DoctorCarousel: React.FunctionComponent<IProps> = ({ insuranceId, practitionerId, onExpand, onCollapse, trackEvent }) => {
  const [appointments, setAppointments] = useState<Map<number, IPractitionerAppointments>>();
  const navigate = useNavigate();
  const apptContext = useContext(AppointmentContext);
  const msgContext = useContext(MessengerContext);

  useEffect(() => {
    const load = async () => {
      try {
        const insuranceCompanyId: number | undefined = ["-1", InsuranceCompanyEnum.SELF_PAY.toString(), InsuranceCompanyEnum.SELF_PAY.toString()].indexOf(insuranceId) >= 0 ? undefined : parseInt(insuranceId, 10);
        const today: Date = new Date();
        const filters = {
          insuranceCompanyId: insuranceCompanyId,
          appointmentStart: today.getTime() / 1000,
          practitionerId: practitionerId
        }
        const data: IAppointment[] = await publicApiService.getAppointments(filters);
        // group 'em
        const grouped = PractitionerHelper.groupAppointmentsByDoctor(data);
        setAppointments(grouped);
      } catch (error: any) {
        console.error((error as Error).message);
      }
    }
    load();
  }, [insuranceId, practitionerId])

  const onAppointmentClick = (timeslot: ITimeSlot, practitioner: IPractitioner) => {
    if (insuranceId.toString() === "-1" && !practitionerId) {
      msgContext.setMessage({ title: "Insurance Carrier Required", body: "Please select your insurance carrier in order to select an appointment with one of our participating physicians" });
      return;
    }
    apptContext?.setAppointment(timeslot, practitioner);
    apptContext?.setInsurance(parseInt(insuranceId, 10));
    trackEvent();
    navigate(Url.PATIENT_APPOINTMENT);
  }

  const allowClick = () => {
    return insuranceId.toString() === "-1" && !practitionerId;    
  }

  const onViewportClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    msgContext.setMessage({ title: "Insurance Carrier Required", body: "Please select your insurance carrier in Step 1 in order to select an appointment with one of our participating physicians" });

  }
  return (
    <>
      {!appointments && <>Loading...</>}
      {appointments && appointments.size > 0 &&
        <>
          <div className={`appt-viewport ${allowClick() ? "appt-viewport-collapsed" : ""}`} onClickCapture={allowClick() ? onViewportClick : ()=>{}}>
            <AppointmentDeck
              appointments={appointments}
              onAppointmentClick={onAppointmentClick}
              insuranceId={parseInt(insuranceId, 10)}
              onCollapse={onCollapse}
              onExpand={onExpand}
            />
          </div>
        </>
      }

      {appointments && appointments.size === 0 &&
        <div className="alert alert-warning mt-3 py-5 text-center">Sorry, there are no appointments available.</div>
      }
    </>

  );
}



export { DoctorCarousel }