import { AccountContext } from 'components/common/AccountContext';
import { ConfirmCheck } from 'components/common/form';
import { FormattedPhone } from 'components/common/form/FormattedPhone';
import { ErrorMessage, Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { validationMap, Validator } from 'schemas';


const PhoneForm: React.FunctionComponent<{ onSubmit: any }> = ({ onSubmit }) => {

  const initialValues = {
    phoneNumber: undefined,
    agreeToReceiveText: true
  }

  const acctContext = useContext(AccountContext);

  const _onSubmit = async (data: any, actions: any) => {
    // verify the phone number
    const { setStatus } = actions;
    const phoneNumber: number = typeof data.phoneNumber === "number" ? data.phoneNumber : parseInt(data.phoneNumber.replaceAll(/[^\d]/g,''), 10);
    try {
      await acctContext?.registerPhoneNumber(phoneNumber);
      onSubmit(phoneNumber, data.emailAddress);
    } catch (error: any) {

      if ((error as Error).message === 'Error: phoneNumber exists (phoneNumber)') {
        setStatus('Phone number already exists.')
      } else {
        setStatus((error as Error).message);
      }
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={_onSubmit} validationSchema={validationMap.get(Validator.FREE_KIT_VERIFY_PHONE_FORM)}>
      {({ values, errors, touched, status, setFieldValue, isSubmitting }) => {
        return <Form>


          <div className="form-group">
            <label>Mobile Phone Number</label>
            <FormattedPhone
              initialValue={values["phoneNumber"]}
              className={'form-control' + (errors && errors["phoneNumber"] && touched && touched["phoneNumber"] ? ' is-invalid' : '')}
              fieldName={"phoneNumber"} setPhoneNumber={(phone: string) => { setFieldValue("phoneNumber", phone) }} />
            {errors && <ErrorMessage name={"phoneNumber"} component="div" className="invalid-feedback" />}
          </div>


          <div className="my-3">
          <ConfirmCheck
            name="agreeToReceiveText"
            errors={errors}
            touched={touched}
          >
            <div>By submitting your phone number, you consent to Convena Care sending you a text message to your phone.</div>
          </ConfirmCheck>

          </div>


          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>

          {status && <div className="alert alert-warning my-3">{status}</div>}

        </Form>
      }}
    </Formik>

  );
}

export { PhoneForm }