import React, { useEffect, useState } from 'react';
import { ProductCodeStatus } from '_constants';
import { IActivationCode, IEnvelopeDetail } from 'interfaces';
import { adminCodeService } from 'services';


const EnvelopeQR: React.FunctionComponent = (props: any) => {

  const codeHash = props.match.params.envelopeID;
  const [validCodes, setValidCodes] = useState<IActivationCode[] | undefined>([]);

  useEffect(()=> {
    const _init = async () => {
      if (codeHash) {
        try {
          const detail: IEnvelopeDetail = await adminCodeService.getEnvelopeDetail(codeHash);
          const validCodes = detail.activationCodes && detail.activationCodes.filter( 
            (code: IActivationCode) => code.statusId === ProductCodeStatus.ACTIVATED
          );
          setValidCodes(validCodes);
        } catch (error: any) {
          throw Error(error.message);
        }
       }
    }
    _init();
  }, [codeHash])


  return(
    <>

    {!validCodes && <>None of the Activation Codes associated with this envelope has been activated. Do not open envelope.</>}

    {validCodes && <>At least one Activation Code associated with this envelope has been activated. You may open the envelope.</>}

    </>
  );
}

export { EnvelopeQR}