import React from "react";
import { AddressFields, BaseForm, FieldWithError } from "components/common";
import { IProspect } from "interfaces";
import { adminProspectApiService as apiService } from "services";
import { Validator, validationMap } from 'schemas';
import { Helper } from "_utils";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IProspect) => void;
  initialValues: any;
  onCancelClick: () => void;
}

const ProspectForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick }: IAppProps) => {

  const beforeSubmit = async (data: any, actions: any) => {
    // format the phone number
    data.phoneNumber = Helper.formatPhoneAsUS(data.phoneNumber);
  }

  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.COMMON_ADDRESS)}
      beforeSubmit={beforeSubmit}
    >
      {({errors, touched, setFieldValue, values}) => {
        return(
          <>
          <div className="container-md my-2">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="email"
            placeholder="E-Mail Address"
            label="Email Address"
          />

          </div>
          <AddressFields values={values} setFieldValue={setFieldValue} errors={errors} touched={touched} label="Prospect Name and Address" />          
          </>
        )
      }}
    </BaseForm>
  );
};


export {ProspectForm}