import React from 'react';
import { AccountProvider } from 'components/common/AccountProvider';
import { MessengerProvider } from 'components/common/messenger';
import { adminAccountService, adminAuthenticationService } from 'services';
import { AdminBody } from './Body';
import { AdminFooter } from './Footer';
import { AdminHeader } from './Header';

const Layout: React.FunctionComponent = () => {
  return (
    <div className="practitioner-layout container-fluid">
      <AccountProvider accountService={adminAccountService} authService={adminAuthenticationService}>
        <MessengerProvider>
          <AdminHeader />
          <AdminBody />
          <AdminFooter />
        </MessengerProvider>
      </AccountProvider>
    </div>
  );
}

export { Layout as AdminLayout }