import React from 'react';
import { FieldWithError } from 'components/common';
import { ErrorMessage } from 'formik';
import { FormattedPhone } from 'components/common/form/FormattedPhone';


const InsuranceDetails: React.FunctionComponent<{ errors: any, touched: any, setFieldValue: any, values: any }> = ({ errors, touched, setFieldValue, values }) => {
  return <>

    <div className="container g-0">
      <div className="row">
        <div className="col-12 col-md-4 my-2">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="insuranceMemberId"
            placeholder="Insurance Member ID"
            label="Member ID"
          />
        </div>
        <div className="col-12 col-md-4 my-2">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="insuranceGroupId"
            placeholder="Group ID"
            label="Insurance Group ID"
          /></div>
        <div className="col-12 col-md-4 my-2">
          <div className="form-group">
            <label>Insurance Phone Number</label>
            <FormattedPhone
              initialValue={values["insurancePhoneNumber"]}
              className={'form-control' + (errors && errors["insurancePhoneNumber"] && touched && touched["insurancePhoneNumber"] ? ' is-invalid' : '')}
              fieldName={"insurancePhoneNumber"} setPhoneNumber={(phone: string) => { setFieldValue("insurancePhoneNumber", phone) }} />
            {errors && <ErrorMessage name={"insurancePhoneNumber"} component="div" className="invalid-feedback" />}
          </div>

        </div>
      </div>
    </div>
  </>
}

export { InsuranceDetails }