import React, { useEffect, useState } from "react";
import { IApiService, IDataField } from "interfaces";
import { BaseTable } from "components/common";


interface IChildProps<T> {
  isEditing: boolean;
  onSuccess: (item: T) => void;
  initialValues: any;
  onCancelClick: () => void;
  editableFields?: IDataField [];
}

interface IAppProps<T> {
  apiService: IApiService<T>,
  emptyValues: T,
  onSelectedRows?: (ids: string[]) => void,
  children: (props: IChildProps<T>) => React.ReactNode,
  hasMultiSelect?: boolean,
  multiSelectLabel?: string,
  readOnly?: boolean,
  buttons?: number[],
  title?: string,
  editableFields?: IDataField [],
  fieldList?: Map<string, [string, Function | undefined]>,
}

const ListContainer = <T extends { id?: number }>({
  apiService,
  emptyValues,
  onSelectedRows,
  hasMultiSelect,
  multiSelectLabel,
  readOnly,
  buttons,
  title,
  children,
  editableFields,
  fieldList
}: IAppProps<T>) => {

  const [data, setData] = useState<T[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<T>({} as T);
  const [status, setStatus] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const _init = async () => {
      try {
        const data = await apiService.getAll();
        setData(data);
      } catch (error: any) {
        setStatus(error.message);        
      } finally {
        setLoading(false);
      }
    };
    _init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // callback when record was successfully created or updated
  const onSuccess = (savedItem: T) => {
    console.log('onSuccess', {savedItem});
    setIsFormVisible(false);
    if (isEditing) {
      setData(data.map((item: T) => (item.id === savedItem.id ? { ...item, ...savedItem } : item)));
    } else {
      setData([...data, savedItem]);
    }
  };

  // callback for when "Edit" button is clicked
  const onEditClick = (item: T, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setStatus("");
    setIsEditing(true);
    setInitialValues(item);
    setIsFormVisible(true);
  };

  // callback for when "Add" button is clicked
  const onAddClick = (e: React.MouseEvent <HTMLElement>) => {
    e.stopPropagation();
    setStatus("");
    setIsEditing(false);
    setInitialValues(emptyValues);
    setIsFormVisible(true);
  };

  // callback for when "Cancel" button is clicked
  const onCancelClick = () => {
    setIsEditing(false);
    setIsFormVisible(false);
    setInitialValues(emptyValues);
  };

  // callback for when "Delete" button is clicked
  const onDeleteClick = async (item: T, e:React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (window.confirm("Are You Sure You Want to Delete?")) {
      try {
        if (item.id) {
          await apiService.delete(item.id);
          setData(data.filter((row: T) => row.id !== item.id));
          setStatus("Record Deleted");
        } else {
          throw Error("Error deleting. No ID found");
        }
      } catch (error) {
        setStatus("Could Not Delete");
      }
    }
  };

  const onSelectClick = (ids: string[]) => {
    if (onSelectedRows) {
      onSelectedRows(ids);
    }
  };

  return (
    <>
      <h4>{title}</h4>
      {!isFormVisible && (
        <>
        <BaseTable
          data={data}
          onAddClick={onAddClick}
          onDeleteClick={onDeleteClick}
          onEditClick={onEditClick}
          onSelectClick={onSelectClick}
          hasMultiSelect={hasMultiSelect}
          multiSelectLabel={multiSelectLabel}
          readOnly={readOnly}
          buttons={buttons}
          fieldList={fieldList}
          loading={loading}
        />
        </>
      )}

      {isFormVisible && <>{children({ isEditing, onSuccess, initialValues, onCancelClick, editableFields })}</>}

      {status && <div>{status}</div>}
    </>
  );
};

export { ListContainer };
