import React, { useContext, useState } from 'react';
import { AccountContext, FieldWithError } from 'components/common';
import { FormattedPhone } from 'components/common/form/FormattedPhone';
import { ErrorMessage, Form, Formik } from 'formik';
import { validationMap, Validator } from 'schemas';
import { trackingService } from 'services';
import { useLandingPage } from '_hooks/useLandingPage';

const Verify: React.FunctionComponent<{ onVerifyCallback: (phoneNumberCode: string, phoneNumber: number) => void }> = ({ onVerifyCallback }) => {

  const accountContext = useContext(AccountContext);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [isResend, setIsResend] = useState<boolean>(false);
  const [resendCount, setResendCount] = useState<number>(0);
  const [phoneNumber, setPhoneNumber] = useState<number | undefined>(undefined);

  const landingPage = useLandingPage();

  const initialValues = {
    phoneNumber: ""
  }

  const onSubmitPhone = async (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;
    try {
      const phoneNumber = parseInt(`${data.phoneNumber.replaceAll(/[^\d]/g, '')}`);
      await accountContext?.registerPhoneNumber(phoneNumber);
      setPhoneNumber(phoneNumber);
      setWaiting(true);
      setSubmitting(false);
      trackingService.fireGoogleEvent({
        action: `${landingPage.getLandingPage()}Appointment | Entered Phone Number`,
        category: 'Appointment',
        label: 'Text My Verification Code',
      });


    } catch (error: any) {
      setStatus((error as Error).message);
    }
  }

  const onVerify = async (data: any, actions: any) => {
    const { setStatus } = actions;
    try {
      if (!phoneNumber) throw Error('missing phone number');

      const { confirmationCode } = data;
      const success: boolean = await accountContext?.checkRegisteredPhoneNumber(phoneNumber, confirmationCode);
      if (!success) {
        throw Error('Could not verify the code');
      }
      if (onVerifyCallback) {
        onVerifyCallback(confirmationCode, phoneNumber);
      }
    } catch (error: any) {
      trackingService.fireGoogleEvent({
        action: `${landingPage.getLandingPage()}Appointment | Failed Verification`,
        category: 'Appointment',
        label: 'Enter Verification Code',
      });
      setStatus((error as Error).message);
    }
  }

  const onClickResend = async () => {
    try {
      if (!phoneNumber) throw Error('Missing phone number. Could not resend.');
      await accountContext?.registerPhoneNumber(phoneNumber)
      setWaiting(true);
      setIsResend(true);
      setResendCount(resendCount + 1);
      trackingService.fireGoogleEvent({
        action: `${landingPage.getLandingPage()}Appointment | Clicked Resend`,
        category: 'Appointment',
        label: 'Resend Verification Code',
      });

    } catch (error: any) {
      console.error('error resending', { error });
    }
  }

  return <>

    <div className="container-sm">

      <div className="small-text-3 mt-3 py-3">
        We need to verify your phone number in order to set up an appointment.
      </div>

      {!waiting &&
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validationMap.get(Validator.VERIFY_PHONE_FORM)} onSubmit={onSubmitPhone}>
          {({ status, errors, touched, setFieldValue }) => {
            return (
              <Form>
                <div className="form-group">
                  <div className="my-2 verify-phone">Please enter your phone number:</div>
                  <FormattedPhone
                    className={'verify-phone form-control' + (errors && errors["phoneNumber"] && touched && touched["phoneNumber"] ? ' is-invalid' : '')}
                    fieldName={"phoneNumber"} setPhoneNumber={(phone: string) => { setFieldValue("phoneNumber", phone) }} />
                  {errors && <ErrorMessage name={"phoneNumber"} component="div" className="invalid-feedback" />}
                </div>
                {status && <div className="alert alert-warning my-2">{status}</div>}
                <button type="submit" className="btn btn-primary my-3" >Text My Verification Code</button>
              </Form>
            )
          }}
        </Formik>
      }

      {waiting &&
        <>
          <div className="container">
            <div className="row justify-content-left">
              <div className="col-12 col-md-6 align-self-center">
                <Formik initialValues={{ confirmationCode: '' }} onSubmit={onVerify} validationSchema={validationMap.get(Validator.PATIENT_MOBILE_CONFIRMATION)}>
                  {({ status, setStatus, isSubmitting, errors, touched }) => {
                    return (
                      <Form>
                        <div className="container mt-2 g-0">
                          <div className="row justify-content-md-start">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                              <FieldWithError fieldName="confirmationCode" errors={errors} touched={touched} label="Verification Code" />
                              <button type="submit" className="btn btn-primary mt-2" disabled={isSubmitting}>Verify</button>
                            </div>
                          </div>
                          {status && <div className="alert alert-warning mt-2">{status}</div>}

                        </div>
                      </Form>
                    );
                  }}
                </Formik>

              </div>
              <div className="col-12 col-md-6 align-self-center mt-sm-3">
                <div className="mt-3">
                  If you do not receive it after a couple of minutes, click the button below:<br />
                  <button className="btn btn-primary mt-3" onClick={() => onClickResend()}>Resend</button>
                </div>

                {isResend &&
                  <div className="mt-3">Verification Code resent ({resendCount}).</div>
                }

              </div>
            </div>
          </div>

        </>

      }

    </div>

  </>
}

export { Verify }