import React, { useContext, useEffect, useState } from 'react';
import { patientService } from 'services';
import { AccountContext } from 'components/common/AccountContext';
import { useSearchParams } from 'react-router-dom';
import { ActivationForm } from 'components/patient/scheduler/ActivationForm';
import { AppointmentContext } from 'components/patient/scheduler/AppointmentContext';
import { IAppointmentResponseItem } from 'components/patient/appointments';
import { AppointmentStatusEnum } from '_constants/AppointmentStatusEnum';
import { DoctorCarousel } from './DoctorCarousel';
import { InsuranceFilterSingle } from './InsuranceFilterSingle';
import { IPractitioner } from 'interfaces';
import { trackingService } from 'services/common/TrackingService';
import { useLandingPage } from '_hooks/useLandingPage';

const PractitionerListing: React.FunctionComponent<any> = (props: any) => {

  const [insuranceId, setInsuranceId] = useState<string>('');
  const [searchParams] = useSearchParams();
  const [practitionerId, setPractitionerId] = useState<number | undefined>(undefined);
  const kit = searchParams.get('kit') || false;
  const code = searchParams.get('code');
  const insId = searchParams.get('insuranceId') || null;
  const practId = searchParams.get('practitionerId') || undefined;
  const [showKit, setShowKit] = useState<boolean>(!!kit === true);

  const apptContext = useContext(AppointmentContext);
  const acctContext = useContext(AccountContext);
  const [hasAppt, setHasAppt] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isFilterDisabled, setIsFilterDisabled] = useState<boolean>(false);

  const landingPage = useLandingPage();

  useEffect(() => {
    const _load = async () => {
      if (acctContext?.account) {
        const appts: IAppointmentResponseItem[] = await patientService.getAppointments();
        if (appts.filter((appt: IAppointmentResponseItem) => [AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE, AppointmentStatusEnum.SCHEDULE_PRACTITIONER_CONFIRMED].indexOf(appt.statusId || 0) >= 0).length > 0) {
          setHasAppt(true);
        }

        if (insId) {
          setInsuranceId(insId);
        }
      }
      setLoaded(true);
    }
    _load();

    if (practId) {
      setPractitionerId(parseInt(practId, 10));
    }
    window.scrollTo(0, 0);

  }, [hasAppt, acctContext?.account, insId, practId])

  const onClickSearch = (insuranceId: string) => {
    trackingService.fireGoogleEvent({
      action: `${landingPage.getLandingPage()}Make Appointment Page | Selecting Insurance`,
      category: 'Make Appointment Page',
      label: 'Select Insurance Carrier',
    });    
    setInsuranceId(insuranceId);
    apptContext?.setInsurance(parseInt(insuranceId, 10));
  }

  const onSubmitActivationForm = (data: any) => {
    setShowKit(false);
    apptContext?.setActivationCode(data.data.activationCode);
    apptContext?.setIsActivatingKit(true);
    trackingService.fireGoogleEvent({
      action: `${landingPage.getLandingPage()}Make Appointment Page | Activate Kit`,
      category: 'Make Appointment Page',
      label: 'Activate Kit',
    });  
  }

  const trackEvent = () => {
    trackingService.fireGoogleEvent({
      action: `${landingPage.getLandingPage()}Make Appointment Page | Clicked Appointment Slot`,
      category: 'Make Appointment Page',
      label: 'Select Time Slot',
    });
  }

  return (
    <>
      {loaded ? (
        <>
          {!!hasAppt &&
            <div className="container mt-5">
              <h2>Appointment Already Booked</h2>
              <div>
                Looks like you already have an appointment. If you need further assistance,
                please call us at 908 895 6969. Or you can manage your existing appointment
                at your <a href="/patient/dashboard">My Appointments</a> page.
              </div>
            </div>

          }

          {!!!hasAppt &&
            <div className="container">
              {showKit &&
                <>
                  <div className="row">
                    <div className="medium-text-1">Steps to Activating Your Code</div>
                    <div className="container mt-3">
                      <div className="row">
                        <div className="col py-3 border mx-2">Enter Activation Code</div>
                        <div className="col py-3 border mx-2">Have TeleHealth Visit with licensed physician to get required prescription.</div>
                        <div className="col py-3 border mx-2">Return Card Sample in enclosed envelope</div>
                      </div>
                    </div>
                    <ActivationForm onSubmit={onSubmitActivationForm} activationCode={code || undefined} />
                  </div>
                </>
              }

              {!!!showKit &&
                <>
                  {insId === null && !practitionerId &&
                    <div className="d-flex">
                      <div className="">

                        <div className="medium-text-2 dark-blue strong mt-3">Make an Appointment Now</div>
                        <div className="dark-blue mt-3 mr-3">
                          <p>Most insurance plans cover preventive health visits and cancer screening and <strong>do not charge any co-pay or deductible.</strong></p>
                          <p>Verify your insurance below to see if you qualify:</p>

                          <InsuranceFilterSingle onClickSearch={onClickSearch} disabled={insId !== null || isFilterDisabled} />

                        </div>
                      </div>

                      <div style={{ maxWidth: "500px" }} className="d-none d-sm-none d-xs-none d-md-block mx-2">
                        <img className="rounded-3" src="/images/woman-telehealth-visit.png" width="100%" alt="ConvenaCare" />
                      </div>
                    </div>
                  }
                  <div>
                    <div className="small-text-2 orange strong mt-3">Step 2</div>
                    <div className="medium-text-2 strong dark-blue mt-1">Select a Time With One of Our Physicians Below</div>

                    <DoctorCarousel insuranceId={insuranceId || "-1"} practitionerId={practitionerId}
                      onExpand={(practitioner?: IPractitioner) => setIsFilterDisabled(true)}
                      onCollapse={() => setIsFilterDisabled(false)}
                      trackEvent={trackEvent}
                    />
                  </div>
                </>
              }

            </div>
          }
        </>
      ) : (
        <div></div>
      )}

    </>
  );
}

export { PractitionerListing }