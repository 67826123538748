import React from 'react';
import { ForgotPassword } from 'components/common/ForgotPassword';
import { practitionerAccountService } from 'services';

const PractitionerForgotPassword: React.FunctionComponent = () => {

  return(
    <ForgotPassword accountService={practitionerAccountService} />
  );
}

export { PractitionerForgotPassword }