
import React from 'react';
import { AccountProvider } from 'components/common/AccountProvider';
import { MessengerProvider } from 'components/common/messenger';
import { practitionerAccountService, practitionerAuthenticationService } from 'services';
import { PractitionerBody } from './Body';
import { PractitionerFooter } from './Footer';
import { PractitionerHeader } from './Header';

const Layout: React.FunctionComponent = () => {
  return (
    <div className="practitioner-layout container-fluid">
      <AccountProvider accountService={practitionerAccountService} authService={practitionerAuthenticationService}>
        <MessengerProvider>
          <PractitionerHeader />
          <PractitionerBody />
          <PractitionerFooter />
        </MessengerProvider>
      </AccountProvider>
    </div>
  );
}

export { Layout as PractitionerLayout }