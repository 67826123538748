import React, { useEffect, useState } from 'react';
import { IPractitionerResponseItem } from 'interfaces/appointment/IPractitionerResponseItem';
import { publicApiService } from 'services';
import { IPractitioner } from 'interfaces';
import { PhysicianBioCard } from './BioCard';
import { trackingService } from 'services/common/TrackingService';


const Physicians: React.FunctionComponent = () => {

  const [docs, setDocs] = useState<IPractitionerResponseItem[]>([]);
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    const _load = async () => {
      try {
        const docs: IPractitionerResponseItem[] = await publicApiService.getPhysicians();
        setDocs(docs);
        window.scrollTo(0, 0);
      } catch (error: any) {
        console.error({error});
        setStatus((error as Error).message);
      }
    }
    _load();
  }, [])

  const trackEvent = () => {
    trackingService.fireGoogleEvent({
      action: 'Meet Our Physicians | Clicked Make Appointment button',
      category: 'Meet Our Physicians',
      label: 'Make Appointment',
    });    
  }

  return (
    <div className="container">
      <div className="medium-text-2 dark-blue strong mt-3">Meet Our Physicians</div>
      <div className="">
        {docs && docs.map((practitioner: IPractitionerResponseItem, i: number) => {
          return <div key={`biocard-key-${i}`}>
            <PhysicianBioCard practitioner={practitioner as IPractitioner} trackEvent={trackEvent} />
          </div>
        })
        }
        {status && <div className="alert alert-warning">{status}</div>}
      </div>
    </div>
  );
}

export { Physicians }