import React, { useEffect, useState } from "react";
import { IAppointment, IPractitioner, IPractitionerAppointments, ITimeSlot, IUser } from "interfaces";
import { publicApiService } from "services";
import { PractitionerHelper } from "components/common";
import { AppointmentDeck } from "components/common/PractitionerListingPage/AppointmentDeck";

const AddUserAppointment: React.FunctionComponent<{ user: IUser, cancel: () => void, onSelect: (timeslot: ITimeSlot) => void }> = ({ user, cancel, onSelect }) => {

  const [appts, setAppts] = useState<Map<number, IPractitionerAppointments>>(new Map<number, IPractitionerAppointments>());

  useEffect(() => {
    const filters = {
      appointmentStart: new Date().getTime() / 1000,
    }
    const _load = async () => {
      const appts: IAppointment[] = await publicApiService.getAppointments(filters);
      setAppts(PractitionerHelper.groupAppointmentsByDoctor(appts));
    }
    _load();

  }, [user]);

  const onAppointmentClick = (timeslot: ITimeSlot, practitioner: IPractitioner) => {
    onSelect(timeslot);
  }

  return (
    <div className="container">
      <div className="small-text-2 fw-bold">Select Appointment</div>

      <AppointmentDeck
        appointments={appts}
        onAppointmentClick={onAppointmentClick}
      />

      <button className="btn btn-link" onClick={() => { cancel() }}>Cancel </button>
    </div>
  );
}

export { AddUserAppointment }
