import React, { useContext, useState } from "react";
import { ITimeSlot, IUser } from "interfaces";
import { adminUserApiService } from "services";
import { ListContainer } from "components/common";
import { UserForm } from "./UserForm";
import { FormButtons } from "_constants";
import { practitionerService } from "services/PractitionerService";
import { MessengerContext } from "components/common/messenger";
import { Modal } from "react-bootstrap";
import { Helper } from "_utils";
import { AddUserAppointment } from "components/practitioner/patient/AddUserAppointment";
import { PatientDetail } from "components/practitioner/patient/PatientDetail";

const AdminPatientList: React.FunctionComponent = () => {

  const msgrContext = useContext(MessengerContext);

  const emptyValues: IUser = {
    email: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    phoneNumber: null,
    city: '',
    country: "USA", //@Todo: Add country field in form
    zipcode: '',
    state: '',
    dateOfBirth: undefined
  };

  const [addingUserAppt, setAddingUserAppt] = useState<IUser | undefined>(undefined);
  const [detailedPatient, setDetailedPatient] = useState<IUser | undefined>(undefined);

  const onSelectedRows = (ids: string[]) => {
    //console.log('selected ', ids);
  }

  const onAddAppointmentClick = (user: IUser) => {
    setAddingUserAppt(user)
  }

  const appointmentLink = (row: any) => {
    return (
      <div role="link" className="btn btn-link m-0 p-0" onClick={() => onAddAppointmentClick(row)}>Add Appointment</div>
    );
  }

  const patientLink = (patient: IUser) => {
    return <div role="link" className="btn btn-link m-0 p-0" onClick={() => setDetailedPatient(patient)}>{patient.id}</div>
  }

  const localTime = (patient: IUser) => {
    return <>{Helper.getLocalDateTimeFromUTC(new Date(patient.creationTimestamp))}</>
  }

  const cancelAdding = () => {
    setAddingUserAppt(undefined);
  }

  const onSelectTimeSlot = async (timeslot: ITimeSlot) => {
    try {
      if (addingUserAppt && addingUserAppt.id) {
        await practitionerService.scheduleAppointment(timeslot.id, addingUserAppt.id)
        msgrContext.setMessage({ title: 'Schedule Appointment', body: <>Appointment scheduled. Now waiting for Doctor to confirm availability.</> })
        cancelAdding();
      } else {
        throw Error('Unexpected error. Missing Patient');
      }
    } catch (error: any) {
      console.error('error in onSelectTimeSlot', { error });
      msgrContext.setMessage({ title: 'Unexpected Error', body: <>{(error as Error).message}</> })
    }
  }

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['creationTimestamp', ['Creation Date', localTime]],
    ['id', ['Patient ID', patientLink]],
    ['email', ['E-Mail Address', undefined]],
    ['firstName', ['First Name', undefined]],
    ['lastName', ['Last Name', undefined]],
    ['DOB', ['DOB', (user: IUser) => {return user.dateOfBirth ? Helper.formattedMDY(user.dateOfBirth) : ''}]],
    ['address1', ['Address1', undefined]],
    ['city', ['City', undefined]],
    ['state', ['State', undefined]],
    ['zipcode', ['Zip Code', undefined]],
    ['phoneNumber', ['Phone Number', undefined]],
    ['appt', ['New Appointment', appointmentLink]]
  ]);

  return (
    <>
      <div className='practitioner-layout-section-title'>Patient Manager</div>

      {addingUserAppt ? (
        <AddUserAppointment user={addingUserAppt} cancel={cancelAdding} onSelect={onSelectTimeSlot} />
      ) : (

        <div className="d-flex flex-row justify-content-evenly">
          <div>
            <ListContainer
              apiService={adminUserApiService}
              emptyValues={emptyValues}
              onSelectedRows={onSelectedRows}
              buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
              hasMultiSelect={false}
              fieldList={fieldList}
            >
              {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
                return (
                  <UserForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick} />
                );
              }}
            </ListContainer>
          </div>

          <Modal scrollable={true} show={detailedPatient !== undefined } onHide={()=>setDetailedPatient(undefined)} size="lg" centered>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter" className="text-primary">Patient Detail</Modal.Title>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setDetailedPatient(undefined)}></button>
            </Modal.Header>
            <Modal.Body>
              <PatientDetail patient={detailedPatient || {} as IUser} />
            </Modal.Body>
          </Modal>

        </div>
      )}


    </>
  );
};

export { AdminPatientList };
