import React from "react";
import { ErrorMessage } from "formik";
import { FieldWithError, StateField } from "components/common";
import { IAddressFieldMap, IFieldTitle } from "interfaces";
import { FormattedPhone } from "./FormattedPhone";
import { DateOfBirth } from "./DateOfBirth";

interface IProps { 
  errors: any, 
  touched: any, 
  setFieldValue: any,
  values: any,
  label?: string | JSX.Element,
  fieldMap?: IAddressFieldMap,
}
const AddressFields: React.FunctionComponent<IProps> = ({
  errors,
  touched,
  setFieldValue,
  label,
  fieldMap,
  values
}) => {
  if (!fieldMap) {
    fieldMap = {
      firstName: {fieldName: 'firstName', fieldTitle: 'First Name'},
      lastName: {fieldName:'lastName', fieldTitle: 'Last Name'},
      address1: {fieldName:'address1', fieldTitle: 'Address 1'},
      address2: {fieldName:'address2', fieldTitle: 'Address 2'},
      city: {fieldName:'city', fieldTitle: 'City'},
      state: {fieldName:'state', fieldTitle: 'State'},
      zipcode: {fieldName:'zipcode', fieldTitle: 'Zip Code'},
      phoneNumber: {fieldName:'phoneNumber', fieldTitle: 'Phone Number'},
    };
  }


  function getFieldTitle(field: string | IFieldTitle): string | JSX.Element {
    if (typeof field === 'string') {
      return field;
    }
    return (field as IFieldTitle).fieldTitle;
  }

  function getFieldName(field: string | IFieldTitle): string  {
    if (typeof field === 'string') {
      return field;
    }
    return (field as IFieldTitle).fieldName;

  }

  return (
    <div className="container g-0">
      {/* <pre>{JSON.stringify(errors,null, 20)}</pre> */}
      <div className="">
        {(label && typeof label === 'object') ? (<>{label}</>) : (<h4 className="card-header">{label}</h4>)}
          <div className="container g-0">
            <div className="row mb-2">
              <div className="col-sm-12 col-md-6 my-2">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={getFieldName(fieldMap.firstName)}
                  placeholder="Enter First Name"
                  label={getFieldTitle(fieldMap.firstName)}
                />
              </div>
              <div className="col-sm-12 col-md-6 my-2">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={getFieldName(fieldMap.lastName)}
                  placeholder="Enter Last Name"
                  label={getFieldTitle(fieldMap.lastName)}
                />
              </div>
            </div>

            {fieldMap.dateOfBirth && 
            <div className="row mb-2">
              <div className="col">
                <DateOfBirth errors={errors} touched={touched} values={values} 
                    fieldName={getFieldName(fieldMap.dateOfBirth)} 
                    setFieldValue={setFieldValue} title={<div>Date of Birth</div>} />
              </div>
            </div>
            }

            <div className="row mb-2">
              <div className="col my-2">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={getFieldName(fieldMap.address1)}
                  placeholder="Enter Address Line 1"
                  label={getFieldTitle(fieldMap.address1)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col my-1">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={getFieldName(fieldMap.address2)}
                  placeholder="Enter Address Line 2 (Optional)"
                  label={getFieldTitle(fieldMap.address2)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-5 my-1">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={getFieldName(fieldMap.city)}
                  placeholder="Enter City"
                  label={getFieldTitle(fieldMap.city)}
                />
              </div>

              <div className="col-md-3 my-1">
                <div className="form-group">
                  <label>{getFieldTitle(fieldMap.state)}</label>
                  <StateField
                    name={getFieldName(fieldMap.state)}
                    className={
                      "form-control" +
                      (errors && errors[getFieldName(fieldMap.state)] && 
                          touched && touched[getFieldName(fieldMap.state)] ? " is-invalid" : "")
                    }
                  />
                {errors && <ErrorMessage name={getFieldName(fieldMap.state)} 
                              component="div" className="invalid-feedback" />}
                </div>
              </div>

              <div className="col-md-3 my-1">
                <FieldWithError
                  errors={errors}
                  touched={touched}
                  fieldName={getFieldName(fieldMap.zipcode)}
                  placeholder="Zip Code"
                  label={getFieldTitle(fieldMap.zipcode)}
                />
              </div>
            </div>
            {fieldMap.phoneNumber && 
            <div className="row mb-2">
              <div className="col-auto">
                <div className="form-group">
                  <label>{getFieldTitle(fieldMap.phoneNumber)}</label>
                  <FormattedPhone
                    initialValue={values[getFieldName(fieldMap.phoneNumber)]}
                    className={'form-control' + (errors && errors[getFieldName(fieldMap.phoneNumber)] && touched && touched[getFieldName(fieldMap.phoneNumber)] ? ' is-invalid' : '')}
                    fieldName={getFieldName(fieldMap.phoneNumber)} setPhoneNumber={(phone: string) => { fieldMap?.phoneNumber && setFieldValue(getFieldName(fieldMap.phoneNumber), phone) }} />
                  {errors && <ErrorMessage name={getFieldName(fieldMap.phoneNumber)} component="div" className="invalid-feedback" />}
                </div>
              </div>
            </div>
            }
          </div>
        </div>
    </div>
  );
};

export { AddressFields };
