import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { IAdminCodeHash, IKeyValue } from "interfaces";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ConfirmCheck, FieldWithError } from "components/common";
import { Helper } from "_utils";
import { adminCodeService } from "services";


const CodeResult = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [collectionDate, setCollectionDate] = useState<Date>(new Date());
  const hashedId: string | null = urlSearchParams.get("hashedId");
  const [codeHashRow, setCodeHashRow] = useState<IAdminCodeHash>({} as IAdminCodeHash);

  useEffect(() => {
    const _init = async () => {
      if (hashedId) {
        try {
          const response: IAdminCodeHash = await adminCodeService.getCodeFromHash(hashedId);
          setCodeHashRow(response);
        } catch (error: any) {
          throw Error(error.message);
        }
      }
    }
    _init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialFormState = {
    id: undefined,
    result: undefined,
    collectionDate: Helper.getFormattedDate(new Date()),
    techConfirmedPatient: false,
    techConfirmedCollectionDate: false,
    techConfirmedCollectionCard: false,
    file: undefined,
  };

  const onSubmit = (data: any, actions: any) => {
    data.id = codeHashRow.id;
    try {
      adminCodeService.updateResult(data);
    } catch (error: any) {
      console.error({error});
      alert('error. see console.');
    }
  };

  const ResultMap: Map<number, { image: string; label: string }> = new Map([
    [1, { image: "/images/result-positive.png", label: "Positive" }],
    [2, { image: "/images/result-negative.png", label: "Negative" }],
    [3, { image: "/images/result-invalid-1.png", label: "Invalid-1" }],
    [4, { image: "/images/result-invalid-2.png", label: "Invalid-2" }],
  ]);

  const ConfirmationChecks: IKeyValue<string>[] = [
    {key: "techConfirmedPatient", value: "I confirmed that the Patient Name matches"},
    {key: "techConfirmedCollectionDate", value: "I confirmed that the Collection Date is correct"},
    {key: "techConfirmedCollectionCard", value: "I confirmed that the Collection Card matches the result selected"},
  ];

  const validationSchema = Yup.object().shape({
    result: Yup.string().required("Please select the result"),
    collectionDate: Yup.string().required("Please enter the Collection Date"),
    techConfirmedPatient: Yup.boolean().oneOf([true], "Please confirm that you matched the patient names"),
    techConfirmedCollectionDate: Yup.boolean().oneOf(
      [true],
      "Please confirm that you matched the collection date to the collection card"
    ),
    techConfirmedCollectionCard: Yup.boolean().oneOf(
      [true],
      "Please confirm that you matched your result selection with the result card"
    ),
    file: Yup.mixed().required('Image of Collection is Required'),
  });

  return (
    <div className="container-lg">
      <h3>Kit Result</h3>

      <table className="table">
        <thead>
          <tr>
            <th colSpan={2}>Patient Info</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>First Name</td>
            <td>{codeHashRow.patientFirstName}</td>
          </tr>
          <tr>
            <td>Last Name</td>
            <td>{codeHashRow.patientLastName}</td>
          </tr>
          <tr>
            <td>Date of Birth</td>
            <td>{codeHashRow.patientDateOfBirth}</td>
          </tr>
        </tbody>
      </table>

      <Formik initialValues={initialFormState} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values, isSubmitting, errors, touched, status, setFieldValue }) => {
          return (
            <Form>
              <div>
                <span className="me-2">Collection Date</span>
                <FieldWithError errors={errors} touched={touched} fieldName="collectionDate" placeholder="YYYY/MM/DD" />
                <DatePicker
                  onChange={(date: Date) => {
                    setCollectionDate(date);
                    setFieldValue("collectionDate", Helper.getFormattedDate(date));
                  }}
                  selected={collectionDate}
                />
              </div>
              <div className="my-2">
                Please select the result:
                <div
                  role="group"
                  className={
                    "d-flex flex-column form-control" +
                    (errors && errors["result"] && touched && touched["result"] ? " is-invalid" : "")
                  }
                >
                  {Array.from(ResultMap.entries()).map((i: any) => {
                    const [key, value] = i;
                    return (
                      <div className="my-2">
                        <Field id={`result-${key}`} name="result" type="radio" value={key.toString()} />
                        <label className="form-class-label" htmlFor={`result-${key}`}>
                          <img src={value.image} alt={value.label} className="mx-4" />
                          <span className="mx-2">{value.label}</span>{" "}
                        </label>
                      </div>
                    );
                  })}
                </div>
                {errors && <ErrorMessage className="mt-2 invalid-feedback" name="result" component="div" />}
              </div>
              <div className="form-group">
                <label htmlFor="file">Upload a picture of the collection card and the result card together</label>
                <input
                  id="collectionCardImage"
                  name="file"
                  type="file"
                  onChange={(event) => {
                    if (event.currentTarget.files) {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }
                  }}
                  className="form-control"
                />
                {errors && <MyErr errors={errors} touched={touched} name="file" />}
              </div>
              Technician Checklist
              {ConfirmationChecks.map((i: IKeyValue<string>) => (
                <ConfirmCheck errors={errors} touched={touched} name={i.key} label={i.value} />
              ))}
              <div>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                  Submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};


const MyErr:React.FunctionComponent<{errors: any, touched: any; name: string}> = ({errors, touched, name}) => {
  return(
<div className="error">{errors && errors[name] && touched && touched[name] ? errors[name] : ''}</div>
  );
}

export { CodeResult };
