import { AccountContext } from 'components/common';
import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { Url } from '_constants';
import { PractitionerRolesEnum } from '_constants/PractitionerRolesEnum';

const Dashboard: React.FunctionComponent = () => {

  const acctContext = useContext(AccountContext);
  const account = acctContext?.account;

  return (
    <>
      <div className="container-fluid">

        <div className='practitioner-layout-section-title'>Dashboard</div>
        <div className="medium-text-2 fw-bold"> </div>

        {account &&
          <div className="container d-flex flex-wrap  my-3">
            {account.roleId === PractitionerRolesEnum.DOCTOR_ROLE &&
              <div className="my-3 mx-2 flex-fill">
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>
                      Manage Availability
                    </Card.Title>
                    <Card.Text>
                      Use the calendar to manage your availability. Add and remove time slots.
                    </Card.Text>
                    <Card.Link href={Url.PRACTITIONER_CALENDAR}>Go to Calendar</Card.Link>
                  </Card.Body>
                </Card>
              </div>
            }

            <div className="my-3 mx-2 flex-fill">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>
                    Manage Appointments
                  </Card.Title>
                  <Card.Text>
                    Check the status as well as confirm, cancel and reschedule appointments.
                  </Card.Text>
                  <Card.Link href={Url.PRACTITIONER_APPOINTMENTS}>Go to Appointment Manager</Card.Link>
                </Card.Body>
              </Card>
            </div>

            <div className="my-3 mx-2 flex-fill">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>
                    Manage Patients
                  </Card.Title>
                  <Card.Text>
                    Add, Edit and View patient information, including insurance coverage.
                    <p>
                    For call-ins, use this section to create the patient's
                    Convena Care account and schedule a new appointment.
                    </p>
                  </Card.Text>
                  <Card.Link href={Url.PRACTITIONER_APPOINTMENTS}>Go to Patient Manager</Card.Link>
                </Card.Body>
              </Card>
            </div>


            <div className="my-3 mx-2 flex-fill">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>
                    Reference
                  </Card.Title>
                  <Card.Text>
                    Documentation and overview of using this portal. 
                  </Card.Text>
                  <Card.Link href={Url.PRACTITIONER_REFERENCE}>Go to Reference</Card.Link>

                </Card.Body>
              </Card>
            </div>


            <div className="my-3 mx-2 flex-fill">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>
                    Manage Profile
                  </Card.Title>
                  <Card.Text>
                    Change your name, address, password.
                  </Card.Text>
                  <Card.Link href={Url.PRACTITIONER_PROFILE}>Go to Profile</Card.Link>

                </Card.Body>
              </Card>
            </div>

          </div>
        }

      </div>
    </>
  );
}

export { Dashboard as PractitionerDashboard }