import React, { useEffect, useState } from 'react';
import { IAppointmentResponseItem } from 'components/patient/appointments';
import { AppointmentStatusEnum } from '_constants/AppointmentStatusEnum';

import { AdminPractitionerSchedulerAppointmentGrid } from './AdminPractitionerSchedulerAppointmentGrid';
import { adminAppointmentService } from 'services/AdminAppointmentService';
import { AppointmentForm } from 'components/practitioner/scheduler/AppointmentForm';

const AdminPractitionerSchedulerList: React.FunctionComponent = () => {

  const [appts, setData] = useState<IAppointmentResponseItem[]>([] as IAppointmentResponseItem[]);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    const _load = async () => {
      try {
        const appts = await adminAppointmentService.getAppointments();
        setData(appts.filter((d: IAppointmentResponseItem) => d.statusId !== AppointmentStatusEnum.OPEN && d.start * 1000 >= new Date().getTime() ));
      } catch (error: any) {
        throw Error((error as Error).message);
      }
    }
    _load();
  }, [counter])

  const onAdd = () => {
    setIsAdding(false);
  }

  const onCancel = () => {
    setIsAdding(false);
  }

  return (
    <>
      <div className='practitioner-layout-section-title'>Appointment Manager</div>

      {isAdding ? (
        <AppointmentForm onAdd={onAdd} onCancel={onCancel}/>
      )
        : (
          <>
            <div className='strong medium-text-2'>List of Appointments</div>

            {appts && <>
              <AdminPractitionerSchedulerAppointmentGrid appts={appts} setData={setData} refresh={()=>setCounter(counter + 1)}/>
            </>}
          </>
        )}
    </>
  );
}

export { AdminPractitionerSchedulerList }