import React from 'react';
import { FieldWithError } from "components/common";

const PersonalInfo: React.FunctionComponent<{ errors: any; touched: any, readOnly?: boolean, title?: JSX.Element }> = ({ errors, touched, readOnly, title }) => {
  return (
    <div className="container">
      {title ? (<>{title}</>) : (<div className="title medium-text-1">Patient Information</div>)}

      <div className="row">
        <div className="col-12 col-md-6 my-2">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="firstName"
            placeholder="Enter First Name"
            label="First Name"
            readOnly={readOnly}
          />
        </div>

        <div className="col-12 col-md-6 my-2">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="lastName"
            placeholder="Enter Last Name"
            label="Last Name"
            readOnly={readOnly}
          />
        </div>
      </div>
    </div>
  );
};

export { PersonalInfo }