import React from "react";
import { HashLink } from "react-router-hash-link";
import { Url } from "_constants";
import * as Icons from 'react-bootstrap-icons';

const Footer: React.FunctionComponent = () => {
  return (<>
    <div className="container-fluid mt-5 mb-0 footer justify-content-center">
      <div className="row p-5 align-items-start bg-big-white-circle">
        <div className="col-auto col-12 col-md-3">
        <div className="footer-links my-3">
        The easy and convenient way to get screened annually for Cancer and Disease.
        </div>
        </div>

        <div className="col-auto col-12 col-md-3">
          <div className="footer-links my-3">
            <div className="footer-link-header">
              Our Services
            </div>
            <div className="footer-section-items">
              <div className="footer-link-item"><a href="/">Home</a></div>
              <div className="footer-link-item"><a href={Url.PUBLIC_PHYSICIANS}>Our Physicians</a></div>
              <div className="footer-link-item"><a href={Url.PUBLIC_PRICING}>Pricing</a></div>
              <div className="footer-link-item">
              <HashLink className="footer-link-item" scroll={(el: any) => el.scrollIntoView({ behavior: "smooth" })} to={"/#faq"}>FAQ</HashLink>
              </div>
            </div>
          </div>
        </div>

        <div className="col-auto col-12 col-md-3">
          <div className="footer-links my-3">
            <div className="footer-link-header">
              Your Account
            </div>
            <div className="footer-section-items">
              <div className="footer-link-item"><a href={Url.PATIENT_DASHBOARD}>Appointments</a></div>
              <div className="footer-link-item"><a href="https://25661.portal.athenahealth.com/">Patient Portal</a></div>
            </div>
          </div>
        </div>

        <div className="col-auto col-12 col-md-3">
          <div className="footer-links my-3">
            <div className="footer-link-header">
              Contact us
            </div>
            <div className="footer-section-items">
              <div className="footer-link-item"><Icons.TelephoneOutbound /><span className="mx-2"><a href="tel:19088956969" className="telno">(908) 895 6969</a></span></div>
              <div className="footer-link-item"><Icons.Envelope /><span className="mx-2">contact@convenacare.com</span></div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div className="container-fluid">
            <div className="row bottom-footer">
          <div className="col-4 bottom-footer-item">
          &copy; Convena Care, {new Date().getFullYear()}. All rights reserved.
          </div>
          <div className="col-4 bottom-footer-item">
          <a href={Url.PATIENT_PRIVACY_POLICY}>Privacy Policy</a>
          </div>
          <div className="col-4 bottom-footer-item">
          <a href={Url.PATIENT_TERMS}>Terms and Conditions</a>
          </div>
        </div>

    </div>
    </>
  );
};

export { Footer };
