import axios from "axios";
import { config } from 'config';
import { IUserActionLog } from "interfaces/IUserActionLog";
import { adminAuthenticationService, AuthenticationService } from "./AuthenticationService";

class AdminReportApiService  {

  constructor (
    protected apiBaseUrl: string,
    protected authService: AuthenticationService
  ) {}

  async getUserActionLogs (): Promise<IUserActionLog[]> {

    const url = `${this.apiBaseUrl}/report/user-action-logs`;

    try {
      const response: any = await axios.post<IUserActionLog[]>(url, {}, await this.authService.getAuthHeader());
      if (response.error) {
        throw Error(response.error.message);
      }
      return response.data.data as IUserActionLog[];
    } catch (error: any) {
      console.error('error getting user logs', {error});
      throw Error(error);
    }    

  }


}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/admin`;
const adminReportApiService = new AdminReportApiService(apiBaseUrl, adminAuthenticationService);

export { adminReportApiService, AdminReportApiService } 