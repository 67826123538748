import { IConfig } from "interfaces";

const config: IConfig = {
  apiDomain: 'https://portal-api.devex.convenacare.com',
  apiBasePath: '/v1',
  tokenName: 'tk',
}

export default config;

