import React from 'react';

const VerifyPhonePage:React.FunctionComponent = () => {
  return (
    <>
      VerifyCodePage 
    </>
  );
}

export { VerifyPhonePage }