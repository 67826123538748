import * as Yup from 'yup';

enum Validator {
  ADMIN_PRODUCT_FORM = 'admin_product_form',
  ADMIN_ACTIVATION_CODE_FORM = 'admin_activation_code_form',
  ADMIN_COUPON_FORM = 'admin_coupon_form',
  ADMIN_INSURANCE_FORM = 'admin_insurance_form',

  COMMON_ADDRESS = 'common_address',
  BILLING_ADDRESS = 'billing_address',
  SHIPPING_ADDRESS = 'shipping_address',
  ACTIVATION_FORM = 'activation_form',
  REGISTRATION_FORM = 'registration_form',
  PROFILE_FORM = 'profile_form',
  PROFILE_FORM_PASSWORD = 'profile_form_password',
  CHECKOUT_FORM = 'checkout_form', // anonymous, shipping validator only, billing is same
  CHECKOUT_FORM_LOGGED_IN = 'checkout_form_logged_in', // logged in, shipping validator only, billing is same, logged in
  CHECKOUT_FORM_WITH_BILL = 'checkout_form_with_bill', // anonymous, diff billing so additional validator for billing
  CHECKOUT_FORM_WITH_BILL_LOGGED_IN = 'checkout_form_with_bill_logged_in', // logged in, diff billing so additional validator for billing
  CHECKOUT_FORM_BILL_ONLY = 'checkout_form_bill_only',
  CHECKOUT_FORM_BILL_ONLY_LOGGED_IN = 'checkout_form_bill_only_logged_in',
  FORGOT_PASSWORD = 'forgot_password_form',
  RESET_PASSWORD_FORM = 'reset_password_form',
  SIMPLE_REGISTRATION = 'simple_registration',
  CONFIRM_PROFILE_FORM = 'confirm_profile_form',

  VERIFY_PHONE_FORM = 'verify_phone_form',
  PATIENT_MOBILE_CONFIRMATION = 'patient_mobile_confirmation_form',
  CREDIT_CARD_FORM = 'patient_credit_card_form',

  APPOINTMENT_REASON_FORM = 'appointment_reason_form',

  LOGIN_FORM = 'LOGIN_FORM',

  INSURANCE_FORM = 'insurance_form',

  PRACTITIONER_USER_FORM = 'practitioner_user_form',

  FREE_KIT_QUALIFIED_FORM = 'free_kit_qualified_form',
  FREE_KIT_VERIFY_PHONE_FORM = 'free_kit_verify_phone_form',
  MAILING_ADDRESS_FORM = 'mailing_address_form',
  
}
const validationMap = new Map<string, object>([]);

const email = Yup.string().email("Must be a valid email address").required("Email is Required");

const password = Yup
  .string()
  .required("Please enter your password")
  .matches(
    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    "Password must contain at least 8 characters, one uppercase, one number and one special case character"
  )
const confirmPassword = Yup
  .string()
  .required("Please confirm your password")
  .when(["password"], {
    is: (password: string) => (password && password.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref("password")], "Password doesn't match")
  })

const phoneNumber = Yup
  .string()
  .required("Phone Number is required");

const usState = Yup
.string()
.required("State is required")
.oneOf(["NJ"], "ConvenaCare is not yet available in your state. Please check back in a couple of months.");

const dateOfBirth = Yup.string().nullable().required("You must provide the Date of Birth");


validationMap.set(Validator.LOGIN_FORM, Yup.object().shape({
  email: Yup.string().required("Please enter your email address"),
  password: Yup.string().required("Please enter your password")
}))


const mailingAddressValidators = {
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
}


validationMap.set(Validator.MAILING_ADDRESS_FORM, Yup.object().shape({
  ...mailingAddressValidators
}));


validationMap.set(Validator.COMMON_ADDRESS, Yup.object().shape({
email,
...mailingAddressValidators,
phoneNumber,
}));

validationMap.set(Validator.ADMIN_PRODUCT_FORM, Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  description: Yup.string().required("Description is required"),
}));


const shippingValidators = {
  shippingFirstName: Yup.string().required("First Name is required"),
  shippingLastName: Yup.string().required("Last Name is Required"),
  shippingAddress1: Yup.string().required("Shipping Address is required"),
  shippingCity: Yup.string().required("Shipping City is required"),
  shippingState: usState,
  shippingZip: Yup.string().required("Shipping Zip is required"),
  shippingPhoneNumber: phoneNumber,
};

const billingValidators = {
  billingFirstName: Yup.string().required("First Name is required"),
  billingLastName: Yup.string().required("Last Name is Required"),
  billingAddress1: Yup.string().required("Billing Address is required"),
  billingCity: Yup.string().required("Billing City is required"),
  billingState: Yup.string().required("Billing State is required"),
  billingZip: Yup.string().required("Billing Zip is required"),
  billingPhoneNumber: phoneNumber,
}

const simpleRegistrationValidators = {
  email: Yup.string().email("Must be valid email address").required("Email is Required"),
  password,
  confirmPassword,
  agreeTerms: Yup.boolean().oneOf([true], "You must accept the Terms and Conditions"),

}

validationMap.set(Validator.SHIPPING_ADDRESS, Yup.object().shape(shippingValidators));
validationMap.set(Validator.BILLING_ADDRESS, Yup.object().shape(billingValidators));

// anonymous, ship and bill the same
validationMap.set(Validator.CHECKOUT_FORM, Yup.object().shape({
  ...shippingValidators,
  ...simpleRegistrationValidators,
}));

// logged in, ship and bill the same
validationMap.set(Validator.CHECKOUT_FORM_LOGGED_IN, Yup.object().shape({
  ...shippingValidators,
}));

// anonymous, bill different
validationMap.set(Validator.CHECKOUT_FORM_WITH_BILL, Yup.object().shape({
  ...billingValidators,
  ...shippingValidators,
  ...simpleRegistrationValidators,
}));

// logged in, bill different
validationMap.set(Validator.CHECKOUT_FORM_WITH_BILL_LOGGED_IN, Yup.object().shape({
  ...billingValidators,
  ...shippingValidators,
}));


// anonymous, billing only (premailed checkout)
validationMap.set(Validator.CHECKOUT_FORM_BILL_ONLY, Yup.object().shape({
  ...billingValidators,
  ...simpleRegistrationValidators,
}));

// logged in, billing only (premailed checkout)
validationMap.set(Validator.CHECKOUT_FORM_BILL_ONLY_LOGGED_IN, Yup.object().shape({
  ...billingValidators,
}));


validationMap.set(Validator.ACTIVATION_FORM, Yup.object().shape({
  activationCode: Yup.string().required("Activation Code Required"),
  codeA: Yup.string().required('Required'),
  codeB: Yup.string().required('Required'),
}));

validationMap.set(Validator.ADMIN_ACTIVATION_CODE_FORM, Yup.object().shape({
  statusId: Yup.string().required("Status is Required"),
}));

validationMap.set(Validator.REGISTRATION_FORM, Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Must be valid email address").required("Email is Required"),
  password,
  confirmPassword,
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
  //phoneNumber,
  dateOfBirth: Yup.string().nullable().required("You must provide your date of birth"),
  agreeTerms: Yup.boolean().oneOf([true], "You must accept the Terms and Conditions"),
}));

const profileFormValidator = {
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Must be valid email address").required("Email is Required"),
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
  phoneNumber,
}

validationMap.set(Validator.PROFILE_FORM, Yup.object().shape(
  {
    ...profileFormValidator
  }
));

validationMap.set(Validator.PROFILE_FORM_PASSWORD, Yup.object().shape(
  {
    ...profileFormValidator,
    password,
    confirmPassword
  }
));



validationMap.set(Validator.ADMIN_COUPON_FORM, Yup.object().shape({
  name: Yup.string().required("Please enter the name of the coupon").min(5, 'Must be at least 5 characters long'),
  code: Yup.string().required("Please enter the code of the coupon").min(5, 'Must be at least 5 characters long'),
  discount: Yup.number().required("Please enter the discount"),
  expireDateUtc: Yup.string().required("Please enter the expiration date (UTC)"),
  quantity: Yup.number().required("Please enter the number of coupons"),
  discountTypeId: Yup.string().required("Please select the Discount Type"),
}));

validationMap.set(Validator.FORGOT_PASSWORD, Yup.object().shape(
  {
    emailAddress: Yup.string().email("Must be valid email address").required("Email is Required"),
  }
))

validationMap.set(Validator.RESET_PASSWORD_FORM, Yup.object().shape(
  {
    password,
    confirmPassword,
  }
))

validationMap.set(Validator.SIMPLE_REGISTRATION, Yup.object().shape(simpleRegistrationValidators));

validationMap.set(Validator.CONFIRM_PROFILE_FORM, Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: usState,
  zipcode: Yup.string().required("Zip Code is required"),
  phoneNumber: Yup
    .string().required("In case we need to contact you about your results, your phone number is required")
    .matches(
      /\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/,
      "Please use this format: (123) 456-7890"
    ),
  agreeHipaa: Yup.boolean().oneOf([true], "You must accept the HIPAA Authorization"),
  agreeProductConsent: Yup.boolean().oneOf([true], "You must accept the Product Consent"),
  dateOfBirth: Yup.string().nullable().required("You must provide your date of birth"),
}));

validationMap.set(Validator.VERIFY_PHONE_FORM, Yup.object().shape({
  phoneNumber
}))

validationMap.set(Validator.PATIENT_MOBILE_CONFIRMATION, Yup.object().shape({
  confirmationCode: Yup.string().required("Confirmation Code Required"),
}))

validationMap.set(Validator.CREDIT_CARD_FORM, Yup.object().shape({
  creditCardNumber: Yup.number().typeError("Must be a number").moreThan(0, 'Cannot be 0'),
  expirationMonth: Yup.number().typeError("Must be a number").moreThan(0, 'Please select a Month'),
  expirationYear: Yup.number().typeError("Must be a number").moreThan(0, 'Please select a Year'),
  cardCode: Yup.number().typeError("Must be a number").moreThan(0, 'Cannot be 0').required('Cannot be blank'),
}))

const activationCodeConditional = Yup.string().when("reasonId", {
  is: (reasonId: string) => reasonId === "3",
  then: Yup.string().required("Required")
});

validationMap.set(Validator.APPOINTMENT_REASON_FORM, Yup.object().shape({
  reasonId: Yup.string().typeError("Please select a reason").required('Please select a reason'),
  activationCode: activationCodeConditional,
  codeA: activationCodeConditional,
  codeB: activationCodeConditional,
  reasonText: Yup.string().when("reasonId", {
    is: (reasonId: string) => reasonId === "2",
    then: Yup.string().required("Required")
  })
}))


validationMap.set(Validator.ADMIN_INSURANCE_FORM, Yup.object().shape({
  name: Yup.string().required("Please enter the Insurance Name")
}))

validationMap.set(Validator.INSURANCE_FORM, Yup.object().shape(
  {
  /*
      insuranceCompanyId: 0, //apptContext?.appointment.insuranceCompanyId,
    insurancePhoneNumber: '',
    insuranceMemberId: '',
    insuranceGroupId: '',

    guarantorRelationshipId: 0,
    guarantorFirstName: '',
    guarantorLastName: '',
    guarantorAddress1: '',
    guarantorAddress2: '',
    guarantorCity: '',
    guarantorState: '',
    guarantorZipcode: '',
    guarantorPhoneNumber: '',

    agreeConsentBilling: false,
    */

    insuranceCompanyId: Yup.string().notOneOf(["0"], "You must select the Insurance Provider"),
    insurancePhoneNumber: Yup
      .string()
      .required("Insurance Phone Number is required")
      .matches(
        /\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/,
        "Insurance Phone number is invalid"
      ),
    insuranceMemberId: Yup.string().required('Please enter your Member ID'),
    insuranceGroupId: Yup.string().required('Please enter your Group ID'),

    guarantorRelationshipId: Yup.string().notOneOf(["0"], "You must select the Guarantor's relationship with you"),

    guarantorFirstName: Yup.string().required("Please enter the Guarantor's First Name"),
    guarantorLastName: Yup.string().required("Please enter the Guarantor's Last Name"),
    guarantorAddress1: Yup.string().required("Please enter the Guarantor's Address"),
    guarantorCity: Yup.string().required("Please enter the Guarantor's City"),
    guarantorState: Yup.string().required("Please select the Guarantor's State"),
    guarantorZipcode: Yup.string().required("Please enter the Guarantor's Zip Code"),
    guarantorPhoneNumber: Yup
      .string()
      .required("Guarantor Phone Number is required")
      .matches(
        /\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/,
        "Gurantor Phone number is invalid"
      ),
    guarantorDateOfBirth: Yup.string().nullable().required("You must provide your date of birth"),

    agreeConsentBilling: Yup.boolean().oneOf([true], "You must agree to the Billing Consent"),
  }
))

validationMap.set(Validator.PRACTITIONER_USER_FORM, Yup.object().shape({
  email: Yup.string().email("Must be a valid email address").required("Email is Required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  phoneNumber,
  dateOfBirth
}))  

validationMap.set(Validator.FREE_KIT_QUALIFIED_FORM, Yup.object().shape({
  age: Yup.string().required('Please specify if you are 45 years or older'),
  insuranceCompanyId: Yup.string().required('Please select the insurance provider').notOneOf(["0"], "Please select the Insurance Provider"),
  preventiveVisit: Yup.string().required("Please specify if you've had a preventive visit in the last 12 months"),
  selfIsPatient: Yup.string().required("Please specify if you are the patient"),
}))

validationMap.set(Validator.FREE_KIT_VERIFY_PHONE_FORM, Yup.object().shape({
  phoneNumber: Yup
  .string()
  .required("Phone Number is required")
  .matches(
    /\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/,
    "Phone number is invalid"
  ),
  agreeToReceiveText: Yup.boolean().oneOf([true], "You must consent to receiving text messages on your phone from Convena Care"),  
}))

export { validationMap, Validator }
