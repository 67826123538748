import {v4 as uuidv4} from 'uuid';
import { StorageKey } from '_constants';

const useUniqueUserId = (): string => {
  
  if (!localStorage.getItem(StorageKey.UNIQUE_USER_ID)) {
    const _id: string = uuidv4();
    localStorage.setItem(StorageKey.UNIQUE_USER_ID, _id.replaceAll(/-/g,''));
  }

  const uniqueUserId = localStorage.getItem(StorageKey.UNIQUE_USER_ID) || '' ;

  return uniqueUserId

}

export { useUniqueUserId }