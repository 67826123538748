import React, { useEffect, useState } from 'react';
import { trackingService } from 'services';
import { useUnload } from '_hooks/useUnload';
import { PhoneForm } from './PhoneForm';
import { VerificationCodeForm } from './VerificationCodeForm';
import { MailingAddressForm } from './MailingAddressForm';
import { VerificationCodeFancy } from 'components/common/utils/VerificationCodeFormFancy';

const SubscribeWithPhonePage: React.FunctionComponent = () => {


  enum Stage {
    VERIFY_PHONE = 1,
    VERIFY_CODE = 2,
    ENTER_MAILING_ADDRESS = 3,
    THANK_YOU = 4,
  }

  const EVENT_CATEGORY = 'Subscribe with Phone';
  const [stage, setStage] = useState<Stage>(Stage.VERIFY_PHONE);
  const [phoneNumber, setPhoneNumber] = useState<number | undefined>(undefined);
  const [phoneNumberCode, setPhoneNumberCode] = useState<string | undefined>(undefined);


  useUnload((e: Event) => {
    e.preventDefault();
    trackingService.fireGoogleUnload({ category: EVENT_CATEGORY, label: 'exit page' });
  })

  useEffect(() => {
    document.title = 'Convena Care At-Home Colon Cancer Testing';
    window.scrollTo(0, 0);
  }, [stage])

  const onSubmitPhone = (phoneNumber: number) => {
    setPhoneNumber(phoneNumber);
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Submitted Phone`,
      category: EVENT_CATEGORY,
      label: 'Submit',
    });
    setStage(stage + 1);
  }

  const onSubmitVerificationCode = (phoneNumberCode: string) => {
    setPhoneNumberCode(phoneNumberCode);
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Submitted Verification Code`,
      category: EVENT_CATEGORY,
      label: 'Verify',
    });
    setStage(stage + 1);
  }

  const onSubmitMailingAddress = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Submitted Mailing Address`,
      category: EVENT_CATEGORY,
      label: 'Submit',
    });
    setStage(stage + 1);
  }

  const onClickAbout = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Doctor Image`,
      category: EVENT_CATEGORY,
      label: 'Image',
    });
  }

  return (
    <>

      <div className="bg-lighterblue rounded">
        <div className="container">

          {stage === Stage.VERIFY_PHONE &&
            <>
              <div className="row">
                <div className="col-12 my-3">
                  <div className="subscribe-bg-container">
                    <div className="subscribe-phone-icon"></div>
                  </div>

                  <div className="text-center">
                    <div className="fw-bold text-green medium-text-2 my-3">Please enter your number.</div>
                    <div className="fw-bold mt-3">We will send you a verification code.</div>
                    <div className="mt-2">(Do not worry. We will never share your phone number.)</div>
                  </div>

                  <div className="my-3 subscribe-phone-form text-center">
                    <PhoneForm onSubmit={onSubmitPhone} />
                  </div>
                </div>
              </div>
            </>
          }

          {stage === Stage.VERIFY_CODE &&

            <div className="row">
            <div className="col-12 my-3">
              <div className="subscribe-bg-container">
                <div className="subscribe-phone-icon"></div>
              </div>

              <div className="text-center">
              <div className="small-text-2 dark-blue my-3">We sent a verification code to your mobile phone. Please enter the verification code below</div>
              </div>

              <div className="my-3 subscribe-phone-form">
                 <VerificationCodeFancy length={6} phoneNumber={phoneNumber || 0} onSubmit={onSubmitVerificationCode} />
              </div>
            </div>
            </div>

            // <div className="py-5">
            //   <div className="small-text-2 dark-blue my-3">We sent a verification code to your mobile phone. Please enter the verification code below</div>
            //   <VerificationCodeFancy length={6}/>
            //   {/* <VerificationCodeForm phoneNumber={phoneNumber || 0} onSubmit={onSubmitVerificationCode} /> */}
            // </div>
          }

          {stage === Stage.ENTER_MAILING_ADDRESS &&
            <div className="py-5">
              <div className="small-text-2 fw-bold dark-blue my-3 text-center">Code was successfully verified. Please complete your registration below.</div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 subscribe-mailing-form">
                    <MailingAddressForm onSubmit={onSubmitMailingAddress} phoneNumber={phoneNumber} phoneNumberCode={phoneNumberCode} />
                  </div>
                </div>
              </div>
            </div>
          }

          {stage === Stage.THANK_YOU && <>
            <div className="small-text-2 dark-blue my-3 py-5">
              <p>Thank you for signing up!</p>
              <p>A member of our staff will be calling you shortly to complete your membership signup.</p>
              <p>If you have any questions, please feel free to email us at support@convenacare.com, or call us at <a href="tel:19088956969" className="telno">(908) 895 6969</a>.</p>
              <p>- The Convena Care Team</p>
            </div>
          </>}

          {stage === Stage.VERIFY_PHONE &&
            <div className="mx-1 mx-md-5">
            </div>
          }

        </div>

      </div>
    </>
  );
}

export { SubscribeWithPhonePage }