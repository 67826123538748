import React from "react";
import * as Icon from 'react-bootstrap-icons';

export const JumbotronRowGreenV3: React.FunctionComponent<{ onClickShowVideo: () => void }> = ({ onClickShowVideo }) => {
  return (
    <div className="row">

      {/* left side */}
      <div className="col-12 col-md-6 my-1 my-md-3">
        <div className="mt-1 mb-4 text-center text-md-start">
          <div role="button" className="d-inline-block recommended-annually-green p-1 px-4 mt-2 py-md-2 px-md-3 mx-3">
            Recommended Annually
          </div>
        </div>
        <div className="fw-bold large-text-1 d-none d-md-block">
          <span className="dark-blue">Get an At-home</span> <span className="light-blue">Colon Cancer</span><br />
          <span className="dark-blue">Screening Test - FREE</span>
        </div>

        <div className="fw-bold large-text-1 d-block d-md-none text-center">
          <span className="dark-blue">At-home</span> <span className="light-blue">Colon Cancer</span> <span className="dark-blue">Screening Test - FREE</span>
        </div>

        <div className="fw-bold text-dark-gray medium-text-2 my-3 text-center text-md-start mobile-small-text-3">Cancer deaths are up so we are trying to increase colon cancer screening in New Jersey</div>
        <div className="fw-bold text-dark-gray medium-text-2 my-3 text-center text-md-start mobile-small-text-3">We've made it free for a limited time if you've had no preventive health visit in the last 12 months.</div>

      </div>

      {/* right side */}
      <div className="col-12 col-md-6 align-self-center">

        <div className="text-blue fw-bold small-text-3 show-video my-3">
          <div className="d-flex flex-column align-items-center">
            <div role="button" onClick={() => onClickShowVideo()}>
              <Icon.CameraVideo /> Video: How The Test Is Done
            </div>
            <div className="thumb">
              <div role="button" onClick={() => onClickShowVideo()}  >
                <img src="/images/Envelop.png" alt="how the test is done" />
                <Icon.PlayCircle className="envelop-play" />
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  );
}