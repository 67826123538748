import { config } from "config";
import { AuthenticationService, practitionerAuthenticationService } from "./AuthenticationService";
import axios from "axios";
import { IAppointmentResponseItem } from "components/patient/appointments";
import { AppointmentStatusEnum } from "_constants/AppointmentStatusEnum";
import { AppointmentPaymentMethodEnum } from "_constants/AppointmentPaymentMethod";

class PractitionerService {
  constructor(
    protected basePath: string,
    protected authService: AuthenticationService
  ) { }

  // async getAppointments()
  async getAppointments(): Promise<IAppointmentResponseItem[]> {
    const url = `${this.basePath}/management/appointments`;
    try {
      const response: any = await axios.get<IAppointmentResponseItem[]>(url, await this.authService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.data as IAppointmentResponseItem[];
    } catch (error: any) {
      console.error('error getting appointments', { error });
      throw Error((error as Error).message);
    }
  }

  async updateAppointmentStatus(appointmentId: number, statusId: AppointmentStatusEnum): Promise<any> {
    const url = `${this.basePath}/management/appointment/${appointmentId}`;
    try {
      const response: any = await axios.put(url, {statusId}, await this.authService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (error: any) {
      throw Error((error as Error).message);
    }
  }

  // async postAppointments
  // adds to the appointment table
  async addAppointmentSlots(slots: {start: number, end: number, price: number }[]):Promise<any> {
    const url = `${this.basePath}/management/appointment/bulk`;
    try {
      const response: any = await axios.post(url, slots, await this.authService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (error: any) {
      throw Error((error as Error).message);
    }    
  }

  async deleteAppointment(appointmentId: number): Promise<any> {
    const url = `${this.basePath}/management/appointment/${appointmentId}`;
    try {
      const response: any = await axios.delete(url, await this.authService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (error: any) {
      throw Error((error as Error).message);
    }
  }  

  // async cancelAppointment 
  async cancelAppointment(appointmentId: number): Promise<any> {
    const url = `${this.basePath}/management/appointment/cancel/${appointmentId}`;
    try {
      const response: any = await axios.put(url, {}, await this.authService.getAuthHeader());
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data;
    } catch (error: any) {
      throw Error((error as Error).message);
    }
  }  

  // async rescheduleAppointment 
  async rescheduleAppointment (newAppointmentId: number, oldAppointmentId: number) {
    const url = `${this.basePath}/management/appointment/reschedule`;
    try {
      const response = await axios.put(url, {newAppointmentId, oldAppointmentId}, await this.authService.getAuthHeader())
      if (response.data.error) {
        throw new Error(response.data.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.error('error rescheduling', {error});
      throw Error(`could not reschedule appointment: ${(error as Error).message} `);
    }
  }

  async scheduleAppointment (appointmentId: number, patientId: number) {
    const url = `${this.basePath}/management/appointment/schedule/${appointmentId}`;
    try {
      const response = await axios.put(url, {reasonId: 1, userId: patientId, paymentMethodId: AppointmentPaymentMethodEnum.INSURANCE}, await this.authService.getAuthHeader())
      if (response.data.error) {
        throw new Error(response.data.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.error('error rescheduling', {error});
      throw Error(`could not reschedule appointment: ${(error as Error).message} `);
    }
  }

  async sendSmsReminder(appointmentId: number): Promise<any> {
    const url = `${this.basePath}/management/appointment/send-reminder/${appointmentId}`;
    try {
      const response = await axios.post(url, {}, await this.authService.getAuthHeader())
      if (response.data.error) {
        throw new Error(response.data.error.message);
      } else {
        //console.log('sent reminder', {response});
        return response.data;
      }
    } catch (error: any) {
      console.error('error sending SMS', {error});
      throw Error(`could not send SMS reminder: ${(error as Error).message} `);
    }
  }
}

const practitionerService = new PractitionerService(`${config.apiDomain}${config.apiBasePath}/practitioner`, practitionerAuthenticationService)

export { practitionerService, PractitionerService }