import React, { useContext, useState } from 'react';
import { Formik, Form } from "formik";
import { AccountContext } from "components/common";
import { IMultiPartCode } from 'interfaces/IMultiPartCode';
import { validationMap, Validator } from 'schemas';
import { IPractitioner } from 'interfaces';
import { ITimeSlot } from 'interfaces';
import { ConfirmMobile } from '../account';
import { UserStatusEnum } from '_constants/UserStatusEnum';
import { AppointmentReason } from './AppointmentReason';
import { AppointmentContext } from './AppointmentContext';
import { PractitionerCard } from 'components/common/PractitionerListingPage/PractitionerCard';

const PatientSchedulerHome: React.FunctionComponent = () => {


  const [initialFormState, setInitialFormState] = useState<IMultiPartCode>({} as IMultiPartCode);
  const apptContext = useContext(AppointmentContext);
  const { practitioner, timeslot }: { practitioner?: IPractitioner | null, timeslot?: ITimeSlot | null } = apptContext?.appointment || { practitioner: null, timeslot: null };
  const accountContext = useContext(AccountContext);


  const onSubmit = async (data: any, actions: any) => {

  }

  return (
    <div className="container">
      <h2>Schedule an Appointment</h2>

      {accountContext?.account && accountContext.account.statusId === UserStatusEnum.NOT_VERIFIED &&
        <ConfirmMobile />
      }

      {practitioner && timeslot &&
        <PractitionerCard practitioner={practitioner}>
          <div className="container g-0">
            <div className="small-text-1 strong text-center">Appointment Details</div>
            <div className="small-text-1 text-center">Date: {timeslot.date}</div>
            <div className="small-text-1 text-center">Time: {timeslot.startTime} - {timeslot.endTime}</div>
          </div>
        </PractitionerCard>

      }


      <Formik enableReinitialize initialValues={initialFormState} onSubmit={onSubmit}>
        {({ values, isSubmitting, errors, touched, setStatus, status, setFieldValue }) => {
          return (
            <Form>

              <div className="my-2">
                <AppointmentReason />
              </div>

              {/* <div className="border p-3 mx-1">
                <div className="">
                <ActivationForm errors={errors} values={values} touched={touched} setFieldValue={setFieldValue} />
                </div>
              </div> */}

              <div className="text-center">
                <button className="btn btn-primary fs-5 mt-3" type="submit">
                  Set Appointment
                </button>
                {status && <div className="alert alert-danger my-3">{status}</div>}
              </div>
            </Form>
          );
        }}
      </Formik>

    </div>


  );
}

export { PatientSchedulerHome }