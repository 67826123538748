import React from 'react';
import { Field, ErrorMessage } from 'formik';

import { IInputFieldError } from 'interfaces';

const FieldWithError: React.FunctionComponent<IInputFieldError> = ({ fieldName, label, placeholder, errors, touched, type, readOnly, autoComplete, icon, rows, children, ...rest }) => {

  const renderWithIcon = () => {
    return (
      <div className="input-group mb-3">
        <span className="input-group-text">{icon}</span>

        <Field
          className={'form-control' + (errors && errors[fieldName] && touched && touched[fieldName] ? ' is-invalid' : '')}
          name={fieldName}
          type={type ? type : 'input'}
          readOnly={readOnly ? readOnly : false}
          placeholder={placeholder}
          autoComplete={autoComplete}
          rows={rows}
          {...rest}
        >{children}</Field>
        {errors && <ErrorMessage name={fieldName} component="div" className="invalid-feedback" />}
      </div>
    );
  }

  const renderWithLabel = () => {
    return (
      <div className="form-group">
        {label && <label>{label}</label>}
        <Field
          className={'form-control' + (errors && errors[fieldName] && touched && touched[fieldName] ? ' is-invalid' : '')}
          name={fieldName}
          type={type ? type : 'input'}
          readOnly={readOnly ? readOnly : false}
          placeholder={placeholder}
          autoComplete={autoComplete}
          rows={rows}
          {...rest}
        >{children}</Field>
        {errors && <ErrorMessage name={fieldName} component="div" className="invalid-feedback" />}
      </div>

    );
  }

  return (
    icon ? renderWithIcon() : renderWithLabel()
  )
}

export { FieldWithError }