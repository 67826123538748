import React, { useState } from 'react';
import { IMessage, ModalMessage } from 'components/common/messenger';
import { HowToVideo } from './HowToVideo';
import * as Icon from 'react-bootstrap-icons';
import { JumbotronRow } from './JumbotronRow';

const QualifySection: React.FunctionComponent<{ onClickShowVideo?: () => void, children: any }> = ({ onClickShowVideo, children }) => {

  const [showVideo, setShowVideo] = useState<boolean>(false);

  const _onClickShowVideo = () => {
    if (onClickShowVideo) {
      onClickShowVideo();
    }
    setShowVideo(true);
  }

  return (
    <div className="container">

      <ModalMessage show={showVideo} handleClose={() => { setShowVideo(false) }} message={
        {
          title: 'FIT Test - How To Video',
          body: <HowToVideo />,
        } as IMessage} />

      <JumbotronRow />

      <div className="row">
        <div className="text-blue fw-bold small-text-3 show-video my-3">
          <div className="d-flex flex-column align-items-center">
            <div className="thumb">
              <div role="button" onClick={() => _onClickShowVideo()}  >
                <img src="/images/how-the-test-is-done-thumb.png" alt="how the test is done" />
                <div className="play"><Icon.PlayCircle className="play" /></div>
              </div>
            </div>
            <div role="button" onClick={() => _onClickShowVideo()}>
              <Icon.CameraVideo /> Video: How The Test Is Done
            </div>
          </div>
        </div>
        <div className="medium-text-2 fw-bold orange my-2 my-md-0">Colon cancer is treatable when diagnosed early.  Get tested for FREE now.</div>
      </div>

      <div className="row">
          {children}
      </div>

    </div>
  );
}

export { QualifySection }