import React, { useEffect, useState } from 'react';
import { IPractitioner } from 'interfaces';
import { adminPractitionerApiService } from 'services';
import { PractitionerRolesEnum } from '_constants/PractitionerRolesEnum';

const PractitionerDropDown: React.FunctionComponent<{ initialPractitionerId: string, onSelect: any }> = ({ initialPractitionerId, onSelect }) => {

  const [practitioners, setPractitioners] = useState<IPractitioner[]>([]);

  useEffect(() => {
    const _load = async () => {
      try {
        const practitioners = await adminPractitionerApiService.getAll();
        setPractitioners(practitioners.filter((practitioner: IPractitioner) => practitioner.roleId === PractitionerRolesEnum.DOCTOR_ROLE));
      } catch (error: any) {
        console.error({ error });
        alert('error loading practitioners');
      }
    }
    _load();
  }, [])

  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const practitioner = practitioners.find((practitioner: IPractitioner) => practitioner.id.toString() === value);
    console.log({ practitioner });
    onSelect(practitioner)
  };

  return (
    <>
    <select name="practitioner" onChange={selectChange}>
      <option value="">Select Practitioner</option>
      {practitioners.map((practitioner: IPractitioner, i: number) => (
        <option
          selected={practitioner.id.toString() === initialPractitionerId}
          key={`practitioner-${practitioner.id}`}
          value={practitioner.id}>{`${practitioner.firstName} - ${practitioner.lastName}`}</option>
      ))}
    </select>
    </>
  );
}

export { PractitionerDropDown }