import React from 'react';

import { FieldWithError } from 'components/common';
import { Field, Form, Formik } from 'formik';
import { IMultiPartCode } from 'interfaces/IMultiPartCode';
import { validationMap, Validator } from 'schemas';
import { ProductCodeStatusEnum } from '_constants/ProductCodeStatusEnum';
import { publicApiService } from 'services';


interface IProps {
  onSubmit: (data: any) => void,
  activationCode?: string,
}

const ActivationForm: React.FunctionComponent<IProps> = ({ onSubmit, activationCode }) => {

  const initialValues: IMultiPartCode = {
    activationCode,
    codeA: activationCode ? activationCode.split('-')[0] : '',
    codeB: activationCode ? activationCode.split('-')[1] : ''
  } as IMultiPartCode;

  const onSubmitClick = async (data: any, actions: any) => {
    const { setStatus } = actions;
    const validStatuses: ProductCodeStatusEnum[] = [ProductCodeStatusEnum.CREATED, ProductCodeStatusEnum.MAILED, ProductCodeStatusEnum.PURCHASED];
    try {
      const codeStatus = await publicApiService.checkActivationCode(data.activationCode);
      if (validStatuses.indexOf(codeStatus.statusId) >= 0) {
        onSubmit({data: {codeStatus, activationCode: data.activationCode}});
        //console.log('aftersubmit', {data: {codeStatus, activationCode: data.activationCode}});
      } else {
        setStatus('Invalid Activation Code');
      }
    } catch (error: any) {
      setStatus('Invalid Activation Code');
      console.error('error checking activation code', {error});
      // throw Error((error as Error).message);
    }
  }
  

  return <>
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationMap.get(Validator.ACTIVATION_FORM)} onSubmit={onSubmitClick}>
      {({ values, errors, touched, setFieldValue, status, setStatus }) => {
        return (
          <Form>
            <div className="my-2">
            <ActivationSubForm errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} />
            </div>

            {status && 
            <div className="my-2 alert alert-warning">
            {status}
            </div>
            }

            <div className="mt-3"><button type="submit" className="btn btn-primary">Next - Make a TeleHealth Appointment</button></div>

          </Form>
        )
      }}
    </Formik>
  </>
}


const ActivationSubForm: React.FunctionComponent<{ errors: any, setFieldValue: any, touched: any, values: any }> = ({ errors, touched, setFieldValue, values }) => {
  const updateCode = (setFieldValue: any, values: any) => {
    setFieldValue("activationCode", "" + (values.codeA ? values.codeA.toUpperCase() : "") + (values.codeB ? values.codeB.toUpperCase() : ""));
  };


  return (
    <>
      <div className="small-text-3 my-2">If you received a FIT Test Kit, please enter its Activation Code</div>
      <div className="d-flex justify-content-start">
        <div>
          <Field className="form-control" name="codeA" maxLength={4} size={4} onBlur={() => updateCode(setFieldValue, values)} style={{ textTransform: "uppercase" }} />
          {errors && errors.codeA && <div className="text-red">{errors.codeA}</div>}
        </div>
        <div className="mx-2">-</div>
        <div>
          <Field className="form-control" name="codeB" size={4} maxLength={4} onBlur={() => updateCode(setFieldValue, values)} style={{ textTransform: "uppercase" }} />
          {errors && errors.codeB && <div className="text-red">{errors.codeB}</div>}
        </div>
      </div>

      <div style={{ display: "none" }}>
        <FieldWithError errors={errors} touched={touched} fieldName="activationCode" placeholder="Activation Code" />
      </div>
    </>
  );
}

export { ActivationForm, ActivationSubForm }