import React from 'react';
import { UserLog } from 'components/common/reports/UserLog';
import { adminReportApiService } from 'services/AdminReportApiService';
import { useUserLog } from '_hooks/useUserLog';

const List: React.FunctionComponent = () => {
  const data = useUserLog(adminReportApiService);

  return (
    <UserLog data={data} />
  );

}

export { List as AdminReportUserLogList }
