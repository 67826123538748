import React, { useEffect, useState } from 'react';
import { trackingService } from 'services';
import { IMessage, ModalMessage } from 'components/common/messenger';
import { useUnload } from '_hooks/useUnload';
import { JumbotronRowGreenV3 } from './JumbotronRowGreenV3';
import { PhoneForm } from './PhoneForm';
import { ShortDoctorCards } from './Doctors';
import { VerificationCodeForm } from './VerificationCodeForm';
import { MailingAddressForm } from './MailingAddressForm';
import { HowToVideo } from './HowToVideo';



const FreeKitNoFormPage3: React.FunctionComponent = () => {


  enum Stage {
    VERIFY_PHONE = 1,
    VERIFY_CODE = 2,
    ENTER_MAILING_ADDRESS = 3,
    THANK_YOU = 4,
  }

  const EVENT_CATEGORY = 'Free Kit (No Form) 3';
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [stage, setStage] = useState<Stage>(Stage.VERIFY_PHONE);
  const [phoneNumber, setPhoneNumber] = useState<number | undefined>(undefined);
  const [phoneNumberCode, setPhoneNumberCode] = useState<string | undefined>(undefined);


  useUnload((e: Event) => {
    e.preventDefault();
    trackingService.fireGoogleUnload({ category: EVENT_CATEGORY, label: 'exit page' });
  })

  useEffect(() => {
    document.title = 'Convena Care At-Home Colon Cancer Testing';
    window.scrollTo(0, 0);
  }, [stage])

  const onClickShowVideo = () => {
    setShowVideo(true);
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Show Video`,
      category: `${EVENT_CATEGORY}`,
      label: ' Video: How The Test Is Done',
    });
  }

  const onSubmitPhone = (phoneNumber: number) => {
    setPhoneNumber(phoneNumber);
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Submitted Phone`,
      category: EVENT_CATEGORY,
      label: 'Submit',
    });
    setStage(stage + 1);
  }

  const onSubmitVerificationCode = (phoneNumberCode: string) => {
    setPhoneNumberCode(phoneNumberCode);
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Submitted Verification Code`,
      category: EVENT_CATEGORY,
      label: 'Verify',
    });
    setStage(stage + 1);
  }

  const onSubmitMailingAddress = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Submitted Mailing Address`,
      category: EVENT_CATEGORY,
      label: 'Submit',
    });
    setStage(stage + 1);
  }

  const onClickAbout = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Doctor Image`,
      category: EVENT_CATEGORY,
      label: 'Image',
    });
  }

  return (
    <>
      <ModalMessage show={showVideo} handleClose={() => { setShowVideo(false) }} message={
        {
          title: 'FIT Test - How To Video',
          body: <HowToVideo />,
        } as IMessage} />

      <div className="bg-lighterblue rounded">
        <div className="container">

          {stage === Stage.VERIFY_PHONE &&
            <>
              <JumbotronRowGreenV3 onClickShowVideo={onClickShowVideo}/>
              <div className="row">
                <div className="fw-bold text-green medium-text-2 my-3">Sign up now for your free kit with a valid mobile phone #: <br/><span className="text-gray small-text-3">(we will never share your phone #)</span></div>
                <div className="col-12 col-md-6">
                  <PhoneForm onSubmit={onSubmitPhone} />
                </div>
              </div>
            </>
          }

          {stage === Stage.VERIFY_CODE &&
            <div className="py-5">
              <div className="small-text-2 dark-blue my-3">We sent a verification code to your mobile phone. Please enter the verification code below</div>
              <VerificationCodeForm phoneNumber={phoneNumber || 0} onSubmit={onSubmitVerificationCode} />
            </div>
          }

          {stage === Stage.ENTER_MAILING_ADDRESS &&
            <div className="py-5">
              <div className="small-text-2 fw-bold dark-blue my-3">Code was successfully verified. Please let us know where we should mail your kit by entering the patient's name and mailing address below.</div>
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <MailingAddressForm onSubmit={onSubmitMailingAddress} phoneNumber={phoneNumber} phoneNumberCode={phoneNumberCode} />
                  </div>
                </div>
              </div>
            </div>
          }

          {stage === Stage.THANK_YOU && <>
            <div className="small-text-2 dark-blue my-3 py-5">
              <p>Thank you for signing up!</p>
              <p>We will be sending you our Free Colon Cancer Screening Kit in the mail. It will include all the necessary instructions
                on activating, using, and returning the kit.
              </p>
              <p>If you have any questions, please feel free to email us at support@convenacare.com, or call us at <a href="tel:19088956969" className="telno">(908) 895 6969</a>.</p>
              <p>- The Convena Care Team</p>
            </div>
          </>}

          {stage === Stage.VERIFY_PHONE && 
          <div className="mx-1 mx-md-5">
          <div id="book-now" className="fw-bold text-green medium-text-1 mt-5 mb-3 text-center text-md-start">Our Doctors:</div>
          <ShortDoctorCards onClickAbout={onClickAbout} />
        </div>
        }

        </div>

      </div>
    </>
  );
}

export { FreeKitNoFormPage3 }