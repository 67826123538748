import React from "react";
import { BaseForm, FieldWithError } from "components/common";
import { IProduct } from "interfaces";
import { adminProductApiService as apiService } from "services";
import { validationMap, Validator } from "schemas";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IProduct) => void;
  initialValues: any;
  onCancelClick: () => void;
}

const ProductForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick }: IAppProps) => {
  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ADMIN_PRODUCT_FORM)}
    >
      {({ errors, touched }) => {
        return (
          <>
            <FieldWithError errors={errors} touched={touched} fieldName="name" placeholder="Product Name" label="Product Name" />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="description"
              placeholder="Description"
              label="Description"
            />

            <FieldWithError errors={errors} touched={touched} fieldName="price" placeholder="Price" label="Price" />

            <FieldWithError errors={errors} touched={touched} fieldName="price2" placeholder="Price 2" label="Price" />

            <FieldWithError errors={errors} touched={touched} fieldName="priceN" placeholder="Price N" label="Price" />
          </>
        );
      }}
    </BaseForm>
  );
};

export { ProductForm };
