import { AccountContext } from 'components/common';
import React, { useContext, useEffect, useState } from 'react';
import { trackingService } from 'services';
import { UserStatusEnum } from '_constants/UserStatusEnum';
import { useLandingPage } from '_hooks/useLandingPage';
import { Register } from './Register';
import { Verify } from './Verify';

const RegisterVerify: React.FunctionComponent<{ onSuccess: () => void }> = ({ onSuccess }) => {

  enum Stage {
    VERIFY = 1,
    REGISTER = 2,
  }

  const acctContext = useContext(AccountContext);

  const [currentStage, setCurrentStage] = useState<Stage>(((acctContext && acctContext.account === null) || (acctContext && acctContext.account && acctContext.account.statusId === UserStatusEnum.NOT_VERIFIED)) ? Stage.VERIFY : Stage.REGISTER);
  const [phoneNumberCode, setPhoneNumberCode] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<number | undefined>(undefined);
  const landingPage = useLandingPage();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (acctContext && acctContext.account && acctContext.account.statusId === UserStatusEnum.ACTIVE) {
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStage, acctContext?.account])


  const onVerify = (phoneNumberCode: string, phoneNumber: number) => {
    trackingService.fireGoogleEvent({
      action: `${landingPage.getLandingPage()}Appointment | Verified Phone Number`,
      category: 'Appointment',
      label: 'Verify Number',
    });
    setPhoneNumberCode(phoneNumberCode);
    setPhoneNumber(phoneNumber);
    setCurrentStage(currentStage + 1);
  }

  const trackRegisteredEvent = () => {
    trackingService.fireGoogleEvent({
      action: `${landingPage.getLandingPage()}Appointment | Registered Account`,
      category: 'Appointment',
      label: 'Make Appointment',
    });
  }

  const stages: Map<number, [string, JSX.Element]> = new Map<number, [string, JSX.Element]>([
    [Stage.VERIFY, ['Verify', <Verify onVerifyCallback={onVerify} />]],
    [Stage.REGISTER, ['Register', <Register onSuccess={onSuccess} phoneNumber={phoneNumber} phoneNumberCode={phoneNumberCode} trackEvent={trackRegisteredEvent}/>]]
  ]);

  const renderStage = (stage: [string, JSX.Element] | undefined): JSX.Element => {
    if (!stage) {
      return <></>;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [name, element] = stage;

    return <div>{element}</div>

  }

  return (
    <>
      {renderStage(stages.get(currentStage))}
    </>
  );
}

export { RegisterVerify }