/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { ConfirmCheck, FieldWithError } from "components";
import { ModalAgreement } from "components";
import { Terms } from "components/common/Terms";

const AccountSetup: React.FunctionComponent<{
  errors: any;
  touched: any;
  readOnly?: boolean;
  showPasswordToggle?: boolean;
  emailDisabled?: boolean;
  loginUrl?: string;
  formatAsCard?: boolean;
  onShowPassword?: () => void;
  title?: JSX.Element | string
}> = ({
  errors,
  touched,
  readOnly = false,
  showPasswordToggle = false,
  emailDisabled = false,
  loginUrl,
  formatAsCard = false,
  onShowPassword,
  title = <>Register</>
}) => {
    const [showPasswordFields, setShowPasswordFields] = useState<boolean>(false);
    const [showToC, setShowToC] = useState<boolean>(false);

    const onClickToC = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      setShowToC(true);
    };

    return (
      <>
        {formatAsCard ? (
          <div className="card">
            <h4 className="card-header">{title}</h4>
            <div className="card-body">
              <div className="container">
                <div className="row mb-2">
                  <div className="col">
                    <FieldWithError
                      errors={errors}
                      touched={touched}
                      fieldName="email"
                      placeholder="E-Mail Address"
                      label="Email Address"
                      readOnly={readOnly || emailDisabled}
                    />
                  </div>
                </div>

                {showPasswordToggle && !showPasswordFields && (
                  <div className="row mb-2">
                    <div className="col">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => {
                          setShowPasswordFields(true);
                          if (onShowPassword) {
                            onShowPassword();
                          }
                        }}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                )}

                {(!showPasswordToggle || (showPasswordToggle && showPasswordFields)) && (
                  <>
                    <div className="row mb-2">
                      <div className="col">
                        <FieldWithError
                          errors={errors}
                          touched={touched}
                          fieldName="password"
                          placeholder="Enter Password"
                          type="password"
                          label="Password"
                          readOnly={readOnly}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <FieldWithError
                          errors={errors}
                          touched={touched}
                          fieldName="confirmPassword"
                          placeholder="Confirm Password"
                          type="password"
                          label="Confirm Password"
                          readOnly={readOnly}
                        />
                      </div>
                    </div>

                    {!emailDisabled && (
                      <div className="row my-3">
                        <div className="col">
                          <ModalAgreement show={showToC} handleClose={() => setShowToC(false)} title="Terms and Conditions">
                            <Terms />
                          </ModalAgreement>
                          <ConfirmCheck name="agreeTerms" errors={errors} touched={touched} readOnly={readOnly}>
                            <div className="agree-terms">
                              I have read and accept the{" "}<span role="button" className="btn btn-link" onClick={onClickToC}>Terms and Conditions</span>
                            </div>
                          </ConfirmCheck>
                        </div>
                      </div>
                    )}

                    {loginUrl && (
                      <div className="row">
                        <div className="col text-center my-3">
                          Already have an account? <button type="button" role="link" className="btn btn-link">Log in</button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="">
            <div className="container">
              <div className="medium-text-1 text-primary">{title}</div>
              <div className="row my-2">
                <div className="col">
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="email"
                    placeholder="E-Mail Address"
                    label={`Email Address`}
                    readOnly={readOnly || emailDisabled}
                  />
                </div>
              </div>

              {showPasswordToggle && !showPasswordFields && (
                <div className="row mb-2">
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        setShowPasswordFields(true);
                        if (onShowPassword) {
                          onShowPassword();
                        }

                      }}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              )}

              {(!showPasswordToggle || (showPasswordToggle && showPasswordFields)) && (
                <>
                  <div className="row mb-2">
                    <div className="col">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="password"
                        placeholder="Enter Password"
                        type="password"
                        label="Password"
                        readOnly={readOnly}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="confirmPassword"
                        placeholder="Confirm Password"
                        type="password"
                        label="Confirm Password"
                        readOnly={readOnly}
                      />
                    </div>
                  </div>

                  {!emailDisabled && (
                    <div className="row my-3">
                      <div className="col">
                        <ModalAgreement show={showToC} handleClose={() => setShowToC(false)} title="Terms and Conditions">
                          <Terms />
                        </ModalAgreement>
                        <ConfirmCheck name="agreeTerms" errors={errors} touched={touched} readOnly={readOnly}>
                          <div className="agree-terms ">
                              I have read and accept the{" "} <a role="button" className="btn-link" onClick={onClickToC}>
                                Terms and Conditions
                              </a>
                            </div>
                        </ConfirmCheck>
                      </div>
                    </div>
                  )}

                  {loginUrl && (
                    <div className="row">
                      <div className="col text-center my-3">
                        Already have an account? <button type="button" role="link" className="btn btn-link">Log in</button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

export { AccountSetup };
