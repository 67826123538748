import React, { useContext } from 'react';
import { Url } from '_constants';
import { PractitionerRolesEnum } from '_constants/PractitionerRolesEnum';
import { AccessDenied } from '../AccessDenied';
import { AccountContext } from '../AccountContext';

interface IProps {
  roles?: PractitionerRolesEnum[],
  children: JSX.Element,
}
const ProtectedRoute: React.FunctionComponent<IProps> = ({roles, children}) => {

  const acctContext = useContext(AccountContext);
  const account = acctContext?.account;
  
  const isAllowed: boolean =
  !roles ||
  roles.length === 0 ||
  (account && account.roleId && roles.indexOf(account.roleId) >= 0) || false;

  if (isAllowed) {
    return children;
  }

  return <AccessDenied account={account} loginUrl={Url.PRACTITIONER_LOGIN} />
}

export { ProtectedRoute }