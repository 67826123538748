import React, { useEffect, useState } from 'react';
import { IPractitioner } from 'interfaces';
import { publicApiService } from 'services';
import { IPractitionerResponseItem } from 'interfaces/appointment/IPractitionerResponseItem';
import { PractitionerCardTall } from './PractitionerCardTall';
import { PractitionerCardWide } from './PractitionerCardWide';

const PractitionerCard: React.FunctionComponent<{
  practitioner: IPractitioner,
  children: any,
  clickableTitle?: boolean,
}> = ({ practitioner, children, clickableTitle = false }) => {

  const [data, setData] = useState<IPractitionerResponseItem | null>(null);

  useEffect(() => {
    const _load = async () => {
      try {
        const data: IPractitionerResponseItem[] = await publicApiService.getPhysicians({ practitionerId: practitioner.id });
        setData(data[0]);
      } catch (error: any) {
        console.error((error as Error).message);
      }
    }
    _load();
  }, [practitioner])

  return (
    <>
      {data &&
        <>
          <div className="d-none d-md-block">
            <PractitionerCardTall about={data.about || ''} practitioner={practitioner} clickableTitle={clickableTitle}>{children}</PractitionerCardTall>
          </div>
          <div className="d-block d-md-none">
            <PractitionerCardWide about={data.about || ''} practitioner={practitioner} clickableTitle={clickableTitle}>{children}</PractitionerCardWide>
          </div>
        </>
      }
    </>
  )
}

export { PractitionerCard }