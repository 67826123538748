import React, { useEffect, useState } from 'react';
import { SpecialtyEnumName } from '_constants/Specialty';
import { IPractitioner } from 'interfaces';
import { IPractitionerResponseItem } from 'interfaces/appointment/IPractitionerResponseItem';
import { publicApiService } from 'services';
import { ModalMessage } from 'components/common/messenger';


const ShortCard: React.FunctionComponent<{ practitioner: IPractitioner, onClickAbout: ()=> void }> = ({ practitioner, onClickAbout }) => {

  const [ showAbout, setShowAbout ] = useState<boolean>(false);

  const _onClickAbout = () => {
    onClickAbout();
    setShowAbout(true);
  }

  return (

    <div className="practitioner-card-bio g-0 my-3" style={{minWidth: "300px"}}>
      <div className="practitioner-card-bio-title-container pb-3">
        <div className='practitioner-card-title'>Dr. {practitioner.firstName} {practitioner.lastName}, {practitioner.degree}</div>
        <div className="practitioner-card-specialty">{SpecialtyEnumName.get(practitioner.specialityId || 0)}</div>
        <div className="practitioner-card-board-certified">Board Certified</div>
        <div className="practitioner-card-avatar" role="button" onClick={()=> _onClickAbout()}><img src={practitioner.avatarUrl || '/images/doc-icon.png'} alt="Doctor" className="rounded" /></div>
      </div>

      {showAbout && practitioner.about && 
        <ModalMessage show={showAbout} handleClose={() => setShowAbout(false)} message={
          {
            title: `About Dr. ${practitioner.lastName}`,
            body: <>
              <div className="practitioner-card-bio-about">
                {practitioner.about.split("\n").map((p: string) => <p>
                  {p}
                </p>
                )}
              </div>
            </>
          }
        } />
      }

    </div>



  );
}

const ShortDoctorCards: React.FunctionComponent<{onClickAbout: ()=>void}> = ({onClickAbout}) => {
  const [docs, setDocs] = useState<IPractitionerResponseItem[]>([]);
  const [status, setStatus] = useState<string>('');

  useEffect(() => {
    const _load = async () => {
      try {
        const docs: IPractitionerResponseItem[] = await publicApiService.getPhysicians();
        setDocs(docs);
        window.scrollTo(0, 0);
      } catch (error: any) {
        console.error({ error });
        setStatus((error as Error).message);
      }
    }
    _load();

  }, [])

  return (
    <>
      {docs &&
      <div className="d-flex flex-row flex-wrap justify-content-start">
        {docs.map((doc: IPractitionerResponseItem, i: number) => <div className="mx-3" key={`doctor-${i}`}>
          <ShortCard practitioner={doc as IPractitioner} onClickAbout={onClickAbout} />
        </div>)}
      </div>
      }

      {status && <div className="alert alert-warning">Could not load doctors. <pre>{JSON.stringify(status, null, 2)}</pre></div>}

    </>
  );

}

export { ShortDoctorCards }


