import React from 'react';
import { ResetPassword } from 'components/common/ResetPassword';
import { practitionerAccountService } from 'services';

const PractitionerResetPassword: React.FunctionComponent = () => {

  return(
    <ResetPassword accountService={practitionerAccountService} />
  );
}

export { PractitionerResetPassword }