import React, { useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Url } from "_constants";
import { AccountContext } from "components/common";

const Navigation = () => {
  const navigate = useNavigate();

  const accountContext = useContext(AccountContext);
 
  const logout = () => {
    accountContext?.logout();
    navigate(Url.ADMIN_HOME_PAGE);
  };

  const onClickMyAccount = () => {
    navigate(Url.ADMIN_PROFILE);
  };

  const onClickLogin = () => {
    navigate(Url.ADMIN_LOGIN);
  }

  return (
    <Navbar expand="lg">
      <Navbar.Brand href={Url.ADMIN_HOME_PAGE}>Convena Care Admin</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="justify-content-end w-100">
          <Nav.Link href={Url.ADMIN_HOME_PAGE}>Home</Nav.Link>
          <Nav.Item>
            <div className="btn-toolbar ml-auto">
              {!accountContext?.account ? (
                <button type="button" className="btn btn-primary mx-2" onClick={onClickLogin}>Login</button>
              ) : (
                <>
                  <Nav.Link href={Url.ADMIN_AVAILABILITY_CALENDAR}>Calendar</Nav.Link>
                  <Nav.Link href={Url.ADMIN_APPOINTMENTS}>Appointments</Nav.Link>
                  <Nav.Link href={Url.ADMIN_PRACTITIONERS}>Practitioners</Nav.Link>
                  <Nav.Link href={Url.ADMIN_ACTIVATION_CODES}>Activation Codes</Nav.Link>
                  {/*<Nav.Link href={Url.ADMIN_HOME_PAGE}>Labels and Mailings</Nav.Link>*/}
                  {/* <Nav.Link href={Url.ADMIN_ORDER}>Orders</Nav.Link> */}
                  {/* <Nav.Link href={Url.ADMIN_COUPON}>Coupons</Nav.Link> */}
                  <Nav.Link href={Url.ADMIN_INSURANCE}>Insurance</Nav.Link>
                  <Nav.Link href={Url.ADMIN_USER}>Patients</Nav.Link>
                  <Nav.Link href={Url.ADMIN_PROSPECT}>Prospects</Nav.Link>
                  <Nav.Link href={Url.ADMIN_REPORT_USER_LOGS}>User Logs</Nav.Link>
                  <Nav.Link href={Url.ADMIN_PROFILE} onClick={onClickMyAccount}>My Account</Nav.Link>
                  <Nav.Link href={Url.ADMIN_HOME_PAGE} onClick={logout}>Sign Out</Nav.Link>
                </>
              )}
            </div>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export { Navigation as AdminNavigation};
