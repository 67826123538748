import React, { useState } from "react";
import { Formik, Form } from "formik";
import { FieldWithError } from "components/common";
import { PersonCircle, KeyFill, Eye, EyeSlash } from "react-bootstrap-icons";

const LoginForm: React.FunctionComponent<{
  initialFormState: any;
  validationSchema: any;
  onSubmit: any;
  onClickRegister?: any;
  passwordResetUrl?: string;

}> = ({ initialFormState, validationSchema, onSubmit, onClickRegister, passwordResetUrl }) => {

  const [ show, setShow ] = useState<boolean>(false);

  const toggleShowHidePassword = () => {
    setShow(!show);
  }

  return (
    <Formik initialValues={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, status, touched, isSubmitting }) => {
        return (
          <Form className="form">
            <div className="card">
              <h4 className="card-header">Login Page</h4>
              <div className="card-body">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="email"
                        placeholder="Enter E-Mail Address"
                        icon={<PersonCircle />}
                      />
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col">
                      <div className="d-flex flex-shrink">
                      <FieldWithError
                        errors={errors}
                        touched={touched}
                        fieldName="password"
                        icon={<KeyFill />}
                        placeholder="Enter Password"
                        type={show ? "input" : "password"}
                      />
                      <div role="link" className="btn btn-link" onClick={()=>{toggleShowHidePassword()}}>{show ? <Eye className="text-dark"/> : <EyeSlash className="text-dark"/>}</div>

                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col text-center">
                      <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-md justify-text-center">
                        Login
                      </button>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col lg-auto text-center">
                      Forgot password? <a href={passwordResetUrl || "/forgot-password"}>Reset it!</a>
                    </div>
                  </div>

                  {status && <div className={"alert alert-danger"}>{status}</div>}
                  {isSubmitting && <div className="spinner-border"></div>}
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export { LoginForm };
