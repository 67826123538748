import React from 'react';
import { SpecialtyEnumName } from '_constants/Specialty';
import { IPractitioner } from 'interfaces';
import { useNavigate } from 'react-router';
import { Url } from '_constants';
import { insuranceCompanyNames } from '_constants/InsuranceCompanyEnum';

interface IProps {
  practitioner: IPractitioner,
  trackEvent: ()=>void,
}

const BioCard: React.FunctionComponent<IProps> = ({ practitioner, trackEvent }) => {

  const navigate = useNavigate();

  const makeAppointment = (id: number) => {
    trackEvent();
    const url = `${Url.ROOT_DOCTOR_LIST}?practitionerId=${id}`;
    navigate(url);
  }

  return (

    <div className="practitioner-card-bio container g-0 my-3">
      <div className="row mb-5">
        <div className="col col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3 py-3 px-5">
          <div className="practitioner-card-bio-title-container">
            <div className='practitioner-card-title'>Dr. {practitioner.firstName} {practitioner.lastName}, {practitioner.degree}</div>
            <div className="practitioner-card-specialty">{SpecialtyEnumName.get(practitioner.specialityId || 0)}</div>
            <div className="practitioner-card-board-certified">Board Certified</div>
            <div className="practitioner-card-avatar"><img src={practitioner.avatarUrl || '/images/doc-icon.png'} alt="Doctor" className="rounded" /></div>
          </div>
        </div>

        <div className="col col-sm-12 col-xs-12 col-md-9 col-lg-9 col-xl-9 py-3 px-5">
          <div className="practitioner-card-bio-about">
            <div className="d-flex justify-content-between my-2">
              <div className="practitioner-card-bio-title">Biography</div>
              <div className="practitioner-card-bio-btn">
                <div className="btn-make-appointment" onClick={()=>makeAppointment(practitioner.id)}>Make Appointment</div>
              </div>
            </div>
            <div className="practitioner-card-bio-about mt-3">
              {practitioner.about?.split("\n").map((p: string, i: number) => <p key={`p-key-${i}`}>
                {p}
              </p>
              )}
            </div>
            <div className="practitioner-card-bio-insurance">
              Insurance Accepted: {practitioner.insuranceCompanies?.map((insCo: number, i:number) => 
              insuranceCompanyNames.get(insCo)).join(", ")}
            </div>
          </div>
        </div>

      </div>
    </div>

  );
}

export { BioCard as PhysicianBioCard }