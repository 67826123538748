import React, { useContext, useEffect } from "react";
import { PatientProfile } from "components/patient/account";
import { Tab, Tabs } from "react-bootstrap";
import { PatientAppointments } from "../appointments";
import { AccountContext } from "components/common";
import { useNavigate } from "react-router";
import { Url } from "_constants";

const PatientDashboard: React.FunctionComponent = () => {

  const navigate = useNavigate();
  const acctContext = useContext(AccountContext);

  useEffect(() => {
    if (!(acctContext && acctContext.account)) {
      navigate(Url.PATIENT_LOGIN);
    } else {
      window.scrollTo(0, 0)
    }
  }, [])

  return (
    <div className="mt-4">
      {acctContext && acctContext.account &&
        <Tabs defaultActiveKey="appointments" id="orders-and-tests" className="mb-3">
          <Tab eventKey="appointments" title="My Appointments">
            <PatientAppointments />
          </Tab>
          <Tab eventKey="profile" title="Profile">
            <PatientProfile />
          </Tab>

        </Tabs>
      }
    </div>
  );
};

export { PatientDashboard };
