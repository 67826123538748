import { AccountContext } from "components/common";
import { FormattedPhone } from "components/common/form/FormattedPhone";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { Url } from "_constants";
import { AccountSetup, AddressInfo, PersonalInfo } from "components/patient/account/subforms";
import { validationMap, Validator } from "schemas";
import { MessengerContext } from "components/common/messenger";
import { IAccount } from "interfaces";

const Profile: React.FunctionComponent = () => {

  const accountContext = useContext(AccountContext);
  const msgrContext = useContext(MessengerContext);
  const navigate = useNavigate();
  const [ validationSchema, setValidationSchema] = useState<any>(validationMap.get(Validator.PROFILE_FORM));

  // useEffect(()=>{
  //   if (!accountContext || !accountContext.account) {
  //     navigate(Url.PATIENT_LOGIN);
  //   }
  // },[])

  const onClickLogout = async () => {
    await accountContext?.logout();
    navigate(Url.DEFAULT_HOME);
  }

  const initialValues = {

    email: (accountContext && accountContext.account && accountContext.account.email) || '',
    firstName: (accountContext && accountContext.account && accountContext.account.firstName) || '',
    lastName: (accountContext && accountContext.account && accountContext.account.lastName) || '',
    address1: (accountContext && accountContext.account && accountContext.account.address1) || '',
    address2: (accountContext && accountContext.account && accountContext.account.address2) || '',
    city: (accountContext && accountContext.account && accountContext.account.city) || '',
    state: (accountContext && accountContext.account && accountContext.account.state) || '',
    zipcode: (accountContext && accountContext.account && accountContext.account.zipcode) || '',
    organization: (accountContext && accountContext.account && accountContext.account.organization) || '',
    country: (accountContext && accountContext.account && accountContext.account.country) || '',
    phoneNumber: (accountContext && accountContext.account && accountContext.account.phoneNumber) || '',
  }

  const onSubmit = async (data: any, actions: any) => {
    
    const { setSubmitting, setStatus } = actions;

    if (data.password === "") {
      delete data.password;
      delete data.confirmPassword;
    }

    const account: IAccount = {
      firstName: data.firstName,
      lastName: data.lastName,
      // email: data.email,  // cannot update email
      password: data.password,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      zipcode: data.zip,
      phoneNumber: typeof data.phoneNumber === "number" ? data.phoneNumber : data.phoneNumber.replaceAll(/[^\d]/g,''),
      dateOfBirth: data.dateOfBirth,
    };

    try {
      setSubmitting(true);
      await accountContext?.update(account)
      msgrContext.setMessage({title: 'My Profile', body: "Changes Saved"});
    } catch (err: any) {
      setStatus(`Error: ${err}`);
    }

  }
  const togglePasswordSection = () => {
    setValidationSchema(validationMap.get(Validator.PROFILE_FORM_PASSWORD));
    // if (showPasswordFields) {
    //   setValidationSchema(validationMap.get(Validator.PROFILE_FORM));
    // } else {
    //   setValidationSchema(validationMap.get(Validator.PROFILE_FORM_PASSWORD));
    //   console.log('with password form');
    // }
    // setShowPasswordFields(!showPasswordFields);
  }


  return (
    <>
      {accountContext && accountContext.account &&
        <>
          <div className='practitioner-layout-section-title'>My Profile</div>

          <div className="container-md g-0">
            <div className="text-end">
              <button type="button" className="btn btn-primary" onClick={onClickLogout}>Logout</button>
            </div>
          </div>

          <div className="patient-profile container-md border rounded py-3 mt-2">

            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
              {({ touched, errors, setFieldValue, status, values }) => {
                return (
                  <Form>
                    <div className="container g-0">


                      <div className="row justify-content-center mt-5">
                        <div className="col-md-6">
                          {/* Personal Information*/}
                          <AccountSetup title={<>Account Credentials</>} errors={errors} touched={touched} emailDisabled={true} showPasswordToggle={true} onShowPassword={togglePasswordSection}/>
                        </div>
                      </div>


                      <div className="row justify-content-center mt-5">
                        <div className="col-md-6">
                          {/* Personal Information*/}
                          <PersonalInfo errors={errors} touched={touched} title={<div className="title medium-text-1">Personal Info</div>}/>
                        </div>
                      </div>

                      <div className="row justify-content-center mt-5">
                        <div className="col-md-6">
                          {/* Address */}
                          <AddressInfo errors={errors} touched={touched} title={<div className="title medium-text-1">Practitioner Address</div>}/>
                        </div>
                      </div>

                      <div className="row justify-content-center mt-5">
                        <div className="col-md-6">
                          <div className="container">
                          <div className="form-group">
                            <label>Phone Number</label>
                            <FormattedPhone
                              initialValue={parseInt(values["phoneNumber"] as string)}
                              className={'form-control' + (errors && errors["phoneNumber"] && touched && touched["phoneNumber"] ? ' is-invalid' : '')}
                              fieldName={"phoneNumber"} setPhoneNumber={(phone: string) => { setFieldValue("phoneNumber", phone) }} />
                            {errors && <ErrorMessage name={"phoneNumber"} component="div" className="invalid-feedback" />}
                          </div>
                          </div>
                        </div>
                      </div>


                      <div className="row justify-content-center mt-5">
                        <div className="col-md-6 text-center">
                          <button type="submit" className="btn btn-primary">Update</button>
                        </div>
                      </div>

                      {status &&
                        <div className="row mt-5 alert alert-warning">
                          {status}
                        </div>
                      }

                    </div>
                  </Form>
                )
              }}

            </Formik>
          </div>
        </>
      }
    </>
  );
};

export { Profile as PractitionerProfile };
