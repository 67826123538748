import React from 'react';
import { Field } from 'formik';

const YearDropDown: React.FunctionComponent<any> = ({ ...rest }) => {

  const numYears: number = 10;
  const currentYear = new Date().getFullYear();

  return (
    <Field {...rest} as="select" className="form-control" >
      <option key={`year-item-0`} value="0">Select Year</option>
      {Array.from(Array(numYears)).map((_, i: number) =>
        <option
          key={`year-item-${i + currentYear}`}
          value={i + currentYear}>{i + currentYear}</option>
      )}
    </Field>
  );
}

export { YearDropDown }