import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { publicApiService } from 'services';

const ActivateWithHash: React.FunctionComponent = (props: any) => {
  const { codeHash } = useParams();
  const [status, setStatus] = useState<string>('');

  interface IResponseType {
    statusId: number,
    code: string
  }

  const navigate = useNavigate();

  useEffect(() => {
    const _load = async () => {
      try {
        if (codeHash) {
          const result: IResponseType = await publicApiService.getActivationCodeFromHash(codeHash);
          const url = `/make-appointment?kit=true&code=${result.code}`
          navigate(url);
        } else {
          throw Error('No Hash Found');
        }
      } catch (error) {
        setStatus((error as Error).message);
      }
    }
    _load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeHash]);

  return (
    <>
      {status &&
        <div className="container">
          <div className="alert alert-warning">
            {status}
          </div>
        </div>
      }
    </>
  );
}

export { ActivateWithHash }