import React from "react";
import { IActivationCode, IDataField } from "interfaces";
import { adminActivationCodeApiService } from "services";
import { ListContainer } from "components/common";
import { FormButtons, ProductCodeResultNames, ProductCodeStatusName, Url } from "_constants";
import { CodeForm } from ".";

const CodeList: React.FunctionComponent = () => {
  
  const emptyValues: IActivationCode = {
    statusId: 0,
  };

  const onSelectedRows = (ids: string[]) => {
    //console.log('selected ', ids);
  }

  const editableFields: IDataField[] = [
      {name: 'productId', title: 'Product ID', editable: false},
      {name: 'statusId', title: 'Status ID', editable: true},
      {name: 'patientId', title: 'Patient ID', editable: false},
      {name: 'resultId', title: 'Result ID', editable: true},
  ];

  const getStatusName = (row: any): string | undefined => {
    return ProductCodeStatusName.get(Number(row['statusId']));
  }

  const getResultImage = (row: any)  => {
    if (row['resultFilePath']) {
      return <iframe src={`${Url.ADMIN_RESULT_IMAGE}?path=${row['resultFilePath']}`} title='Result'></iframe>
    }
  }
  
  const getResultName = (row: any) => {
    if (row['resultId']) {
      return ProductCodeResultNames.get(row['resultId']);
    }

  }

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['Row ID', undefined]],
    ['productId', ['Product ID', undefined]],
    ['statusId', ['Status Id', undefined]],
    ['statusName', ['Status Name', getStatusName]],
    ['resultId', ['Result Name', getResultName]],
    ['resultImage', ['Result Image', getResultImage]],
    ['patientFirstName', ['Patient First Name', undefined]],
    ['patientLastName', ['Patient Last Name', undefined]],
    ['prospectFirstName', ['Prospect First Name', undefined]],
    ['prospectLastName', ['Prospect Last Name', undefined]],
    ['payerFirstName', ['Payer First Name', undefined]],
    ['payerLastName', ['Payer Last Name', undefined]],
  ]);

  
  
  return (
    <>
    <div className='practitioner-layout-section-title'>Activation Code Manager</div>
    <ListContainer 
      apiService={adminActivationCodeApiService} 
      emptyValues={emptyValues} 
      onSelectedRows={onSelectedRows}
      multiSelectLabel="Generate Labels"
      hasMultiSelect={false}
      buttons={[FormButtons.EDIT]}
      editableFields={editableFields}
      fieldList={fieldList}
    >
      {({ isEditing, onSuccess, initialValues, onCancelClick, editableFields }) => {
        const {patientId, patientFirstName, patientLastName} = initialValues;
        return (
          <div className="container-sm">
          <table className="table">
            <tbody>
            <tr><td>Patient ID</td><td>{patientId}</td></tr>
            <tr><td>Patient First Name</td><td>{patientFirstName}</td></tr>
            <tr><td>Patient Last Name</td><td>{patientLastName}</td></tr>
            </tbody>
          </table>
          <CodeForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick} editableFields={editableFields}/>
          </div>
        );
      }}
    </ListContainer>
    </>

  );
};

export { CodeList };
