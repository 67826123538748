import React from "react";
import { ErrorMessage, Field } from "formik";
import { IKeyValue } from "interfaces";
const YesNoField: React.FunctionComponent<{ groupName?: string; label: string | JSX.Element; name: string, errors: any, touched: any, className?: string }> = ({ groupName, label, name, errors, touched, className }) => {
  const options: IKeyValue<string>[] = [
    { key: "1", value: "Yes" },
    { key: "0", value: "No" },
  ];

  return (
    <div className={className}>
      <div id={name}>{label}</div>
      <div role="group" aria-labelledby={name} className={"d-flex flex-row form-control" + (errors && errors[name] && touched && touched[name] ? " is-invalid" : "")}>
        {options.map((i: IKeyValue<string>) => (
          <div className="form-check mx-3" key={`key-${name}-${i.key}`} >
            <Field id={`${name}-${i.key}`} className="form-check-input" type="radio" name={name} value={i.key} />
            <label className="form-check-label" htmlFor={`${name}-${i.key}`}>
              {i.value}
            </label>
          </div>
        ))}
      </div>
      {errors && errors[name] && <ErrorMessage name={name} component="div" className="invalid-feedback" />}

    </div>
  );
};

export { YesNoField };
