import React, { useEffect, useState } from 'react';
import { trackingService } from 'services/common/TrackingService';
import { useUnload } from '_hooks/useUnload';
import { MailingAddressForm } from './MailingAddressForm';
import { PhoneForm } from './PhoneForm';
import { QualifyForm } from './QualifyForm';
import { QualifySection } from './QualifySection';
import { VerificationCodeForm } from './VerificationCodeForm';

const FreeKitPage: React.FunctionComponent = () => {

  enum Stage {
    QUALIFY_FORM = 1,
    VERIFY_PHONE = 2,
    VERIFY_CODE = 3,
    ENTER_MAILING_ADDRESS = 4,
    THANK_YOU = 5,
  }

  const EVENT_CATEGORY = 'Free Kit (No Appt)';

  const [qualified, setQualified] = useState<boolean>(false);
  const [stage, setStage] = useState<Stage>(Stage.QUALIFY_FORM);
  const [phoneNumber, setPhoneNumber] = useState<number | undefined>(undefined);
  const [phoneNumberCode, setPhoneNumberCode] = useState<string | undefined>(undefined);

  useUnload( (e: Event) => {
    e.preventDefault();
    trackingService.fireGoogleUnload({category: EVENT_CATEGORY, label: 'exit page' });
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stage])


  const onSubmitQualifyForm = (isQualified: boolean) => {
    setQualified(isQualified);
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Submitted Qualify Form`,
      category: EVENT_CATEGORY,
      label: 'Submit',
    });
    setStage(stage + 1);
  }

  const onSubmitPhone = (phoneNumber: number) => {
    setPhoneNumber(phoneNumber);
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Submitted Phone`,
      category: EVENT_CATEGORY,
      label: 'Submit',
    });
    setStage(stage + 1);
  }

  const onSubmitVerificationCode = (phoneNumberCode: string) => {
    setPhoneNumberCode(phoneNumberCode);
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Submitted Verification Code`,
      category: EVENT_CATEGORY,
      label: 'Verify',
    });
    setStage(stage + 1);
  }

  const onSubmitMailingAddress = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Submitted Mailing Address`,
      category: EVENT_CATEGORY,
      label: 'Submit',
    });
    setStage(stage + 1);
  }

  const onClickShowVideo = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Show Video`,
      category: EVENT_CATEGORY,
      label: ' Video: How The Test Is Done',
    });
  }

  return (
    <div className="container-fluid g-0 g-md-3 bg-lighterblue pt-3 pb-5">

      {stage === Stage.QUALIFY_FORM &&
        <QualifySection onClickShowVideo={onClickShowVideo}>
          <QualifyForm onSubmit={onSubmitQualifyForm} />
        </QualifySection>
      }

      {stage === Stage.VERIFY_PHONE && qualified &&
        <>
          <div className="congratulations-section">
            <div className="envelope-with-check margin-center"><img src="/images/envelope-with-check.png" alt="test kit" /></div>
            <div className="small-text-2 fw-bold dark-blue my-3">Great! It looks like your insurance should cover 100% of the cost for a Virtual Visit with us. </div>
            <div className="small-text-2 fw-bold dark-blue my-3">To confirm you are human, we need to send a verification code to your phone. Please enter your phone number below.</div>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <PhoneForm onSubmit={onSubmitPhone} />
                </div>
              </div>
            </div>
          </div>
        </>
      }

      {stage === Stage.VERIFY_PHONE && !qualified &&
        <div className="my-3">Regretfully, you are not qualified for a free colon cancer screening kit.</div>
      }

      {stage === Stage.VERIFY_CODE &&
        <>
          <div className="small-text-2 dark-blue my-3">We sent a verification code to your mobile phone. Please enter the verification code below</div>
          <VerificationCodeForm phoneNumber={phoneNumber || 0} onSubmit={onSubmitVerificationCode} />
        </>
      }

      {stage === Stage.ENTER_MAILING_ADDRESS &&
        <>
          <div className="small-text-2 fw-bold dark-blue my-3">Code was successfully verified. Please let us know where we should mail your kit by entering the patient's name and mailing address below.</div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <MailingAddressForm onSubmit={onSubmitMailingAddress} phoneNumber={phoneNumber} phoneNumberCode={phoneNumberCode} />
              </div>
            </div>
          </div>

        </>
      }

      {stage === Stage.THANK_YOU && <>
        <div className="small-text-1 dark-blue my-3">

          <p>Thank you for signing up!</p>

          <p>We will be sending you our Free Colon Cancer Screening Kit in the mail. It will include all the necessary instructions
            on activating, using, and returning the kit.
          </p>

          <p>If you have any questions, please feel free to email us at support@convenacare.com, or call us at <a href="tel:19088956969" className="telno">(908) 895 6969</a>.</p>

          <p>- The Convena Care Team</p>
        </div>
      </>}


    </div>


  );
}

export { FreeKitPage }