import { AddressFields } from 'components/common/form';
import { Form, Formik } from 'formik';
import { IAccount, IAddressFieldMap } from 'interfaces';
import React from 'react';
import { validationMap, Validator } from 'schemas';
import { patientAccountService } from 'services';
import { useUniqueUserId } from '_hooks/useUniqueUserId';

const MailingAddressForm: React.FunctionComponent<{ onSubmit: any, phoneNumber?: number, phoneNumberCode?: string }> = ({ onSubmit, phoneNumber, phoneNumberCode }) => {
  const initialValues: IAccount = {
    firstName: undefined,
    lastName: undefined,
    address1: undefined,
    address2: undefined,
    city: undefined,
    state: undefined,
    zipcode: undefined,

  }

  const fieldMap: IAddressFieldMap = {
    firstName: { fieldName: 'firstName', fieldTitle: 'First Name' },
    lastName: { fieldName: 'lastName', fieldTitle: 'Last Name' },
    address1: { fieldName: 'address1', fieldTitle: 'Address 1' },
    address2: { fieldName: 'address2', fieldTitle: 'Address 2' },
    city: { fieldName: 'city', fieldTitle: 'City' },
    state: { fieldName: 'state', fieldTitle: 'State' },
    zipcode: { fieldName: 'zipcode', fieldTitle: 'Zip Code' },
  };

  const _onSubmit = async (data: any, actions: any) => {
    const { setStatus } = actions;
    const { firstName, lastName, address1, address2, city, state, zipcode } = data;
    const uniqueUserId = useUniqueUserId();
    const payload: IAccount = {
      firstName, lastName, address1, address2, city, state, zipcode, phoneNumber, phoneNumberCode, country: 'USA'
    }
    try {
      const response: any = await patientAccountService.registrationFreeKit(payload, uniqueUserId );
      onSubmit(response);
    } catch (error: any) {
      if (error === 'phoneNumber exists (phoneNumber)') {
        setStatus('Phone number already exists');
      } else {
        setStatus(error);
      }
    }

  }

  return (
    <Formik initialValues={initialValues} onSubmit={_onSubmit} validationSchema={validationMap.get(Validator.MAILING_ADDRESS_FORM)}>
      {({ values, errors, touched, setFieldValue, isSubmitting, status }) => {
        return <Form>
          <AddressFields errors={errors} touched={touched} setFieldValue={setFieldValue} values={values} fieldMap={fieldMap} />

          <button type="submit" className="btn btn-primary bg-blue" disabled={isSubmitting}>Submit</button>

          {status && <div className="alert alert-warning">{status}</div>}
        </Form>
      }}
    </Formik>

  );
}

export { MailingAddressForm }