

const HowToVideo = (): JSX.Element => {
  return (
    <video controls autoPlay={true} width="100%" poster="/images/play-background.png">
      <source src="/videos/colon-cancer-animation.mp4" type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
}

export { HowToVideo }