import { IAppointmentResponseItem } from 'components/patient/appointments';
import React, { ChangeEvent, useState } from 'react';
import { Form as BootstrapForm} from 'react-bootstrap';
import { ITimeRange } from './ITimeRange';
import { IToggleProp } from './IToggleProp';
import { TimeSlotButton } from './TimeSlotButton';

const TimeSlotButtons: React.FunctionComponent<{
  calendarDate: Date, 
  appts: IAppointmentResponseItem[],
  onClickSave: { (timeRange: ITimeRange[]): Promise<any>},
}> = ({calendarDate: currentDate, appts: currentAppts, onClickSave}) => {

  // const [ currentDate, setCurrentDate ] = useState<Date>(calendarDate);
  // const [ currentAppts, setCurrentAppts ] = useState<IAppointmentResponseItem[]>(appts);

  // useEffect(()=>{
  //   setCurrentDate(calendarDate);
  //   setCurrentAppts(appts);
  //   console.log('in useeffect');
  // },[calendarDate, appts])

  // row [0][1] = [08:00] [08:15] [08:30] [08:45]
  // row [0][2] = [08:00] [08:20] [08:40]
  // row [0][3] = [08:00] [08:30]

  // row [1][1] = [09:00] [09:15] [09:30] [09:45]
  // row [1][2] = [09:00] [09:20] [09:40]
  // row [1][3] = [09:00] [09:30]

  // row [h][d] = [min]
  // row [hour][half] = [09:00] [09:30]

  // starting times
  const startTime = [
    ['00', '15', '30', '45'],
    ['00', '20', '40'],
    ['00', '30'],
  ];

  const hours: string[] = ['08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18','19','20','21'];

  const [ slotToggleState, setSlotToggleState ] = useState<IToggleProp[]>([]);
  
  const [ intervals, setIntervals ] = useState<number[]>([...new Array(hours.length).fill(0)]);
  const [ mainInterval, setMainInterval ] = useState<number>(0);
  const times = hours.map((h: string, i: number) => startTime.map((t: string[]) => t.map((m: string) => { 
    return new Date(`${currentDate.toISOString().slice(0, 10)}T${parseInt(h, 10).toString().padStart(2, '0')}:${m}:00`) } )
    ));


  const onIntervalChange = (e: ChangeEvent<HTMLSelectElement>, index: number) => {
    intervals[index] = parseInt(e.currentTarget.value, 10);
    const newIntervals = [...intervals];
    setIntervals(newIntervals);
  }

  const onMainIntervalChange = (e: ChangeEvent<HTMLSelectElement>) => {
    intervals.fill(parseInt(e.currentTarget.value, 10));
    const newIntervals = [...intervals];
    setIntervals(newIntervals);
    setMainInterval(parseInt(e.currentTarget.value, 10));
  }

  const onToggle = (value: IToggleProp) => {
    const slots = slotToggleState.filter((s: IToggleProp) => s.timestamp === value.timestamp);

    if (slots.length > 0) { // found existing, update
      setSlotToggleState(slotToggleState.map((s: IToggleProp) => (value.timestamp === s.timestamp ? {...s, ...value} : s)))
    } else { // nothing existing, add
      setSlotToggleState([...slotToggleState, value]);
    }
  }

  const onSave = async () => {
    //only save state === AVAILABLE
    const data: ITimeRange[] = slotToggleState.map((slot: IToggleProp) => ({
      start: slot.timestamp / 1000,
      end: slot.endTimeStamp / 1000,
      price: 1,
      status: slot.state,
      appointment: slot.appointment
    }))
    try {
      await onClickSave(data);
    } catch (error) {
      console.error(error);      
    }
  }

  const getDuration = (i: number) => {
    switch (i) {
      case 0: {
        return 14.99 * 60 * 1000;
      }
      case 1: {
        return 19.99 * 60 * 1000;
      }
      default: {
        return 29.99 * 60 * 1000;
      }
    }
  }

  const getAppointment = (t: Date): IAppointmentResponseItem | undefined  => {
    const appts = currentAppts.filter((appt: IAppointmentResponseItem) => appt.start * 1000 === t.getTime());
    if (appts.length > 0) {
      return appts[0];
    } else {
      return undefined;
    }
  }

  return (
    <div>
      <div className="d-flex align-bottom my-3">
        <div className="align-self-center mx-3">
          Select Default Interval
        </div>
        <div>
          <BootstrapForm.Select aria-label="Select Interval" name="mainInterval" onChange={onMainIntervalChange}>
            <option>Select</option>
            <option value={0} selected={mainInterval === 0}>15 minutes</option>
            <option value={1} selected={mainInterval === 1}>20 minutes</option>
            <option value={2} selected={mainInterval === 2}>30 minutes</option>
          </BootstrapForm.Select>
        </div>
      </div>

    <div className="timeslot-row">

      {times.map((slots: any, i:number) => <>
      <div className="d-inline-flex my-2">
        {slots.map((hr: any, idx: number) => 
        <div className={`d-inline-flex ${idx !== intervals[i] ? "d-none" : ""}`}>
          <div className="d-inline-flex timeslot">
            {hr.map((t: any) => <>
              {/* <div className="btn btn-available mx-2" onClick={()=>toggleSlot(t)}><pre>{JSON.stringify(t, null, 2)}</pre>{( t as Date).toLocaleTimeString('en-us')}</div> */}
              <div>
              {/* <pre>rendered on {new Date().toLocaleTimeString()}</pre>
              <div><pre>{JSON.stringify(currentAppts.map((appt: IAppointmentResponseItem) => appt.start * 1000), null, 2)}</pre></div>
              <div>match with: {t.getTime()}</div>
              <div>{currentAppts.map((appt: IAppointmentResponseItem) => appt.start * 1000).indexOf(t.getTime()) >=0 ? ToggleStateEnum.FILLED : ToggleStateEnum.OPEN}</div>  */}
              <TimeSlotButton dateTime={t} duration={getDuration(intervals[i])} appt={getAppointment(t)} onToggle={onToggle}/>
              </div>
              {/* <div>
                <pre>time: {t.getTime()}</pre>
                <pre>{JSON.stringify(appts.map((appt: IAppointmentResponseItem) => appt.start * 1000),null, 2)}</pre>
              </div> */}
            </>)}
          </div>
        </div>
        )}
        <div>
          <BootstrapForm.Select aria-label="Select Interval" className="timeslot-hourly-interval" name="interval" onChange={(e: ChangeEvent<HTMLSelectElement>) => onIntervalChange(e, i)}>
            <option value={0} selected={intervals[i] === 0}>15 minutes</option>
            <option value={1} selected={intervals[i] === 1}>20 minutes</option>
            <option value={2} selected={intervals[i] === 2}>30 minutes</option>
          </BootstrapForm.Select>
        </div>
        {/* <div>
          <button type="button" className="btn btn-secondary mx-2">X</button>
        </div> */}
      </div></>)}
      <div className="m-2">
      <button type="button" className="btn btn-primary" onClick={()=>onSave()}>Save</button>
      </div>
    </div>
    </div>
  );
}

export { TimeSlotButtons }