import React from 'react';
import { FieldWithError, StateField,  } from "components/common";
import { ErrorMessage } from "formik";

const AddressInfo: React.FunctionComponent<{ errors: any; touched: any, readOnly?: boolean, title?: JSX.Element }> = ({ errors, touched, readOnly, title }) => {
  return (
    <div className="container">
      {title ? (<>{title}</>) : (<div className="title medium-text-1">Patient Address</div>)}
      
      <div className="row my-2">
        <div className="col">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="address1"
            placeholder="Address Line 1"
            label="Address Line 1"
            readOnly={readOnly}
          />
        </div>
      </div>

      <div className="row my-2">
        <div className="col">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="address2"
            placeholder="Address Line 2"
            label="Address Line 2 (Optional)"
            readOnly={readOnly}
          />
        </div>
      </div>

      <div className="row my-2">
        <div className="col-12 col-md-4 my-2">
          <FieldWithError errors={errors} touched={touched} fieldName="city" placeholder="City" label="City" readOnly={readOnly}/>
        </div>

        <div className="col-12 col-md-4 my-2">
          <div className="form-group">
            <label>State</label>
            <StateField
              name="state"
              disabled={readOnly}
              className={"form-control" + (errors && errors["state"] && touched && touched["state"] ? " is-invalid" : "")}
            />
          {errors && <ErrorMessage name="state" component="div" className="invalid-feedback" />}
          </div>
        </div>

        <div className="col-12 col-md-4 my-2">
          <FieldWithError errors={errors} touched={touched} fieldName="zipcode" placeholder="Zip Code" label="Zip Code" readOnly={readOnly}/>
        </div>
      </div>
    </div>
  );
};


export { AddressInfo }