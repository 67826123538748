import { Field } from 'formik';
import React from 'react';

const MonthDropDown:React.FunctionComponent<any> = ({...rest}) => {

  const months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  return(
    <div className="form-group">
      <Field {...rest} as="select" className="form-control" >
      <option key={`month-item-0`} value="0">Select Month</option>
      {months.map((month: string, i: number)=>(
        <option 
          key={`month-item-${i+1}`}
          value={(i + 1).toString().padStart(2, '0')}>{(i + 1).toString().padStart(2, '0')} - {month}</option>
      ))}
    </Field>

    </div>
  );
}

export { MonthDropDown }