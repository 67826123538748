import React from 'react';
import { IPractitioner } from 'interfaces';
import { ITimeSlot, IDateTimeSlots } from 'interfaces/appointment';

const DayCard: React.FunctionComponent<{ 
  dateTimeSlots: IDateTimeSlots, 
  onAppointmentClick: (timeslot: ITimeSlot, practitioner: IPractitioner) => void, 
  practitioner: IPractitioner, 
  activationCode?: string, 
  insuranceId?: number,
  expandAppts: (practitioner: IPractitioner) => void,
}> = ({ dateTimeSlots, onAppointmentClick, practitioner, insuranceId, expandAppts }) => {

  return (
    <div className="">
      <div className="daycard-datetime">{dateTimeSlots.date}</div>
      <div className="daycard">
        {dateTimeSlots.timeSlots.slice(0,6).map((timeslot, key: number) => {
            return <div key={`daycard-key-${key}`} className={`btn-daycard btn-daycard-dark ${key > 1 ? "d-none d-md-block" : ""}`} onClick={() => { onAppointmentClick(timeslot, practitioner) }}>{timeslot.startTime}</div>
        }
        )}
        <div className="btn btn-link text-decoration-none btn-daycard-show-more" onClick={() => expandAppts(practitioner)}>More...</div>
      </div>
    </div>
  );
}

export { DayCard }