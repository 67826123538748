enum AppointmentStatusEnum {
  OPEN                                    = 1,
  PRACTITIONER_PENDING_SMS_RESPONSE       = 2,
  SCHEDULE_PRACTITIONER_CONFIRMED         = 3,
  SCHEDULE_PRACTITIONER_NOT_AVAILABLE     = 4,

  FINISHED                                = 10,
  CANCELED                                = 20,
  CANCELLING                              = 21,
}

const appointmentStatusNames: Map<number, string> = new Map<number, string>([
  [AppointmentStatusEnum.OPEN, 'Available Slot'],
  [AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE, 'Pending SMS Confirmation'],
  [AppointmentStatusEnum.SCHEDULE_PRACTITIONER_CONFIRMED, 'Confirmed by Practitioner'],
  [AppointmentStatusEnum.SCHEDULE_PRACTITIONER_NOT_AVAILABLE, 'Practitioner No Longer Available'],
  [AppointmentStatusEnum.FINISHED, 'Completed'],
  [AppointmentStatusEnum.CANCELED, 'Canceled'],
  [AppointmentStatusEnum.CANCELLING, 'Patient Requested To Cancel'],
]);

const appointmentStatusSort: Map<number, number> = new Map<number, number>([
  [AppointmentStatusEnum.SCHEDULE_PRACTITIONER_NOT_AVAILABLE, 1],
  [AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE, 2],
  [AppointmentStatusEnum.CANCELLING, 3],
  [AppointmentStatusEnum.SCHEDULE_PRACTITIONER_CONFIRMED, 4],
  [AppointmentStatusEnum.FINISHED, 5],
  [AppointmentStatusEnum.CANCELED, 6],
  [AppointmentStatusEnum.OPEN, 7],
]);

const appointmentStatusNamesPatient: Map<number, string> = new Map<number, string>([
  [AppointmentStatusEnum.OPEN, 'Available Slot'],
  [AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE, 'Pending Confirmation'],
  [AppointmentStatusEnum.SCHEDULE_PRACTITIONER_CONFIRMED, 'Confirmed'],
  [AppointmentStatusEnum.SCHEDULE_PRACTITIONER_NOT_AVAILABLE, 'Pending Confirmation'],
  [AppointmentStatusEnum.FINISHED, 'Completed'],
  [AppointmentStatusEnum.CANCELED, 'Canceled'],
  [AppointmentStatusEnum.CANCELLING, 'Patient Requested To Cancel'],
]);

export { AppointmentStatusEnum, appointmentStatusNames, appointmentStatusSort, appointmentStatusNamesPatient};