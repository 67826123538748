import React from "react";
import { IUser } from "interfaces";
import { guarantorRelationshipNames } from "_constants";
import { insuranceCompanyNames } from "_constants/InsuranceCompanyEnum";
import { Helper } from "_utils";

const PatientDetail: React.FunctionComponent<{ patient: IUser }> = ({ patient }) => {
  return (
    <>
      <div className={`container-fluid patient-detail`}>
        <div className="row bg-dark-gray fw-bold text-white p-1 mt-3">Identification</div>
        <div className="row py-1">
          <div className="col fw-bold">Last Name</div> <div className="col">{patient.lastName}</div>
        </div>
        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold">First Name</div> <div className="col">{patient.firstName}</div>
        </div>
        <div className="row py-1">
          <div className="col fw-bold">DOB</div> <div className="col">{patient.dateOfBirth ? Helper.formattedMDY(patient.dateOfBirth) : ''}</div>
        </div>

        <div className="row py-1 bg-dark-gray fw-bold text-white p-1 mt-3">Contact</div>

        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold">E-Mail Address</div> <div className="col">{patient.email}</div>
        </div>

        <div className="row py-1">
          <div className="col fw-bold">Address</div> <div className="col">{patient.address1}</div>
        </div>
        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold">Address 2</div> <div className="col">{patient.address2}</div>
        </div>
        <div className="row py-1">
          <div className="col fw-bold">Zip Code</div> <div className="col">{patient.zipcode}</div>
        </div>
        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold">City</div> <div className="col">{patient.city}</div>
        </div>
        <div className="row py-1">
          <div className="col fw-bold">State</div> <div className="col">{patient.state}</div>
        </div>
        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold">Phone Number</div> <div className="col">{patient.phoneNumber ? Helper.formattedPhoneFromNumber(patient.phoneNumber) : ''}</div>
        </div>

        <div className="row py-1 bg-dark-gray fw-bold text-white p-1 mt-3">Insurance</div>

        <div className="row py-1">
          <div className="col fw-bold">Provider Name</div> <div className="col">{insuranceCompanyNames.get((patient && patient.insuranceCompanyId) ? parseInt(patient.insuranceCompanyId, 10) : 0)}</div>
        </div>

        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold">Member ID</div><div className="col fw-normal text-start">{patient.insuranceMemberId}</div>
        </div>

        <div className="row py-1">
          <div className="col fw-bold">Group Number </div> <div className="col fw-normal text-start">{patient.insuranceGroupId}</div>
        </div>

        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold">Phone Number </div><div className="col fw-normal text-start">{patient.insurancePhoneNumber ? Helper.formattedPhoneFromNumber(patient.insurancePhoneNumber) : ''}</div>
        </div>

        <div className="row py-1 bg-dark-gray fw-bold text-white p-1 mt-3">Guarantor</div>

        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold my-1">Patient's Relationship to Guarantor</div><div className="col fw-normal">{guarantorRelationshipNames.get(patient.guarantorRelationshipId || 0)}</div>
        </div>
        <div className="row py-1">
          <div className="col fw-bold">Last Name</div><div className="col fw-normal">{patient.guarantorLastName}</div>
        </div>
        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold">First Name</div><div className="col fw-normal">{patient.guarantorFirstName}</div>
        </div>
        <div className="row py-1">
          <div className="col fw-bold">DOB</div><div className="col fw-normal">{patient.guarantorDateOfBirth ? Helper.formattedMDY(patient.guarantorDateOfBirth) : ''}</div>
        </div>

        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold">Address 1</div><div className="col fw-normal">{patient.guarantorAddress1}</div>
        </div>

        <div className="row py-1">
          <div className="col fw-bold">Address 2</div><div className="col fw-normal">{patient.guarantorAddress2}</div>
        </div>

        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold">Zip Code</div><div className="col fw-normal">{patient.guarantorZipcode}</div>
        </div>
        <div className="row py-1">
          <div className="col fw-bold">City</div><div className="col fw-normal">{patient.guarantorCity}</div>
        </div>
        <div className="row py-1 bg-lightergray">
          <div className="col fw-bold">State </div><div className="col fw-normal">{patient.guarantorState}</div>
        </div>
        <div className="row py-1">
          <div className="col fw-bold">Phone Number</div><div className="col fw-normal">{patient.guarantorPhoneNumber ? Helper.formattedPhoneFromNumber(patient.guarantorPhoneNumber) : ''}</div>
        </div>
      </div>


    </>
  );
}

export { PatientDetail }