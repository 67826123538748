export enum Url {
  // ADMIN paths
  ADMIN_HOME_PAGE = '/admin',
  ADMIN_PROFILE = '/admin/profile',
  ADMIN_DASHBOARD = '/admin/dashboard',
  ADMIN_LOGIN = '/admin',
  ADMIN_PRODUCT = '/admin/product',
  ADMIN_PROSPECT = '/admin/prospect',
  ADMIN_ORDER = '/admin/order',
  ADMIN_ACTIVATION_CODES = '/admin/codes',
  ADMIN_USER = '/admin/user',
  ADMIN_DOWNLOAD = '/admin/download',
  ADMIN_CODE_RESULT = '/admin/code',
  ADMIN_COUPON = '/admin/coupon',
  ADMIN_RESULT_IMAGE = '/admin/result-image',
  ADMIN_ENVELOPE_CODE = '/admin/envelope/code/:envelopeID',
  ADMIN_PRACTITIONERS = '/admin/practitioner',
  ADMIN_INSURANCE = '/admin/insurance',
  ADMIN_REPORT_USER_LOGS = '/admin/report/user-logs',
  ADMIN_AVAILABILITY_CALENDAR = '/admin/availability-calendar',
  ADMIN_APPOINTMENTS = '/admin/appointments',

  // PATIENT paths
  PATIENT_LOGIN = '/patient/login',
  PATIENT_LOGOUT = '/patient/logout',
  PATIENT_REGISTRATION = '/patient/register',
  PATIENT_SCHEDULER = '/patient/scheduler',
  PATIENT_APPOINTMENT = '/patient/appointment',
  PATIENT_APPOINTMENT_CONFIRMED = '/patient/appointment-confirmed',


  PATIENT_DASHBOARD = '/patient/dashboard',
  PATIENT_ACCOUNT_PROFILE = '/patient/profile',
  

  PATIENT_KIT_ACTIVATION = '/test-activation',
  PATIENT_TEST_QUESTIONNAIRE = '/test-questionnaire',
  PATIENT_TEST_RESULTS_LIST = '/test-results-list',
  PATIENT_CHECKOUT = '/checkout',
  PATIENT_ORDER = '/order',
  PATIENT_THANK_YOU = '/thank-you',
  PATIENT_THANK_YOU_QUESTIONNAIRE = '/thank-you-questionnaire',
  PATIENT_COLON_CANCER_INFO = '/colon-cancer-info',
  PATIENT_FAQ = '/faq',
  PATIENT_PRIVACY_POLICY = '/privacy-policy',
  PATIENT_TERMS = '/terms-and-conditions',
  PATIENT_ACTIVATION = '/activation',
  PATIENT_PURCHASE_ACTIVATION = '/purchase-activation',
  PATIENT_FORGOT_PASSWORD = '/forgot-password',
  PATIENT_RESET_PASSWORD = '/reset-password/:authID',
  PATIENT_VIDEO_PAGE = '/colon-cancer-fit-animation',
  PATIENT_CONFIRM_EMAIL = '/confirm-email/:authID',

  PATIENT_SUBSCRIBE = '/subscribe',
  HOME_SUBSCRIPTION = '/home-subscription',
  ROOT_DOCTOR_LIST = '/make-appointment',
 

  PATIENT_COLON_HOME = '/colon',
  PATIENT_HIPAA = '/hipaa',
  PATIENT_PRODUCT_CONSENT = '/product-consent',
  

  PRACTITIONER_HOME = '/practitioner',
  PRACTITIONER_LOGIN = '/practitioner',
  PRACTITIONER_DASHBOARD = '/practitioner/dashboard',
  PRACTITIONER_APPOINTMENTS = '/practitioner/appointment-manager',
  PRACTITIONER_PATIENTS = '/practitioner/patient-manager',
  PRACTITIONER_PROFILE = '/practitioner/profile',
  PRACTITIONER_CALENDAR = '/practitioner/availability-calendar',
  PRACTITIONER_REFERENCE = '/practitioner/reference',
  PRACTITIONER_REPORT_USER_LOGS = '/practitioner/report/user-logs',


  PUBLIC_PHYSICIANS = '/our-physicians',
  PUBLIC_CONTACT = '/contact',
  PUBLIC_PRICING = '/pricing',
  
  // common paths
  LOGOUT = '/logout',
  DEFAULT_HOME = '/',
  
}