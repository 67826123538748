import { IAppointment, IPractitioner } from 'interfaces';
import { Helper } from '_utils';
import { IPractitionerAppointments, ITimeSlot, IDateTimeSlots } from 'interfaces/appointment';

class PractitionerHelper {
  public static groupTimeSlotsByDate = (appts: IAppointment[]): Map<string, IDateTimeSlots> => {
    const grouped = appts.reduce((map: Map<string, IDateTimeSlots>, appt: IAppointment) => {
      if (appt) {
        const dateMDY = Helper.getFormattedDayMDY(new Date(appt.start * 1000));
        const timeSlot: ITimeSlot = {
          id: appt.id,
          date: Helper.getFormattedDayMDY(new Date(appt.start * 1000)),
          startTime: Helper.getFormattedTimeHHMM(new Date(appt.start * 1000)),
          endTime: Helper.getFormattedTimeHHMM(new Date(appt.end * 1000)),
        }
  
        const item = map.get(dateMDY);
        if (item) {
          item.timeSlots.push(timeSlot);
        } else {
          map.set(dateMDY, { date: dateMDY, timeSlots: [timeSlot] });
        }
      }
      return map;
    }, new Map<string, IDateTimeSlots>());
    return grouped;
  }
  
  public static groupAppointmentsByDoctor = (appts: IAppointment[]): Map<number, IPractitionerAppointments> => {
    const grouped = appts.reduce((map, entry: IAppointment) => {
      if (entry) {
        const practitioner: IPractitioner = entry.practitioner;
        const appt = {
          id: entry.id,
          start: entry.start,
          end: entry.end,
          statusId: entry.statusId,
          price: entry.price
        } as IAppointment;
  
        if (map.has(entry.practitioner.id)) {
          const appts = map.get(entry.practitioner.id);
          if (appts) {
            appts.appointments.push(appt);
          }
        } else {
          map.set(entry.practitioner.id, { practitioner, appointments: [appt] });
        }
      }
      return map;
    }, new Map<number, IPractitionerAppointments>());
    return grouped;
  }
}

export { PractitionerHelper }