import React, { useState } from "react";
import { Formik, Form } from "formik";
import { FieldWithError } from "components/common";
import { validationMap, Validator } from "schemas";
import { patientAccountService } from "services";
import { useParams } from "react-router";

const ResetPassword: React.FunctionComponent = () => {
  const [isComplete, setIsComplete] = useState<Boolean>(false);
  const { authID } = useParams();

  const initialValues: any = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = validationMap.get(Validator.RESET_PASSWORD_FORM);

  const onSubmit = async (data: any, actions: any) => {
    // @TODO: change to AccountProvider
    const { setSubmitting, setStatus } = actions;
    setSubmitting(true);
    const payload = {
      authId: authID || "",
      password: data.password,
      repeatPassword: data.confirmPassword,
    };
    try {
      await patientAccountService.resetPassword(payload);
      setIsComplete(true);
    } catch (err) {
      setSubmitting(false);
      setStatus(`Password reset failed: ${err}`);
    }
  };

  return (
    <div className="container-md">
      <div className="row justify-content-center">
        <div className="col col-md-4">
          <h2 className="text-center text-primary">Reset Your Password</h2>
          {isComplete ? (
            <div className="text-center my-5 small-text-2">Your password has been succesfully reset.</div>
          ) : (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ isSubmitting, errors, touched, status, setSubmitting }) => {
                return (
                  <Form className="form">
                    <div className="container my-3">

                      <div className="row justify-content-center">
                        <div className="col">
                          <FieldWithError
                            errors={errors}
                            touched={touched}
                            fieldName="password"
                            placeholder="Enter Password"
                            type="password"
                            label="Password"
                          />
                        </div>
                      </div>

                      <div className="row justify-content-center my-2">
                        <div className="col">
                          <FieldWithError
                            errors={errors}
                            touched={touched}
                            fieldName="confirmPassword"
                            placeholder="Enter Password Again"
                            label="Confirm Password"
                            type="password"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center my-2">
                      <div className="col text-center">
                        <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                          Confirm
                        </button>
                      </div>
                    </div>

                    {status && (
                      <div className="row justify-content-center">
                        <div className="col">
                          <div className="alert">{status}</div>
                        </div>
                      </div>
                    )}
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export { ResetPassword };
