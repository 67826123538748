import React, { useReducer, useState } from "react";
import { IMessengerContext, IMessage, MessengerContext } from ".";
import { ModalMessage } from './ModalMessage';

interface IMessengerState {
  message: IMessage;
}

enum MessengerAction {
  SET = 1,
  CLEAR = 2,
}

const messageReducer = (state: any, action: any) => {
  switch (action.type) {
    case MessengerAction.CLEAR:
      return {
        message: "",
      };
    case MessengerAction.SET:
      return {
        message: action.payload.message,
      };
    default:
      throw Error("Invalid action in MessageReducer");
  }
};

const MessengerProvider: React.FunctionComponent<{ children: any }> = ({ children }) => {
  const defaultMessengerState: IMessengerState = {message: {} as IMessage};

  const [show, setShow] = useState<boolean>(false);
  const [messengerState, dispatchMessengerAction] = useReducer(messageReducer, defaultMessengerState);

  const setMessageHandler = (message: IMessage) => {
    dispatchMessengerAction({ type: MessengerAction.SET, payload: { message } });
    setShow(true);
  };

  const clearMessageHandler = () => {
    dispatchMessengerAction({ type: MessengerAction.CLEAR, payload: { message: {} as IMessage } });
  };

  const messengerContext: IMessengerContext = {
    message: messengerState.message,
    setMessage: setMessageHandler,
    clearMessage: clearMessageHandler,
  };

  return (
    <MessengerContext.Provider value={messengerContext}>
      <ModalMessage show={show} handleClose={() => setShow(false)} message={messengerState.message} />
      {children}
    </MessengerContext.Provider>
  );
};

export { MessengerProvider };
