import { StorageKey } from "_constants";


const useLandingPage = (): {getLandingPage: () => string, setLandingPage: () => void  } => {

  const entryPages: Map<string, string> = new Map<string, string>([
    ['/colon-cancer-test-kit/free', 'Free Kit (Form)'],
    ['/colon-cancer-test-kit/free-with-appointment', 'Free Kit (Appt)'],
    ['/colon-cancer-test-kit/free-kit', 'Free Kit (No Form)'],
    ['/home-page-doctors', 'Home Page With Docs'],
    ['/home-page-doctors-2', 'Home Page With Docs - 2'],
    ['/colon-cancer-test-kit/free-kit-2', 'Free Kit (No Form) - 2'],
    ['/home-subscription', 'Home Page - Subscription'],
  ])

  const setLandingPage = (): void => {
    localStorage.setItem(StorageKey.LANDING_PAGE, window.location.pathname);
  }

  const getLandingPage = (): string  => {
    const landingPage = localStorage.getItem(StorageKey.LANDING_PAGE);
    if (!landingPage) return '';

    const pageName = entryPages.get(landingPage);
    if (!pageName) return '';

   return `${pageName} | `;
  }

  return { getLandingPage, setLandingPage }

}

export { useLandingPage }