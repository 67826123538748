import React, { useContext } from 'react';
import { AppointmentConfirmation } from 'components/patient/scheduler/AppointmentConfirmation';
import { AppointmentContext } from 'components/patient/scheduler/AppointmentContext';
import { IPractitioner, ITimeSlot } from 'interfaces';
import { Helmet } from 'react-helmet';
import { useTapNative } from '_hooks/useTapNative';
import { trackingService } from 'services';
import { useLandingPage } from '_hooks/useLandingPage';


const ConfirmedPage: React.FunctionComponent = () => {

  const landingPage = useLandingPage();


  const googlePixel = (): JSX.Element => {
    trackingService.fireGoogleEvent({
      action: `${landingPage.getLandingPage()}Make Appointment | Confirmed Appointment`,
      category: 'Make Appointment',
      label: 'method',
    });
    return <></>
  }

  const apptContext = useContext(AppointmentContext);
  const tapNative = useTapNative();

  return <>
    {!apptContext?.appointment.practitioner && 
      <div className="alert alert-warning">Empty Practitioner</div>
    }
    {apptContext && apptContext.appointment.practitioner &&
      <>
        {googlePixel()}
        
        <Helmet>
          <script data-obct type="text/javascript">{`
        obApi('track', 'Checkout');
        `}
          </script>
        </Helmet>

        <AppointmentConfirmation practitioner={apptContext?.appointment.practitioner || {} as IPractitioner} timeslot={apptContext?.appointment.timeslot || {} as ITimeSlot} />

        <div className="d-none">
          <img src={tapNative.getConversionPixelUrl("17251")} alt="" />
        </div>
      </>
    }
  </>;
}

export { ConfirmedPage }