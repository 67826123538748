import { DoctorCarousel, VirtualVisitInfo } from 'components/common/PractitionerListingPage';
import React, { useEffect, useState } from 'react';
import { trackingService } from 'services';
import { InsuranceCompanyEnum } from '_constants/InsuranceCompanyEnum';
import * as Icon from 'react-bootstrap-icons';
import { IMessage, ModalMessage } from 'components/common/messenger';
import { HowToVideo } from './HowToVideo';
import { JumbotronRowGreen } from './JumbotronRowGreen';
import { useUnload } from '_hooks/useUnload';

const Message: React.FunctionComponent<{ onClickBook: () => void }> = ({ onClickBook }): JSX.Element => {

  return <>

      <div className="small-text-3 my-3 fw-bold">
      Get a FREE At-home colon cancer screening and virtual preventive health visit with our doctors if you live in New Jersey and have one of the following health insurance carriers: <span className="text-primary">Horizon BC/CS, United HealthCare, Cigna, Medicare Part B.</span>
      </div>

      <VirtualVisitInfo isLarge={false} />

  </>
}

const FreeKitNoFormPage2: React.FunctionComponent = () => {

  const EVENT_CATEGORY = 'Free Kit (No Form) 2';
  const [showVideo, setShowVideo] = useState<boolean>(false);

  useUnload( (e: Event) => {
    e.preventDefault();
    trackingService.fireGoogleUnload({category: EVENT_CATEGORY, label: 'exit page' });
  })

  useEffect(() => {
    document.title = 'Convena Care At-Home Colon Cancer Testing';
  }, [])

  const onClickShowVideo = () => {
    setShowVideo(true);
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Show Video`,
      category: `${EVENT_CATEGORY}`,
      label: ' Video: How The Test Is Done',
    });
  }

  const onClickBook = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Book A Virtual Visit`,
      category: `${EVENT_CATEGORY}`,
      label: 'book a virtual visit',
    });
  }

  const trackEvent = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Appointment Slot`,
      category: `${EVENT_CATEGORY}`,
      label: 'Select Time Slot',
    });
  }

  return (
    <>
      <ModalMessage show={showVideo} handleClose={() => { setShowVideo(false) }} message={
        {
          title: 'FIT Test - How To Video',
          body: <HowToVideo />,
        } as IMessage} />

      <div className="bg-lighterblue rounded">
        <div className="container">
          <JumbotronRowGreen />
          <div className="medium-text-2 fw-bold text-green my-2 my-md-0">Colon cancer is treatable when diagnosed early. Get Checked Now.</div>
          <div className="row">
            <Message onClickBook={onClickBook} />
          </div>
        </div>
      </div>
      <div id="book-now" className="fw-bold dark-blue medium-text-1 my-3 text-center">Book a Virtual Visit with a Physician Below:</div>
      <DoctorCarousel insuranceId={InsuranceCompanyEnum.HORIZON_BCBS.toString()} trackEvent={trackEvent} />

      <div className="text-blue fw-bold small-text-3 show-video my-3">
          <div className="d-flex flex-column align-items-center">
            <div className="thumb">
              <div role="button" onClick={() => onClickShowVideo()}  >
                <img src="/images/how-the-test-is-done-thumb.png" alt="how the test is done" />
                <div className="play"><Icon.PlayCircle className="play" /></div>
              </div>
            </div>
            <div role="button" onClick={() => onClickShowVideo()}>
              <Icon.CameraVideo /> Video: How The Test Is Done
            </div>
          </div>
        </div>

    </>
  );
}

export { FreeKitNoFormPage2 }