import React, { useContext, useEffect, useState } from 'react';
import { IAppointmentResponseItem } from 'components/patient/appointments';
import { IPractitioner } from 'interfaces';
import { adminAppointmentService } from 'services/AdminAppointmentService';
import { Calendar } from 'components/practitioner/scheduler/Calendar';
import { AppointmentStatusEnum, appointmentStatusNames } from '_constants/AppointmentStatusEnum';
import { TimeSlotButtons } from 'components/practitioner/scheduler/TimeSlotButtons';
import { Helper } from '_utils';
import { MessengerContext } from 'components/common/messenger';
import { ITimeRange } from 'components/practitioner/scheduler/ITimeRange';
import { ToggleStateEnum } from 'components/practitioner/scheduler/ToggleStateEnum';


const AdminPractitionerCalendar: React.FunctionComponent<{ practitioner: IPractitioner }> = ({ practitioner }) => {

  const [appts, setAppts] = useState<IAppointmentResponseItem[]>([]);
  const [appt, setAppt] = useState<IAppointmentResponseItem | undefined>(undefined);
  const [showTimeForm, setShowTimeForm] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentAppts, setCurrentAppts] = useState<IAppointmentResponseItem[]>([]); // appointments only for the day
  const msgrContext = useContext(MessengerContext);

  useEffect(() => {
    const _load = async () => {
      try {
        const appts: IAppointmentResponseItem[] = await adminAppointmentService.getAppointments();
        const filtered = appts.filter((appt: IAppointmentResponseItem) => appt.practitioner.id === practitioner.id && [
          AppointmentStatusEnum.CANCELED,
          AppointmentStatusEnum.FINISHED,
          AppointmentStatusEnum.CANCELLING].indexOf(appt.statusId || 0) < 0 );

        console.log({practitioner, filtered});
        setAppts(filtered);

      } catch (error: any) {
        console.error({ error });
        throw Error((error as Error).message);
      }
    }
    _load();
  }, [practitioner, currentAppts])

  const onAppointmentClick = (appt: IAppointmentResponseItem) => {
    setAppt(appt);
    setShowTimeForm(false);
  }

  const onDateClick = (date: Date) => {
    const currentAppts = appts.filter((appt: IAppointmentResponseItem) => Helper.isInDay(new Date(appt.start * 1000), new Date(appt.end * 1000), date));
    setCurrentAppts(currentAppts);
    setCurrentDate(date);
    setShowTimeForm(true);
    setAppt(undefined);
  }

  const onClickSave = async (timeRange: ITimeRange[]) => {
    const availableSlots = timeRange.filter((t: ITimeRange) => t.status === ToggleStateEnum.AVAILABLE).map((t: ITimeRange) => ({ start: t.start, end: t.end, price: t.price }));
    const slotsToRemove = timeRange.filter((t: ITimeRange) => t.status === ToggleStateEnum.OPEN);

    slotsToRemove.forEach(async (t: ITimeRange) => {
      if (t.appointment && t.appointment.id) {
        await adminAppointmentService.deleteAppointment(t.appointment.id);
      }
    })

    if (availableSlots.length > 0) {
      try {
        if (practitioner) {
          await adminAppointmentService.addAppointmentSlots(availableSlots, practitioner.id);
        } else {
          throw Error('Invalid practitioner');
        }
      } catch (error: any) {
        console.error(error);
        msgrContext.setMessage({ title: "Error", body: `Could not save. Error: ${(error as Error).message}` });
      }
    }

    const currentAppts = appts.filter((appt: IAppointmentResponseItem) => Helper.isInDay(new Date(appt.start * 1000), new Date(appt.end * 1000), currentDate));
    setCurrentAppts(currentAppts);
    //msgrContext.setMessage({title: "Availability", body: "Availability Saved."})    
    setShowTimeForm(false);
  }

  const onClickMakeNotAvailable = async (appt: IAppointmentResponseItem) => {
    try {
      await adminAppointmentService.updateAppointmentStatus(appt.id, AppointmentStatusEnum.SCHEDULE_PRACTITIONER_NOT_AVAILABLE);
      msgrContext.setMessage({ title: "Slot Update", body: "Appointment Made Unavailable" });
    } catch (error: any) {
      console.error(error);
      msgrContext.setMessage({ title: "Update error", body: `Could not set slot to Unavailable. Error: ${(error as Error).message}` })
    }
  }

  const appointmentDetail = (appt: IAppointmentResponseItem): JSX.Element => {
    return (
      <div className="container">
        <div>Appointment:
          <div className="fw-bold">{new Date(appt.start * 1000).toLocaleString()}</div>
        </div>
        <div className="my-2">Status: {appointmentStatusNames.get(appt.statusId || 0)}</div>
        {appt.reasonText && <div>Reason: {appt.reasonText}</div>}
        {appt.statusId !== AppointmentStatusEnum.OPEN && <div className="my-2 fw-bold">Practitioner:
          <div>
            Dr. {appt.practitioner.firstName} {appt.practitioner.lastName}
          </div>
        </div>
        }
        {appt.user && <div className="my-2 fw-cold">Patient:
          <div>
            Patient ID: {appt.user?.id || ''}<br />
            Name: {appt.user?.firstName} {appt.user?.lastName}
          </div>
        </div>
        }

        {appt.statusId === AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE &&
          <div className="d-inline-flex">
            <button type="button" className="btn btn-primary" onClick={() => onClickMakeNotAvailable(appt)}>Set Slot To Not Available</button>
            {/* <button type="button" className="btn btn-primary mx-2">Confirm Appointment</button>
            <button type="button" className="btn btn-link mx-2">Cancel Appointment</button> */}
          </div>
        }

        {appt.statusId === AppointmentStatusEnum.OPEN &&
          <div className="d-inline-flex">
            <button type="button" className="btn btn-primary">Delete This Slot</button>
          </div>
        }
      </div>
    );
  }

  return (
    <div className="container-fluid g-0">
      <div className='practitioner-layout-section-title'>Availability Manager</div>
      <div className="row">
        <div className="col">
          <Calendar appts={appts} onAppointmentClick={onAppointmentClick} onDateClick={onDateClick} />
        </div>
        <div className="col">
          {appt &&
            <>
              <div className="medium-text-2 mt-4">Details</div>
              <>{appointmentDetail(appt)}</>
            </>
          }

          {showTimeForm && currentDate &&
            <>
              <div className="medium-text-2 mt-4">{currentDate.toDateString()} Time Slots</div>
              <TimeSlotButtons calendarDate={currentDate} appts={currentAppts} onClickSave={onClickSave} />
            </>
          }
        </div>
    </div>
    </div>
  );
}

export { AdminPractitionerCalendar }