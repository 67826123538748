import { useEffect, useRef } from "react"

const useUnload = (fn: any) => {
  const cb = useRef(fn);
  useEffect(()=>{
    // console.log('in useUnload useEffect');
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);

    return () => {
      // console.log('unloading from useUnload');
      window.removeEventListener('beforeunload', onUnload);
    }
  }, [cb])
}

export { useUnload }