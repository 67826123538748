import { ErrorMessage } from "formik";
import React from "react";
import { DateDropDown, FieldWithError } from "components";
import { Helper } from '_utils'

const DateOfBirth: React.FunctionComponent<{
  errors: any;
  touched: any;
  fieldName: string;
  values: any;
  setFieldValue: any;
  readOnly?: boolean;
  title?: any;
}> = ({
  errors,
  touched,
  fieldName,
  values,
  setFieldValue,
  readOnly,
  title = <div className="title medium-text-1">Date of Birth</div>,
}) => {
  return (
    <>
      {title}
      {readOnly ? (
        <>{Helper.getFormattedDateMDY(new Date(values.dateOfBirth + " 00:00:00"))}</>
      ) : (
        <>
          <div style={{ display: "none" }}>
            <FieldWithError errors={errors} touched={touched} fieldName={fieldName} />
          </div>
          <div className="input-group">
            <div className={"form-control" + (errors && errors[fieldName] && touched && touched[fieldName] ? " is-invalid" : "")}>
              <DateDropDown setValue={(date: string) => setFieldValue(fieldName, date)} selected={values.dateOfBirth} />
            </div>
            {errors && <ErrorMessage name={fieldName} component="div" className="invalid-feedback" />}
          </div>
        </>
      )}
    </>
  );
};

export { DateOfBirth };
