import React, { useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";

import { AccountContext } from "../common";
import { IAccountContext } from "interfaces";
import { Url } from "_constants";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router";

const Navigation: React.FunctionComponent = () => {

  const accountContext: IAccountContext | null = useContext(AccountContext);
  const location = useLocation();
  
  const isMembershipModel = ['/home-subscription', '/subscribe'].indexOf(location.pathname) >= -1;

  return (
    <>
      <Navbar expand="lg" className="navbar fixed-top bg-white main-container pb-0 g-0">
        <div className="container-fluid">
          <Navbar.Brand href={Url.DEFAULT_HOME}><div className="header-logo"><img src="/images/logo.png" alt="Convena Health" /></div></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="justify-content-center w-100">
              {isMembershipModel && 
              <div className="d-sm-inline-block d-md-none">
              <Nav.Link className="menu-item fw-bold" href={Url.PATIENT_SUBSCRIBE}>Join Now</Nav.Link>
              </div>
              }


              <Nav.Link className="menu-item fw-bold" href={Url.ROOT_DOCTOR_LIST}>Appointments</Nav.Link>
              <Nav.Link className="menu-item fw-bold" href={Url.PUBLIC_PHYSICIANS}>Our Physicians</Nav.Link>
              <Nav.Link className="menu-item fw-bold" href="https://25661.portal.athenahealth.com/">Patient Portal</Nav.Link>
              <HashLink
                className="menu-item fw-bold nav-link"
                scroll={(el: any) => el.scrollIntoView({ behavior: 'smooth' })}
                to={'/#faq'}>
                FAQ
              </HashLink>
              <Nav.Link className="menu-item fw-bold" href={Url.PUBLIC_CONTACT}>Contact Us</Nav.Link>
            </Nav>

            {/* collapsed - hab*/}
            <div className="d-sm-inline-block d-xs-inline-block d-md-block d-lg-none d-xl-none">
              <Nav className="justify-content-end w-100 ">
                <div className="menu-item fw-bold"><a href="tel:19088956969" className="telno">(908) 895 6969</a></div>
                {accountContext ?
                  <Nav.Link href={Url.PATIENT_DASHBOARD} className="menu-item fw-bold">My Account</Nav.Link> :
                  <Nav.Link href={Url.PATIENT_LOGIN} className="menu-item fw-bold">Login</Nav.Link>}
              </Nav>
            </div>
          </Navbar.Collapse>

          {/* menu using icons (expanded) */}
          <div className="d-none d-lg-inline-block">
            <Nav className="justify-content-end w-100">
              {isMembershipModel && <Nav.Link href={`${Url.PATIENT_SUBSCRIBE}`} className="mx-3"><div className="btn-cta btn-activate">Join Now</div></Nav.Link>}

              {!isMembershipModel && <Nav.Link href={`${Url.ROOT_DOCTOR_LIST}`} className="mx-3"><div className="btn-cta btn-activate">Book Virtual Visit</div></Nav.Link>}

              {/*<Nav.Link href={Url.PATIENT_CHECKOUT} className="icon icon-cart my-2 mx-1" ><div className="icon-cart"></div></Nav.Link>*/}
              {accountContext ?
                <Nav.Link href={Url.PATIENT_DASHBOARD} className="icon icon-account my-2 mx-1"></Nav.Link> :
                <Nav.Link href={Url.PATIENT_LOGIN} className="icon icon-account my-2 mx-1"></Nav.Link>}
            </Nav>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export { Navigation };
