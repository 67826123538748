import axios from "axios";
import { config } from 'config';
import { StorageKey } from "_constants";

class StorageService {

  constructor(
    protected apiBaseUrl: string,
    protected storageTokenKey: string,
  ) { }

  getToken(): string {
    const token = localStorage.getItem(this.storageTokenKey) || '';
    return token;
  }

  async getAuthHeader(redir?: string): Promise<{headers: {Authorization: string, token: string}}> {

    // check if token is expired
    // if (await this.isTokenExpired()) {
    //   let redirPath = '/login';
    //   if (redir) {
    //     redirPath = `${redirPath}?redir={redir}`
    //   }
    //   window.location.href = redirPath;
    // }

    const header = {headers: {
      Authorization: 'Bearer ' + this.getToken(),
      token: this.getToken()
    }};

    return header;
  }

  async downloadFile(relativePath: string): Promise<string> {
    const url: string = `${this.apiBaseUrl}/storage/download-file`;
    const authHeader: any = await this.getAuthHeader();
    authHeader.responseType = 'blob';
    const item = {filePath: relativePath};
    const response: any = await axios.post<any>(url, item, authHeader);
    const data = new Blob([response.data]);
    return window.URL.createObjectURL(data);
  }

  async uploadImage(file: any, encrypted: boolean = false): Promise<string> { //@TODO: find the correct data type for file upload
    const url = `${this.apiBaseUrl}/storage/upload-file-tmp?encrypt=${!!encrypted ? 1 : 0}`;
    return await this.postFile<string>(url, { file: file });
  }

  async uploadPublicImage(file: any, encrypted: boolean = false): Promise<string> { //@TODO: find the correct data type for file upload
    const url = `${this.apiBaseUrl}/storage/upload-file-tmp?encrypt=${!!encrypted ? 1 : 0}&scope=public`;
    return await this.postFile<string>(url, { file: file });
  }

  async uploadJson(data: any): Promise<string> {
    const url = `${this.apiBaseUrl}/storage/upload-nft-json`;
    return this.post<string>(url, {data});
  }



  async post<T>(url: string, item: any): Promise<T> {

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.getToken(),
        token: this.getToken(),
      }
    };

    try {
      const response: any = await axios.post<T>(url, item, options);
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.filePath as T;
    } catch (error: any) {
      console.error('error posting json', {error});
      throw Error(error.message);      
    }
  }

  async postFile<T>(url: string, item: any): Promise<T> {
    const formData = new FormData();
    formData.append('file', item.file);

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + this.getToken(),
        token: this.getToken(),
      }
    };

    try {
      const response: any = await axios.post<T>(url, formData, options);
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      return response.data.filePath as T;
    } catch (error: any) {
      console.error('error posting file', {error});
      throw Error(error.message);
    }
  }
}


const apiBasePath: string = `${config.apiDomain}${config.apiBasePath}`;
const patientStorageService = new StorageService(`${apiBasePath}/practitioner`, StorageKey.PRACTITIONER_TOKEN);
const adminStorageService = new StorageService(`${apiBasePath}/admin`, StorageKey.ADMIN_TOKEN);

export { StorageService, patientStorageService, adminStorageService }