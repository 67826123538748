import React from "react";
import { ErrorMessage, Field } from "formik";
import { IKeyValue } from "interfaces";

const DropDown: React.FunctionComponent<{
  errors: any;
  touched: any;
  enumMap: Map<number, string> ;
  defaultItem?: IKeyValue<number>;
  label: string | JSX.Element;
  name: string;
  [key: string]: any;
}> = ({ errors, touched, enumMap, label, name, defaultItem, ...rest }) => {
  return (
    <div className="form-group">
      {label && 
       <>{label}</>
      }

      {!label && 
      <label>Select Insurance Provider</label>
    }
      <Field
        name={name}
        as="select"
        className={"form-control" + (errors && errors[name] && touched && touched[name] ? " is-invalid" : "")}
        {...rest}
      >
        {defaultItem && <option value={defaultItem.key}>{defaultItem.value}</option>}

        {Array.from(enumMap.keys()).map((key: number, i: number) => (
          <option key={`code_${i}`} value={key}>
            {enumMap.get(key)}
          </option>
        ))}
      </Field>
      {errors && <ErrorMessage name={name} component="div" className="invalid-feedback" />}
    </div>
  );
};

export { DropDown };
