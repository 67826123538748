import React from 'react';
import { Modal } from "react-bootstrap";
import { IMessage } from './IMessage';

const ModalMessage: React.FunctionComponent<{ show: any; handleClose: any; message: IMessage; }> = ({
  show,
  handleClose,
  message,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header>
        {message.title && <Modal.Title id="contained-modal-title-vcenter">{message.title}</Modal.Title>}
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-md-12">{message.body}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { ModalMessage }