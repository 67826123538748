import { AccountContext } from 'components/common';
import React, { useContext } from 'react';

const Profile:React.FunctionComponent = () => {
  const acctContext = useContext(AccountContext);

  return (
    <>
    <h2>Account Profile</h2>

    <pre>{JSON.stringify(acctContext?.account, null, 2)}</pre>
    </>
  );
}

export { Profile as AdminProfile }