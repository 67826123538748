import React, { useEffect, useState } from 'react';
import { PractitionerHelper } from 'components/common/PractitionerListingPage/Helper';
import { Modal } from 'react-bootstrap';
import { IAppointment, IPractitioner, IPractitionerAppointments, ITimeSlot } from 'interfaces';
import { publicApiService } from 'services';
import { AppointmentDeck } from 'components/common/PractitionerListingPage/AppointmentDeck';

interface IProps { 
  show: any, 
  handleClose: any, 
  children: any, 
  title: string,
  insuranceCompanyId: any,  // had to change to "any" type. When "numeric", filters passes it as string and causes an http 500 error.
  onAppointmentClick:  (timeslot: ITimeSlot, practitioner: IPractitioner) => void
}

const ModalAppointmentPicker: React.FunctionComponent<IProps> = ({ show, handleClose, children, title, insuranceCompanyId, onAppointmentClick }) => {

  const [appts, setAppts] = useState<Map<number, IPractitionerAppointments>>(new Map<number, IPractitionerAppointments>());

  useEffect(()=>{
    const filters = {
      insuranceCompanyId: parseInt(insuranceCompanyId, 10),
      appointmentStart: new Date().getTime() / 1000,
    }
    console.log({filters});
    const _load = async () => {
      const appts: IAppointment[] = await publicApiService.getAppointments(filters);
      setAppts(PractitionerHelper.groupAppointmentsByDoctor(appts));
    }
    _load();

  }, [insuranceCompanyId]);


  return (
    <Modal scrollable={true} show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="text-primary">{title}</Modal.Title>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        <>
        {children}
        {Array.from(appts.values()).length === 0 ? (<div className="alert alert-danger">We are sorry but there are no doctors available who accepted your insurance.</div>) : (
          <AppointmentDeck appointments={appts} insuranceId={insuranceCompanyId} onAppointmentClick={onAppointmentClick}/>
        )}
        </>
        
      </Modal.Body>
    </Modal>
  );
}

export { ModalAppointmentPicker }