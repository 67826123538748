import React, { useContext, useState } from 'react';
import { EntryPageContext } from './EntryPageContext';
import { IEntryPage } from './IEntryPage';
import { IEntryPageContext } from './IEntryPageContext';

const EntryPageProvider: React.FunctionComponent<{children: any}> = ({children}) => {

  const initialValue: IEntryPage = {
    title: 'title',
    url: 'url',
  }
  const [ entryPage, setEntryPage ] = useState<IEntryPage | null>(initialValue);
  const entryPageContext: IEntryPageContext = {
    entryPage,
    setEntryPage
  }
  return <EntryPageContext.Provider value={entryPageContext}>{children}</EntryPageContext.Provider>
}

const useEntryPage = () => {
  const entryPageContext = useContext(EntryPageContext);
  return {
    entryPage: entryPageContext?.entryPage,
    setEntryPage: entryPageContext?.setEntryPage,
  }
}

export { EntryPageProvider, useEntryPage }