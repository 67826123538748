import { IUserActionLog } from 'interfaces/IUserActionLog';
import React from 'react';
import { Helper } from '_utils';


const UserLog: React.FunctionComponent<{data: IUserActionLog[]}> = ({data}) => {


  const parseData = (data: any): JSX.Element => {
    const _data = Object.entries(data).map((entry) => entry )
    return <>
      <ul>
      {_data.map((item: [key: string, value: any]) => {
          const [key, value] = item;
          if (key === 'path') {
            const path = value.split("?");
            if (path.length > 1) {
              return <li>path: {path[0]} : {parseData(Object.assign({}, path[1].split("&")))}</li>
            }
            return <li>path: {path[0]}</li>
          }
          return <li>{key}: {typeof value === 'object' ? parseData(value) : value}</li>
      })}
      </ul>
    </>
  }

  
return(
  <>
  <div className='practitioner-layout-section-title'>User Logs</div>

  {data.length > 0 &&
  <table className="table table-striped">
  <thead>
    <tr>
      <th>Creation Timestamp</th>
      <th>URL</th>
      <th>Data</th>
      <th>OS</th>
      <th>IP Forwarded For</th>
      <th>Unique User ID</th>
      <th>User Agent</th>
    </tr>
  </thead>

  <tbody>
    {data.map( (item: IUserActionLog, i: number ) => <tr key={`log-key-${i}`}>
      <td>{Helper.getLocalDateTimeFromUTC(new Date(item.creationTimestamp))}</td>
      <td>{item.urlPath}</td>
      {/* <td style={{maxWidth: "400px", overflow: "auto"}}><pre>{JSON.stringify(item.data, null, 2)}</pre></td> */}
      <td>
        <>
        {/* {item.data && Object.entries(item.data) && Object.keys(item.data).map((entry)=> <div>{entry}</div>)} */}
        {parseData(item.data)}
        </>
      </td>
      <td>{item.os}</td>
      <td>{item.ipForwarded4}</td>
      <td>{item.fingerprint}</td>
      <td style={{wordBreak: 'break-word'}}>{item.userAgent}</td>
    </tr>
    )}
  </tbody>

  </table>
  }


</>  
);
}

export { UserLog }