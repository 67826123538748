import React from 'react';
import { YesNoField } from 'components/common/form';
import { Form, Formik } from 'formik';
import { validationMap, Validator } from 'schemas';
import { InsuranceCompanyDropDownNoSelfPay } from 'components/common/form/InsuranceCompanyDropDownNoSelfPay';
import { InsuranceCompanyEnum } from '_constants/InsuranceCompanyEnum';
import { trackingService } from 'services';
import { useUniqueUserId } from '_hooks/useUniqueUserId';

const QualifyForm: React.FunctionComponent<{ onSubmit: any }> = ({ onSubmit }) => {

  const initialValues = {
    age: undefined,
    insuranceCompanyId: undefined,
    preventiveVisit: undefined,
    selfIsPatient: undefined,

  }

  const _onSubmit = async (data: any, actions: any) => {

    const uniqueUserId = useUniqueUserId();

    await trackingService.fireConvenaTracking(window.location.href, data, uniqueUserId);

    const isQualified: boolean = (data.age === '1' &&
      data.insuranceCompanyId !== InsuranceCompanyEnum.OTHER.toString() &&
      data.preventiveVisit === '1');

    onSubmit(isQualified);

  }

  return (
    <Formik onSubmit={_onSubmit} initialValues={initialValues} validationSchema={validationMap.get(Validator.FREE_KIT_QUALIFIED_FORM)}>
      {({ values, errors, touched, status, isSubmitting }) => {
        return <Form>
          <div className="d-flex flex-column">
            <div className="my-3">
              <YesNoField errors={errors} label={<div className="fw-bold">Are you the patient?</div>} touched={touched} name="selfIsPatient" />
              {values['selfIsPatient'] === "0" &&
                <div className="alert alert-warning mt-2">Please make sure you provide the patient's information in the questions below.</div>
              }
            </div>

            <YesNoField className="my-3" errors={errors} label={<div className="fw-bold">Are you 45 years old or older?</div>} touched={touched} name="age" />

            <InsuranceCompanyDropDownNoSelfPay errors={errors} touched={touched} label={<div className="fw-bold">Select Your Insurance Provider</div>} name="insuranceCompanyId" />

            <YesNoField className="my-3" errors={errors} label={<div className="fw-bold">Has it been more than 12 months since your last preventive visit?</div>} touched={touched} name="preventiveVisit" />

          </div>

          <button type="submit" className="btn btn-primary bg-blue" disabled={isSubmitting}>Submit</button>

        </Form>
      }}

    </Formik>
  );

}

export { QualifyForm }