import React, { useEffect } from 'react';
import "app.scss";
import "react-bootstrap-icons/dist/index";
import { Outlet } from 'react-router-dom';
import { PublicLayout } from './components/common';
import { patientAccountService, patientAuthenticationService } from 'services';
import { AccountProvider } from 'components/common/AccountProvider';
import { MessengerProvider } from 'components/common/messenger';
import { AppointmentProvider } from 'components/patient/scheduler/AppointmentProvider';

function App() {
  
  // moved the useEffect to PublicLayout
  // useEffect(() => {

  //   // UA
  //   window.gtag("event", "page_view", {
  //     page_path: window.location.pathname + window.location.search + window.location.hash,
  //     page_search: window.location.search,
  //     page_hash: window.location.hash,
  //     send_to: 'UA-202899508-2',
  //   });

  //   // GA-4
  //   window.gtag("event", "page_view", {
  //     send_to: 'G-5MSCKEXNVE',
  //   });

  // }, []);

  return (
    <MessengerProvider>
      <AccountProvider accountService={patientAccountService} authService={patientAuthenticationService}>
        <AppointmentProvider>
        <PublicLayout>
          <Outlet />
        </PublicLayout>
        </AppointmentProvider>
      </AccountProvider>
    </MessengerProvider>
  );



}

export default App;
