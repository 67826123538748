import { IKeyValue } from 'interfaces';
import React from 'react';
import { practitionerRolesName } from '_constants/PractitionerRolesEnum';
import { DropDown } from 'components/common/form/DropDown';

const RoleDropDown: React.FunctionComponent<
{
  errors: any,
  touched: any,
  label: string,
  name: string,
  [x: string] : any,
}
> = ({errors, touched, label, name, ...rest}) => {

const defaultItem: IKeyValue<number> = {
  key: 0, value: 'Select Role'
}

return <>
    <DropDown
    errors={errors} 
    touched={touched} 
    label={label} 
    name={name}
    defaultItem={defaultItem}
    enumMap={practitionerRolesName} 
    {...rest}/>
  </>
}

export { RoleDropDown }