import React from 'react';
import { Outlet } from 'react-router';

const Body:React.FunctionComponent = () => {

  return (
    <>
    <Outlet />
    </>
  );
}

export { Body as PractitionerBody }