import React, { useState } from 'react';
import { IPractitioner } from 'interfaces';
import { SpecialtyEnumName } from '_constants/Specialty';
import { ModalMessage } from '../messenger';

const PractitionerCardTall: React.FunctionComponent<{
  practitioner: IPractitioner,
  children: any,
  clickableTitle?: boolean,
  about: string,
}> = ({ practitioner, children, about, clickableTitle = false }) => {

  const [showAbout, setShowAbout] = useState<boolean>(false);

  return (
    <>

      <div className="practitioner-card margin-center">
        <div className='practitioner-card-title'>Dr. {practitioner.firstName} {practitioner.lastName}, {practitioner.degree}</div>
        {clickableTitle &&
          <div className='practitioner-card-bio-link' onClick={() => setShowAbout(clickableTitle && true)} style={clickableTitle ? { cursor: "pointer" } : {}}>Bio</div>
        }
        <div className="practitioner-card-specialty">{SpecialtyEnumName.get(practitioner.specialityId || 0)}</div>
        <div className="practitioner-card-board-certified">Board Certified</div>
        {clickableTitle &&
          <div className="practitioner-card-avatar" onClick={() => setShowAbout(clickableTitle && true)} style={clickableTitle ? { cursor: "pointer" } : {}}><img src={practitioner.avatarUrl || '/images/doc-icon.png'} alt="Doctor" className="rounded" /></div>
        }

        {!clickableTitle &&
          <div className="practitioner-card-avatar"><img src={practitioner.avatarUrl || '/images/doc-icon.png'} alt="Doctor" className="rounded" /></div>
        }
        <hr />
        <div className="practitioner-card-children">
          {children}
        </div>
      </div>

      {showAbout &&
        <ModalMessage show={showAbout} handleClose={() => setShowAbout(false)} message={
          {
            title: `About Dr. ${practitioner.lastName}`,
            body: <>
              <div className="practitioner-card-bio-about">
                {about.split("\n").map((p: string) => <p>
                  {p}
                </p>
                )}
              </div>
            </>
          }
        } />
      }

    </>
  )
}

export { PractitionerCardTall }