enum InsuranceCompanyEnum {
  HORIZON_BCBS= 2,
  UNITED_HEALTHCARE = 3,
  CIGNA = 4,
  MEDICARE_PART_B = 6,
  SELF_PAY = 98,
  OTHER = 99,
 }

 const insuranceCompanyNames: Map<number, string> = new Map<number, string>([
  // [ InsuranceCompanyEnum.AETNA, 'Aetna' ], 
  [ InsuranceCompanyEnum.CIGNA, 'Cigna' ], 
  [ InsuranceCompanyEnum.HORIZON_BCBS, 'Horizon Blue Cross/Blue Shield' ], 
  [ InsuranceCompanyEnum.MEDICARE_PART_B, 'Medicare Part B' ], 
  [ InsuranceCompanyEnum.UNITED_HEALTHCARE, 'United Healthcare' ], 
  [ InsuranceCompanyEnum.OTHER, 'Other' ], 
  [ InsuranceCompanyEnum.SELF_PAY, 'Self-Pay' ], 
 ]);

 const insuranceCompanyNoOtherSelfPay: Map<number, string> = new Map<number, string>([
  [ InsuranceCompanyEnum.CIGNA, 'Cigna' ], 
  [ InsuranceCompanyEnum.HORIZON_BCBS, 'Horizon Blue Cross/Blue Shield' ], 
  [ InsuranceCompanyEnum.MEDICARE_PART_B, 'Medicare Part B' ], 
  [ InsuranceCompanyEnum.UNITED_HEALTHCARE, 'United Healthcare' ], 
 ]);

 const insuranceCompanyNoSelfPay: Map<number, string> = new Map<number, string>([
  [ InsuranceCompanyEnum.CIGNA, 'Cigna' ], 
  [ InsuranceCompanyEnum.HORIZON_BCBS, 'Horizon Blue Cross/Blue Shield' ], 
  [ InsuranceCompanyEnum.MEDICARE_PART_B, 'Medicare Part B' ], 
  [ InsuranceCompanyEnum.UNITED_HEALTHCARE, 'United Healthcare' ],
  [ InsuranceCompanyEnum.OTHER, 'Other' ]
 ]);


export { InsuranceCompanyEnum, insuranceCompanyNames, insuranceCompanyNoOtherSelfPay, insuranceCompanyNoSelfPay }

