import React, { useState } from 'react';
import { IPractitioner } from 'interfaces';
import { SpecialtyEnumName } from '_constants/Specialty';
import { ModalMessage } from '../messenger';

const PractitionerCardWide: React.FunctionComponent<{
  practitioner: IPractitioner,
  children: any,
  about: string,
  clickableTitle?: boolean,
}> = ({ practitioner, children, about, clickableTitle = false }) => {

  const [showAbout, setShowAbout] = useState<boolean>(false);

  return (
    <>
      <div className="container-fluid g-0 d-flex flex-row practitioner-wide-card">
        <div className="practitioner-wide-card-avatar" onClick={() => setShowAbout(clickableTitle && true)} style={clickableTitle ? {cursor: "pointer"} : {}}>
          <img src={practitioner.avatarUrl || '/images/doc-icon.png'} alt="Doctor" className="rounded" />
        </div>
        <div className="flex-fill">
          <div className="practitioner-wide-card-specialty">{SpecialtyEnumName.get(practitioner.specialityId || 0)}</div>
          <div className='practitioner-wide-card-title'>Dr. {practitioner.firstName} {practitioner.lastName}, {practitioner.degree}</div>
          <div className="practitioner-wide-card-board-certified">Board Certified</div>
          <div className="practitioner-wide-card-children">
          {children}
          </div>
        </div>
      </div>
      
      {showAbout && 
        <ModalMessage show={showAbout} handleClose={() => setShowAbout(false)} message={
          {
            title: `About Dr. ${practitioner.lastName}`,
            body: <>
              <div className="practitioner-card-bio-about">
                {about.split("\n").map((p: string) => <p>
                  {p}
                </p>
                )}
              </div>
            </>
          }
        } />
      }

    </>
  )
}

export { PractitionerCardWide }