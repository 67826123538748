import axios from "axios";
import { config } from "config";
import { IAccount, IUser } from "interfaces";
import { adminAuthenticationService, AuthenticationService, patientAuthenticationService, practitionerAuthenticationService } from "./AuthenticationService";

class AccountService {
  constructor (
    protected apiBaseUrl: string,
    protected authenticationService: AuthenticationService
  ) {}

  async register(user: IUser): Promise<any> {
    const url = `${this.apiBaseUrl}/registration`;
    try {
      const response: any = await axios.post(url, user);
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      this.authenticationService.setToken(response.data.token);
      await this.authenticationService.setLocalAccount();
      return this.authenticationService.getLocalAccount();
    } catch (error: any) {
      console.error('error', (error as Error).message);
      throw( (error as Error).message);
    }
  } 

  isLoggedIn(): boolean {
    return this.authenticationService.getLocalAccount() !== null;
  }

  getAccount(): IAccount | null {
    return this.authenticationService.getLocalAccount();
  }

  async updateAccount(accountData: IAccount): Promise<any> {
    const token = this.authenticationService.getToken();
    if (token === '' || token === null) {
      throw Error('Cannot update without a token');
    }
    const url: string = `${this.apiBaseUrl}/account`;
    try {
      const account = await axios.put(url, accountData, await this.authenticationService.getAuthHeader());
      if (!account.data || account.data.error) {
        throw Error(`error updating: ${account.data.error.message}`);
      }
      await this.authenticationService.setLocalAccount();
      return this.authenticationService.getLocalAccount();
    } catch (err: any) {
      throw Error(err);
    }
  }

  async requestResetPassword(emailAddress: string): Promise<any> {
    const url = `${this.apiBaseUrl}/request-reset-password`;
    const data = {
      email: emailAddress,
      ip: 0,
    }

    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      console.error({err});
      throw Error(err.message);
    }
  }

  async resetPassword(data: {authId: string, password: string, repeatPassword: string}) {
    const url = `${this.apiBaseUrl}/reset-password`;
    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async confirmEmail(data: {authId: string}) {
    const url = `${this.apiBaseUrl}/confirm-email`;
    try {
      const response = await axios.post(url, data);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async registerPhoneNumber(phoneNumber: number, uniqueUserId: string ): Promise<boolean> {
    const url = `${this.apiBaseUrl}/register-phone-number?fg=${uniqueUserId}`;
    try {
      const response: any = await axios.post(url, {phoneNumber});
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return (response as {success:boolean}).success === true;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async checkRegisteredPhoneNumber(phoneNumber: number, phoneNumberCode: string, uniqueUserId: string): Promise<boolean> {
    const url = `${this.apiBaseUrl}/check-registered-phone-number?fg=${uniqueUserId}`;
    try {
      const response: any = await axios.post(url, {phoneNumber, phoneNumberCode});
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      return (response.data.success === true);
    } catch (err: any) {
      throw Error(err.message);
    }
  }



  async verifyMobile(data: {code: string}) {
    const url = `${this.apiBaseUrl}/account/verify-sms-phone-number`;
    try {
      const response = await axios.post(url, data, await this.authenticationService.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      // update local
      await this.authenticationService.setLocalAccount();
      return response;
    } catch (err: any) {
      throw Error(err.message);
    }
  }

  async registrationFreeKit(user: IUser, uniqueUserId: string): Promise<any> {
    const url = `${this.apiBaseUrl}/registration-free-kit?fg=${uniqueUserId}`;
    try {
      const response: any = await axios.post(url, user);
      if (response.data.error) {
        throw Error(response.data.error.message);
      }
      console.log('registrationFreeKit', {response});
    } catch (error: any) {
      console.error('error', (error as Error).message);
      throw( (error as Error).message);
    }
  } 
  
}

const patientAccountService = new AccountService(`${config.apiDomain}${config.apiBasePath}/user`, patientAuthenticationService);
const practitionerAccountService = new AccountService(`${config.apiDomain}${config.apiBasePath}/practitioner`, practitionerAuthenticationService);
const adminAccountService = new AccountService(`${config.apiDomain}${config.apiBasePath}/admin`, adminAuthenticationService);

export { AccountService, patientAccountService, practitionerAccountService, adminAccountService }