import React, { useContext, useState } from 'react';
import { AccountContext, FieldWithError } from 'components/common';
import { Form, Formik } from 'formik';
import { validationMap, Validator } from 'schemas';


const ConfirmMobileForm = () => {

  const initialValues = {
    confirmationCode: '',
  }

  const onSubmit = async (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;
    alert('submitted confirmation');

  }

  return <>

    <div className="small-text-3">Enter the code you received:</div>

    <div className="container">

      <div className="row">
        <div className="col-6">
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationMap.get(Validator.PATIENT_MOBILE_CONFIRMATION)}>
            {({ isSubmitting, errors, touched }) => {
              return (
                    <Form>
                      <FieldWithError fieldName="confirmationCode" errors={errors} touched={touched} label="Confirmation Code" />
                      <button type="submit" className="btn btn-primary mt-2" disabled={isSubmitting}>Confirm</button>
                    </Form>
              );
            }}
          </Formik>

        </div>
        <div className="col-6">
          If you didn't receive the confirmation after a few minutes, click "Resend Confirmation". <button type="button" className="btn btn-primary mt-2" >Resend Confirmation</button>

        </div>
      </div>
    </div>




  </>;
}

const ConfirmMobile = () => {

  const [waiting, setWaiting] = useState<boolean>(false);
  const accountContext = useContext(AccountContext);

  const onTextMeClick = () => {
    setWaiting(true);
  }

  return <div className="container g-0 mb-3 border px-3 pb-3">
    <div className="small-text-3 mt-2 alert alert-danger">Confirm Mobile Number</div>
    <div className="small-text-1">Please be aware that you will not be able to pick an appointment until you confirm your mobile phone number.</div>

    {waiting && <ConfirmMobileForm />}

    {!waiting &&
    <div className="d-flex justify-content-evenly">
      <button type="button" className="btn btn-primary mt-2" onClick={onTextMeClick}>Please text me my confirmation number.</button>
      <button type="button" className="btn btn-primary mt-2" onClick={()=>setWaiting(true)}>I have a confirmation number.</button>
    </div>
    }

  </div>
}

export { ConfirmMobile }