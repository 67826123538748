import { AppointmentReason } from 'components/patient/scheduler/AppointmentReason';
import React from 'react';

const Questions: React.FunctionComponent<{onSuccess: ()=>void}> = ({onSuccess}) => {
  return <>
    <div className="medium-text-1 mt-3 py-3">Reason for Visit</div>
    <AppointmentReason onSuccess={onSuccess}/>
  </>
}

export { Questions }