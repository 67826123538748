enum PractitionerRolesEnum {
  DOCTOR_ROLE       = 1,
  NURSE_ROLE        = 2,
}

const practitionerRolesName: Map<number, string> = new Map<number, string>([
  [PractitionerRolesEnum.DOCTOR_ROLE, 'Medical Doctor'],
  [PractitionerRolesEnum.NURSE_ROLE, 'Nurse/Medical Assistant'],
])


export { PractitionerRolesEnum, practitionerRolesName }