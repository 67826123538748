import axios from "axios";
import { config } from 'config';
import { adminAuthenticationService as authService} from 'services';

class LabelService {
  constructor (protected apiBaseUrl: string) {

  }

  async generateWebOrders(paymentIds: number[], productId: number): Promise<string> {
    const url: string = `${this.apiBaseUrl}/generate-web-orders`;
    return this.post<any>(url, {paymentIds, productId});
  }

  async generateProspects(prospectIds: number[], productId: number): Promise<string> {
    const url: string = `${this.apiBaseUrl}/generate-prospects`;
    return this.post<any>(url, {prospectIds, productId});
  }

  // utility
  async post<T>(url:string, item: object): Promise<T> {
    try {
      const response: any = await axios.post<any>(url, item, await authService.getAuthHeader());
      if (response?.error) {
        throw Error(response.error.message);
      }
      return response.data;
    } catch (error: any) {
      throw Error(error);
    }    
  }  
}

const apiBaseUrl: string = `${config.apiDomain}${config.apiBasePath}/admin/label`;
const adminLabelService = new LabelService(apiBaseUrl);

export { adminLabelService }