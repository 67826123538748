import { IKeyValue } from 'interfaces';
import React from 'react';
import { guarantorRelationshipNames } from '_constants';
import { DropDown } from './DropDown';

const GuarantorRelationshipDropDown: React.FunctionComponent<
{
  errors: any,
  touched: any,
  label: string,
  name: string,
}
> = ({errors, touched, label, name}) => {

const defaultItem: IKeyValue<number> = {
  key: 0, value: 'Select One'
}

return <>
    <DropDown 
    errors={errors} 
    touched={touched} 
    label={label} 
    name={name}
    defaultItem={defaultItem}
    enumMap={guarantorRelationshipNames} />
  </>
}

export { GuarantorRelationshipDropDown }