import React, { useState } from "react";
import { Helper } from "_utils";

const FileUploadError: React.FunctionComponent<{ errors: any; touched: any; name: string }> = ({ errors, touched, name }) => {
  return <div className="error">{errors && errors[name] && touched && touched[name] ? errors[name] : ""}</div>;
};


/**
 * FileUpload - component to upload a single file.
 *  * 
 * @name {string} the name of the component
 * @title {string} The title displayed on the preview render
 * @setFieldValue {} from Formik
 * @errors {}  from Formik
 * @touched {} from Formik
 * @values {} from Formik
 * @targetField {string} the field that will return the path of the uploaded image
 */
const FileUpload: React.FunctionComponent<{
  name: string, 
  title: string;
  setFieldValue: any;
  errors: any;
  touched: any;
  values: any;
  targetField: string;
  readOnly?: boolean;
  preview?: boolean;
  initialUrl?: string; // default view when first loaded
}> = ({ name, title, setFieldValue, errors, touched, values, targetField, initialUrl, readOnly = false, preview = true }) => {

  const [filePreview, setFilePreview] = useState<any>(initialUrl || preview);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setFieldValue(targetField, e.currentTarget.files[0]);
      const uploadedFile = await Helper.getPreviewFile(e.currentTarget.files[0]);
      setFilePreview(uploadedFile);
    }
  }

  return (
    <>
      <div className="form-group">
        <div className="my-2">{title}</div>
        <div className="my-2">
        {preview && filePreview && <img style={{ width: "150px" }} src={filePreview} alt={values[name]} />}
        </div>
        <div className="my-2">
        <input
          id={name}
          name={name}
          type="file"
          className="form-control"
          onChange={onChange}
          readOnly={readOnly}
          disabled={readOnly}
        />
        {errors && <FileUploadError errors={errors} touched={touched} name="image" />}
        </div>
      </div>

    </>
  );
};

export { FileUpload, FileUploadError };
