import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import { useTapNative } from '_hooks/useTapNative';
import { EntryPageProvider } from './EntryPageProvider';
import { Footer } from './Footer';
import { Navigation } from './Navigation';

const PublicLayout: React.FunctionComponent<any> = ({ children }) => {

  useTapNative();

  useEffect(()=>{
    // UA
    window.gtag("event", "page_view", {
      page_path: window.location.pathname + window.location.search + window.location.hash,
      page_search: window.location.search,
      page_hash: window.location.hash,
      send_to: 'UA-202899508-2',
    });

    // GA-4
    window.gtag("event", "page_view", {
      send_to: 'G-5MSCKEXNVE',
    });

    // outbrain
    window.obApi('track', 'PAGE_VIEW');

  },[])

  return (
    <EntryPageProvider>
    <div className="main-container">
      <div className="container-fluid">
        <div className="d-flex flex-column min-vh-100">
          <Navigation />
          <div className="mt-2 mt-md-5 mt-lg-5 mt-xl-5 mt-xxl-5 pt-5"></div>
          <div className="flex-grow-1">
          <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </div>
    </EntryPageProvider>
  );
}

export { PublicLayout }