import React, { useContext } from "react";
import { AccountContext } from "components/common";


const Dashboard: React.FunctionComponent = () => {
  const acctContext = useContext(AccountContext);
  return (
    <>
    
    <div>Admin Dashboard</div>

    You are logged in as {acctContext?.account?.email}
    </>
  );
}

export { Dashboard as AdminDashboard}