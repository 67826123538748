import { IAppointmentResponseItem } from 'components/patient/appointments';
import React, { useEffect, useState } from 'react';
import { AppointmentStatusEnum } from '_constants/AppointmentStatusEnum';
import { Helper } from '_utils';
import { IToggleProp } from './IToggleProp';
import { ToggleStateEnum, toggleStateEnumNames } from './ToggleStateEnum';

interface IProps {
  dateTime: Date, 
  appt?: IAppointmentResponseItem,
  onToggle: (value: IToggleProp)=>void,
  duration: number
}

const TimeSlotButton: React.FunctionComponent<IProps> = ({dateTime, onToggle, duration, appt}) => {

  const getToggleState = () => {
    let status: ToggleStateEnum = ToggleStateEnum.OPEN;
    if (appt) {
      switch (appt.statusId) {
        case AppointmentStatusEnum.SCHEDULE_PRACTITIONER_NOT_AVAILABLE:
          status = ToggleStateEnum.UNAVAILABLE;
          break;
        case AppointmentStatusEnum.PRACTITIONER_PENDING_SMS_RESPONSE:
        case AppointmentStatusEnum.SCHEDULE_PRACTITIONER_CONFIRMED:
          status = ToggleStateEnum.FILLED;
          break;
        case AppointmentStatusEnum.OPEN:
          status = ToggleStateEnum.AVAILABLE;
          break;
        case AppointmentStatusEnum.CANCELLING:
          status = ToggleStateEnum.FILLED;
          break;
        case AppointmentStatusEnum.CANCELED:
        case AppointmentStatusEnum.FINISHED:
        default:
          status = ToggleStateEnum.OPEN;
      }
    }
    return status;
  }

  //const initialToggleState: ToggleStateEnum = getToggleState();
  const [ toggleState, setToggleState ] = useState<ToggleStateEnum>();
  const [ isDeactivateOnly, setDeactivateOnly ] = useState<boolean>(false);
  const [ isDirty, setIsDirty ] = useState<boolean>(false);

  useEffect(()=>{
    setToggleState(getToggleState());
  },[appt]);

  const toggle = (dateTime: Date) => {

    let newToggleState: ToggleStateEnum = ToggleStateEnum.OPEN;
    if (isDeactivateOnly) {
      switch (toggleState) {
        case ToggleStateEnum.UNAVAILABLE: {
          newToggleState = ToggleStateEnum.FILLED;
          setToggleState(ToggleStateEnum.FILLED);
          setIsDirty(true);
          break;
        }
        default: {
          newToggleState = ToggleStateEnum.UNAVAILABLE;
          setToggleState(ToggleStateEnum.UNAVAILABLE);
          setIsDirty(true);
        }
      }
    } else {
      switch (toggleState) {
        case ToggleStateEnum.AVAILABLE:
          newToggleState = ToggleStateEnum.OPEN;
          setToggleState(ToggleStateEnum.OPEN);
          setIsDirty(true);
          break;
        case ToggleStateEnum.FILLED: {
          newToggleState = ToggleStateEnum.UNAVAILABLE;
          setToggleState(ToggleStateEnum.UNAVAILABLE);
          setDeactivateOnly(true);
          setIsDirty(true);
          break;
        }
        case ToggleStateEnum.UNAVAILABLE: {
          newToggleState = ToggleStateEnum.OPEN;
          setToggleState(ToggleStateEnum.OPEN);
          setIsDirty(true);
          break;
        }
        case ToggleStateEnum.OPEN: {
          newToggleState = ToggleStateEnum.AVAILABLE;
          setToggleState(ToggleStateEnum.AVAILABLE);
          setIsDirty(true);
          break;
        }
        default: {
          newToggleState = ToggleStateEnum.UNAVAILABLE;
          setToggleState(ToggleStateEnum.UNAVAILABLE);
          setIsDirty(true);
        }
      }
    }

    onToggle({
      state: newToggleState,
      timestamp: dateTime.getTime(),
      endTimeStamp: dateTime.getTime() + duration,
      isDirty,
      appointment: appt
    }); 
  }

  return (
    <button type="button" className={`btn mx-1 btn-${toggleStateEnumNames.get(toggleState || 0)?.className}`} onClick={()=>{toggle(dateTime)}}>{`${Helper.getFormattedTimeHHMM(dateTime)}`}</button>
  )
}

export { TimeSlotButton }