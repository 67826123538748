import React, { useEffect, useState } from 'react';
import { QualifyForm } from './QualifyForm';
import { QualifySection } from './QualifySection';
import { Url } from '_constants';
import { useNavigate } from 'react-router';
import { trackingService } from 'services/common/TrackingService';
import { useUnload } from '_hooks/useUnload';

const FreeKitApptPage: React.FunctionComponent = () => {

  const EVENT_CATEGORY = 'Free Kit (Appt)';

  enum Stage {
    QUALIFY_FORM = 1,
    DONE = 2,
  }

  const [qualified, setQualified] = useState<boolean>(false);
  const [stage, setStage] = useState<Stage>(Stage.QUALIFY_FORM);
  const navigate = useNavigate();

  useUnload( (e: Event) => {
    e.preventDefault();
    trackingService.fireGoogleUnload({category: EVENT_CATEGORY, label: 'exit page' });
  })

  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [stage])


  const onSubmitQualifyForm = (isQualified: boolean) => {
    setQualified(isQualified);
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Submitted Qualify Form`,
      category: EVENT_CATEGORY,
      label: 'Submit',
    })
    setStage(stage + 1);
  }

  const onClickShowVideo = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Show Video`,
      category: EVENT_CATEGORY,
      label: ' Video: How The Test Is Done',
    });
  }

  const onClickMakeAppointment = () => {
    trackingService.fireGoogleEvent({
      action: `${EVENT_CATEGORY} | Clicked Book Virtual Visit`,
      category: EVENT_CATEGORY,
      label: 'Book Virtual Visit',
    });
    navigate(Url.ROOT_DOCTOR_LIST);
  }


  return (
    <div className="container-fluid g-0 g-md-3 bg-lighterblue pt-3 pb-5">

      {stage === Stage.QUALIFY_FORM &&
        <QualifySection onClickShowVideo={onClickShowVideo}>
          <>
            <div className="my-3 small-text-3 fw-bold">Answer the following questions to find out if you qualify for a FREE Prescription and At-home colon cancer screening test:</div>
            <div className="col-12 col-md-6">
              <div className="mx-5">
                <QualifyForm onSubmit={onSubmitQualifyForm} />
              </div>
            </div>
          </>
        </QualifySection>
      }

      {stage === Stage.DONE && !qualified &&
        <div className="my-3">Regretfully, you are not qualified for a free colon cancer screening kit.</div>
      }

      {stage === Stage.DONE && qualified &&
        <div className="my-3 d-flex flex-column congratulations-section">
          <div className="envelope-with-check margin-center"><img src="/images/envelope-with-check.png" alt="test kit" /></div>
          <p>Great! It looks like your insurance should cover 100% of the cost for a Virtual Visit with us. </p>
          <p className="mb-3">In order to provide your kit, the State of NJ requires a prescription from a physician. Fortunately, this is covered by insurance at no cost to you if your last preventive visit was more than 12 months ago. Click the button below to select an appointment slot, and then register to book an appointment with one of our board-certified, licensed physicians. </p>
          <p>
            <div className="my-3">
              <div role="button" onClick={() => onClickMakeAppointment()} className="nav-link d-inline btn-cta btn-activate">Book Virtual Visit</div>
            </div>
          </p>
        </div>
      }

    </div>
  );
}

export { FreeKitApptPage }