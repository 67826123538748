import React from "react";
import { AddressFields, BaseForm, FieldWithError } from "components/common";
import { IAddressFieldMap, IUser } from "interfaces";
import { practitionerApiService as apiService } from "services";
import { Validator, validationMap } from 'schemas';
import { Helper } from "_utils";

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IUser) => void;
  initialValues: any;
  onCancelClick: () => void;
}

const UserForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick }: IAppProps) => {

  const beforeSubmit = async (data: any, actions: any) => {
    data.phoneNumber = (typeof data.phoneNumber === "string" ? Helper.formatPhoneAsUS(data.phoneNumber) : data.phoneNumber);

    // delete blank and null from payload
    // eslint-disable-next-line array-callback-return
    Object.keys(data).map( (k: string) => {
      if (data[k] === null || data[k] === '') {
        delete data[k]
      }
    })
    const readOnlyFields: string[] = ['statusId','emailConfirmed','passwordSet', 'creationTimestamp'];
    readOnlyFields.forEach( (k: string) => delete data[k]);
  };

  const userFieldMap: IAddressFieldMap = {
    firstName: 'firstName',
    lastName: 'lastName',
    address1: 'address1',
    address2: 'address2',
    city: 'city',
    state: 'state',
    zipcode: 'zipcode',
    phoneNumber: 'phoneNumber',
    dateOfBirth: 'dateOfBirth',
  }

  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.PRACTITIONER_USER_FORM)}
      beforeSubmit={beforeSubmit}
    >
      {({errors, touched, setFieldValue, values}) => {
        return(
          <>
          <div className="container g-0">
          <FieldWithError
            errors={errors}
            touched={touched}
            fieldName="email"
            placeholder="E-Mail Address"
            label="Email Address"
          />
          </div>
          <div className="my-3">
          <AddressFields
            fieldMap={userFieldMap}
            values={values} 
            setFieldValue={setFieldValue} 
            errors={errors} 
            touched={touched} 
            label={<div className="strong">Name and Address</div>} />          
          </div>
          </>
        )
      }}
    </BaseForm>
  );
};


export {UserForm}