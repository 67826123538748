import React from 'react';

const MailingAddressPage:React.FunctionComponent = () => {
  return (
    <>
      Mailing Address
    </>
  );
}

export { MailingAddressPage }