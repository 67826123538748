import { AccountContext } from 'components/common';
import React, { useContext } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { PractitionerRolesEnum } from '_constants/PractitionerRolesEnum';

const RegisteredPatients = (): JSX.Element => {
  return (
    <>
      <div className="medium-text-2 fw-bold mt-3"> Registered Patients</div>

      <div className="my-3">Follow this workflow for patients who first register and schedule themselves at ConvenaCare.com.</div>
      <div className="my-3">When the Patient requests an appointment, the Physician is automatically notified of the request via SMS. Optionally, you may be copied with the request as well.</div>
      <ul>
        <li>Periodically monitor the Appointment Manager of any changes in the appointment. The Physician may confirm or remove that availability.</li>
        <li>If the Physician changes his availability, the status of that appointment will change to <i>"No Longer Available"</i> and the Physician will be automatically asked by the system to confirm the rest of their schedule. If you see this “No Longer Available” status, contact the Patient and reschedule the appointment. Note that you cannot reschedule an appointment until the Physician has confirmed their availability for the rest of the schedule.</li>
        <li>If Physician confirms the appointment, create a Patient record in Athena Health and copy the data from the Convena Care page.</li>
        <li>In Convena Care, click "Patients" in the navigation menu to view the list of Patients.</li>
        <li>Find the Patient you are working with and click on their Patient ID. This should open a window showing the patient's data</li>
        <li>Open another tab in your browser and log on to your Athena Health account</li>
        <li>Register a new patient and copy the data from Convena Care tab or window</li>
      </ul>

      <p className="alert alert-warning">NOTE: the Physician may or may not immediately confirm the appointment. They may be seeing a patient or otherwise busy and unable to confirm. They may also decide to wait until the end of the day to confirm all appointments. If the appointment needs immediate attention, for example, same-day appointments, press the “Send SMS” button to send a reminder to the Physician. If they still do not respond, give them a call</p>

    </>
  );
}


const CallInPatients = (): JSX.Element => {
  return (
    <>
      <div className="medium-text-2 fw-bold mt-3">Call-In Patients</div>
      <p className="my-3">
        Follow this workflow when a patient, who does not have an account in Convena Care, calls in directly and requests to schedule an appointment.
      </p>

      <ul>
        <li>Log on to your Athena Health (AH) portal and create a patient account</li>
        <li>Collect the information required in AH by going through the entire AH workflow to schedule the appointment</li>
        <li>Log on to Convena Care’s Practitioner Portal at <a href="https://www.convenacare.com/practitioner">https://www.convenacare.com/practitioner</a>. </li>
        <li>Click on Patients on the top navigation</li>
        <li>Click on New Patient</li>
        <li>Enter the email address, first and last name, DoB and phone number (copied from the AH portal).</li>
        <li>Locate the Patient record added and click “Add Appointment”</li>
        <li>Select the Physician and Time Slot</li>
        <li>The system notifies the Physician you selected, via SMS, of the appointment. They will need to confirm the appointment before you can proceed.</li>
        <li>Check the Appointment Manager periodically for the Physician’s confirmation. </li>
        <li>If the Appointment time is within the next few hours and the Physician has not responded, click on the “Send SMS” button to send a text reminder to the Physician. If they still do not respond, call them.</li>
      </ul>

    </>
  );
}


const KitActivation = (): JSX.Element => {
  return (
    <>
      <div className="medium-text-2 fw-bold mt-3">Kit Activation</div>
      <p className="my-3">
      Some Patients may receive an at-home FIT test (detects blood in the stool) from Convena that requires activation before use. Patients who receive the kits are instructed to activate them by entering an activation code in the ConvenaCare website. This Activation Code can be viewed from the Appointment Manager page under the “Reason Text” column. It would have something like:

        <div className="d-flex">
          <div className="border my-3 p-3">
            Activating Kit<br />
            RCLC7B7R
          </div>
        </div>
      </p>

      <p>The last line is the Activation Code. </p>
      <p>The state of NJ requires a prescription for this test kit. Patients who request a Tele-health appointment with Convena usually do so with this in mind.</p>
      <p>In Athena Health, you need to enter this code for when the Physician writes a prescription for the kit. It should be noted in the prescription that this is the code of the prescribed kit.</p>
      <p>The results of the Kit will be entered into Athena Health manually by the Convena Lab staff.</p>
      <p>The remainder of “Kit Patients” workflow is the same as other Patients who initially register through the ConvenaCare.com site.</p>

    </>
  );
}


const CancelingAppt = (): JSX.Element => {
  return (
    <>
      <div className="medium-text-2 fw-bold mt-3">Cancelling Appointments</div>
      <p className="my-3">
        There are two way of canceling app
      </p>

      <ul>
        <li><span className="fw-bold">Cancel and Close Slot</span> - Cancel and the appointment and make the slot no longer available for use by patients. This is usually when the Physician’s availability has changed and is no longer available for that Slot.</li>
        <li><span className="fw-bold">Cancel and Re-Open Slot</span> - this cancels the appointment but makes the time slot available for use by patients. This is usually when the Patient cancels the appointment but the Physician is still available for that time slot.</li>
      </ul>

      <p className="my-3">Having trouble canceling? It could be because:</p>
      <ul>
        <li>The system does not allow canceling appointments less than 24 hours before it is scheduled.</li>
        <li>The Physician has not yet confirmed the appointment (status is “Waiting for SMS Confirmation”).</li>
      </ul>

      <p className="alert alert-warning">IMPORTANT: Remember to also reschedule the appointment in Athena.</p>

    </>
  );
}

const ReschedulingAppt = (): JSX.Element => {
  return (
    <>
      <div className="medium-text-2 fw-bold mt-3">Rescheduling Appointments</div>
      <p className="my-3">
        There are two ways of rescheduling appointments:
      </p>

      <ul>
        <li><span className="fw-bold">Reschedule and Close Slot</span> - use this option to reschedule an appointment and make the time slot no longer available. Use this option when the Physician wants to reschedule because they are no longer available for that slot. Patients will not be able to request this slot any more.</li>
        <li><span className="fw-bold">Reschedule and Re-Open Slot</span> - use this option to reschedule the current appointment but keep the time slot open for other patients. Use this option when Patient requests to reschedule the appointment and the Physician is still available for that time slot.</li>
      </ul>

      <p className="alert alert-warning">IMPORTANT: Remember to also reschedule the appointment in Athena.</p>

    </>
  );
}


const ApptStates = (): JSX.Element => {
  return (
    <>
      <div className="medium-text-2 fw-bold mt-3">Appointment States</div>
      <p className="my-3">
        <p>Appointments have various states. They are:</p>
        <ul>
          <li><span className="fw-bold">Available Slot</span> - the initial state of the appointment. </li>
          <li><span className="fw-bold">Pending SMS Confirmation</span> - an SMS has been sent to the Physician and waiting for them to confirm their availability for the appointment.</li>
          <li><span className="fw-bold">Confirmed By Practitioner</span> - the Physician has confirmed their availability for that appointment</li>
          <li><span className="fw-bold">Practitioner No Longer Available</span> - the Physician has rejected the appointment. They are no longer available for that time slot. Nurse will need to coordinate with the patient on a new appointment. Note that when a new appointment is selected, the Physician must still confirm this appointment.</li>
          <li><span className="fw-bold">Completed</span> - the appointment has concluded</li>
          <li><span className="fw-bold">Canceled</span> - the appointment has been canceled.</li>
          <li><span className="fw-bold">Patient Requested to Cancel</span> - the Patient has requested to cancel the appointment. To confirm cancellation, click Cancel and Re-Open timeslot on the appointment row in the Appointment Manager page.</li>
        </ul>
      </p>
    </>
  );
}

const AvailabilityCalendar = (): JSX.Element => {
  return (
    <>
      <div className="medium-text-2 fw-bold mt-3">Availability Calendar</div>
      <p className="my-3">
        Use the Availability Calendar to manage you availability.
      </p>

      <p className="my-3">
        To add or edit your availability, navigate to the date you want to pick the time slots, and click on the date.
        <ul>
          <li>To the right, select the interval: 15, 20 or 30 minutes.</li>
          <li>Click the time slots that you are available. Selected slots are blue. Click it a second time to toggle it off.</li>
          <li>Click Save to save the slots for that day.        </li>
        </ul>
      </p>

      <p className="my-3">
        If a Patient requests one of the time slots, and you are no longer available for that slot:

        <ul>
          <li>Click the Time Slot the Patient requested</li>
          <li>To the right of the calendar, click "No Longer Available". The Nurse will see this change and reschedule the appointment accordingly. To avoid multiple rescheduling attempts, please make sure your availability is up-to-date.</li>
        </ul>
      </p>



    </>
  );
}
const ReferencePage: React.FunctionComponent = () => {

  const acctContext = useContext(AccountContext);

  return (
    <div className="container-fluid g-0">
      <div className='practitioner-layout-section-title'>Documentation and How-Tos</div>
      <div className="container">
        <Tabs id="practitioner-reference">
          {acctContext?.account.roleId === PractitionerRolesEnum.DOCTOR_ROLE &&
            <Tab eventKey="availability-calendar" title="Availability Calendar">
              <AvailabilityCalendar />
            </Tab>
          }
          <Tab eventKey="registered-patient-workflow" title="Registered Patients">
            <RegisteredPatients />
          </Tab>
          <Tab eventKey="call-in-patient-workflow" title="Call-In Patients">
            <CallInPatients />
          </Tab>
          <Tab eventKey="kit-activation" title="Kit Activation">
            <KitActivation />
          </Tab>
          <Tab eventKey="cancel-appointment" title="Canceling Appointments">
            <CancelingAppt />
          </Tab>
          <Tab eventKey="reschedule-appointment" title="Rescheduling Appointments">
            <ReschedulingAppt />
          </Tab>
          <Tab eventKey="appointment-states" title="Appointment States">
            <ApptStates />
          </Tab>
        </Tabs>

      </div>
    </div>
  );
}

export { ReferencePage }