import { FieldWithError } from 'components/common';
import { DateOfBirth } from 'components/common/form/DateOfBirth';
import { Form, Formik } from 'formik';
import React from 'react';

const SearchPatientForm: React.FunctionComponent = () => {
  const initialValues = {
    firstName: '',
    lastName: '',
    dateOfBirth: null,
  }

  const onSubmit = (data: any, actions: any) => {

  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, errors, touched, status, setFieldValue }) => {
        return (
          <Form>
            <div className="container">
              <div className="row">

                <div className="col">
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="firstName"
                    placeholder="First Name"
                    label="First Name"
                  />
                </div>

                <div className="col">
                  <FieldWithError
                    errors={errors}
                    touched={touched}
                    fieldName="lastName"
                    placeholder="Last Name"
                    label="Last Name"
                  />
                </div>

                <div className="col">
                  <DateOfBirth errors={errors} touched={touched} values={values} fieldName="dateOfBirth" setFieldValue={setFieldValue} title={<div>Date of Birth</div>}/>
                </div>
                
              </div>
              
              <button className="btn btn-primary my-2">Search</button>
            </div>



          </Form>
        )
      }}
    </Formik>
  );
}

interface IProps {
  onAdd: () => void,
  onCancel: () => void,
}
const AppointmentForm: React.FunctionComponent<IProps> = ({ onAdd, onCancel }) => {

  return (
    <div className="container">
      <div>New Appointment</div>

      <div>Search for Patient</div>
      <div className="min-vh-50">
      <SearchPatientForm />
      </div>

      <button role="link" className="btn btn-link" onClick={onCancel}>Cancel Adding Appointment</button>
    </div>
  );
}

export { AppointmentForm }