import { IUserActionLog } from 'interfaces/IUserActionLog';
import { useEffect, useState } from 'react';
import { AdminReportApiService } from 'services/AdminReportApiService';
import { PractitionerReportApiService } from 'services/PractitionerReportApiService';



const useUserLog = (apiService: PractitionerReportApiService | AdminReportApiService ): IUserActionLog[] => {
  const [ data, setData ] = useState<IUserActionLog[]>([]);

  useEffect(()=>{
    const _load = async () => {
      const data = await apiService.getUserActionLogs();
      setData(data.sort((a, b) => {return a.creationTimestamp < b.creationTimestamp ? 1 : -1}));
    }
    _load();
  }, [])

  return data
  
}


export { useUserLog }

