import React from "react";
import { adminPractitionerApiService } from "services";
import { ListContainer } from "components/common";
import { PractitionerForm } from ".";
import { FormButtons } from "_constants";
import { SpecialtyEnumName } from "_constants/Specialty";
import { IDataField, IPractitioner } from "interfaces";
import { insuranceCompanyNames } from "_constants/InsuranceCompanyEnum";
import { practitionerRolesName } from "_constants/PractitionerRolesEnum";

const PractitionerList: React.FunctionComponent = () => {

  const emptyValues: any = {
    email: '',
    roleId: 0,
    organization: 'ConvenaCare',
    firstName: '',
    specialityId: 0,
    lastName: '',
    address1: '',
    address2: '',
    phoneNumber: 0,
    city: '',
    country: "USA", //@Todo: Add country field in form
    zipcode: '',
    state: '',
    avatar: '',
    avatarUrl: '',
    about: '',
  };

  const onSelectedRows = (ids: string[]) => {
    //console.log('selected ', ids);
  }

  const getSpecialtyName = (row: IPractitioner): JSX.Element => {
    return <>{SpecialtyEnumName.get(row.specialityId)}</>
  }

  const getRole = (row: IPractitioner): JSX.Element => {
    return <>{practitionerRolesName.get(row.roleId || 0)}</>
  }

  const getAcceptedInsurance = (row: IPractitioner): JSX.Element => {
    return <>{row.insuranceCompanies?.map((insuranceId: number) => insuranceCompanyNames.get(insuranceId)).join(", ")}</>
  }

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['roleId', ['Role', getRole]],
    ['specialty', ['Specialty', getSpecialtyName]],
    ['insurance', ['Accepted Insurance', getAcceptedInsurance]],
    ['email', ['E-Mail Address', undefined]],
    ['firstName', ['First Name', undefined]],
    ['lastName', ['Last Name', undefined]],
    ['address1', ['Address1', undefined]],
    ['city', ['City', undefined]],
    ['state', ['State', undefined]],
    ['zipcode', ['Zip Code', undefined]],
  ]);

  const editableFields: IDataField[] = [
    { name: 'email', title: 'Email Address', editable: true },
    { name: 'organization', title: 'Organization', editable: true },
    { name: 'roleId', title: 'Role ID', editable: false },
    { name: 'firstName', title: 'First Name', editable: true },
    { name: 'lastName', title: 'Last Name', editable: true },
    { name: 'specialityId', title: 'Specialty ID', editable: true },
    { name: 'address1', title: 'Address 1', editable: true },
    { name: 'address2', title: 'Address 2', editable: true },
    { name: 'city', title: 'City', editable: true },
    { name: 'state', title: 'State', editable: true },
    { name: 'zipcode', title: 'Zip Code', editable: true },
    { name: 'avatar', title: 'Avatar', editable: true },
    { name: 'avatarUrl', title: 'Avatar', editable: false },
    { name: 'allowNewsletter', title: 'Allow Newsletter', editable: false },
    { name: 'emailConfirmed', title: 'Email Confirmed', editable: false },
    { name: 'degree', title: 'Degree', editable: true},
    { name: 'about', title: 'About', editable: true },
    { name: 'phoneNumber', title: 'Phone Number', editable: true }
  ];

  return (
    <>
      <div className='practitioner-layout-section-title'>Practitioner Manager</div>
      <ListContainer
        apiService={adminPractitionerApiService}
        emptyValues={emptyValues}
        onSelectedRows={onSelectedRows}
        buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
        hasMultiSelect={false}
        fieldList={fieldList}
        editableFields={editableFields}
      >
        {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
          return (
            <PractitionerForm editableFields={editableFields} isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick} />
          );
        }}
      </ListContainer>
    </>
  );
};

export { PractitionerList as AdminPractitionerList };
