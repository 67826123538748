export enum ProductCodeStatusEnum {
  CREATED                         = 1,
  MAILED                          = 2,
  PURCHASED                       = 3,
  ACTIVATED                       = 4,
  PREPARING_KIT                   = 5,
  SHIPPED                         = 6,
  RETURN_TRANSIT                  = 7,
  RECEIVED                        = 8,
  AWAITING_Q_VERIFICATION         = 9,
  COMPLETED                       = 10,
  ACTIVATING                      = 11,
}