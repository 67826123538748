import { Form, Formik } from 'formik';
import { ICreditCard } from 'interfaces/appointment/ICreditCard';
import React, { useContext } from 'react';
import { validationMap, Validator } from 'schemas';
import { AppointmentPaymentMethodEnum } from '_constants/AppointmentPaymentMethod';
import { BillingForm } from '../payment/BillingForm';
import { AppointmentContext } from '../scheduler/AppointmentContext';
import { CreditCardForm } from './CreditCardForm';

const CreditCardSection: React.FunctionComponent<{onSuccess?: ()=>void}> = ({onSuccess}) => {


  const initialValues = {
    id: 0,
    billingFirstName: '',
    billingLastName: '',
    billingAddress1: '',
    billingAddress2: '',
    billingCity: '',
    billingState: '',
    billingZipcode: '',
    billingCountry: '',
    billingPhoneNumber: '',
  }

  const validationSchema = validationMap.get(Validator.BILLING_ADDRESS);

  const onSubmit = (data: any, actions: any) => {
    alert('submitted');
  }

  const apptContext = useContext(AppointmentContext);

  const onCreditCardSubmit = (creditCard: ICreditCard) => {
    apptContext?.setCreditCard(creditCard);
    apptContext?.setPaymentMethod(AppointmentPaymentMethodEnum.PAYMENT_CC_VERIFY);
    if (onSuccess) {
      onSuccess();
    }
  }

  return (
    <div className="container">
      <div className="row">
        {/* <div className="col">
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ values, errors, touched, setFieldValue }) => {
              return (
                <Form>
                  <BillingForm setFieldValue={setFieldValue} errors={errors} touched={touched} />
                </Form>
              );
            }}
          </Formik>


        </div> */}

        <div className="col">
            <CreditCardForm onSubmit={onCreditCardSubmit}/>
        </div>
      </div>
    </div>
  );
}

export { CreditCardSection }