import React, { useState } from 'react';
import { IPractitioner } from 'interfaces';
import { PractitionerCard } from './PractitionerCard';
import { IPractitionerAppointments, ITimeSlot } from 'interfaces/appointment';
import { DayCard } from './DayCard';
import { PractitionerHelper } from './Helper';
import { FullSchedule } from './FullSchedule';

const AppointmentDeck: React.FunctionComponent<{ 
  appointments: Map<number, IPractitionerAppointments>, 
  onAppointmentClick: (timeslot: ITimeSlot, practitioner: IPractitioner) => void, 
  insuranceId?: number,
  onExpand?: (practitioner: IPractitioner) => void,
  onCollapse?: () => void,
}> = (
  { 
    appointments, 
    onAppointmentClick, 
    onExpand,
    insuranceId,
    onCollapse,
  }) => {

  const [practitioner, setPractitioner] = useState<IPractitioner | undefined>(undefined);

  const expandAppts = (practitioner: IPractitioner) => {
    setPractitioner(practitioner);
    if (onExpand) {
      onExpand(practitioner);
    }
  }

  const collapseAppts = () => {
    if (onCollapse) {
      onCollapse();
    }
    setPractitioner(undefined);
  }

  return (
    <div className="appointment-deck mt-2 d-flex flex-row flex-wrap justify-content-center justify-content-md-start justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
      {practitioner ? (
        <FullSchedule
          showPractitionerCard={true}
          practitioner={practitioner}
          appointments={appointments}
          returnButton={<div className="text-center text-md-start text-lg-start text-xl-start text-xxl-start"><button type="button" className="btn btn-link" onClick={()=>{collapseAppts()}}>&lt;&lt; Back to Doctors</button></div>}
          onAppointmentClick={onAppointmentClick} />
      ) : (
        <>
          {
            Array.from(appointments.values()).map((practitionerAppts: IPractitionerAppointments, key: number) =>
              <div className="appointment-deck-card flex-fill" key={`appt-deck-key-${key}`}>
                <PractitionerCard practitioner={practitionerAppts.practitioner} clickableTitle={true}>
                  {
                    Array.from(PractitionerHelper.groupTimeSlotsByDate(practitionerAppts.appointments).values()).slice(0, 1).map((dateTimeSlot, key: number) =>
                    <div key={`daycard-key-${key}`}>
                      <DayCard
                        dateTimeSlots={dateTimeSlot}
                        onAppointmentClick={onAppointmentClick}
                        practitioner={practitionerAppts.practitioner}
                        insuranceId={insuranceId}
                        expandAppts={expandAppts}
                      />
                    </div>
                    )
                  }
                </PractitionerCard>
              </div>
            )
          }
        </>
      )}
    </div>
  );
}




export { AppointmentDeck }