import React from "react";
import { BaseForm, FieldWithError } from "components/common";
import { IUser } from "interfaces";
import { adminInsuranceApiService as apiService } from "services";
import { Validator, validationMap } from 'schemas';

interface IAppProps {
  isEditing: boolean;
  onSuccess: (item: IUser) => void;
  initialValues: any;
  onCancelClick: () => void;
}

const AdminInsuranceForm: React.FunctionComponent<IAppProps> = ({ isEditing, onSuccess, initialValues, onCancelClick }: IAppProps) => {

  return (
    <BaseForm
      apiService={apiService}
      isEditing={isEditing}
      onSuccess={onSuccess}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      validationSchema={validationMap.get(Validator.ADMIN_INSURANCE_FORM)}
    >
      {({ errors, touched, setFieldValue, values }) => {
        return (
          <>
            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="name"
              placeholder="name"
              label="Insurance Name"
            />

            <FieldWithError
              errors={errors}
              touched={touched}
              fieldName="description"
              placeholder="description"
              label="Description"
            />

          </>
        )
      }}
    </BaseForm>
  );
};


export { AdminInsuranceForm }