import React, { useState } from "react";
import { IProspect } from "interfaces";
import { adminProspectApiService, adminLabelService } from "services";
import { ListContainer } from "components/common";
import { ProspectForm } from ".";
import { FormButtons, Url } from "_constants";

const ProspectList: React.FunctionComponent = () => {
  
  const emptyValues: IProspect = {
    email: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    phoneNumber: null,
    city: '',
    country: "USA", //@Todo: Add country field in form
    zipcode: '',
    state: '',
  };

  const [ status, setStatus ] = useState<string>('');

  const onSelectedRows = async (ids: string[]) => {
    try {
      await adminLabelService.generateProspects(ids.map(i => Number(i)), 1)
      setStatus('Labels Generated');
    } catch (error: any) {
      setStatus(error);
    }
  }

  const downloadLink = (row: any): any => {
    if (row['labelFilePath']) {
      return <a href={`${Url.ADMIN_DOWNLOAD}?path=${row['labelFilePath']}`}>Download</a>;
    } else {
      return <></>
    }
  }

  const fieldList: Map<string, [string, Function | undefined]> = new Map([
    ['id', ['ID', undefined]],
    ['email', ['E-Mail Address',undefined]],
    ['firstName', ['First Name',undefined]],
    ['lastName', ['Last Name',undefined]],
    ['address1', ['Address1',undefined]],
    ['city', ['City',undefined]],
    ['state', ['State',undefined]],
    ['zipcode', ['Zip Code', undefined]],
    ['labelFilePath', ['Label Download', downloadLink]],
  ]);

  return (
    <>
    <div className='practitioner-layout-section-title'>Prospects Manager</div>
    <ListContainer 
      apiService={adminProspectApiService} 
      emptyValues={emptyValues} 
      onSelectedRows={onSelectedRows}
      buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
      hasMultiSelect={true}
      multiSelectLabel="Generate Labels"
      fieldList={fieldList}
    >
      {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
        return (
          <ProspectForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick}/>
        );
      }}
    </ListContainer>
    { status && <div className="alert">{status}</div>}
    </>
  );
};

export { ProspectList };
