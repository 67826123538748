import React, { useContext } from 'react';
import { FieldWithError } from 'components/common';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { validationMap, Validator } from 'schemas';
import { ActivationSubForm } from './ActivationForm';
import { AppointmentContext } from './AppointmentContext';

const AppointmentReason: React.FunctionComponent<{ onSuccess?: () => void }> = ({ onSuccess }) => {

  const initialValues = {
    reasonId: null,
    reasonText: '',
    activationCode: '',
  }

  const apptContext = useContext(AppointmentContext);

  const reasonEnum = {
    ANNUAL_PREVENTIVE: "Annual Preventive Visit",
    AT_HOME_COLON_SCREENING: "At-Home Colon Cancer Screening"
  }

  const onSubmit = (data: any, actions: any) => {
    switch (data.reasonId) {
      case "1": {
        apptContext?.setReason(data.reasonId, reasonEnum.ANNUAL_PREVENTIVE);
        apptContext?.setActivationCode(null);
        break;
      }
      case "2": {
        apptContext?.setReason(data.reasonId, data.text);
        apptContext?.setActivationCode(null);
        break;
      }
      case "3": {
        apptContext?.setReason(data.reasonId, reasonEnum.AT_HOME_COLON_SCREENING);
        apptContext?.setActivationCode(data.activationCode);
        break;
      }
    }
    if (data.reasonId === "3") {
      // @TODO: check if code is valid


    } 

    if (onSuccess) {
      onSuccess();
    }
  }

  return <>
    {/* <div>What is the reason for this appointment</div> */}

    <div className="fw-bold small-text-1 my-3 alert alert-warning">IF YOU HAVE A MEDICAL EMERGENCY, PLEASE CALL 911 IMMEDIATELY</div>

    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationMap.get(Validator.APPOINTMENT_REASON_FORM)}>
      {({ status, errors, touched, setSubmitting, setStatus, values, setFieldValue }) => {
        return <Form>
          <div id="reasonId" className="small-text-3">Please select the reason for this appointment:</div>
          <div role="group" aria-labelledby="reasonId" className={"form-control" + (errors && errors["reasonId"] ? " is-invalid" : "")}>
            <div className="form-check" key="key-1" >
              <Field id="reasonId-1" className="form-check-input" type="radio" name="reasonId" value="1" />
              <label className="form-check-label" htmlFor="reasonId-1">
                {reasonEnum.ANNUAL_PREVENTIVE}
              </label>
            </div>

            <div className="form-check" key="key-2" >
              <Field id="reasonId-2" className="form-check-input" type="radio" name="reasonId" value="2" />
              <label className="form-check-label" htmlFor="reasonId-2">
                I have a medical complaint.
              </label>

              {values.reasonId === "2" &&
              <FieldWithError fieldName="reasonText" errors={errors} touched={touched} />
              }
              
            </div>

            <div className="form-check" key="key-3" >
              <Field id="reasonId-1" className="form-check-input" type="radio" name="reasonId" value="3" />
              <label className="form-check-label" htmlFor="reasonId-3">
              {reasonEnum.AT_HOME_COLON_SCREENING}
              </label>

              {values.reasonId === "3" &&
              <ActivationSubForm errors={errors} touched={touched} setFieldValue={setFieldValue} values={values}/>
              }
            </div>



          </div>

          <button type="submit" className="btn btn-primary my-3">Next - Insurance Info</button>

          {errors && <ErrorMessage name="reasonId" component="div" className="invalid-feedback" />}


        </Form>
      }}
    </Formik>

    {/* <div>
      <div>
        <input type="radio" name="reason"></input>
        <label htmlFor="annualvisit" className="mx-2">Annual Preventive Visit. Note that most insurance usually covers only one annual preventive visit per year. This visit may not be covered.</label>
      </div>
      <div>
        <input type="radio" name="reason"></input>
        <label htmlFor="annualvisit" className="mx-2">I have a medical complaint</label>
        <input className="mx-5" type="text" name="reason_text" placeholder='Enter brief reason' />
      </div>

    </div> */}

  </>
}

export { AppointmentReason }