import { IKeyValue } from 'interfaces';
import React from 'react';
import { DropDown } from 'components/common/form/DropDown';
import { SpecialtyEnumName } from '_constants/Specialty';

const SpecialtyDropDown: React.FunctionComponent<
{
  errors: any,
  touched: any,
  label: string,
  name: string,
}
> = ({errors, touched, label, name}) => {

const defaultItem: IKeyValue<number> = {
  key: 0, value: 'Select Speciality'
}

return <>
    <DropDown 
    errors={errors} 
    touched={touched} 
    label={label} 
    name={name}
    defaultItem={defaultItem}
    enumMap={SpecialtyEnumName} />
  </>
}

export { SpecialtyDropDown }