import React from 'react';

const VirtualVisitInfo: React.FunctionComponent<{isLarge?: boolean}> = ({isLarge = true}): JSX.Element => {
  return (
    <div className="d-flex flex-row flex-wrap mt-5 justify-content-evenly align-items-center virtual-visit">
      <div className="virtual-visit-item virtual-visit-item-left">
        <div className="m-3">
          <img className="rounded-3" src="/images/woman-telehealth-visit.png" width="100%" alt="ConvenaCare" />
        </div>
      </div>
      <div className="virtual-visit-item virtual-visit-item-right">
        <div className="d-flex justify-content-center">
          <div className="m-3">
            <span className={`${isLarge ? "large-text-1" : "medium-text-1"} dark-blue strong`}>Your Virtual Visit Includes:</span>
            <ul className={`${isLarge ? "medium-text-1" : "small-text-3"}`}>
              <li className="my-2">Medical History Review</li>
              <li className="my-2">Live Doctor Check-up via Telehealth</li>
              <li className="my-2">At-home tests and Lab/Imaging orders</li>
              <li className="my-2">Prescription Drug Refills</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export { VirtualVisitInfo }