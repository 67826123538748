import React, { useContext, useEffect } from "react";
import { IAccount, ICredentials } from "interfaces";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { AccountContext } from "components/common";
import { LoginForm } from "components/common";
import { Url } from "_constants";

const PatientLogin: React.FunctionComponent<{onLogin?: ()=>void}> = ({onLogin}) => {
  const navigate = useNavigate();
  const accountContext = useContext(AccountContext);

  useEffect(()=>{
    if (accountContext?.account) {
      // check if expired; may need to log in again
      navigate(Url.PATIENT_DASHBOARD);
    }
  },[])

  const initialFormState: ICredentials = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter your email address"),
    password: Yup.string().required("Password required"),
  });

  const onSubmit = async (data: any, actions: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    const redir = queryParams.get("redir");

    const { email, password } = data;
    const { setStatus } = actions;
    try {
      const account: IAccount | undefined = await accountContext?.login({email, password});
      if (!account) {
        throw Error('Could not get account');
      }
      if (onLogin) {
        onLogin();
      } else {
        navigate(Url.PATIENT_DASHBOARD, {replace: true});
      }

      // navigate(Url.PATIENT_ACTIVATION); //@TODO: this should redirect to scheduler if already selected a time slot

      //   await customerAuthenticationService.login({ email, password });
    //   const account: any = await customerAuthenticationService.getLocalAccount();
    //   if (!account) {
    //     throw Error("unexpected error");
    //   }
    //   userContext?.setAccount(account);

    //   // see if there's a redirect first and redirect there
    //   if (redir) {
    //     navigate(redir);
    //   } else {
    //     // if no redir, go to the Role's default page
    //     switch (account.roleId) {
    //       case Role.ADMIN:
    //         navigate(Url.ADMIN_HOME_PAGE);
    //         break;
    //       case Role.CUSTOMER:
    //         navigate(Url.PATIENT_DASHBOARD);
    //         break;
    //       case Role.TECH:
    //         navigate("/tech");
    //         break;
    //       default:
    //         navigate(Url.DEFAULT_HOME);
    //     }
    //   }
    } catch (error) {
      setStatus("Invalid Login");
    }
  };

  const onClickRegister = () => {
    navigate(Url.PATIENT_REGISTRATION);
  };

  return (
    <div className="container">
      <div className="row justify-content-center my-3">
        <div className="col-12 col-md-8 col-lg-6 col-xl-6 col-xxl-6">
        <LoginForm onClickRegister={onClickRegister} initialFormState={initialFormState} validationSchema={validationSchema} onSubmit={onSubmit}/>
        </div>
      </div>
    </div>
  );
};

export { PatientLogin };
