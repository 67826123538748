import React, { useContext, useState } from 'react';
import { IPractitioner, ITimeSlot } from 'interfaces';
import { useNavigate } from 'react-router';
import { Url } from '_constants';
import { Form, Formik } from 'formik';
import { patientService } from 'services';
import { AppointmentConfirmation } from 'components/patient/scheduler/AppointmentConfirmation';
import { AppointmentContext } from 'components/patient/scheduler/AppointmentContext';
import { PractitionerCard } from 'components/common/PractitionerListingPage/PractitionerCard';
import { PractitionerCardTall } from 'components/common/PractitionerListingPage/PractitionerCardTall';

const Completed: React.FunctionComponent<{ onSuccess: () => void }> = ({ onSuccess }) => {

  const navigate = useNavigate();

  const apptContext = useContext(AppointmentContext);

  const [done, setDone] = useState<boolean>(false);

  const onSubmit = async (data: any, actions: any) => {
    const { setStatus, setSubmitting } = actions;

    const appt = apptContext?.appointment;

    try {
      if (appt) {
        if (appt.isActivatingKit) {
          appt.reason = {
            id: 3,
            name: `Activating Kit ${appt.activationCode}`
          }
        }
        await patientService.saveAppointment(appt);
        setDone(true);
        onSuccess();
        apptContext.setIsConfirming(false);
        apptContext.setIsNewPatient(false);
      } else {
        throw Error('Unexpected error. Could not find appointment.')
      }
    } catch (error: any) {
      setSubmitting(false);
      setStatus((error as Error).message);
    }

  }

  const onPickNo = () => {
    apptContext?.setIsConfirming(true);
    navigate(`${Url.ROOT_DOCTOR_LIST}?insuranceId=${apptContext?.appointment.insuranceCompanyId}`);
  }

  return <>


    {done ? <>
      <div className="mt-4">
        <AppointmentConfirmation practitioner={apptContext?.appointment.practitioner || {} as IPractitioner} timeslot={apptContext?.appointment.timeslot || {} as ITimeSlot} />
      </div>
    </> : <>
      {/* {apptContext?.appointment.isConfirming === false && 
      <div className="medium-text-2 mt-3 py-3">Thank you for completing the registration process.</div>
    } */}
      <div className="d-flex flex-column justify-content-center">
        <div className="align-self-center">
          <div className="my-2 text-start medium-text-2 strong dark-blue mt-3 py-3">Please confirm the appointment you selected below:</div>
          <div>
            {apptContext?.appointment.practitioner && apptContext.appointment.timeslot &&
              <>
                <PractitionerCardTall about="" practitioner={apptContext?.appointment.practitioner}>
                  <div className="container g-0">
                    <div className="small-text-1 strong text-center">Appointment Details</div>
                    <div className="small-text-1 text-center">Date: {apptContext.appointment.timeslot.date}</div>
                    <div className="small-text-1 text-center">Time: {apptContext.appointment.timeslot.startTime} - {apptContext.appointment.timeslot.endTime}</div>
                  </div>
                </PractitionerCardTall>

                <Formik initialValues={{ activationCode: '' }} onSubmit={onSubmit}>
                  {({ errors, values, touched, setFieldValue, isSubmitting, setSubmitting, status, setStatus }) => {
                    return <Form>

                      <div className="my-2 text-start d-flex flex-column justify-content-center">
                        <div className="margin-center"><button type="submit" className="btn btn-primary my-1 btn-appt btn-appt-confirm">Confirm</button></div>
                        <div className="margin-center"><button type="button" className="btn btn-primary my-1 btn-appt btn-appt-change" onClick={onPickNo}>Change</button></div>
                      </div>

                      {status && <div className="alert alert-warning">{status}</div>}

                    </Form>
                  }}
                </Formik>
              </>
            }

            {!apptContext?.appointment.practitioner && !apptContext?.appointment.timeslot &&
              <div>Click here to go to the Doctor Listing to select your appointment</div>}
          </div>
        </div>
      </div>
    </>}



  </>
}

export { Completed }