
import { IAccount, IApiConfig, ICredentials } from "interfaces";
import axios from "axios";
import { config } from 'config';
import { AccountTypeEnum, StorageKey } from "_constants";

class AuthenticationService {

  constructor(
    protected apiConfig: IApiConfig,
    protected accountType: AccountTypeEnum
  ) { }

  async login(credentials: ICredentials): Promise<IAccount | undefined> {
    const url: string = `${this.apiConfig.baseUrl}/login`;
    try {
      const response: any = await axios.post(url, credentials);
      if (!response.data || response.data.error) {
        throw Error(`${response.data.error.message}`)
      }
      localStorage.setItem(this.apiConfig.storageTokenName, response.data.token);
      await this.setLocalAccount();
      return response.data;

    } catch (error: any) {
      throw Error(error.message);
    }
  }

  setToken(token: string) {
    //console.log('setting token to ', token);
    localStorage.setItem(this.apiConfig.storageTokenName, token);
  }

  getLocalAccount(): IAccount | null {
    const data = localStorage.getItem(this.apiConfig.storageAccountName);
    if (data) {
      const obj = JSON.parse(data);
      return obj.account;
    } else {
      return null;
    }
  }

  async setLocalAccount() {
    // get the account and save it
    try {
      const url: string = `${this.apiConfig.baseUrl}/account`;
      const response = await axios.get(url, await this.getAuthHeader());
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      const account: IAccount = response.data;
      account.accountType = this.accountType;
      localStorage.setItem(this.apiConfig.storageAccountName, JSON.stringify(account));
    } catch (err) {
      console.error(err);
      throw Error(`error setting account: ${err}`);
    }
  }

  logout = async () => {
    [
      this.apiConfig.storageTokenName,
      this.apiConfig.storageAccountName
    ].forEach((i: string) => localStorage.removeItem(i));
  }

  getToken(): string {
    const token = localStorage.getItem(this.apiConfig.storageTokenName) || '';
    return token;
  }

  async getAuthHeader(redir?: string): Promise<Object> {

    // check if token is expired
    if (await this.isTokenExpired()) {
      this.logout();
      let redirPath = this.apiConfig.loginUrl;
      if (redir) {
        redirPath = `${redirPath}?redir={redir}`
      }
      window.location.href = redirPath;
    }
    const header = {
      headers: {
        Authorization: 'Bearer ' + this.getToken(),
        token: this.getToken()
      }
    };
    return header;
  }


  async isTokenExpired(): Promise<boolean> {
    const url: string = `${this.apiConfig.baseUrl}/account`;
    let result: boolean = false;
    const header = {
      headers: {
        Authorization: 'Bearer ' + this.getToken(),
        token: this.getToken()
      }
    };
    try {
      const response = await axios.get(url, header);
      if (response.data && response.data.error) {
        throw Error(response.data.error.message);
      }
      if (response.data.account) {
        result = false;
      }
    } catch (error: any) {
      if (error.message === 'Failed to authenticate token.') {
        result = true;
      } else {
        console.error(error);
        throw Error(error);
      }
    } finally {
      return result;
    }
  }
}


const basePath = `${config.apiDomain}${config.apiBasePath}`;

const practitionerAuthenticationService = new AuthenticationService({
  baseUrl: `${basePath}/practitioner`,
  storageAccountName: StorageKey.PRACTITIONER_ACCT,
  storageTokenName: StorageKey.PRACTITIONER_TOKEN,
  loginUrl: `${basePath}/practitioner`,
}, AccountTypeEnum.PRACTITIONER);

const adminAuthenticationService = new AuthenticationService({
  baseUrl: `${basePath}/admin`,
  storageAccountName: StorageKey.ADMIN_ACCT,
  storageTokenName: StorageKey.ADMIN_TOKEN,
  loginUrl: `${basePath}/admin`,
}, AccountTypeEnum.ADMIN);

const patientAuthenticationService = new AuthenticationService({
  baseUrl: `${basePath}/user`,
  storageAccountName: StorageKey.PATIENT_ACCT,
  storageTokenName: StorageKey.PATIENT_TOKEN,
  loginUrl: `${basePath}/patient/login`,
}, AccountTypeEnum.PATIENT);


export {
  AuthenticationService,
  adminAuthenticationService,
  practitionerAuthenticationService,
  patientAuthenticationService
}
