import React, { useContext, useEffect, useState } from 'react';
import { InsuranceCompanyEnum, insuranceCompanyNames } from '_constants/InsuranceCompanyEnum';
import { MessengerContext } from 'components/common/messenger';
import { AppointmentContext } from 'components/patient/scheduler/AppointmentContext';

const InsuranceFilterSingle: React.FunctionComponent<{ 
  onClickSearch: (insuranceId: string) => void,
  disabled? : boolean,
 }> = ({ onClickSearch, disabled}) => {

  const msgContext = useContext(MessengerContext);
  const apptContext = useContext(AppointmentContext);
  const [selected, setSelected] = useState<string|undefined|null>();


  useEffect(()=>{
    setSelected((apptContext && apptContext.appointment.insuranceCompanyId?.toString() ? apptContext.appointment.insuranceCompanyId?.toString() : ''));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === InsuranceCompanyEnum.OTHER.toString()) {
      msgContext.setMessage({
        title: 'Insurance Carrier Selection', body: <>
          Unfortunately, we currently only work with the listed insurance carriers. If you would like to continue without insurance coverage, please select 'Self Pay' option from the dropdown.
        </>
      })
    }
    setSelected(e.target.value);
    onClickSearch(e.target.value || '');
  }
  return <>
    <div className="">
      <div className="small-text-2 orange strong">Step 1</div>
      <div className="medium-text-2 dark-blue strong mt-1">Select your Medical Insurance Carrier</div>
      <div className="d-flex-col justify-content-start">
        <div className="mt-2 align-self-center" >
          <select name="insurance" disabled={disabled} onChange={onSelect} className="select-insurance-carrier">
            <option value="-1">-- Select Insurance Carrier -- </option>
            {Array.from(insuranceCompanyNames.entries()).map(([key, value]) =>
            {
             // eslint-disable-next-line eqeqeq
             return <option value={key.toString()} selected={selected == key.toString()} key={`ins_key_${key}`} >{value}</option>
            }
            )}
          </select>
        </div>
        {/* <div className="mt-2"><button type="button" className="btn btn-primary" onClick={() => onClickSearch(selected || '')}>Search</button></div> */}
      </div>
    </div>
  </>
}

export { InsuranceFilterSingle }