import ReactDOM from 'react-dom/client';
import App from './App';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PractitionerSchedulerList, HomePage, NotFoundPage, PatientLayout, PractitionerLoginPage, AdminLogin, AdminDashboard, ProductList, ProspectList, UserList, CodeList, CodeResult, EnvelopeQR, AdminDownload, HomePage2, HomePage3 } from './components';
import { AdminPractitionerList } from 'components/admin/_practitioners';
import { PractitionerLayout } from 'components/practitioner/layout/Layout';
import { PatientSchedulerHome } from './components/patient/scheduler';
import { ForgotPassword, PatientDashboard, PatientLogin, ResetPassword } from 'components/patient'
import { AboutUs, Contact, PractitionerListing, PrivacyPolicy } from './components/common';
import { Terms } from './components/common/Terms';
import { AppointmentWizard } from 'components/patient/registration/AppointmentWizard';
import { PractitionerDashboard } from 'components/practitioner/Dashboard';
import { AdminLayout } from 'components/admin/layout/Layout';
import { AdminProfile } from 'components/admin/account/Profile';
import { ActivateWithHash } from 'components/common/ActivateWithHash';
import { PractitionerCalendar } from 'components/practitioner/scheduler/PractitionerCalendar';
import { Physicians } from 'components/common/PhysicianPage';
import { PractitionerPatientList } from 'components/practitioner/patient';
import { PractitionerProfile } from 'components/practitioner/account/Profile';
import { ProtectedRoute } from 'components/common/utils/ProtectedRoute';
import { AdminInsuranceList } from 'components/admin/_insurance';
import { PractitionerRolesEnum } from '_constants/PractitionerRolesEnum';
import { ProtectedAdminRoute } from 'components/common/utils/ProtectedAdminRoute';
import { ReferencePage } from 'components/practitioner/reference/ReferencePage';
import { PractitionerResetPassword } from 'components/practitioner/account/ResetPassword';
import { PractitionerForgotPassword } from 'components/practitioner/account/ForgotPassword';
import { AdminForgotPassword } from 'components/admin/account/ForgotPassword';
import { AdminResetPassword } from 'components/admin/account/ResetPassword';
import { PricingPage } from 'components/common/PricingPage';
import { ConfirmedPage } from 'components/patient/registration/components/ConfirmedPage';
import { FreeKitPage } from 'components/common/marketing/mailed/FreeKitPage';
import { FreeKitApptPage } from 'components/common/marketing/mailed/FreeKitAppt';
import { VerifyPhonePage } from 'components/common/marketing/mailed/VerifyPhonePage';
import { MailingAddressPage } from 'components/common/marketing/mailed/MailingAddressPage';
import { AdminReportUserLogList } from 'components/admin/_reports/UserLog/List';
import { FreeKitNoFormPage } from 'components/common/marketing/mailed/FreeKitNoFormPage';
import { LandingPage } from 'components/common/LandingPage';
import { FreeKitNoFormPage2 } from 'components/common/marketing/mailed/FreeKitNoFormPage2';
import { FreeKitNoFormPage3 }  from 'components/common/marketing/mailed/FreeKitNoFormPage3';
import { AdminPractitionerCalendarPage } from 'components/admin/_scheduler/AdminPractitionerCalendarPage';
import { AdminPractitionerSchedulerList } from 'components/admin/_appointments/AdminPractitionerSchedulerList';
import { AdminPatientList } from 'components/admin/_patient/AdminPatientList';
import { PractitionerReportUserLogList } from 'components/practitioner/_reports/UserLog/List';
import { SubscribeWithPhonePage } from 'components/common/marketing/mailed/SubscribeWithPhonePage';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} >
        <Route index element={<HomePage />} />
        <Route path="home-page-doctors" element={<LandingPage><HomePage showDoctorCarousel={true} /></LandingPage>} />
        <Route path="home-page-doctors-2" element={<LandingPage><HomePage2 /></LandingPage>} />
        <Route path="home-subscription" element={<LandingPage><HomePage3 /></LandingPage>} />
        <Route path="our-physicians" element={<Physicians />} />
        <Route path="contact" element={<Contact />} />
        <Route path="terms-and-conditions" element={<Terms />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="make-appointment" element={<PractitionerListing />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="pricing" element={<PricingPage />} />
        <Route path="reset-password/:authID" element={<ResetPassword />} />

        <Route path="subscribe" element={<SubscribeWithPhonePage />} />


        <Route path="colon-cancer-test-kit/free" element={<LandingPage><FreeKitPage /></LandingPage>} />
        <Route path="colon-cancer-test-kit/free-with-appointment" element={<LandingPage><FreeKitApptPage /></LandingPage>} />
        <Route path="colon-cancer-test-kit/verify-phone" element={<VerifyPhonePage />} />
        <Route path="colon-cancer-test-kit/mailing-address" element={<MailingAddressPage />} />
        <Route path="colon-cancer-test-kit/free-kit" element={<LandingPage><FreeKitNoFormPage /></LandingPage>} />
        <Route path="colon-cancer-test-kit/free-kit-2" element={<LandingPage><FreeKitNoFormPage2 /></LandingPage>} />
        <Route path="colon-cancer-test-kit/free-kit-3" element={<LandingPage><FreeKitNoFormPage3 /></LandingPage>} />

        <Route path="code/activate/:codeHash" element={<ActivateWithHash />} />

        <Route path="patient" element={<PatientLayout />} >
          <Route index element={<PatientDashboard />} />
          {/* <ProtectedRoute path="profile" element={<PatientProfile />} accountType={[AccountTypeEnum.PATIENT]} /> */}
          <Route path="dashboard" element={<PatientDashboard />} />
          <Route path="login" element={<PatientLogin />} />
          <Route path="appointment" element={<AppointmentWizard />} />
          <Route path="appointment-confirmed" element={<ConfirmedPage />} />
          <Route path="scheduler" element={<PatientSchedulerHome />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route path="practitioner" element={<PractitionerLayout />} >
        {/* <Route index element={<ProtectedRoute roles={[PractitionerRolesEnum.DOCTOR_ROLE, PractitionerRolesEnum.NURSE_ROLE]}><PractitionerDashboard /></ProtectedRoute>} /> */}
        <Route index element={<PractitionerLoginPage />} />
        <Route path="forgot-password" element={<PractitionerForgotPassword />} />        
        <Route path="reset-password/:authID" element={<PractitionerResetPassword />} />
        <Route path="availability-calendar" element={<ProtectedRoute roles={[PractitionerRolesEnum.DOCTOR_ROLE]}><PractitionerCalendar /></ProtectedRoute>} />
        <Route path="appointment-manager" element={<ProtectedRoute roles={[PractitionerRolesEnum.DOCTOR_ROLE, PractitionerRolesEnum.NURSE_ROLE]}><PractitionerSchedulerList /></ProtectedRoute>} />
        <Route path="patient-manager" element={<ProtectedRoute roles={[PractitionerRolesEnum.DOCTOR_ROLE, PractitionerRolesEnum.NURSE_ROLE]}><PractitionerPatientList /></ProtectedRoute>} />
        <Route path="profile" element={<ProtectedRoute roles={[PractitionerRolesEnum.DOCTOR_ROLE, PractitionerRolesEnum.NURSE_ROLE]}><PractitionerProfile /></ProtectedRoute>} />
        <Route path="dashboard" element={<ProtectedRoute roles={[PractitionerRolesEnum.DOCTOR_ROLE, PractitionerRolesEnum.NURSE_ROLE]}><PractitionerDashboard /></ProtectedRoute>} />
        <Route path="reference" element={<ProtectedRoute roles={[PractitionerRolesEnum.DOCTOR_ROLE, PractitionerRolesEnum.NURSE_ROLE]}><ReferencePage /></ProtectedRoute>} />
        <Route path="report/user-logs" element={<ProtectedRoute roles={[PractitionerRolesEnum.DOCTOR_ROLE, PractitionerRolesEnum.NURSE_ROLE]}><PractitionerReportUserLogList /></ProtectedRoute>} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route path="admin" element={<AdminLayout />} >
        {/* <Route index element={<AdminHomePage />} /> */}
        <Route index element={<AdminLogin />} />
        {/* <Route path="login" element={<AdminLogin />} /> */}
        <Route path="forgot-password" element={<AdminForgotPassword />} />        
        <Route path="reset-password/:authID" element={<AdminResetPassword />} />
        <Route path="dashboard" element={<ProtectedAdminRoute><AdminDashboard /></ProtectedAdminRoute>} />
        <Route path="product" element={<ProtectedAdminRoute><ProductList /></ProtectedAdminRoute>} />
        <Route path="practitioner" element={<ProtectedAdminRoute><AdminPractitionerList /></ProtectedAdminRoute>} />
        <Route path="prospect" element={<ProtectedAdminRoute><ProspectList /></ProtectedAdminRoute>} />
        <Route path="user" element={<ProtectedAdminRoute><AdminPatientList /></ProtectedAdminRoute>} />
        <Route path="codes" element={<ProtectedAdminRoute><CodeList /></ProtectedAdminRoute>} />
        <Route path="code" element={<ProtectedAdminRoute><CodeResult /></ProtectedAdminRoute>} />
        <Route path="availability-calendar" element={<ProtectedAdminRoute><AdminPractitionerCalendarPage /></ProtectedAdminRoute>} />
        <Route path="appointments" element={<ProtectedAdminRoute><AdminPractitionerSchedulerList /></ProtectedAdminRoute>} />
        <Route path="envelope/code/:envelopeID" element={<ProtectedAdminRoute><EnvelopeQR /></ProtectedAdminRoute>} />
        <Route path="download" element={<ProtectedAdminRoute><AdminDownload /></ProtectedAdminRoute>} />
        <Route path="insurance" element={<ProtectedAdminRoute><AdminInsuranceList /></ProtectedAdminRoute>} />
        <Route path="profile" element={<ProtectedAdminRoute><AdminProfile /></ProtectedAdminRoute>} />
        <Route path="report/user-logs" element={<ProtectedAdminRoute><AdminReportUserLogList /></ProtectedAdminRoute>} />

        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />

    </Routes>
  </BrowserRouter>
);

