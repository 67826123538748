import { useSearchParams } from "react-router-dom";
import { StorageKey } from "_constants";

const useTapNative = (): {transactionId: string | null, getConversionPixelUrl: (adId: string) => string  } => {
  const [ searchParams ] = useSearchParams();
  const transactionId = searchParams.get('tntxid');

  if (transactionId) {
    localStorage.setItem(StorageKey.TAPNATIVE_TXID, transactionId);
  } 

  const getConversionPixelUrl = (adId: string): string  => {
    const localTxId = localStorage.getItem(StorageKey.TAPNATIVE_TXID);
    if (!localTxId) {
      return '/images/pixel.gif';
    }
    const url = `https://r.tapnative.com/adx-dir-d/action?nid=1&adv=${adId}&adx_txn_id=${localTxId}`;
    // clear conv pixel
    localStorage.removeItem(StorageKey.TAPNATIVE_TXID);
    return url;
  }

  return { transactionId, getConversionPixelUrl }

}

export { useTapNative }