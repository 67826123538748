import * as Icon from 'react-bootstrap-icons';

function Contact() {
  return (
    <div className="container contact">
      <div className="mt-3">
        <div className="medium-text-2 fw-bold dark-blue">Contact Us</div>

        <div className="d-flex my-3 align-items-stretch flex-wrap">
          <div className="contact-section text-center">
            <Icon.Envelope size="2rem" className="my-2" />
            <div className="fw-bold">E-Mail Address</div>
            <div>contact@convenacare.com</div>
          </div>

          <div className="contact-section text-center">
            <Icon.Telephone size="2rem" className="my-2" />
            <div className="fw-bold">Phone Number</div>
            <div>(908) 895 6969</div>
          </div>

          <div className="contact-section text-center">
            <Icon.Mailbox size="2rem" className="my-2" />
            <div className="fw-bold">Mailing Address</div>
            <div>
              92 East Main Street,<br />
              Suite 405 <br />
              Somerville, NJ, 08844
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Contact }