import { Envelope } from "components/HomePage";

export const JumbotronRowGreen = (): JSX.Element => {
  return (
    <div className="row">

    {/* left side */}
    <div className="col-12 col-md-6 my-1 my-md-3">
      <div className="mt-1 mb-4 text-center text-md-start">
        <div role="button" className="d-inline-block recommended-annually-green p-1 px-4 mt-2 py-md-2 px-md-3 mx-3">
          Recommended Annually
        </div>
      </div>
      <div className="fw-bold large-text-1 d-none d-md-block">
        <span className="dark-blue">At-home</span> <span className="light-blue">Colon Cancer</span><br />
        Screening Test
      </div>

      <div className="fw-bold large-text-1 d-block d-md-none text-center">
        <span className="dark-blue">At-home</span> <span className="light-blue">Colon Cancer</span> Screening Test
      </div>


      <div className="dark-gray fw-bold medium-text-2 my-4 my-md-5 d-none d-md-block">
        <span className="text-green">NO CHARGE</span> and <span className="text-green">NO CO-PAY</span> with<br />qualifying health insurance
      </div>

      <div className="dark-gray fw-bold medium-text-2 my-4 my-md-5 text-center d-block d-md-none">
        <span className="text-green">NO CHARGE</span> and <span className="text-green">NO CO-PAY</span> with qualifying health insurance
      </div>

    </div>

    {/* right side */}
    <div className="col-12 col-md-6">
      <Envelope />
    </div>

  </div>    
  );
}