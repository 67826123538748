export enum StorageKey {
  ADMIN_ACCT = 'aacct',
  ADMIN_TOKEN = 'atoken',

  PATIENT_ACCT = 'pacct',
  PATIENT_TOKEN = 'ptoken',

  PRACTITIONER_ACCT = 'dacct',
  PRACTITIONER_TOKEN = 'dtoken',

  APPT = 'appt',

  TAPNATIVE_TXID = 'tapTxId',

  LANDING_PAGE = 'lp',
  LANDING_PAGE_EXPIRES = 'lpexp',

  UNIQUE_USER_ID = 'qd',

}