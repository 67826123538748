import React, { useReducer } from 'react';
import { ITimeSlot, IPractitioner } from 'interfaces';
import { AppointmentContext } from './AppointmentContext';
import { IAppointmentContext } from 'interfaces/appointment/IAppointmentContext';
import { IPractitionerAppointment } from 'interfaces/appointment/IPractitionerAppointment';
import { AppointmentPaymentMethodEnum } from '_constants/AppointmentPaymentMethod';
import { ICreditCard } from 'interfaces/appointment/ICreditCard';

enum ApptAction {
  SET_APPOINTMENT = 1,
  SET_REASON = 2,
  SET_PAYMENT = 3,
  SET_ACTIVATION_CODE = 4,
  SET_INSURANCE = 5,
  SET_CREDIT_CARD = 6,
  SET_ACTIVATING_KIT = 7,
  SET_IS_NEW_PATIENT = 8,
  SET_IS_CONFIRMING = 9,
}

interface IAppointmentState {
  appointment: IPractitionerAppointment | null,
}

interface IAccountAction {
  type: ApptAction,
  payload?: any,
}

const apptReducer = (state: IAppointmentState, action: IAccountAction): any => {
  switch(action.type) {
    case ApptAction.SET_APPOINTMENT: {
      return {
        appointment: {
          ...state.appointment,
          practitioner: action.payload.practitioner,
          timeslot: action.payload.timeslot,
        }
      };
    }

    case ApptAction.SET_PAYMENT: {
      const result: {appointment: IPractitionerAppointment} = {
        appointment: {
          ...state.appointment,
          paymentMethod: action.payload.paymentMethod,
        }
      };
      return result;
    }

    case ApptAction.SET_REASON: {
      const result: {appointment: IPractitionerAppointment} = {
        appointment: {
          ...state.appointment,
          reason: action.payload.reason,
        }
      };
      return result;
    }

    case ApptAction.SET_ACTIVATION_CODE: {
      const result: {appointment: IPractitionerAppointment} = {
        appointment: {
          ...state.appointment,
          activationCode: action.payload.activationCode,
        }
      };
      return result;
    }    

    case ApptAction.SET_INSURANCE: {
      const result: {appointment: IPractitionerAppointment} = {
        appointment: {
          ...state.appointment,
          insuranceCompanyId: action.payload.insuranceCompanyId,
        }
      };
      return result;
    }    

    case ApptAction.SET_CREDIT_CARD: {
      const result: {appointment: IPractitionerAppointment} = {
        appointment: {
          ...state.appointment,
          creditCard: action.payload.creditCard,
        }
      };
      return result;
    }    

    case ApptAction.SET_ACTIVATING_KIT: {
      const result: {appointment: IPractitionerAppointment} = {
        appointment: {
          ...state.appointment,
          isActivatingKit: action.payload.isActivatingKit,
        }
      };
      return result;
    }    

    case ApptAction.SET_IS_NEW_PATIENT: {
      const result: {appointment: IPractitionerAppointment} = {
        appointment: {
          ...state.appointment,
          isNewPatient: action.payload.isNewPatient,
        }
      };
      return result;
    } 

    case ApptAction.SET_IS_CONFIRMING: {
      const result: {appointment: IPractitionerAppointment} = {
        appointment: {
          ...state.appointment,
          isConfirming: action.payload.isConfirming,
        }
      };
      return result;
    } 

    default:
      throw new Error(`Invalid Action Type: ${action.type}`);
  }

}

const AppointmentProvider: React.FunctionComponent<{children: any}> = ({children}) => {

  const defaultApptState: IAppointmentState = {
    appointment: {
      activationCode: null,
      practitioner: null,
      reason: null,
      paymentMethod: null,
      timeslot: null,
      insuranceCompanyId: null,
      creditCard: null,
      isActivatingKit: false,
      isNewPatient: true,
      isConfirming: false,
    },

  }
  
  const [ apptState, dispatchAccountAction ] = useReducer( apptReducer, defaultApptState);

  const setAppointmentHandler = (timeslot: ITimeSlot, practitioner: IPractitioner) => {
      dispatchAccountAction({type: ApptAction.SET_APPOINTMENT, payload: {practitioner, timeslot}});
  }

  const setPaymentMethodHandler = (paymentMethod: AppointmentPaymentMethodEnum) => {
    dispatchAccountAction({type: ApptAction.SET_PAYMENT, payload: {paymentMethod}});
  }

  const setReasonHandler = (reasonId: number, reasonText: string) => {
    dispatchAccountAction({type: ApptAction.SET_REASON, payload: {reason: {
      id: reasonId,
      name: reasonText,
    }}});
  }

  const setActivationCodeHandler = (activationCode: string | null) => {
    dispatchAccountAction({type: ApptAction.SET_ACTIVATION_CODE, payload: {activationCode}});
  }

  const setInsuranceHandler = (insuranceCompanyId: number) => {
    dispatchAccountAction({type: ApptAction.SET_INSURANCE, payload: {insuranceCompanyId}});
  }

  const setCreditCardHandler = (creditCard: ICreditCard) => {
    dispatchAccountAction({type: ApptAction.SET_CREDIT_CARD, payload: {creditCard}});
  }

  const setIsActivatingKitHandler = (isActivatingKit: boolean) => {
    dispatchAccountAction({type: ApptAction.SET_ACTIVATING_KIT, payload: {isActivatingKit}});
  }

  const setIsNewPatientHandler = (isNewPatient: boolean) => {
    dispatchAccountAction({type: ApptAction.SET_IS_NEW_PATIENT, payload: {isNewPatient}});
  }  

  const setIsConfirmingHandler = (isConfirming: boolean) => {
    dispatchAccountAction({type: ApptAction.SET_IS_CONFIRMING, payload: {isConfirming}});
  }  

  const apptContext: IAppointmentContext = {
    appointment: apptState.appointment,
    setAppointment: setAppointmentHandler,
    setPaymentMethod: setPaymentMethodHandler,
    setReason: setReasonHandler,
    setActivationCode: setActivationCodeHandler, 
    setInsurance: setInsuranceHandler,
    setCreditCard: setCreditCardHandler,
    setIsActivatingKit: setIsActivatingKitHandler,
    setIsNewPatient: setIsNewPatientHandler,
    setIsConfirming: setIsConfirmingHandler,
  }

  return (
    <AppointmentContext.Provider value={apptContext}>
      {children}
    </AppointmentContext.Provider>
  );

}

export { AppointmentProvider }

