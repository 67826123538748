import React from 'react';
import { ForgotPassword } from 'components/common/ForgotPassword';
import { adminAccountService } from 'services';

const AdminForgotPassword: React.FunctionComponent = () => {

  return(
    <ForgotPassword accountService={adminAccountService} />
  );
}

export { AdminForgotPassword }