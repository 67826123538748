import React from "react";
import { adminInsuranceApiService } from "services";
import { ListContainer } from "components/common";
import { AdminInsuranceForm } from ".";
import { FormButtons } from "_constants";

const AdminInsuranceList: React.FunctionComponent = () => {
  
  const emptyValues: any = {
    name: '',
    description: '',
  };


  return (
    <>
    <div className='practitioner-layout-section-title'>Insurance Manager</div>
    <ListContainer 
      apiService={adminInsuranceApiService} 
      emptyValues={emptyValues} 
      buttons={[FormButtons.EDIT, FormButtons.ADD, FormButtons.DELETE]}
      hasMultiSelect={false}
      title="Manage Insurance Companies"
    >
      {({ isEditing, onSuccess, initialValues, onCancelClick }) => {
        return (
          <AdminInsuranceForm isEditing={isEditing} onSuccess={onSuccess} initialValues={initialValues} onCancelClick={onCancelClick}/>
        );
      }}
    </ListContainer>
    </>

  );
};

export { AdminInsuranceList };
