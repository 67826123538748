import React, { useContext } from 'react';
import { Url } from '_constants';
import { AccessDenied } from '../AccessDenied';
import { AccountContext } from '../AccountContext';

interface IProps {
  children: JSX.Element,
}
const ProtectedAdminRoute: React.FunctionComponent<IProps> = ({children}) => {

  const acctContext = useContext(AccountContext);
  const account = acctContext?.account;
  
  // @TODO: when Admin Account supports Roles, include them for validation. 
  // For now, logged in as admin is enough

  if (account) {
    return children;
  }

  return <AccessDenied account={account} loginUrl={Url.ADMIN_LOGIN} />
}

export { ProtectedAdminRoute }